import { types } from "../types/types";

const initialState = {
    acuerdo:{
        id: 0,
        idTipoAcuerdo: 0,
        idSubTipoAcuerdo: 0,
        idPlantilla: 0,
        idDemandaInicial: 0,
        idExpediente: 0,        
        idPromocion: 0, 
        idAmparo: 0, 
        idExhortoRecibido: 0, 
        idExhortoLibrado: 0, 
        idIrregular: 0, 
        demandaDigitalizada: '',
        exhortoDigitalizado: '',
        amparoDigitalizado: '',
        acuerdo: ''
    },
    prestaciones: [],
    demanda: {},
    expediente: {},
    exhortoLibrado: {},
    exhortoRecibido: {},
    irregular: {},
    amparo: {},
    promocion: {},
    expedientilloSuspension: {},
    promocionFolio: {},
    notificaciones: [],
    partes: [],
    checkCalificarTramite: false
}

export const acuerdoReducer = ( state = initialState, action) => {

    switch (action.type) {
        case types.actualizarDetalleAcuerdo:
            return {    
                ...state,          
                ...action.payload
            } 
        case types.actualizarPrestacionesAcuerdo:
            return {               
                ...state,          
                prestaciones: action.payload 
            }         
        case types.actualizarNotificacionesAcuerdo:
            return {               
                ...state,          
                notificaciones: action.payload 
            }   
        case types.actualizarPartesAcuerdo:
            return {               
                ...state,          
                partes: action.payload 
            }   
        case types.actualizarCheckCalificar:
            return {               
                ...state,          
                checkCalificarTramite: true 
            }  
        case types.actualizarExhortoRecibidoAcuerdo:
            return {               
                ...state,          
                exhortoRecibido: action.payload 
            }  
        case types.actualizarExhortoLibradoAcuerdo:
            return {               
                ...state,          
                exhortoLibrado: action.payload 
            }               
        case types.actualizarAmparoAcuerdo:
            return {               
                ...state,          
                amparo: action.payload 
            }     
        case types.actualizarExpedienteAcuerdo:
            return {               
                ...state,          
                expediente: action.payload 
            }     
        case types.actualizarIrregularAcuerdo:
            return {               
                ...state,          
                irregular: action.payload 
            }  
        case types.eliminarAcuerdoDetalle:
            return { ...initialState }    
        default:
            return state;
    }
}