import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, LinearProgress, List, ListItem, Paper, Radio, RadioGroup, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { ModalBuscarProyectoSentencia } from './ModalBuscarProyectoSentencia';
import { ModalBuscarCierreInstruccion } from './ModalBuscarCierreInstruccion';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';
import { GenerarSentencia } from '../../actions/sentencias/sentenciaActions';

export const ModalNuevaSentencia = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const {juzgado, tribunal} = useSelector(state => state.auth);

    const [openModalBuscarProyecto, setOpenModalBuscarProyecto] = useState(false);
    const [openModalBuscarCierre, setOpenModalBuscarCierre] = useState(false);

    const [errors, setErrors] = useState({ });

    const [expediente, setExpediente] = useState( {} );
    const [numeroExpediente, setNumeroExpediente] = useState('');

    const [proyectoSentencia, setProyectoSentencia] = useState( { idProyectoSentencia: 0, usuarioElaboro: '', noSeguimiento: '', fecha: '' } );
    const [cierreInstruccion, setCierreInstruccion] = useState( { idCierreInstruccion: 0, numeroCierreInstruccion: '', fechaCierre: '' } );

    const { idProyectoSentencia, usuarioElaboro, noSeguimiento, fecha } = proyectoSentencia;

    const [loading, setLoading] = useState( false );
    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const [tipoSentencia, setTipoSentencia] = useState( 1 );
    const [numeroReferencia, setNumeroReferencia] = useState('');

    const handleOpenModalBuscarProyecto = () => {       
        setOpenModalBuscarProyecto(true);
    };

    const handleCloseModalBuscarProyecto = () => {
        setOpenModalBuscarProyecto(false);
    };

    const CerrarModal = () => {
        handleCloseModal();
        setExpediente( { } );
        setProyectoSentencia( { idProyectoSentencia: 0, usuarioElaboro: '', noSeguimiento: '', fecha: '' } );
        setCierreInstruccion( { idCierreInstruccion: 0, numeroCierreInstruccion: '', fechaCierre: '' } );
        setNumeroReferencia( '' );
    }

    const SeleccionarProyectoSentencia = ( proyecto ) => {
        setProyectoSentencia( proyecto );
    }

    const SeleccionarCierreInstruccion = ( cierre ) => {
        setCierreInstruccion( cierre );
    }

    const handleGenerarSentencia = async () => {

        const params = {
            idExpediente: expediente?.success ? expediente?.idExpediente : 0,
            idProyectoSentencia: idProyectoSentencia,
            idCierreInstruccion: cierreInstruccion?.idCierreInstruccion,
            idTipoSentencia: tipoSentencia,
            numeroReferencia: numeroReferencia,
        };

        setLoadingGuardar(true);

        await GenerarSentencia( params ).then( response => {

            if( response ){

                if( response.status === 200){
                   
                    if(response.data){
                        
                        setErrors( { } );
                        setNumeroExpediente('');
                        
                       const { idSentencia } = response.data;

                        setTimeout(() => {

                            setLoadingGuardar(false);

                            history.push("/Sentencia/ElaborarSentencia/" + idSentencia);

                        }, 1200);  
                    }

                } 
                else {
                    
                    setTimeout(() => {
                        setErrors( { } );
                        setNumeroExpediente('');
                       
                        setLoadingGuardar(false);
                        
                        const { message } = response.data;

                        let errores = {};  
                            errores.error = message;
                            errores.type = 'error';
                        
                        setErrors( errores );
                    }, 1200);  

                }
            }
        });
        
    }

    const validateFiels = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === 0 || numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const handleClicBuscarExpediente = async () => {

        setLoading( true );

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }
       
        await BuscarExpediente( params ).then( response => {

                setLoading( true );

                if( response ){
                    
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExpediente(response.data);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );

                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
        });        
      
    }

    return (
        <>

            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
               
                <DialogTitle>Nueva sentencia</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={4} >

                        {
                            tribunal
                            &&
                                <Grid item md={5} xs={12} > 
                                    
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                            placeholder={"Escribe el número de sentencia (1/" + (new Date().getFullYear()) + ")"} 
                                            name="numeroReferencia"
                                            value={ numeroReferencia } 
                                            onChange={ ( e ) => { setNumeroReferencia( e.target.value ) }}
                                            autoComplete="off"
                                        />                                
                                    </Paper>
                                    {   
                                        errors.numeroReferencia && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroReferencia  } </Alert> )
                                    }
                                </Grid>
                        }

                        <Grid item md={ tribunal ? 7 : 12} xs={12} > 
                        
                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                            
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"
                                    value={ numeroExpediente } 
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                    autoComplete="off"
                                /> 

                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    loading={ loading }
                                    onClick={ handleClicBuscarExpediente }
                                    size='small'
                                >
                                    <SearchIcon />
                                </LoadingButton>   

                            </Paper>
                            {   
                                errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                            }

                        </Grid>

                        {
                            juzgado
                            &&
                                <Grid item md={12} xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Seleccione un tipo de sentencia:</FormLabel>
                                        <RadioGroup row
                                            aria-label="tipoOficio"
                                            defaultValue="expediente"
                                            name="radio-buttons-group"  
                                        >
                                            <FormControlLabel value="1" control={ <Radio checked={ tipoSentencia === 1 } onChange={ () => { setTipoSentencia( 1 ) } } />} label="Sentencia Definitiva" />
                                            <FormControlLabel value="2" control={ <Radio checked={ tipoSentencia === 2 } onChange={ () => { setTipoSentencia( 2 ) } } />} label="Sentencia Interlocutoria" />
                                        
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                        }


                        {
                            ( expediente.success ) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Expediente:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.numeroExpediente }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        {
                                            expediente.ponencia
                                            &&                                
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        {
                                            expediente.ponencia
                                            &&  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.ponencia }
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                           
                                        {
                                            expediente.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                 
                                        </List>      
                                    </Grid>
                                    
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                                                                
                                        </List>
                                    </Grid>   
                                    
                                </>
                            )
                        }

                        {
                            tribunal
                            &&  
                            <Grid item md={12} xs={12} >    

                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                    <InputBase 
                                        sx={{ ml: 1, flex: 1 }} 
                                        inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                        placeholder="Buscar proyecto de sentencia"     
                                        name="proyectoSentencia"
                                        value={ '' } 
                                        onChange={ ( ) => { }}
                                        autoComplete="off"
                                    /> 

                                    <Button 
                                        variant='contained' 
                                        sx={{ borderRadius: 10 }} 
                                        disabled={ !expediente?.success }
                                        onClick={ handleOpenModalBuscarProyecto }
                                        size='small'
                                    >
                                        <SearchIcon />
                                    </Button>   

                                </Paper>                         

                            </Grid>
                        }

                        {
                            ( idProyectoSentencia !== 0 ) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            No. Seguimiento:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { noSeguimiento }
                                        </Typography>
                                    </Grid>           

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Elaboró:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { usuarioElaboro }
                                        </Typography>
                                    </Grid>           

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Fecha:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { fecha }
                                        </Typography>
                                    </Grid>                                   
                                    
                                </>
                            )
                        }

                        {
                            tribunal
                            &&  
                            <Grid item md={12} xs={12} >    

                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                    <InputBase 
                                        sx={{ ml: 1, flex: 1 }} 
                                        inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                        placeholder="Buscar cierre de instrucción"     
                                        name="cierreInstruccion"
                                        value={ '' } 
                                        onChange={ ( ) => { }}
                                        autoComplete="off"
                                    /> 

                                    <Button 
                                        variant='contained' 
                                        sx={{ borderRadius: 10 }} 
                                        disabled={ !expediente?.success }
                                        onClick={ () => setOpenModalBuscarCierre(true) }
                                        size='small'
                                    >
                                        <SearchIcon />
                                    </Button>   

                                </Paper>                         

                            </Grid>
                        }

                        {
                            ( cierreInstruccion?.idCierreInstruccion !== 0 ) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            No. Cierre de Instrucción:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { cierreInstruccion?.numeroCierreInstruccion }
                                        </Typography>
                                    </Grid>         

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Fecha:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { cierreInstruccion?.fecha }
                                        </Typography>
                                    </Grid>                                   
                                    
                                </>
                            )
                        }

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled"> { errors.error  } </Alert> 
                                </Grid>
                            )
                        }

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ CerrarModal }>Cancelar</Button>
                    
                    <Button
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ handleGenerarSentencia } 
                        disabled={ 
                            ( 
                                tribunal 
                                ? 
                                    !expediente.success || idProyectoSentencia === 0 || cierreInstruccion?.idCierreInstruccion === 0 || numeroReferencia === ''
                                : 
                                    !expediente.success 
                            ) 
                            || loadingGuardar
                        }
                    >
                        Guardar
                    </Button>

                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

            <ModalBuscarProyectoSentencia 
                openModal={ openModalBuscarProyecto } 
                handleCloseModal={ handleCloseModalBuscarProyecto }
                expediente={ expediente }
                SeleccionarProyectoSentencia={ SeleccionarProyectoSentencia }
            />

            <ModalBuscarCierreInstruccion 
                openModal={ openModalBuscarCierre } 
                setOpenModal={ setOpenModalBuscarCierre }
                expediente={ expediente }
                SeleccionarCierreInstruccion={ SeleccionarCierreInstruccion }
            />

        </>
    )
}
