import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert,  Autocomplete, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../../ui/AppBarMenu';

import { ObtenerDependencias } from '../../../actions/admin/catalogs/dependenciaActions';
import { ObtenerClaseDocumentos } from '../../../actions/admin/catalogs/claseDocumentoActions';

import { ModalNuevaPersona } from '../../modals/ModalNuevaPersona';
import { ModalBuscarPersona } from '../../modals/ModalBuscarPersona';

import { ActualizarRegistroValor, RegistroValorDetalle } from '../../../actions/tribunal/registrarValorActions';

import moment from 'moment';

import { types } from '../../../types/types';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

export const NuevoRegistroValorScreen = ( { match } ) => {   
    
    const dispatch = useDispatch();

    const { juzgado, tribunal } = useSelector( state => state.auth );

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [loading, setLoading] = useState(false);

    const [sendAjax, setSendAjax] = useState(false);

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);
    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);    

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const [registroValor, setRegistroValor] = useState( { } );    

    const [referencia, setReferencia] = useState( '' );
    const [cantidad, setCantidad] = useState( '' );
    const [fechaExhibicion, setFechaExhibicion] = useState( DateNow );

    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );

    const [claseDocumento, setClaseDocumento ] = useState( 0 );
    const [claseDocumentoArray, setClaseDocumentoArray ] = useState( [] );

    const [tipoConsignante, setTipoConsignante ] = useState( 0 );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const { idPersona, nombreCompleto } = persona;  
    
    const [errors, setErrors] = useState({ });

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const handleChangeTipoConsignante = ( tipoConsignante ) => {

        setTipoConsignante(tipoConsignante);
        
        setDependencia( 0 ); 
        setAutocompleteValue( null );
        
        setPersona( { idPersona: 0, nombreCompleto: '' } );  
        
        setErrors( { ...errors, dependencia: '' });   

    }

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };   

    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const seleccionarPersona = ( persona ) => {

        if( persona ){

            setPersona( persona );

        }

    } 

    const handleValidate = () => {
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        handleOpenConfirmacion();
    }

    const Finalizar = async () => {

        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }


        const params = {

            idRegistroValor: match?.params?.Id,
            idClaseDocumento: claseDocumento,
            referencia: referencia,
            cantidad: cantidad,
            idPersonaConsignante: idPersona,
            idDependenciaConsignante: dependencia,
            fechaExhibicion: fechaExhibicion,

        };

        setLoading( true );

        await ActualizarRegistroValor( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        handleCloseConfirmacion();

                        setLoading( false );

                        setSendAjax( true );
                        
                    }, 1500);

               } 
               
            }           

        });    

    }


    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};     
       

        if( referencia === "" ){
            valid = false;
            errores.referencia = 'Debes seleccionar la referencia';
        } 

        if( cantidad === "" ){
            valid = false;
            errores.cantidad = 'Debes seleccionar la cantidad';
        }   

        if( fechaExhibicion === "" ){
            valid = false;
            errores.fechaExhibicion = 'Debes seleccionar una fecha';
        }   

        if( claseDocumento === 0  ){      
            valid = false;
            errores.claseDocumento = 'Debes seleccionar la clase del documento';  
        } 

        if( tipoConsignante === 0 && tribunal ){      
            valid = false;
            errores.tipoConsignante = 'Debes seleccionar un tipo de consignante';  
        } 

        if( ( tipoConsignante === 1 && nombreCompleto === "" && tribunal ) || ( juzgado && nombreCompleto === "") ){      
            valid = false;
            errores.nombreCompleto = 'Debes seleccionar una persona consignante';  
        } 
        
        if( tipoConsignante === 2 && dependencia === 0 && tribunal ){      
            valid = false;
            errores.dependencia = 'Debes seleccionar la dependencia';  
        }
        
        setErrors( errores );
        return valid;
    }

    useEffect(() => {

        async function Obtener(){

            await ObtenerDependencias().then( response => {

                if( response ){
             
                    if( response.status === 200){
                        setDependenciaArray( response.data ); 
                    }

                }
            })
              
        }

        Obtener();
        
    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerClaseDocumentos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setClaseDocumentoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);


    useEffect(() => {
      

        async function Obtener(){
           
            const params = {
                idRegistroValor: match?.params?.Id 
            };

            dispatch({ type: types.openLoading });

            await RegistroValorDetalle( params ).then( response => {

                if( response ){
                   
                    if( response.status === 200){
                        
                        setRegistroValor( response.data ); 

                        if( response.data.idEstatus === 53 ){

                            setSendAjax( true );
                        }

                        setTimeout(() => {
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                        }, 1000);

                    }

                }
                
            })
          
        }

        if( match ){
            Obtener();
        }


    }, [ match, dispatch])  

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    
    return (
        <>
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            No se tienen actividades pendientes para este trámite
                                        </Typography>

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>    

                            <InformacionSeguimiento
                                noSeguimiento={ 'GRV-' + registroValor?.id }
                                actividad={ 'Registrar valor' }
                                tipo={                                    
                                        registroValor?.numeroExpediente !== ''
                                        ?
                                            'Número de expediente'
                                        :                                   
                                            registroValor?.numeroIrregular !== ''
                                            ?
                                                'Número de irregular'
                                            :
                                                ''                                       
                                }
                                referencia={ 
                                    registroValor?.numeroExpediente !== ''
                                        ?
                                            registroValor?.numeroExpediente
                                        :                                   
                                            registroValor?.numeroIrregular !== ''
                                            ?
                                                registroValor?.numeroIrregular
                                            :
                                                ''    
                                }
                            />   

                            <Grid container spacing={3} mt={1}>  

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        registroValor?.actor?.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                              
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List 
                                        disablePadding 
                                        sx={{ maxHeight: registroValor?.demandado?.length > 10 ? 300 : null, overflow: registroValor?.demandado?.length > 10 ? 'scroll' : null }}
                                    >  
                                    {
                                        registroValor?.demandado?.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                                              
                                    </List>
                                </Grid>                  

                                <Grid item md={12} xs={12}> </Grid>

                                <Grid item md={4} xs={12} >
                                    <TextField                              
                                        label="Referencia"                                                                                           
                                        fullWidth                          
                                        value={ referencia }
                                        onChange={ ( e ) => { setReferencia( e.target.value ); } }
                                        autoComplete="off"
                                    />
                                    {   
                                        errors.referencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.referencia } </Alert> )
                                    }

                                </Grid>

                                <Grid item md={4} xs={12} >
                                    <TextField                              
                                        label="Cantidad"
                                        fullWidth    
                                        type="number"
                                        value={ cantidad }
                                        onChange={ ( e ) => { setCantidad( e.target.value ); } }                      
                                    />
                                    {   
                                        errors.cantidad && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.cantidad } </Alert> )
                                    }
                                    
                                </Grid>

                                <Grid item md={4} xs={12} >
                                    <TextField                              
                                        label="Fecha de exhibición"
                                        type="datetime-local"              
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}   
                                        value={ fechaExhibicion }
                                        onChange={ ( e ) => { setFechaExhibicion( e.target.value ); } }                             
                                    />
                                    {   
                                        errors.fechaExhibicion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaExhibicion } </Alert> )
                                    }
                                </Grid>

                                <Grid item md={12} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-claseDocumento-label">Clase documento</InputLabel>
                                    
                                        <Select
                                            labelId="select-claseDocumento-label"
                                            id="select-claseDocumento"                                            
                                            label="Clase documento"
                                            name="claseDocumento"
                                            value={ claseDocumento }                                           
                                            onChange={ ( e ) => { setClaseDocumento( e.target.value ); } }
                                        >
                                            <MenuItem value={0}>Selecciona una opción</MenuItem>
                                            {
                                                claseDocumentoArray
                                                .map( ( element ) => (
                                                    <MenuItem key={ element.idClaseDocumento } value={ element.idClaseDocumento }>{ element.descripcion }</MenuItem>                                                        
                                                ))
                                            } 
                                        </Select>  
                                        {   
                                            errors.claseDocumento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.claseDocumento } </Alert> )
                                        }                                     

                                    </FormControl>
                                </Grid>  

                                {
                                    tribunal
                                    &&
                                    <Grid item md={12} xs={12} sx={{ mt: 1 }} >
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Seleccione el tipo de consignante:</FormLabel>
                                            <RadioGroup row
                                                aria-label="tipoOficio"
                                                defaultValue="persona"
                                                name="radio-buttons-group"  
                                            >
                                                <FormControlLabel value="persona" control={ <Radio checked={ tipoConsignante === 1 } onChange={ () => { handleChangeTipoConsignante(1) }}  />} label="Persona Física" />
                                                <FormControlLabel value="dependencia" control={ <Radio checked={ tipoConsignante === 2 } onChange={ () => { handleChangeTipoConsignante(2) }} />} label="Dependencia" />

                                            </RadioGroup>                                        

                                        </FormControl>
                                        {   
                                            errors.tipoConsignante && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoConsignante } </Alert> )
                                        }
                                    </Grid>
                                }

                                {
                                    ( 
                                        ( tipoConsignante === 1 && tribunal ) ||
                                        juzgado
                                    )
                                    &&  

                                    <Grid item md={12} xs={12} > 
                                    
                                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }}>                            
                                            
                                            <InputBase 
                                                sx={{ ml: 1, flex: 1 }} 
                                                inputProps={{ 'aria-label': 'Buscar consignante' }} 
                                                placeholder="Buscar consignante"     
                                                name="consignante"
                                                value={ nombreCompleto }   
                                                onChange={ () => { } }             
                                                autoComplete="off"      
                                            /> 

                                                    
                                            <Button 
                                                sx={{ borderRadius: 10, mr: 1 }} 
                                                variant='contained' 
                                                onClick={ handleOpenModalBuscarPersona }
                                                size='small'
                                            >
                                                <SearchIcon />
                                            </Button>         
                        
                                            <Button 
                                                sx={{ borderRadius: 10 }} 
                                                variant='contained' 
                                                onClick={ handleOpenModalNuevaPersona }
                                                size='small'
                                            >
                                                <AddCircleIcon />
                                            </Button>                         
                                            
                                        </Paper>
                                            {   
                                                errors.nombreCompleto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.nombreCompleto } </Alert> )
                                            }

                                    </Grid>
                                }

                                {
                                    ( tipoConsignante === 2 && tribunal)
                                    &&                                
                                    <>
                                        <Grid item md={12} xs={12} >     
                                            <Autocomplete                    
                                                disablePortal 
                                                fullWidth              
                                                value={ autocompleteValue }   
                                                options={ dependenciaArray }
                                                onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                                getOptionLabel={ (option) => option.descripcion }
                                                isOptionEqualToValue={(option, value) => option.idDependencia === value.idDependencia }  
                                                renderOption={ ( props, option ) => (
                                                    <li { ...props } key={ option.idDependencia} >                         
                                                        { option.descripcion }
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Dependencia(s)" />}
                                                disabled={ tipoConsignante === 0 }                 
                                            />

                                            {   
                                                errors.dependencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.dependencia } </Alert> )
                                            }
                                        </Grid> 

                                    </>
                                }

                                <Grid item md={12} xs={12}> </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                    <Button variant="contained" onClick={ handleValidate } endIcon={ <CheckCircleIcon /> }> Finalizar </Button>
                                </Grid> 

                            </Grid>   
                           
                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar el registro del valor?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loading }
                    > 
                        Aceptar 
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

        </>
    )
}
