import React, { useRef, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormLabel, FormControlLabel, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Paper, Select, Snackbar, Radio, RadioGroup, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { ObtenerDistritoJudicial } from '../../../actions/admin/catalogs/distritoJudicialActions';
import { ObtenerObjetoExhortos } from '../../../actions/admin/catalogs/objetoExhortoActions';
import { ObtenerClaseExhortos } from '../../../actions/admin/catalogs/claseExhortoActions';
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';
import { ActualizarExhortoLibrado, ObtenerExhortoLibradoDetalle, ActualizarEstatusExhortoLibrado } from '../../../actions/tribunal/exhortoLibradoActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

export const ElaborarExhortoLibradoScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const { tribunal } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    const [loading, setLoading] = useState( false );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [activarFinalizar, setActivarFinalizar] = useState(true);

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [exhortoLibrado, setExhortoLibrado] = useState( [ ] );

    const [distritoJudicial, setDistritoJudicial] = useState(0);
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [ ] );

    const [objetoExhorto, setObjetoExhorto] = useState(0);
    const [objetoExhortoArray, setObjetoExhortoArray] = useState( [ ] );

    const [claseExhorto, setClaseExhorto] = useState(0);
    const [claseExhortoArray, setClaseExhortoArray] = useState( [ ] ) ;

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [richText, setRichText] = useState( '' ); 

    const [loadingGuardar, setLoadingGuardar] = useState( false );  
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [envio, setEnvio] = useState( true );
     
    const GuardarExhorto = async () => {
    
        const valid = validateFiels();
        
        if( !valid ){
            return false;
        }
        
        const contenido = editorRef.current.getContent();
        const params = {
            'idExhortoLibrado' : Id,
            'contenido': contenido,
            'idDistritoJudicial': distritoJudicial,
            'idObjetoExhorto': objetoExhorto,
            'idClaseExhorto': claseExhorto,
            'idPlantilla' : plantilla
        };


        setLoadingGuardar( true );

        await ActualizarExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        
                        setActivarFinalizar(false);

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        const contenido = editorRef.current.getContent();

        if( distritoJudicial === 0 && envio === true ){
            valid = false;
            errores.distritoJudicial = 'Debes seleccionar el distrito judicial';
        } 

        if( objetoExhorto === 0 ){
            valid = false;
            errores.objetoExhorto = 'Debes seleccionar el objeto';
        } 

        if( claseExhorto === 0  ){      
            valid = false;
            errores.claseExhorto = 'Debes seleccionar la clase';
        }   

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        } 

        if( plantilla === 0  ){      
            valid = false;
            errores.plantilla = 'Debes seleccionar la plantilla';
        }
        

        
        setErrors( errores );
        return valid;
    }

    const Finalizar = async () => {
              
        setLoadingFinalizar( true );

        const params = {
            'idExhortoLibrado' : Id,
            'idEstatus': 39,
        };

        await ActualizarEstatusExhortoLibrado( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {                        

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }          

                        setLoading( true );                        

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    const handleOcultarMostrarEnvio = (val) => {
        setEnvio(val)
        setDistritoJudicial(0);
    }

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', exhortoLibrado.tipoJuicio ?? "" )
                            .replaceAll('[Ponencia]', exhortoLibrado.ponencia ?? "" )
                            .replaceAll('[Numero_Exhorto]', exhortoLibrado.numeroExhorto ?? "" )
                            .replaceAll('[Numero_Expediente]', exhortoLibrado.numeroExpediente ?? "" )
                            .replaceAll('[Ciudad]',exhortoLibrado.ciudad ?? "")
                            .replaceAll('[Juez]',exhortoLibrado.juez ?? "");

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }
    
    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerDistritoJudicial().then( response => {

                if( response ){

                    if( response.data ){
                        setDistritoJudicialArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerObjetoExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setObjetoExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            await ObtenerClaseExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setClaseExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])
    
    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idTipoAcuerdo': 13,
                'idSubTipoAcuerdo': 33,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idExhortoLibrado': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerExhortoLibradoDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => { 

                            if( response.data.idDistritoJudicial === 0 ){
                                setEnvio(false)
                            } 
                            else {
                                setEnvio(true)
                                setDistritoJudicial(response.data.idDistritoJudicial);
                            }

                            setExhortoLibrado(response.data);
                            
                            setObjetoExhorto(response.data.idObjetoExhorto);
                            setClaseExhorto(response.data.idClaseExhorto);
                            setPlantilla(response?.data?.idPlantilla);
                            setRichText(response.data.contenido);
                            
                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })
                            setCadenaActores(cadenaA);

                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);

                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                            if (response.data.idDistritoJudicial !== 0 && response.data.idObjetoExhorto !== 0 && response.data.idClaseExhorto !== 0 && response.data.contenido !== null)
                            {
                                setActivarFinalizar(false);
                            }      
                            
                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 37 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 37){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])
    
    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3, mt: 2 }}>  

                            <InformacionSeguimiento
                                noSeguimiento={ 'GEXL-' + exhortoLibrado.idExhortoLibrado }
                                actividad={ 'Elaborar exhorto librado' }
                                tipo={ 'Número de exhorto' }
                                referencia={ tribunal ? exhortoLibrado.numeroReferencia : exhortoLibrado.numeroExhorto }
                            />   

                            <Grid container spacing={3} mt={1}>  
                               
                                {
                                    exhortoLibrado.numeroExpediente
                                    &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de expediente:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoLibrado.numeroExpediente }
                                                </Typography>
                                            </Grid>
                                        </>
                                }
                                
                                {
                                    exhortoLibrado.numeroExhortoRecibido
                                    &&
                                        <>
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Número de exhorto recibido:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { exhortoLibrado.numeroExhortoRecibido }
                                                </Typography>
                                            </Grid>
                                        </>
                                }

                                {
                                    exhortoLibrado.ponencia
                                    &&                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                    }

                                {
                                    exhortoLibrado.ponencia
                                    &&  
                                    <Grid item md={10} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoLibrado.ponencia }
                                        </Typography>
                                    </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        exhortoLibrado?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                              
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List 
                                        disablePadding 
                                        sx={{ maxHeight: exhortoLibrado?.demandado?.length > 10 ? 300 : null, overflow: exhortoLibrado?.demandado?.length > 10 ? 'scroll' : null }}
                                    >  
                                    {
                                        exhortoLibrado?.demandado?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                                              
                                    </List>
                                </Grid>                  

                                <Grid item md={12} xs={12}> </Grid>

                                <Grid item md={6} xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">¿El exhorto librado va dirigido a un juzgado del poder judicial del estado?</FormLabel>
                                        <RadioGroup row
                                            aria-label="envioaudiencia"
                                            defaultValue="no"
                                            name="radio-buttons-group"  
                                        >   
                                            <FormControlLabel value="no" control={ <Radio checked={ !envio } onChange={ () => { handleOcultarMostrarEnvio( false ); } } />} label="No" />
                                            <FormControlLabel value="si" control={ <Radio checked={ envio }  onChange={ () => { handleOcultarMostrarEnvio( true ); } }/>} label="Si" />                                    
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} xs={12} > 
                                {

                                    envio && (
                                        <FormControl fullWidth>
                                          <InputLabel id="select-distritoJudicial-label"> Distrito Judicial </InputLabel>
                                    
                                            <Select
                                                labelId="select-distritoJudicial-label"
                                                id="select-distritoJudicial"                                            
                                                label="Distrito Judicial"
                                                name="distritoJudicial"
                                                value={ distritoJudicial }
                                                onChange={ (e) => {setDistritoJudicial(e.target.value)} }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    distritoJudicialArray.map((elem)=>(
                                                        <MenuItem key={elem.idDistritoJudicial} value={ elem.idDistritoJudicial }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            {   
                                                errors.distritoJudicial && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.distritoJudicial } </Alert> )
                                            }
                                        </FormControl>
                                    )
                                }                           
                                </Grid> 

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-objetoExhorto-label">Objeto del exhorto</InputLabel>
                                    
                                        <Select
                                            labelId="select-objetoExhorto-label"
                                            id="select-objetoExhorto"                                            
                                            label="Objeto del exhorto"
                                            name="objetoExhorto"
                                            value={ objetoExhorto }
                                            onChange={(e) => {setObjetoExhorto(e.target.value)}  }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                exhortoLibrado?.expedienteEmplazado
                                                ?
                                                    objetoExhortoArray
                                                    .filter((elem) => {
                                                        return elem.idObjetoExhorto !== 1;
                                                    })
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idObjetoExhorto} value={ elem.idObjetoExhorto }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                :
                                                    objetoExhortoArray                                                    
                                                    .map((elem)=>(
                                                        <MenuItem key={elem.idObjetoExhorto} value={ elem.idObjetoExhorto }>{ elem.descripcion }</MenuItem>
                                                    ))
                                            }
                                        </Select>
                                        {   
                                            errors.objetoExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.objetoExhorto } </Alert> )
                                        }
                                    </FormControl>
                                </Grid>  

                                <Grid item md={6} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-claseExhorto-label">Clase del exhorto</InputLabel>
                                    
                                        <Select
                                            labelId="select-claseExhorto-label"
                                            id="select-claseExhorto"                                            
                                            label="Clase del exhorto"
                                            name="claseExhorto"
                                            value={ claseExhorto }
                                            onChange={(e) => { setClaseExhorto(e.target.value)}  }
                                        >
                                           <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                claseExhortoArray
                                                .filter((elem) => {
                                                    return elem.idClaseExhorto === 1;
                                                })
                                                .map((elem)=>(
                                                    <MenuItem key={elem.idClaseExhorto} value={ elem.idClaseExhorto }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }

                                        </Select>
                                        {   
                                            errors.claseExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.claseExhorto } </Alert> )
                                        }
                                    </FormControl>
                                </Grid>  

                                <Grid item md={12} xs={12} >                            
                                    <FormControl fullWidth>
                                        <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                    
                                        <Select
                                            labelId="select-plantilla-label"
                                            id="select-plantilla"                                            
                                            label="Plantilla"
                                            name="plantilla"
                                            value={ plantilla }
                                            onChange={ (e) => {handleChangePlantilla(e.target.value)} }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                            {
                                                plantillaArray.map((elem)=>(
                                                    <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {   
                                            errors.plantilla && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.plantilla } </Alert> )
                                        }
                                    </FormControl>
                                </Grid>  
                                
                                <Grid item md={12} xs={12} >  
                                    <Editor
                                        onInit = { (evt, editor) => editorRef.current = editor }
                                        initialValue = {richText}
                                        apiKey = { ObtenerApiKeyRichText( process.env ) }
                                        init = {
                                            {
                                                height: 450,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount pagebreak'
                                                ],
                                                toolbar: 'undo redo | ' +
                                                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                    'removeformat | print pagebreak |  Atajos',
                                                    setup: (editor) => {                                        
                                                        editor.ui.registry.addMenuButton('Atajos', {
                                                            icon: 'bookmark' ,
                                                            tooltip: "Atajos",
                                                            fetch: function (callback) {
                                                                var items = [];
                                                                
                                                                if( exhortoLibrado?.ponencia ){
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExhorto + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ exhortoLibrado.ponencia +'</strong>'); } },
                                                                    ];
                                                                }
                                                                else{                                                                
                                                                    items = [ 
                                                                        { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExhorto + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExpediente + '</strong>'); } },
                                                                        { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaDemandados +'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado.tipoJuicio+'</strong>'); } },
                                                                        { type: 'menuitem', text: 'Juez',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado.juez+'</strong>'); } },
                                                                    ];
                                                                }
                                                                callback(items);
                                                            }
                                                        });
                                                    },                                                 
                                                    content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }',  
                                            }
                                        }  
                                    />
                                </Grid>     
                                {   
                                    errors.contenido && ( 
                                        <Grid item md={12} xs={12}>
                                            <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                        </Grid>
                                    )
                                }
                                {
                                    exhortoLibrado.observaciones !== '' 
                                    &&
                                    <>
                                    <Grid item md={3} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight:'bold'  }}>
                                            Motivo de no autorización:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoLibrado.observaciones }
                                        </Typography>
                                    </Grid>
                                    </>
                                }

                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                    <LoadingButton 
                                        variant="contained" 
                                        onClick={ GuardarExhorto } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                    > 
                                        Guardar exhorto
                                    </LoadingButton>

                                </Grid>

                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                    <Button variant="contained" onClick={ handleOpenConfirmacion } endIcon={ <CheckCircleIcon /> } disabled = { activarFinalizar }> Finalizar </Button>
                                </Grid>       

                            </Grid>   
                           
                        </Container>

                    )
                }

                <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea finalizar el registro del exhorto librado?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>

                        <LoadingButton 
                            onClick={ Finalizar } 
                            variant='contained' 
                            loading={ loadingFinalizar }
                        > 
                            Aceptar 
                        </LoadingButton>

                    </DialogActions>

                </Dialog>

            </Box>
        </>
    )
}
