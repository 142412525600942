import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Alert, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import moment from 'moment';

import { types } from '../../../types/types';

import { ObtenerTipoJuicio } from '../../../actions/admin/catalogs/tipojuicioActions';
import { ObtenerPonencias } from '../../../actions/admin/catalogs/ponenciaActions';

import { RegistrarInformacion } from '../../../actions/recepcion/recepcionActions';

export const RegistrarInformacionScreen = ( { handleNext, idRecepcion } ) => {
    
    const dispatch = useDispatch();

    const { juzgado, tribunal } = useSelector( state => state.auth );
    
    const { demandaInicial, recepcion, partes } = useSelector( state => state.recepcion );

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [fechaReloj, setFechaReloj] = useState( DateNow );
    const [motivoAsignacion, setMotivoAsignacion] = useState( '' );
    
    const [ponencia, setPonencia] = useState( 0 );
    const [ponenciaArray, setPonenciaArray] = useState( [ ] );
    
    const [recibidoBuzon, setRecibidoBuzon] = useState( false );
    const [recibidoReloj, setRecibidoReloj] = useState( false );
    const [asignacionDirecta, setAsignacionDirecta] = useState( false );
    
    const [errors, setErrors] = useState({  });

    const [loadingNext, setLoadingNext] = useState( false );

    const handleChangeTipoJuicio = ( e, { props }) => {

        setTipoJuicio( e.target.value );

    }

    const validateFiels = () => {
        let valid = true;
        let errores = {};
        
        if( tipoJuicio === 0 ){
            valid = false;
            errores.tipoJuicio = 'Debes seleccionar un tipo de juicio';
        }
        if( fechaReloj === '' && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'Debes seleccionar la fecha de reloj';
        }
        if( motivoAsignacion === ''&& asignacionDirecta  ){
            valid = false;
            errores.motivoAsignacion = 'Debes escribir el motivo de la asignación';
        }
        if( ponencia === 0 && asignacionDirecta ){
            valid = false;
            errores.ponencia = 'Debes seleccionar la ponencia';
        }
        if( moment( new Date( fechaReloj ) ) > moment( new Date( ) ) && recibidoReloj ){
            valid = false;
            errores.fechaReloj = 'La fecha de reloj no puede ser mayor a la fecha actual';
        }
        
        setErrors( errores );        

        return valid;
    } 

    const Siguiente = async () => {

        const validFiels = validateFiels();

        if( !validFiels ){
            return false;
        }

        setLoadingNext( true );

        const params = {

            idRecepcion: idRecepcion,
            idTipoJuicio: tipoJuicio,
            recibidoPorReloj: recibidoReloj,
            asignacionDirecta: asignacionDirecta,
            motivoAsignacion: motivoAsignacion,
            idPonencia: ponencia,
            fechaReloj: recibidoReloj ? fechaReloj : null,
            RecibidoBuzonElectronico: recibidoBuzon

        };

        await RegistrarInformacion( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    var { descripcion: descripcionTipoJuicio } = tipoJuicioArray.find( ( element ) => { return element.idTipoJuicio === tipoJuicio });                   

                    dispatch({

                        type: types.actualizarDemandaInicial,
                        payload: {

                            idTipoJuicio: tipoJuicio,
                            tipoJuicio: descripcionTipoJuicio,
                            recibidoBuzon: recibidoBuzon,
                            recibidoReloj: recibidoReloj,
                            asignacionDirecta: asignacionDirecta,
                            motivoAsignacion: motivoAsignacion,
                            idPonencia: ponencia,
                            ponencia: ponencia !== 0 ? ponenciaArray.find( ( element ) => { return element.idPonencia === ponencia }).descripcion : '',
                            fechaReloj: recibidoReloj ? moment( new Date( fechaReloj ) ).format("YYYY-MM-DDTHH:mm") : '',
                            numeroExpediente: demandaInicial.numeroExpediente,    
                        },

                    });   
                    
                    var persona = partes.find(function( element ){ 
                        return element.idPersona === response.data.idPersona ;
                    });

                    if(persona === undefined){

                        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

                        const data = [{
                            id: parseInt( id ),
                            idPersona : response.data.idPersona,
                            persona: response.data,
                            idDependencia: null,
                            dependencia: null,
                            idTipoParte:1,
                            tipoParte: {
                                idTipoParte: 1,
                                descripcion: "Actor",
                                activo: true
                            }          
                        },...partes];

                        dispatch({
    
                            type: types.agregarPartes,
                            payload: data
                
                        });
                    }


                    
                    setTimeout(() => {
                        
                        setLoadingNext( false );

                        handleNext();

                    }, 500);  

                }

            }

        });
    }

    const handleChangeAsignacion = ( checked ) => {

        setAsignacionDirecta( checked );
        setMotivoAsignacion( '' );
        setPonencia( 0 );

    }

    const handleChangeRecibidoReloj = ( value ) => {

        setRecibidoReloj( value );

        const Now = moment( new Date()).format("YYYY-MM-DDTHH:mm");
        setFechaReloj( Now );
    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerPonencias( ).then( response => {

                if( response ){

                    if( response.data ){
                        setPonenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoJuicio( ).then( response => {

                if( response ){

                    if( response.data ){
                        setTipoJuicioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {      

        if( demandaInicial ){
            setRecibidoBuzon( demandaInicial.recibidoBuzon );
            setRecibidoReloj( demandaInicial.recibidoReloj );
            setAsignacionDirecta( demandaInicial.asignacionDirecta );
            setMotivoAsignacion( demandaInicial.motivoAsignacion );
            setFechaReloj( demandaInicial.fechaReloj === '' ? DateNow : demandaInicial.fechaReloj );
        }
      
    }, [ demandaInicial, DateNow ])

    useEffect(() => {      
        
        if( demandaInicial && ponenciaArray.length > 0 ){
            setPonencia( demandaInicial.idPonencia ?? 0 );
        }
      
    }, [ demandaInicial, ponenciaArray ])

    useEffect(() => {   
        
        if( demandaInicial && tipoJuicioArray.length > 0 ){
            setTipoJuicio( demandaInicial.idTipoJuicio ?? 0 );
        }
      
    }, [ demandaInicial, tipoJuicioArray ])

    useEffect(() => {
     
        const requests = axios.CancelToken.source(); 

        return () => {
            requests.cancel();
        }

    }, [ ])
    
    return (
        <>           
            <Grid item md={6} xs={12} >                            
               
                <FormControl fullWidth>
                    <InputLabel id="select-tipoJuicio-label">Tipo de juicio</InputLabel>
                
                    <Select
                        labelId="select-tipoJuicio-label"
                        id="select-tipoJuicio"                                            
                        label="Tipo de juicio"
                        name="tipoJuicio"
                        value={ tipoJuicio }
                        onChange={ handleChangeTipoJuicio }
                        disabled={ demandaInicial.numeroExpediente !== "" }
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                        {
                            juzgado
                            ?                            
                            tipoJuicioArray
                            .filter( (element ) => { return element.juzgado === true; } )
                            .map( ( element ) => (
                                <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                            ))
                            :
                            tipoJuicioArray
                            .filter( (element ) => { return element.tribunal === true; } )
                            .map( ( element ) => (
                                <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                            ))
                        }
                    </Select>

                </FormControl>
                {   
                    errors.tipoJuicio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuicio } </Alert> )
                }
            </Grid> 

            <Grid item md={6} xs={12} >
                <FormControl component="fieldset" disabled={ recepcion?.provieneBuzonElectronico }>
                    <FormLabel component="legend">¿El trámite proviene del buzón electrónico?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoBuzon"
                        name="recibidoBuzon"                         
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === true } onChange={ () => { setRecibidoBuzon( true ); } } />} label="Si" />   
                        <FormControlLabel control={ <Radio checked={ recibidoBuzon === false } onChange={ () => { setRecibidoBuzon( false ); } } />} label="No" />
                       
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿El trámite es recibido por reloj?</FormLabel>
                    <RadioGroup row
                        aria-label="recibidoReloj"
                        name="recibidoReloj"                           
                    >
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === true } onChange={ () => { handleChangeRecibidoReloj( true ); } } />} label="Si" />                        
                        <FormControlLabel control={ <Radio checked={ recibidoReloj === false } onChange={ () => { handleChangeRecibidoReloj( false ); } } />} label="No" />    
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item md={6} xs={12} >

                {
                    recibidoReloj && (
                        <>
                            <TextField                              
                                label="Fecha recibida por reloj"
                                type="datetime-local"   
                                name="fechaReloj"                             
                                value={ fechaReloj }    
                                onChange={ ( e ) => { setFechaReloj( e.target.value ); } }                                                     
                                fullWidth                               
                                inputProps={{
                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                }}                             
                                InputLabelProps={{
                                    shrink: true,
                                }}              
                            />

                            {   
                                errors.fechaReloj && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fechaReloj } </Alert> )
                            }

                        </>
                    )
                }

            </Grid>

            {
                ( tribunal )
                &&
                <Grid item md={3} xs={12} >
                    <FormControl component="fieldset" disabled={ demandaInicial.numeroExpediente !== "" } >
                        <FormLabel component="legend">¿Es asignación directa?</FormLabel>
                        <RadioGroup row
                            aria-label="asignacionDirecta"                                   
                            name="asignacionDirecta"                     
                        >
                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === false } onChange={ () => { handleChangeAsignacion( false ); } } />} label="No" />
                            <FormControlLabel control={ <Radio checked={ asignacionDirecta === true } onChange={ () => { handleChangeAsignacion( true ); } } />} label="Si" />   
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }

            {
                asignacionDirecta && (

                    <>
                        <Grid item md={9} xs={12} >
                            <TextField                              
                                label="Motivo de la asignación directa"    
                                name="motivoAsignacion"                                                 
                                value={ motivoAsignacion }
                                onChange={ ( e ) => { setMotivoAsignacion( e.target.value ); } }                           
                                multiline
                                rows={4}  
                                fullWidth         
                                disabled={ demandaInicial.numeroExpediente !== "" }                 
                            />

                            {   
                                errors.motivoAsignacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.motivoAsignacion } </Alert> )
                            }

                        </Grid>

                        <Grid item md={12} xs={12} >                            
                            <FormControl fullWidth disabled={ demandaInicial.numeroExpediente !== "" }>
                                <InputLabel id="select-Ponencia-label">Ponencia</InputLabel>
                            
                                <Select
                                    labelId="select-Ponencia-label"
                                    id="select-Ponencia"                                            
                                    label="Ponencia"
                                    name="ponencia"
                                    value={ ponencia }
                                    onChange={ ( e ) => { setPonencia( e.target.value ); } }                           
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        ponenciaArray.map( ( element ) => (
                                            <MenuItem key={ element.idPonencia } value={ element.idPonencia }>{ element.descripcion }</MenuItem>                        
                                        ))
                                    }
                                </Select>

                            </FormControl>

                            {   
                                errors.ponencia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.ponencia } </Alert> )
                            }

                        </Grid> 
                    </>

                )
            }   

            <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>  
              
                <LoadingButton 
                    variant="contained" 
                    onClick={ Siguiente }
                    endIcon={ <ArrowForwardIcon /> }
                    loading={ loadingNext }
                > 
                    Siguiente 
                </LoadingButton>

            </Grid>
        </>
    );
}
