import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import FileCopyIcon from '@mui/icons-material/FileCopy';

export const DetallePromocionScreen = () => {

    const { acuerdo, promocion } = useSelector( state => state.acuerdo );

    const [openAnexos, setOpenAnexos] = useState( false );  
    const [openArchivoAnexo, setOpenArchivoAnexo] = useState( false );  
    const [archivoAnexo, setArchivoAnexo] = useState( '' );

    return (
        <>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 3 }} >

                {
                    promocion?.folioBuzon
                    &&
                    <Grid container sx={{ mt: 1, mb: 1 }}>
                        <Grid item md={2} xs={4}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Folio buzón electrónico:
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={8} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { promocion?.folioBuzon }
                            </Typography>
                        </Grid>

                        {
                            ( promocion?.anexosBuzonElectronico?.length !== 0 )
                            &&
                            <>
                                <Grid item md={2} xs={4}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Anexo(s) Buzón electrónico:
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={8} >
                                    <Button
                                        variant='contained'
                                        onClick={ () => setOpenAnexos(true) }
                                    >
                                        VER ANEXOS 
                                    </Button>       
                                </Grid>                            
                            </>
                        }
                    </Grid>  
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item md={2} xs={4}>
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Tipo de promoción:
                        </Typography>
                    </Grid>
                    <Grid item md={10} xs={8} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                            { promocion?.tipoPromocion }
                        </Typography>
                    </Grid>
                </Grid>  
              
                {
                    promocion?.partes?.length !== 0 ?
                        <Grid container sx={{ mt: 1, mb: 1 }}>
                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Parte(s):
                                </Typography>
                            </Grid>
                            <Grid item md={10} xs={8} >
                                <List disablePadding >
                                    {
                                        promocion?.partes?.map( ( element, i  ) => (                                    
                                            <ListItem key={ i } disablePadding>                    
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { element.parte } <strong>({ element.tipoParte })</strong>
                                                </Typography>                                
                                            </ListItem>   
                                        ))
                                    }                         
                                </List>
                            </Grid>
                        </Grid>     
                    :
                    null
                }

                {
                    promocion?.prestaciones?.length !== 0
                    ?
                        <Grid container sx={{ mt: 1, mb: 1 }}>
                            <Grid item md={2} xs={4}>
                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                    Prestacion(es):
                                </Typography>
                            </Grid>
                            <Grid item md={10} xs={8} >
                                <List disablePadding>
                                    {
                                        promocion?.prestaciones?.map( ( element ) => (                                    
                                            <ListItem key={ element.idTipoPrestacion } disablePadding>                    
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { element.descripcion }
                                                </Typography>                                
                                            </ListItem>
                                        ))
                                    }  
                                </List>
                            </Grid>
                        </Grid> 
                    :
                    null
                }

                <Grid container sx={{ mt: 1, mb: 1 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                            Promoción escaneada:
                        </Typography>
                    </Grid>
                    
                    <Grid item lg={12} md={12} xs={12}>                        
                        {
                            acuerdo.promocionDigitalizado
                            ?                        
                            <iframe 
                                title='iframe amparo'
                                style={{ width: '100%', height: '400px' }} 
                                src={ acuerdo.promocionDigitalizado }                                
                            />
                            :
                            null
                        }
                    </Grid>
                </Grid>                    

            </Box>

            <Dialog 
                open={ openAnexos } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="sm" 
            >

                <DialogTitle>
                    Anexos
                </DialogTitle>
                
                <DialogContent>
                    
                    <List>

                    {
                        promocion?.anexosBuzonElectronico?.map( ( elem, index ) => (
                            
                            <ListItemButton key={ index } onClick={ () => { setArchivoAnexo( elem.url ); setOpenArchivoAnexo( true ); } }>
                                <ListItemIcon>
                                    <FileCopyIcon color='primary' />
                                </ListItemIcon>
                                <ListItemText primary={ elem.archivo } />
                            </ListItemButton>

                        ))
                    }

                    </List>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenAnexos( false ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog>  

            <Dialog 
                open={ openArchivoAnexo } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Anexo
                </DialogTitle>
                
                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                            <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                
                                <iframe title='acuse' src={ archivoAnexo } height="500px" width="100%"></iframe>

                            </Grid>
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenArchivoAnexo( false ); setArchivoAnexo( '' ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog> 

        </>
    )
}
