import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import SaveIcon from '@mui/icons-material/Save';

import { Alert,  Box, Card,CardContent, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Tab, Tabs, TableContainer, TableHead, Table, TableRow, TableCell, TableBody, TableFooter, TablePagination, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { usePages } from '../../../hooks/usePages';
import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { ObtenerOficioDetalle, ActualizarEstatusOficio, ActualizarContenidoOficio } from '../../../actions/tribunal/oficioActions';
import { ObtenerDependencias } from '../../../actions/admin/catalogs/dependenciaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AutorizarOficioScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const editorRef = useRef(null);

    const dispatch = useDispatch();

    const [sendAjax, setSendAjax] = useState(false);

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [richText, setRichText] = useState( '' ); 

    const [autorizar, setAutorizar] = useState(true);

    const [oficio, setOficio] = useState( [ ] );

    const [observacion, setObservacion] = useState( '' );

    const [valueTab, setValueTab] = useState(0);

    const [rows, setRows] = useState( [] );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );

    const [cadenaPartesAmparo, setCadenaPartesAmparo] = useState( '' ); 
    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 

    const [loadingRegresarEscrito, setLoadingRegresarEscrito] = useState( false );  
    const [loadingFinalizar, setLoadingFinalizar] = useState( false ); 

    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [loadingGuardar, setLoadingGuardar] = useState( false );  

    const [referenciaA, setReferenciaA] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const GuardarOficio = async () => {
       
        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            'idOficio' : Id,
            'contenido': contenido,
        };

        setLoadingGuardar( true );

        await ActualizarContenidoOficio( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    
        
        if( observacion.trim()  === ''  &&  autorizar === false) {
            valid = false;
            errores.observaciones = 'Debes teclear un motivo de rechazo';
        } 

        const contenido = editorRef.current.getContent();

        if( contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el contenido de la plantilla';
        } 

        setErrors( errores );
        return valid;
    }

    const Finalizar = async () => {

        setLoadingFinalizar( true );
        
        const params = {
            'idOficio' : Id,
            'idEstatus': 36,
        };

        await ActualizarEstatusOficio( params ).then( response => {

            if( response ){

                if( response.status === 200){                    

                    setOpenConfirmacion( false );

                    setLoadingFinalizar( false );

                    if( !response.data.usuarioActualAsignacion ){                            
                        setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                    }  
                    else if( response.data.usuarioActualAsignacion ){
                        setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                    }          

                    setSendAjax( true );
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const Regresar = async () => {

         const valid = validateFiels();
       
        if( !valid ){
            return false;
        }
        
        setLoadingRegresarEscrito( true );
        
        const params = {
            'idOficio' : Id,
            'idEstatus': 34,
            'observacion': observacion
        };

        await ActualizarEstatusOficio( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setLoadingRegresarEscrito( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }         

                        setSendAjax( true );

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingRegresarEscrito( false );

                    }, 1000);  
                }
            }
        });

    }
    
    useEffect(() => {      

        async function Obtener(){

            const params = {
                'idOficio': Id
            };

            dispatch({ type: types.openLoading });

            await ObtenerOficioDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setOficio(response.data);

                            if( ( response?.data?.numeroAmparo ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferenciaA( response?.data?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhorto ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferenciaA( response?.data?.numeroExhorto ?? '' );
                            } 
                            else if( ( response?.data?.numeroExhortoLibrado ?? '') !== '' ){
                                setTipoReferencia( 'Número de exhorto librado' );
                                setReferenciaA( response?.data?.numeroExhortoLibrado ?? '' );
                            } 
                            else if( ( response?.data?.numeroIrregular ?? '') !== '' ){
                                setTipoReferencia( 'Número de irregular' );
                                setReferenciaA( response?.data?.numeroIrregular ?? '' );
                            } 
                            else if(( response?.data?.numeroExpediente ?? '' ) !== '' ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferenciaA( response?.data?.numeroExpediente ?? '' );
                            }   

                            let cadenaPA = '';
                            response.data.partes.forEach((value, key) => { 
                                if (key + 1 !== response.data.partes.length){ 
                                    cadenaPA += (value.parte + ' (' + value.tipoParte + '), ')
                                } else {
                                    cadenaPA += (value.parte + ' (' + value.tipoParte + ') ')
                                }
                            })
                            setCadenaPartesAmparo(cadenaPA);
                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += ( value + ', ');
                                } else {
                                    cadenaA += ( value + ' ');
                                }
                            })
                            setCadenaActores(cadenaA);
                            
                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);    



                            setRichText(response.data.contenido);
                            setAjaxLoading( true );
    
                            if(response.data.dependencia)
                            {
                                setDependencia(response.data.dependencia.idDependencia)
                            }

                            setRows( response.data.destinatarios );

                            dispatch({ type: types.closeLoading });

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 35 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 35){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setSendAjax( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response.status === 200){
                    setDependenciaArray( response.data ); 
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 1 }}>     

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" sx={{ pt: 2 }}>
                                <Tab label="Oficio" { ...a11yProps(0) } />
                                <Tab label="Destinatario(s)" { ...a11yProps(1) } />                          
                            </Tabs>                         

                            <TabPanel value={ valueTab } index={0}> 

                                <InformacionSeguimiento
                                    noSeguimiento={ 'GOF-' + oficio.idOficio }
                                    actividad={ 'Autorizar oficio' }
                                    tipo={ tipoReferencia }
                                    referencia={ referenciaA }
                                />   

                                <Grid container spacing={3} mt={1}>                                    

                                    <Grid item md={2} xs={3}>
                                        <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                            Número de oficio:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={10} xs={9} >
                                        <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                            { oficio.numeroOficio }
                                        </Typography>
                                    </Grid>

                                    { 
                                        (oficio.numeroExpediente !== null && oficio.expediente.ponencia === null) &&
                                        <>                                           
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Actor(es):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Demandado(s):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List 
                                                    disablePadding 
                                                    sx={{ maxHeight: oficio?.demandado?.length > 10 ? 300 : null, overflow: oficio?.demandado?.length > 10 ? 'scroll' : null }}
                                                >                         
                                                {
                                                    oficio.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>
                                        </>
                                    }  

                                    { 
                                        (oficio.numeroExpediente !== null && oficio.expediente.ponencia !== null) &&
                                        <>                                            
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Ponencia:
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={10} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.expediente?.ponencia?.descripcion }
                                                </Typography>
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Actor(es):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2"  style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Demandado(s):
                                                </Typography>   
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List 
                                                    disablePadding 
                                                    sx={{ maxHeight: oficio?.demandado?.length > 10 ? 300 : null, overflow: oficio?.demandado?.length > 10 ? 'scroll' : null }}
                                                >                        
                                                {
                                                    oficio.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List> 
                                            </Grid>
                                        </> 
                                    }  

                                    { 
                                        oficio.numeroIrregular !== null && 
                                        <>   
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de irregular:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.irregular?.tipoIrregular?.descripcion }
                                                </Typography>
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Actor(es):
                                                </Typography>
                                            </Grid>

                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                           
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                 
                                                </List>      
                                            </Grid>
                                            
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                        {
                                                            oficio.demandado.map((elem, i)=>(
                                                                <ListItem key={ i } disablePadding> 
                                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                        { elem }
                                                                    </Typography>
                                                                </ListItem>                    
                                                            ))
                                                        }                                                                                                
                                                </List>
                                            </Grid> 
                                        </>
                                    } 

                                    { 
                                        oficio.numeroAmparo !== null && 
                                        <>
                                       
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de amparo:
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio.amparo.tipoAmparo.descripcion }
                                                </Typography>
                                            </Grid> 
                
                                            <Grid item md={2} xs={3}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Partes(es):
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={10} xs={9} >
                                                <List disablePadding>                               
                                                    {
                                                        oficio.partes.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                
                                                </List>                            
                                        
                                            </Grid> 
                                        </>
                                    } 
                                    
                                    { 
                                        oficio.exhortoRecibido !== null && 
                                        <>                                               
                    
                                            <Grid item md={2} xs={6}>                                                             
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de juicio:
                                                </Typography>                                
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >                               
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.exhortoRecibido?.tipoJuicio?.descripcion }
                                                </Typography>                                
                                            </Grid>                                        
                
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Actor(es):
                                                </Typography>
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>                               
                                                {
                                                    oficio.actor.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                
                                                </List>                            
                                        
                                            </Grid> 
                                    
                                            <Grid item md={2} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                    Demandado(s):
                                                </Typography>
                                            </Grid>
                                    
                                            <Grid item md={4} xs={6} >
                                                <List disablePadding>
                                                    {
                                                        oficio.demandado.map((elem, i)=>(
                                                            <ListItem key={ i } disablePadding> 
                                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                    { elem }
                                                                </Typography>
                                                            </ListItem>                    
                                                        ))
                                                    }                                                  
                                                </List>
                                            </Grid>    

                                            <Grid item md={2} xs={6}>                                                             
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Autoridad del exhorto:
                                                </Typography>                                
                                            </Grid>
                
                                            <Grid item md={4} xs={6} >                               
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { oficio?.exhortoRecibido?.autoridadExhorto?.descripcion }
                                                </Typography>                                
                                            </Grid>                             
                                            
                                            <Grid item md={2} xs={6}>    
                                                {
                                                    oficio?.exhortoRecibido?.ponencia
                                                    &&                                                     
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                        Ponencia:
                                                    </Typography>   
                                                }                             
                                            </Grid>                            
                
                                            <Grid item md={4} xs={6} >
                                                {
                                                    oficio?.exhortoRecibido?.ponencia
                                                    &&       
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { oficio?.exhortoRecibido?.ponencia?.descripcion }
                                                    </Typography>
                                                }
                                            </Grid> 
                                        </>
                                    }

                                    <Grid item md={12} xs={12} >  
                                        <Editor
                                            onInit = { (evt, editor) => editorRef.current = editor }
                                            initialValue = { richText }
                                            apiKey = { ObtenerApiKeyRichText( process.env ) }
                                            init = {
                                                {
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount pagebreak'
                                                    ],
                                                    toolbar: 'undo redo | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                        'removeformat | print pagebreak | Atajos',
                                                        setup: (editor) => {                                        
                                                            editor.ui.registry.addMenuButton('Atajos', {
                                                                icon: 'bookmark' ,
                                                                tooltip: "Atajos",
                                                                fetch: function (callback) {
            
                                                                    var items = [];

                                                                    if (oficio.numeroExpediente !== null && oficio.expediente.ponencia !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.expediente?.tipoJuicio?.descripcion + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.expediente?.ponencia?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    } 
                                                                    else if (oficio.numeroExpediente !== null && oficio.expediente.ponencia === null) {
                                                                        items = 
                                                                        [
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.expediente?.tipoJuicio?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    } 
                                                                    else if (oficio.numeroIrregular !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroIrregular + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  oficio?.irregular?.tipoIrregular?.descripcion +'</strong>'); } },
                                                                        ];

                                                                    } 
                                                                    else if (oficio.numeroAmparo !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad del amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.amparo?.autoridad?.descripcion +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.amparo.tipoAmparo?.descripcion +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Partes',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaPartesAmparo +'</strong>'); } },
                                                                        ];
                                                                    }   
                                                                    else if (oficio.exhortoRecibido !== null && oficio.exhortoRecibido.ponencia !== null) {
                                                                        items = 
                                                                        [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExhorto + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.tipoJuicio?.descripcion + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.ponencia?.descripcion + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad del exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.autoridadExhorto?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    } 
                                                                    else if (oficio.exhortoRecibido !== null && oficio.exhortoRecibido.ponencia === null) {
                                                                        items = 
                                                                        [
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de oficio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.numeroOficio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + oficio?.numeroExhorto + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ cadenaActores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+  cadenaDemandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad del exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ oficio?.exhortoRecibido?.autoridadExhorto?.descripcion + '</strong>'); } },
                                                                        ];
                                                                    }    

                                                                    callback(items);
                                                                }
                                                            });
                                                        },                                                
                                                        content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }', 
                                                }
                                            }  
                                        />
                                    </Grid>  
                                    {   
                                        errors.contenido && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                            </Grid>
                                        )
                                    }    

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 

                                        <LoadingButton 
                                            variant="contained" 
                                            onClick={ GuardarOficio } 
                                            startIcon={ <SaveIcon /> }
                                            loading={ loadingGuardar }
                                        > 
                                            Guardar oficio
                                        </LoadingButton>

                                    </Grid>  

                                    <Grid item md={4} xs={12} >
                                        <FormControl component="fieldset" fullWidth>
                                            <FormLabel component="legend"  sx={{ color: 'rgba(0, 0, 0, 1)' }}>¿Desea autorizar el oficio?</FormLabel>
                                            <RadioGroup row
                                                aria-label="autorizarOficio"
                                                defaultValue="si"
                                                name="radio-buttons-group"  
                                            >
                                                <FormControlLabel value="si" control={ <Radio checked={ autorizar === true } onChange={ () => { setErrors( { } ); setAutorizar(true) } } />}  label="Si" />
                                                <FormControlLabel value="no" control={ <Radio checked={ autorizar === false } onChange={ () => { setErrors( { } ); setAutorizar(false) } } />} label="No" />                                                
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {
                                        !autorizar && (
                                            <>
                                            <Grid item md={8} xs={12} >
                                                <TextField                              
                                                    label="Motivo"                                                     
                                                    multiline
                                                    rows={4}  
                                                    fullWidth                          
                                                    name="observacion"   
                                                    variant="outlined"
                                                    value={ observacion }
                                                    onChange={ ( e ) => { setObservacion( e.target.value ) }}
                                                />
                                                {   
                                                    errors.observaciones && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.observaciones } </Alert> )
                                                }
                                            </Grid>    
                                            </>
                                        )
                                    }

                                    <Grid item md={12} xs={12} sx={{ mt: 1 }}> 
                                        
                                        <Grid container spacing={3}>
                                        
                                            <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 

                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ Regresar } 
                                                    startIcon={ <ArrowBackIcon />  } 
                                                    disabled={ autorizar }
                                                    loading={ loadingRegresarEscrito }
                                                > 
                                                Regresar escrito 
                                                </LoadingButton>
    
                                            </Grid>
                                            
                                            <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                                
                                                <Button 
                                                    variant="contained" 
                                                    onClick={ () => { setOpenConfirmacion( true ); } } 
                                                    endIcon={ <CheckCircleIcon />  } 
                                                    disabled={ !autorizar }
                                                > 
                                                    Finalizar y Firmar Oficio 
                                                </Button>

                                            </Grid>

                                        </Grid>

                                    </Grid>  

                                </Grid>        

                            </TabPanel>    

                            <TabPanel value={ valueTab } index={1}>      

                                <InformacionSeguimiento
                                    noSeguimiento={ 'GOF-' + oficio.idOficio }
                                    actividad={ 'Autorizar oficio' }
                                    tipo={ tipoReferencia }
                                    referencia={ referenciaA }
                                />    
                                
                                <Grid container spacing={3} mt={1}>  

                                    <Grid item md={12} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-dependencia-label">Dependencia</InputLabel>
                                            <Select
                                                labelId="select-dependencia-label"
                                                id="select-dependencia"                                            
                                                label="Dependencia"
                                                name="dependencia"
                                                value={ dependencia }
                                                disabled
                                            >
                                            {
                                                dependenciaArray.map((elem)=>(
                                                    <MenuItem key={elem.idDependencia} value={ elem.idDependencia }>{ elem.descripcion }</MenuItem>
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    </Grid>                                    

                                    <Grid item md={12} xs={12} >
                                        <TableContainer style={{ marginTop: '10px' }}>

                                            <Table>

                                                <TableHead>

                                                    <TableRow>
                                                        <TableCell style={{ width:'30%' }}>Nombre(s)</TableCell>
                                                        <TableCell style={{ width:'30%' }}>Apellido 1</TableCell>
                                                        <TableCell style={{ width:'30%' }}>Apellido 2</TableCell>
                                                    </TableRow>

                                                </TableHead>

                                                <TableBody>

                                                    {
                                                        (rowsPerPage > 0
                                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : rows
                                                        ).map( (row) => (

                                                        <TableRow key={ row.idPersona }>
                                                            <TableCell> { row.nombres } </TableCell>
                                                            <TableCell> { row.apellido1 } </TableCell>
                                                            <TableCell> { row.apellido2 } </TableCell>                                                        
                                                        </TableRow>

                                                        ))
                                                    }

                                                    {
                                                        rows.length === 0 && 
                                                        (
                                                            <TableRow>
                                                                <TableCell
                                                                    colSpan={3}
                                                                    align="center"
                                                                    style={{ fontWeight: 'bold', fontSize: 15 }}
                                                                >
                                                                    Agregue un destinatario
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>

                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[ ]}
                                                            colSpan={3}
                                                            count={rows.length}                                        
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}                                                                                
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }
                                                        />
                                                    </TableRow>
                                                </TableFooter>

                                            </Table>

                                        </TableContainer>
                                    </Grid> 

                                </Grid>

                            </TabPanel>  

                            
                           
                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la autorización del oficio?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>
        </>
    )
}
