import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AppBarMenu from '../../ui/AppBarMenu';

import { Alert, Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import AddIcon from '@mui/icons-material/Add';

import moment from 'moment';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { v4 as uuidv4 } from 'uuid';

import { types } from '../../../types/types';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { usePages } from '../../../hooks/usePages';

import { ModalAgregarDomicilioMasivo } from '../../modals/ModalAgregarDomicilioMasivo';

import { BuscarExpedienteMasivo, EliminarLayoutMasivo, GuardarLayoutMasivo, SubirLayoutMasivo } from '../../../actions/recepcion/expedienteActions';
import { ObtenerAutoridades } from '../../../actions/admin/catalogs/autoridadActions';
import { ObtenerTiposAcreditado } from '../../../actions/admin/catalogs/tipoAcreditarioActions';

export const GestionarRegistroMasivoScreen = () => {

    const { administrador, coordinador } = useSelector( state => state.auth );

    const dispatch = useDispatch();
    
    const [errors, setErrors] = useState({  });
    const [alert, setAlert] = useState( {  } );

    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [expediente, setExpediente] = useState( {} );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [rows, setRows] = useState( [ ] );

    const [expedienteEncontrado, setExpedienteEncontrado] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );

    const [openModal, setOpenModal] = useState(false);
    const [openModalDomicilio, setOpenModalDomicilio] = useState(false);
    const [domicilio, setDomicilio] = useState( { } );

    const [idReferencia, setIdReferencia] = useState( '' );

    const [nombres, setNombres] = useState( '' );
    const [curp, setCurp] = useState( '' );
    const [apellido1, setApellido1] = useState( '' );
    const [apellido2, setApellido2] = useState( '' );
    const [numeroCredito, setNumeroCredito] = useState( '' );

    const [tipoAcreditado, setTipoAcreditado] = useState( 0 );
    const [tipoAcreditadoArray, setTipoAcreditadoArray] = useState( [] );

    const [mostrar, setMostrar] = useState( 1 );

    const refLayout = useRef( null );
    const [layout, setLayout] = useState( null );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [loadingSubir, setLoadingSubir] = useState(false);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);

    const [openConfirmacionGuardar, setOpenConfirmacionGuardar] = useState(false);

    const [openModalEliminarLayout, setOpenModalEliminarLayout] = useState(false);
   const [loadingEliminarLayout, setLoadingEliminarLayout] = useState(false);

    const [opcion, setOpcion] = useState( 'agregar' );

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleCleanFields = () => {

        setMostrar( 1 );
        setDomicilio( {} );
        setIdReferencia( '' );
        setExpediente( {} );

        setCurp( '' );
        setNombres( '' );
        setApellido1( '' );
        setApellido2( '' );
        setNumeroCredito( '' );
        setAutoridad( 0 );
        setTipoAcreditado( 0 );

        setLayout( null );
        refLayout.current = null;

        setRows( [] );

        setErrors( {} );
    }

    const errorExpediente = ( message ) => {
       
        setErrors( { } );

        let errores = {};  
            errores.numeroExpediente = message ? message : 'No se encontró el expediente';
            errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente( '' );
    }

    const handleBuscarExpediente = async () => {     

        dispatch({ type: types.openLoading });

        handleCleanFields();

        await BuscarExpedienteMasivo({ numeroExpediente: numeroExpediente, idAutoridad: autoridad }).then( response => {

            if( response ){
               
                if( response.status === 200){

                    setTimeout(() => {

                        if( response.data ){

                            const { success, message } = response.data;

                            if( success ){

                                setExpediente( response.data );
                                setRows( response?.data?.partes ?? [] );

                                setExpedienteEncontrado( response?.data?.encontrado ?? false );
                            }
                            else{
                                errorExpediente( message );
                            }

                            dispatch({ type: types.closeLoading });
                            setNumeroExpediente('');
                        }

                    }, 600);  

                    
                } 
                else {

                    setTimeout(() => {

                        dispatch({ type: types.closeLoading });
                        setNumeroExpediente('');

                    }, 600);  

                }
            }

        });

    }   

    const SeleccionarDomicilio = ( data ) =>{ 
        
        const newRows = rows.map( ( e ) => {

            if( e.id === idReferencia ){               
                e.idDomicilio = data.idDomicilio ?? 0;
                e.numero = data.numero ?? '';
                e.descripcion = data.descripcion ?? '';
                e.idAsentamiento = data?.idAsentamiento ?? 0;
                e.idTipoDomicilio = 5;                                                                    
                e.asentamiento = data?.asentamiento;
                e.domicilioInmueble = data?.domicilioInmueble ?? '';
            }

            return e;
        });
        
        setRows( newRows );

    }

    const ValidateFields = () => {
       
        let valid = true;
        let errores = {};    

        if( curp === '' ){      
            valid = false;
            errores.curp = 'Debes escribir el curp';
        }  
        else if( curp.length !== 18 ){      
            valid = false;
            errores.curp = 'El CURP debe contener 18 caracteres';
        }     
        
        if( nombres === '' ){      
            valid = false;
            errores.nombres = 'Debes escribir el nombre';
        }     
        
        if( apellido1 === '' ){      
            valid = false;
            errores.apellido1 = 'Debes escribir el apellido 1';
        }  
        
        if( numeroCredito === '' ){      
            valid = false;
            errores.numeroCredito = 'Debes escribir el número de credito';
        }  

        if( tipoAcreditado === 0 ){      
            valid = false;
            errores.tipoAcreditado = 'Debes seleccionar el tipo de acreditado';
        }  

        setErrors( errores );
        return valid;
    }

    const handleGuardarDatos = () => {

        const valid = ValidateFields();

        if( !valid ) return;

        const parte = ( nombres.trim() + ' ' + apellido1.trim()  + ' ' + apellido1.trim() ).trim();

        const validarPersonaCurp = rows.filter( (e) => e.id !== idReferencia && e.curp === curp  );
        const validarPersonaNombreCompleto = rows.filter( (e) => e.id !== idReferencia && ( e.parte?.toLocaleLowerCase() === parte.toLocaleLowerCase() ) );

        if( validarPersonaCurp.length !== 0 ){            

            setErrors({ agregarPersona: 'La persona ya se encuentra agregada con la curp: ' + curp });
            return;
        }
        else if( validarPersonaNombreCompleto.length !== 0 ){            

            setErrors({ agregarPersona: 'La persona ya se encuentra agregada' });
            return;
        }

        const tipoAcreditadoDescripcion = tipoAcreditadoArray.find( (e) => e.idTipoAcreditado === tipoAcreditado )?.descripcion ?? '';

        if( opcion === 'agregar' ){

            setRows([
                ...rows,
                {
                    id: uuidv4(),
                    idPersona: 0,                    
                    parte: parte.toLocaleUpperCase(),
                    curp: curp,
                    nombres: nombres,
                    apellido1: apellido1,
                    apellido2: apellido2,
                    tipoParte: 'Demandado',
                    numeroCredito: numeroCredito,
                    domicilioInmueble: '',
                    domicilioRegistrado: '',                    
                    idTipoAcreditado: tipoAcreditado,
                    tipoAcreditado: tipoAcreditadoDescripcion,
                    registrado: false,
                    idDomicilio: 0,          
                    descripcion: '',
                    numero: '',
                    idAsentamiento: 0,
                    asentamiento: {},
                    modificar: true,
                }
            ]);

        }
        else if( opcion === 'editar' ) {

            const newRows = rows.map( ( e ) => {
    
                if( e.id === idReferencia ){    
                    e.curp = curp;           
                    e.nombres = nombres;
                    e.apellido1 = apellido1;
                    e.apellido2 = apellido2;
                    e.parte = parte.toLocaleUpperCase();
                    e.numeroCredito = numeroCredito;
                    e.idTipoAcreditado = tipoAcreditado;
                    e.tipoAcreditado = tipoAcreditadoDescripcion;
                    e.parte = ( nombres.trim() + ' ' + apellido1.trim() + ' ' + apellido2.trim() ).trim();
                }
    
                return e;
            });
            
            setRows( newRows );

        }

        setOpenModal( false );
    }

    const handleChangeLayout = ({ meta, file }, status) => { 
        if( status === "done" ){ 
            setLayout( file );
        } 
        else if( status === "removed" ){
            setLayout( null );
        }
    }

    const handleSubirLayout = async () => {

        setLoadingSubir( true );

        let formData = new FormData();
            formData.append('Id', expediente?.id ?? '');       
            formData.append('Layout', layout); 
            formData.append('Partes', JSON.stringify( rows )); 

            await SubirLayoutMasivo( formData ).then( response => {

                if( response ){
    
                    if( response.status === 200){
    
                        if( response.data ){
    
                            const { success, message, url, partes } = response.data;
    
                            if( success ){
    
                                setTimeout(() => {
    
                                    setOpenConfirmacion( false );    
    
                                    setLoadingSubir( false );
    
                                    setAlert( {
                                        open: true,
                                        msg: message,
                                        type: 'success'
                                    } ); 
    
                                    const partesExpediente = [
                                        ...rows,
                                        ...partes
                                    ];

                                    setExpediente({
                                        ...expediente,
                                        layout: url,
                                        partes: partesExpediente,
                                    });         
                                    
                                    setRows( partesExpediente );
    
                                }, 1000);                                
                                
                            }
                            else{
                                
                                setTimeout(() => {
    
                                    setAlert( {
                                        open: true,
                                        msg: message,
                                        type: 'error'
                                    } ); 
    
                                    setLoadingSubir( false );
    
                                }, 1000);  
    
                            }
                            
                        } 
    
                    }
                    else{
                                
                        setTimeout(() => {
    
                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, vuelva a enviar el layout masivo.',
                                type: 'error'
                            } );    
                            
                            setLoadingSubir( false );
    
                        }, 1000);  
    
                    }
    
                }
                
            });

    }

    const handleEliminarCredito = () => {

        const newRows = rows.filter( ( e ) => e.id !== idReferencia );
        
        setRows( newRows );
        setOpenModalEliminar( false );
    }

    const handleGuardarLayout = async () => {

        setLoadingGuardar( true );

        await GuardarLayoutMasivo({ id: expediente?.id ?? '', partes: rows }).then( resp => {

            if( resp ){

                if( resp.status === 200){
    
                    setTimeout(() => {
    
                        if( resp.data ){
    
                            const { success, message } = resp.data;
    
                            if( success ){
    
                                setOpenConfirmacionGuardar( false );    
        
                                setLoadingGuardar( false );
    
                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                } ); 
    
                            }
                            else{
    
                                setOpenConfirmacionGuardar( false );    
        
                                setLoadingGuardar( false );
    
                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                } ); 
                             
                            }
               
                        }
    
                    }, 600);  
    
                    
                } 
                else {
    
                    setTimeout(() => {
    
                        setOpenConfirmacionGuardar( false );    
        
                        setLoadingGuardar( false );
    
                        setAlert( {
                            open: true,
                            msg: 'Ocurrio un error al guardar la información',
                            type: 'error'
                        } ); 
    
                    }, 600);  
    
                }

            }

        });

    }

    const handleEliminarLayout = async () => {

        setLoadingEliminarLayout( true );

        await EliminarLayoutMasivo({ id: expediente?.id ?? '' }).then( resp => {

            if( resp ){

                if( resp.status === 200){
    
                    setTimeout(() => {
    
                        if( resp.data ){
    
                            const { success, message } = resp.data;
    
                            if( success ){
    
                                setOpenModalEliminarLayout( false );    
        
                                setLoadingEliminarLayout( false );

                                setExpediente({ ...expediente, layout: '' }); 
                                setLayout( null ); 
                                
                                refLayout.current = null;
    
                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                } ); 
    
                            }
                            else{
    
                                setOpenModalEliminarLayout( false );    
        
                                setLoadingEliminarLayout( false );
    
                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                } ); 
                             
                            }
               
                        }
    
                    }, 600);  
    
                    
                } 
                else {
    
                    setTimeout(() => {
    
                        setOpenModalEliminarLayout( false );    
        
                        setLoadingEliminarLayout( false );
    
                        setAlert( {
                            open: true,
                            msg: 'Ocurrio un error al guardar la información',
                            type: 'error'
                        } ); 
    
                    }, 600);  
    
                }

            }

        });

    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposAcreditado().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoAcreditadoArray( response.data );
                    }
                }
                
            });

        }
       
        Obtener();        

    }, [ ]);

    return (
        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
        
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>   

                <Container maxWidth="lg" sx={{ pb: 3 }}> 

                    <Grid container spacing={3}>

                        {
                            ( administrador || coordinador )
                            &&     
                                <Grid item md={6} xs={12}> 
                                
                                    <FormControl fullWidth>
                                        <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                    
                                        <Select
                                            labelId="select-autoridad-label"
                                            id="select-autoridad"                                            
                                            label="Autoridad"
                                            name="autoridad"
                                            value={ autoridad }                                
                                            onChange={ ( e ) => setAutoridad( e.target.value ) }
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                            {
                                                coordinador
                                                ?
                                                    autoridadArray 
                                                    .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                    .map( ( elem ) => (
                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                    ))
                                                :
                                                    autoridadArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                    ))
                                            }                          
                                        </Select>

                                    </FormControl>
                                
                                </Grid>
                        }

                        <Grid item xs={12} md={ ( administrador || coordinador ) ? 6 : 12 }>

                            <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                
                                <InputBase 
                                    sx={{ ml: 1, flex: 1 }} 
                                    inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                    placeholder="Buscar por número de expediente"     
                                    name="expediente"
                                    value={ numeroExpediente }                                      
                                    onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                                    autoComplete="off"
                                /> 

                                <LoadingButton 
                                    variant='contained' 
                                    sx={{ borderRadius: 10 }} 
                                    onClick={ handleBuscarExpediente }
                                    size='small'
                                    disabled={ numeroExpediente === '' || (( administrador || coordinador ) && autoridad === 0 ) }
                                >
                                    <SearchIcon />
                                </LoadingButton>   

                            </Paper>

                        </Grid>

                    </Grid>

                    {   
                        errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                    }

                </Container>    

                <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>   

                    <Grid container sx={{ mt: 1, mb: 1 }} spacing={3}>                   

                        <Grid item md={2} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de expediente:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.numeroExpediente }
                            </Typography>
                        </Grid>    
                     
                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Tipo de juicio:
                            </Typography>
                        </Grid>                          

                        <Grid item md={4} xs={6} >                            
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.tipoJuicio }
                            </Typography>                               
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Fecha de inicio:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.fecha ? moment( expediente?.fecha ).format('YYYY-MM-DD') : '' }
                            </Typography>                                                
                        </Grid>  
                        
                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Tipo de calificación:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.tipoCalificacion }
                            </Typography>                                                
                        </Grid>    
                        
                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Etapa:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.etapa }
                            </Typography>                                                 
                        </Grid> 

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Sub etapa:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                               { expediente?.subEtapa }
                            </Typography>                                                 
                        </Grid>     

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Juez:
                            </Typography>
                        </Grid>
                        
                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.juez }
                            </Typography>                                                
                        </Grid>       
                      
                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Secretario:
                            </Typography>
                        </Grid>
                        
                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.secretario }
                            </Typography>                                                
                        </Grid>      

                        {
                            expediente?.layout
                            ?
                                <Grid item md={12} xs={12} textAlign={'center'} mt={1} mb={1} >                                

                                    <Button startIcon={ <DownloadIcon /> } variant='contained' href={ expediente?.layout  ?? '' } target='_blank' mb={2}>                                               
                                        Descargar layout masivo
                                    </Button>

                                    <IconButton onClick={ () => setOpenModalEliminarLayout( true ) } >
                                        <DeleteIcon />
                                    </IconButton>

                                </Grid>
                            :
                                expediente?.success
                                &&
                                    <Grid item md={12} xs={12}>

                                        <Grid container spacing={3}>  
                                                                                    
                                            <Grid item md={6} xs={12} >

                                                <Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: 22, mb: 1 }}>
                                                    Subir layout masivo
                                                </Typography>

                                                <Typography variant='subtitle2' sx={{ fontSize: 14, mb: 1 }}>
                                                    El layout deberán cargarse en formato .xlsx de manera independiente, respetando las columnas que se muestran en la plantilla base y no podrán pesar más de 10mb.
                                                </Typography>

                                                <Button href={ process.env.PUBLIC_URL + '/assets/layout-base.xlsx'} target='_blank' mb={2} startIcon={ <DownloadIcon color='secondary' fontSize='small' /> }>  
                                                    Descargar layout base       
                                                </Button>                                               

                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <Dropzone
                                                    ref={ ( ref ) =>  refLayout.current = ref }
                                                    onChangeStatus={ handleChangeLayout }
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                    maxSizeBytes={10000000}
                                                    maxFiles={1}
                                                    inputContent={ 
                                                        <Typography key={0} variant='body2' sx={{ textAlign: 'center' }}>
                                                            <strong style={{ fontSize: 18 }}>Añadir layout.</strong>
                                                        </Typography> 
                                                    }
                                                    styles={{
                                                        dropzone: { border: '1px dashed #252B50' },
                                                        inputLabel:{ color: '#252B50', fontSize: 14, padding: '15px' }
                                                    }}
                                                />
            
                                            </Grid>  

                                            <Grid item md={12} xs={12} textAlign={'center'} >

                                                <Button 
                                                    startIcon={ <UploadFileOutlinedIcon /> } 
                                                    variant='contained'                                                   
                                                    disabled={ layout == null }
                                                    onClick={ () => setOpenConfirmacion( true ) }
                                                >                                               
                                                    Subir layout masivo
                                                </Button>

                                            </Grid>

                                        </Grid>

                                    </Grid>         
                        }

                        <Grid item xs={12} >  

                            {
                                rows.length !== 0
                                &&
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={6}>
                                        
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Mostrar:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="Todos los registros" control={ <Radio checked={ mostrar === 1 } onChange={ () => setMostrar( 1 ) } />} label="Todos los registros" />
                                                <FormControlLabel value="Sin domicilio reconocido" control={ <Radio checked={ mostrar === 2 } onChange={ () => setMostrar( 2 ) } />} label="Sin domicilio reconocido" />   
                                                <FormControlLabel value="Incorrectos" control={ <Radio checked={ mostrar === 3 } onChange={ () => setMostrar( 3 ) } />} label="Incorrectos" />             
                                            </RadioGroup>
                                        </FormControl>

                                    </Grid>

                                    <Grid item xs={12} md={6} textAlign={'end'}>

                                        <Button 
                                            sx={{ mt: 2 }}
                                            startIcon={ <AddIcon /> } 
                                            variant='contained'          
                                            onClick={ () =>{ 

                                                setIdReferencia( '' );

                                                setCurp( '' );
                                                setNombres( '' );
                                                setApellido1( '' );
                                                setApellido2( '' );
                                                setNumeroCredito( '' );
                                                setTipoAcreditado(  0 );

                                                setDomicilio({
                                                    idDomicilio: 0,
                                                    numero: '',
                                                    descripcion: '',
                                                    idAsentamiento: 0,
                                                    idTipoDomicilio: 5,                                                                    
                                                    asentamiento: {},
                                                    domicilioInmueble: ''
                                                });
                                                
                                                setOpcion( 'agregar' );
                                                setOpenModal( true );
                                            }}
                                        >                                               
                                            Agregar persona
                                        </Button>

                                    </Grid>

                                </Grid>
                            }

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'1%', fontWeight: 'bold' }}></TableCell>  
                                            <TableCell style={{ width:'25%', fontWeight: 'bold' }}>NOMBRE</TableCell>                                                        
                                            <TableCell style={{ width:'15%', fontWeight: 'bold' }}>NÚMERO DE CREDITO </TableCell>
                                            <TableCell style={{ width:'40%', fontWeight: 'bold' }}>DOMICILIO DEL INMUEBLE</TableCell>
                                            <TableCell style={{ width:'15%', fontWeight: 'bold' }}>TIPO</TableCell>                             
                                            <TableCell style={{ width:'5%', fontWeight: 'bold' }}> </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                    {
                                        (   rowsPerPage > 0
                                            ? rows.filter( ( e ) => 
                                                    mostrar === 1 
                                                        ? ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                        :
                                                        mostrar === 2 
                                                            ? ( e.idAsentamiento === 0 && e.domicilioInmueble !== '' )
                                                            :
                                                            mostrar === 3
                                                                ? ( e.curp === '' || e.curp.length !== 18 || e.tipoAcreditado === 0 )
                                                                : ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                    )                                            
                                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows.filter( ( e) => 
                                                    mostrar === 1 
                                                        ? ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                        :
                                                        mostrar === 2 
                                                            ? ( e.idAsentamiento === 0 && e.domicilioInmueble !== '' )
                                                            :
                                                            mostrar === 3
                                                                ? ( e.curp === '' || e.curp.length !== 18 || e.tipoAcreditado === 0 )
                                                                : ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                ) 
                                        ).map( ( row, index ) => (

                                            <TableRow key={ index } >

                                                <TableCell> 
                                                    { row.registrado && <RuleIcon /> } 
                                                    { ( row.curp === '' || row.curp.length !== 18 || row.tipoAcreditado === 0 ) && <ClearIcon /> } 
                                                </TableCell> 

                                                <TableCell> 
                                                    { row.parte } 
                                                    { row.curp && <> <br />  { row.curp }  </> }                                                    
                                                    <br /> (<strong>{ row.tipoParte }</strong>)  
                                                </TableCell>      

                                                <TableCell> { row.numeroCredito } </TableCell>

                                                <TableCell>

                                                    {
                                                        row?.modificar === true
                                                        ?        
                                                            <Button 
                                                                color={ row.domicilioInmueble === '' ? 'error' : 'inherit' }
                                                                variant='contained' 
                                                                size='medium'
                                                                fullWidth
                                                                sx={{ textAlign: 'left', fontSize: 12 }}                                                        
                                                                onClick={ () => {
                                                                
                                                                    setIdReferencia( row?.id ?? '' );

                                                                    setDomicilio({
                                                                        idDomicilio: row.idDomicilio ?? 0,
                                                                        numero: row.numero ?? '',
                                                                        descripcion: row.descripcion ?? '',
                                                                        idAsentamiento: row?.idAsentamiento ?? 0,
                                                                        idTipoDomicilio: 5,                                                                    
                                                                        asentamiento: row?.asentamiento,
                                                                        domicilioInmueble: row?.domicilioInmueble ?? ''
                                                                    });

                                                                    setOpenModalDomicilio( true );
                                                                }}
                                                            >
                                                                { row.domicilioInmueble === '' ? 'Agregar domicilio del inmueble' : row.domicilioInmueble } 
                                                            </Button>
                                                        :
                                                            row?.domicilioInmueble ?? ''
                                                    }

                                                    {
                                                        ( row.idAsentamiento === 0 && row.domicilioInmueble !== '' )
                                                        &&
                                                            <Chip
                                                                sx={{ mt: 2 }}
                                                                label={ 'Domicilio no reconocido'}                                           
                                                                deleteIcon={ <ClearIcon /> }
                                                                variant='filled'
                                                                color='error'   
                                                                size='small'          
                                                            />
                                                    }

                                                </TableCell>                                               
                                               
                                                <TableCell> { row.tipoAcreditado } </TableCell>

                                                <TableCell>                                                                                      

                                                    {
                                                        row?.modificar === true
                                                        &&
                                                            <IconButton                                                        
                                                                onClick={ () => {

                                                                    setIdReferencia( row?.id ?? '' );

                                                                    setCurp( row?.curp ?? '');
                                                                    setNombres( row?.nombres ?? '');
                                                                    setApellido1( row?.apellido1 ?? '');
                                                                    setApellido2( row?.apellido2 ?? '');
                                                                    setNumeroCredito( row?.numeroCredito ?? '');
                                                                    setTipoAcreditado( row?.idTipoAcreditado ?? 0 );
                                                                    
                                                                    setOpcion( 'editar' );
                                                                    setOpenModal( true );
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                    }    

                                                    <IconButton onClick={ () =>{ setIdReferencia( row?.id ?? '' ); setOpenModalEliminar( true ); } } >
                                                        <ClearIcon />
                                                    </IconButton>

                                                </TableCell>

                                            </TableRow>

                                        ))
                                    }

                                    {
                                        rows.filter( ( e ) => 
                                            mostrar === 1 
                                                ? ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                :
                                                mostrar === 2 
                                                    ? ( e.idAsentamiento === 0 && e.domicilioInmueble !== '' )
                                                    :
                                                    mostrar === 3
                                                        ? ( e.curp === '' || e.curp.length !== 18 || e.tipoAcreditado === 0 )
                                                        : ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                        ).length === 0 && 
                                        (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="center"
                                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                                >
                                                    {
                                                        'No se encontraron registros'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TableCell colSpan={2}>
                                                <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> 
                                                    Total de registros: 
                                                    { 
                                                        rows.filter( ( e ) => 
                                                            mostrar === 1 
                                                                ? ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                                :
                                                                mostrar === 2 
                                                                    ? ( e.idAsentamiento === 0 && e.domicilioInmueble !== '' )
                                                                    :
                                                                    mostrar === 3
                                                                        ? ( e.curp === '' || e.curp.length !== 18 || e.tipoAcreditado === 0 )
                                                                        : ( e.idDomicilio !== 0 || e.idDomicilio === 0 ) 
                                                        ).length
                                                    } 
                                                </Typography>
                                            </TableCell> 

                                            <TablePagination
                                                rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100 ]}
                                                colSpan={6}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }
                                                labelRowsPerPage="Filas por página"                        
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>     

                        </Grid>

                        {
                            !expedienteEncontrado
                            &&
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                                
                                    <LoadingButton
                                        variant="contained" 
                                        onClick={  () => setOpenConfirmacionGuardar( true ) } 
                                        startIcon={ <SaveIcon /> }                                        
                                        disabled={ 
                                            !expediente?.numeroExpediente || 
                                            rows.length === 0 ||
                                            rows.filter( (e) => ( e.idAsentamiento === 0 && !e.domicilioInmueble ) || ( e.idTipoAcreditado === 0 ) || ( e.numeroCredito === '' || e.numeroCredito === '0' ) || ( e.curp === '' || e.curp.length !== 18 ) ).length !== 0 
                                        }
                                    > 
                                        Guardar información
                                    </LoadingButton>

                                </Grid>   
                        }
                      
                    </Grid>  

                </Container>

            </Box>        

            <ModalAgregarDomicilioMasivo 
                openModal={ openModalDomicilio } 
                handleCloseModal={ () => setOpenModalDomicilio( false ) }    
                domicilio={ domicilio }
                SeleccionarDomicilio={ SeleccionarDomicilio }
            />

            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>

                <DialogContent>
                    
                    <Grid container spacing={3} sx={{ mt: 1 }} >

                        <Grid item md={12} xs={12} >
                            <TextField
                                fullWidth
                                label="CURP"
                                name="curp"
                                variant="outlined"
                                required
                                value={ curp }                   
                                onChange={ ( e ) => { setCurp( e.target.value.toUpperCase() ); } }  
                                inputProps={{ maxLength: 18 }}
                                autoComplete="off"                
                            />
                            {   
                                errors.curp && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.curp } </Alert> )
                            } 
                        </Grid>     

                        <Grid item md={12} xs={12} >
                            <TextField
                                fullWidth
                                label="Nombre(s)"
                                name="nombres"
                                variant="outlined"                           
                                autoComplete='off'             
                                value={ nombres }                                
                                onChange={ ( e ) => { setNombres( e.target.value.toUpperCase() ); } }
                            />
                            {   
                                errors.nombres && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.nombres } </Alert> )
                            }
                        </Grid>        

                        <Grid item md={6} xs={12} >
                            <TextField
                                fullWidth
                                label="Apellido 1"
                                name="apellido1"
                                variant="outlined"                           
                                autoComplete='off'             
                                value={ apellido1 }                                
                                onChange={ ( e ) => { setApellido1( e.target.value.toUpperCase() ); } }
                            />
                            {   
                                errors.apellido1 && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.apellido1 } </Alert> )
                            }
                        </Grid>        

                        <Grid item md={6} xs={12} >
                            <TextField
                                fullWidth
                                label="Apellido 2"
                                name="apellido2"
                                variant="outlined"                           
                                autoComplete='off'             
                                value={ apellido2 }                                
                                onChange={ ( e ) => { setApellido2( e.target.value.toUpperCase() ); } }
                            />
                            {   
                                errors.apellido2 && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.apellido2 } </Alert> )
                            }
                        </Grid>        

                        <Grid item md={6} xs={12} >
                            <TextField
                                fullWidth
                                label="Número de credito"
                                name="numeroCredito"
                                variant="outlined"                           
                                autoComplete='off'             
                                value={ numeroCredito }                                
                                onChange={ ( e ) => { setNumeroCredito( e.target.value ); } }
                            />
                            {   
                                errors.numeroCredito && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.numeroCredito } </Alert> )
                            }
                        </Grid>        
                        
                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-tipoAcreditado-label">Tipo de acreditado</InputLabel>
                            
                                <Select
                                    labelId="select-tipoAcreditado-label"
                                    id="select-tipoAcreditado"                                            
                                    label="Tipo de acreditado"
                                    name="tipoAcreditado"
                                    value={ tipoAcreditado }
                                    onChange={ ( e ) => { setTipoAcreditado( e.target.value ); } }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        tipoAcreditadoArray.map( ( elem ) => (
                                            <MenuItem key={ elem.idTipoAcreditado } value={ elem.idTipoAcreditado }> { elem.descripcion } </MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>
                            {   
                                errors.tipoAcreditado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop : '1em' }} > { errors.tipoAcreditado } </Alert> )
                            }
                        </Grid>     

                        {   
                            errors.agregarPersona && ( <Grid item xs={12}> <Alert color='warning' severity="warning" variant="filled" > { errors.agregarPersona } </Alert> </Grid> )
                        }                                               

                    </Grid>

                </DialogContent>

                <DialogActions>

                    <Button onClick={ () => { setOpenModal( false ); setErrors( {} ); } }>Cancelar</Button>      

                      <LoadingButton 
                        type="button" 
                        variant="contained" 
                        startIcon={ <SaveIcon /> }
                        onClick={ handleGuardarDatos }
                        loading={ false }
                    > 
                        Guardar
                    </LoadingButton>              

                </DialogActions>

            </Dialog>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea subir el layout seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenConfirmacion( false ) }> Cancelar </Button>
                    <LoadingButton onClick={ handleSubirLayout } loading={ loadingSubir } variant='contained'> Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog open={ openModalEliminar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenModalEliminar( false ) }> Cancelar </Button>
                    <Button onClick={ handleEliminarCredito } variant='contained'> Aceptar </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={ openConfirmacionGuardar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea guardar la información del registro masivo?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenConfirmacionGuardar( false ) }> Cancelar </Button>
                    <LoadingButton onClick={ handleGuardarLayout } loading={ loadingGuardar } variant='contained'> Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog open={ openModalEliminarLayout } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea eliminar el layout masivo de este expediente?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => setOpenModalEliminarLayout( false ) }> Cancelar </Button>
                    <LoadingButton onClick={ handleEliminarLayout } variant='contained' loading={ loadingEliminarLayout }> Aceptar </LoadingButton>
                </DialogActions>
            </Dialog>
        
        </>
    )
}
