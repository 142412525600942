import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputBase, InputLabel, LinearProgress, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { ModalBuscarAcuerdo } from './ModalBuscarAcuerdo';
import { ModalNuevaPersona } from './ModalNuevaPersona';
import { ModalBuscarPersona } from './ModalBuscarPersona';

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';
import { BuscarIrregular } from '../../actions/recepcion/irregularActions';
import { BuscarAmparo } from '../../actions/recepcion/amparoActions';
import { BuscarExhortoRecibido } from '../../actions/recepcion/exhortoRecibidoActions';

import { GuardarNotificacion } from '../../actions/notificaciones/notificacionActions';
import { ObtenerTipoParte } from '../../actions/admin/catalogs/tipoparteActions';
import { ObtenerDependencias } from '../../actions/admin/catalogs/dependenciaActions';
import { ObtenerTipoNotificacion } from '../../actions/admin/catalogs/tiponotificacionActions';
import { BuscarConvocatorias } from '../../actions/tribunal/convocatoriaActions';

export const ModalNuevaNotificacion = ( { openModal, handleCloseModal } ) => {

    const { tribunal } = useSelector( state => state.auth );

    const history = useHistory();

    const [loading, setLoading] = useState( false );

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [errors, setErrors] = useState( { } );

    const [tipoBusqueda, setTipoBusqueda] = useState( '' );

    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [numeroIrregular, setNumeroIrregular] = useState('');
    const [numeroAmparo, setNumeroAmparo] = useState('');
    const [numeroExhortoRecibido, setNumeroExhortoRecibido] = useState('');

    const [expediente, setExpediente] = useState('');
    const [irregular, setIrregular] = useState('');
    const [amparo, setAmparo] = useState('');
    const [exhortoRecibido, setExhortoRecibido] = useState('');

    const [openModalBuscarAcuerdo, setOpenModalBuscarAcuerdo] = useState(false);    

    const [acuerdo, setAcuerdo] = useState( { idAcuerdo : 0, tipoAcuerdo: '' } );

    const [nuevaParte, setNuevaParte] = useState( false );
    const [parte, setParte] = useState( 0 );
    const [arrayParte, setArrayParte] = useState( [] );

    const [tipoParte, setTipoParte] = useState( 0 );
    const [tipoParteArray, setTipoParteArray] = useState( [ ] );

    const [personaDependencia, setPersonaDependencia] = useState( 0 );
   
    const [dependencia, setDependencia ] = useState( 0 );
    const [dependenciaArray, setDependenciaArray ] = useState( [] );
    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const [tipoNotificacion, setTipoNotificacion] = useState( 0 );
    const [tipoNotificacionArray, setTipoNotificacionArray] = useState( [ ] ) ;

    const [convocatorias, setConvocatorias] = useState( 0 );
    const [convocatoriasArray, setConvocatoriasArray] = useState( [ ] ) ;

    const [publicarEn, setPublicarEn] = useState( 0 );

    const [persona, setPersona] = useState( {
        idPersona: 0,
        nombreCompleto: ''
    });

    const [openModalNuevaPersona, setOpenModalNuevaPersona] = useState(false);    
    const [openModalBuscarPersona, setOpenModalBuscarPersona] = useState(false);

    const { idPersona: idPersonaNueva, nombreCompleto } = persona;
    const { idAcuerdo, tipoAcuerdo, fecha: fechaAcuerdo } = acuerdo;

    const handleOpenModalBuscarAcuerdo = () => {       
        setOpenModalBuscarAcuerdo(true);
    };

    const handleCloseModalBuscarAcuerdo = () => {       
        setOpenModalBuscarAcuerdo(false);
    };

    const seleccionarAcuerdo = ( acuerdo ) => {
        if( acuerdo ){
            setAcuerdo( acuerdo );
        }
    }       

    const limpiarDatos = () => {
        setErrors( { } );
        setNumeroExpediente('');
        setNumeroIrregular('');
        setNumeroAmparo('');
        setNumeroExhortoRecibido('');
        setExpediente('');
        setIrregular('');
        setAmparo('');
        setExhortoRecibido('');


        setNuevaParte( false );
        setPersonaDependencia( 0 );
        setTipoParte( 0 );
        setDependencia( 0 );
        setAutocompleteValue( null );
        setPersona({ idPersona: 0, nombreCompleto: '' });
        setAcuerdo({ idAcuerdo : 0, tipoAcuerdo: '' });
        setTipoNotificacion( 0 );
        setConvocatorias( 0 );
        setPublicarEn( 0 );
    }

    const cerrarModal = () => {
        
        handleCloseModal();
       
        setAcuerdo({ idAcuerdo : 0, nombreAcuerdo: '' });

        setErrors( { } );
        setTipoBusqueda('');
        setNumeroExpediente('');
        setNumeroIrregular('');
        setNumeroAmparo('');
        setNumeroExhortoRecibido('');
        setExpediente('');
        setIrregular('');
        setAmparo('');
        setExhortoRecibido('');
        setTipoNotificacion(0);
        setConvocatorias(0);  
    }

    const validateFielsExpediente = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un número de expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsIrregular = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroIrregular === '' ){
            valid = false;
            errores.irregular = 'Debes teclear un número de irregular';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsAmparo = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroAmparo === '' ){
            valid = false;
            errores.amparo = 'Debes teclear un número de amparo';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsExhortoRecibido = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExhortoRecibido === '' ){
            valid = false;
            errores.amparo = 'Debes teclear un número de exhorto';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
        setErrors( { } );
        
        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');

    }

    const errorIrregular = () => {
        setErrors( { } );

        let errores = {};  
        errores.irregular = 'No se encontró el irregular';
        errores.type = 'warning';
        
        setIrregular( {} );
        setErrors( errores );

        setNumeroIrregular('');
    }

    const errorAmparo = () => {
        setErrors( { } );

        let errores = {};  
        errores.amparo = 'No se encontró el amparo';
        errores.type = 'warning';
        
        setAmparo( {} );
        setErrors( errores );

        setNumeroAmparo('');
    }

    const errorExhortoRecibido = () => {
        setErrors( { } );

        let errores = {};  
        errores.exhortoRecibido = 'No se encontró el exhorto';
        errores.type = 'warning';
        
        setExhortoRecibido( {} );
        setErrors( errores );

        setNumeroExhortoRecibido('');
    }

    const handleChangeTipoBusqueda = (tipoBusqueda) => {

        setTipoBusqueda(tipoBusqueda);
        limpiarDatos();
        
        setParte( 0 );
        setArrayParte( [] );
    } 

    const handleClicBuscarExpediente = async () => {
    
        setLoading( true );
        setParte( 0 );
        setArrayParte( [] );

        const valid = validateFielsExpediente();

        if( !valid ){
           
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }
       
        await BuscarExpediente( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            
                            if(response.data.success){
                                setExpediente(response.data);
                                setArrayParte( response?.data?.partes ?? [] );
                                setAcuerdo({ idAcuerdo : 0, tipoAcuerdo: '' });
                                setConvocatorias( 0 );
                            }else{
                                errorExpediente();
                                setAcuerdo({ idAcuerdo : 0, tipoAcuerdo: '' });
                                setConvocatorias( 0 );
                            }
                        }
                    }, 1500);  
                    setNumeroExpediente('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroExpediente('');
                }
            }
        });
        
    }

    const handleClicBuscarIrregular = async () => {

        setLoading( true );

        const valid = validateFielsIrregular();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroIrregular: numeroIrregular
        }
        
        await BuscarIrregular( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setIrregular(response.data);
                                setArrayParte( response?.data?.partes ?? [] );
                            }else{
                                errorIrregular();
                            }
                        }
                    }, 1500);  
                    setNumeroIrregular('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroIrregular('');
                }
            }
        });
        
    }

    const handleClicBuscarAmparo = async () => {

        setLoading( true );

        const valid = validateFielsAmparo();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroAmparo: numeroAmparo
        }
        
        await BuscarAmparo( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setAmparo(response.data);
                                setArrayParte( response?.data?.partesAmparo ?? [] );
                            }else{
                                errorAmparo();
                            }
                        }
                    }, 1500);  
                    setNumeroAmparo('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroAmparo('');
                }
            }
        });
            
    }

    const handleClicBuscarExhortoRecibido= async () => {

        setLoading( true );

        const valid = validateFielsExhortoRecibido();
       
        if( !valid ){
            return false;
        }

        const params = {

            numeroExhorto: numeroExhortoRecibido
        }
      
        await BuscarExhortoRecibido( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setExhortoRecibido(response.data);
                                setArrayParte( response?.data?.partes ?? [] );
                            }else{
                                errorExhortoRecibido();
                            }
                        }
                    }, 1500);  
                    setNumeroExhortoRecibido('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroExhortoRecibido('');
                }
            }
        });
           
    }

    const handleChangeTipoNotificacion = ( value ) => {

        setTipoNotificacion( value ); 

        if( value === 3 || value === 4  || value === 5 || value === 6 || value === 7 )
        {
                   
            setAcuerdo({ idAcuerdo : 0, nombreAcuerdo: '' });

        }
        
        if( value === 3 || value === 4 || value === 6 || value === 7){

            setParte(0);
            setNuevaParte( false );
            setPersonaDependencia( 0 );
            setTipoParte( 0 );
            setDependencia( 0 );
            setAutocompleteValue( null );
            setPersona({ idPersona: 0, nombreCompleto: '' });
            

        }
        if(value === 0){

            setAcuerdo({ idAcuerdo : 0, nombreAcuerdo: '' });
            setParte(0);
            setNuevaParte( false );
            setPersonaDependencia( 0 );
            setTipoParte( 0 );
            setDependencia( 0 );
            setAutocompleteValue( null );
            setPersona({ idPersona: 0, nombreCompleto: '' });
            setConvocatorias(0);

        }

        if( value !== 3){

            setConvocatorias(0);
        }

    }

    const GenerarNotificacion = async () => {     
        
        let persona = 0;
        let depen = 0;
        let tipo = 0;

        if( nuevaParte ){
            persona = idPersonaNueva ?? 0;
            depen = dependencia;
            tipo = tipoParte;
        }
        else {
            if( parte !== 0 ){
                const { idPersona, idDependencia, idTipoParte } = arrayParte.find( ( e ) => e.id === parte );
    
                persona = idPersona ?? 0;
                depen = idDependencia ?? 0;
                tipo = idTipoParte ?? 0;
            }
        }

        const params = {
            idTipoNotificacion: tipoNotificacion,
            idPersona: persona,
            idDependencia: depen,
            idTipoParte: tipo,
            idExpediente: expediente?.idExpediente ?? 0,
            idIrregular: irregular?.idIrregular ?? 0,
            idAmparo: amparo?.idAmparo ?? 0,
            idExhortoRecibido: exhortoRecibido?.idExhortoRecibido ?? 0,
            idAcuerdo: idAcuerdo,
            idConvocatoria: convocatorias,
            publicarEn: publicarEn,
        }
      
        setLoadingGuardar( true );
                  
        await GuardarNotificacion( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                        
                        const { idNotificacion } = response.data;

                        setTimeout(() => {

                            setLoadingGuardar(false); 

                            if( tribunal ){
                                history.push("/Notificacion/SubirRazonamiento/" + idNotificacion);
                            }
                            else {
                                history.push("/Notificacion/GenerarNotificacion/" + idNotificacion);
                            }

                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {                      
                        
                        const { message } = response.data;

                        setLoadingGuardar(false);

                        let errores = {};  
                        errores.error = 'Ocurrió un error, contacte con el administrador ' + ( message ? `(${ message })` : '' );
                        errores.type = 'error';

                        setErrors( errores );

                    }, 1200);  
                }
            }
        }); 
        
    }   

    const handleNuevaPersona = ( value ) => {

        setNuevaParte( value ); 

        setParte( 0 );
        setPersonaDependencia( 0 );  
        setPersona( { idPersona: 0, nombreCompleto: '' } );      
        setAutocompleteValue( null );
        setDependencia( 0 );        
        setTipoParte( 0 );       
    }

    const handleChangePersonaDependencia = ( value ) => {
    
        setPersonaDependencia( value );
        setPersona( { idPersona: 0, nombreCompleto: '' } );
        setAutocompleteValue( null );
        setDependencia( 0 );
    }

    const handleOpenModalNuevaPersona = () => {       
        setOpenModalNuevaPersona(true);
    };

    const handleCloseModalNuevaPersona = () => {
        setOpenModalNuevaPersona(false);
    };   

    const handleOpenModalBuscarPersona = () => {       
        setOpenModalBuscarPersona(true);
    };

    const handleCloseModalBuscarPersona = () => {
        setOpenModalBuscarPersona(false);
    };

    const seleccionarPersona = ( persona ) => {
        if( persona ){
            setPersona( persona );
        }
    }   

    const handleChangeCentro = ( value ) => {

        if( value !== 2 ){     
            setNuevaParte( false );
            setParte( 0 );
            setPersonaDependencia( 0 );
            setTipoParte( 0 );
            setDependencia( 0 );
            setAutocompleteValue( null );
        }

        setPublicarEn( value );
    }

    useEffect( () => {
        
        async function Obtener(){

            await ObtenerTipoParte().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoParteArray( response.data );
                    }

                }


            });

        }     

        if( expediente?.success === true || amparo?.success === true || irregular?.success === true || exhortoRecibido?.success === true){
            Obtener();
        }        

    }, [ expediente, amparo, irregular, exhortoRecibido ]);

    useEffect(() => {

        async function Obtener(){            

            await ObtenerDependencias().then( response => {

                if( response ){

                    if( response.status === 200){
                        setDependenciaArray( response.data ); 
                    }
                    
                }
            })             
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {      
        
        async function Obtener(){

            await ObtenerTipoNotificacion().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoNotificacionArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
        
        async function Obtener(){

            await BuscarConvocatorias({idExpediente: expediente?.idExpediente?? 0}).then( response => {

                if( response ){

                    if( response.data ){
                        setConvocatoriasArray( response.data );
                    }

                }


            });

        }     
        if(tipoNotificacion === 3){
            Obtener();
        }


    }, [ tipoNotificacion, expediente])

    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Nueva notificación</DialogTitle>
            
                <DialogContent>
                    
                <Grid container spacing={4} >                    
                    
                    <Grid item md={7} xs={12} >
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Tipo de trámite:</FormLabel>
                            <RadioGroup row
                                aria-label="tipoTramite"
                                defaultValue="expediente"
                                name="radio-buttons-group"  
                            >
                                <FormControlLabel value="expediente" control={ <Radio checked={ tipoBusqueda === 'expediente' } onChange={ () => { handleChangeTipoBusqueda('expediente') } } />} label="Expediente" />
                                <FormControlLabel value="expedienteIrregular" control={ <Radio checked={ tipoBusqueda === 'irregular' } onChange={ () => { handleChangeTipoBusqueda('irregular') } } />} label="Irregular" />
                                <FormControlLabel value="amparo" control={ <Radio checked={ tipoBusqueda === 'amparo' } onChange={ () => { handleChangeTipoBusqueda('amparo') } } />} label="Amparo" />
                                <FormControlLabel value="exhortoRecibido" control={ <Radio checked={ tipoBusqueda === 'exhortoRecibido' } onChange={ () => { handleChangeTipoBusqueda('exhortoRecibido') } } />} label="Exhorto" />

                            </RadioGroup>
                        </FormControl>
                    </Grid>    

                    {
                        tipoBusqueda === 'expediente' && (
                            <>
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                            placeholder="Buscar por número de expediente"     
                                            name="expediente"
                                            value={ numeroExpediente } 
                                            onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarExpediente }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                          
                                    </Paper>
                                    {   
                                        errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                                    }
                                </Grid>
                            </>
                        )
                    }

                    {
                        (expediente.success) && (
                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Expediente:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { expediente.numeroExpediente }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    {
                                        expediente.ponencia
                                        &&                                
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Ponencia:
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    {
                                        expediente.ponencia
                                        &&  
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.ponencia }
                                        </Typography>
                                    }
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                           
                                    {
                                        expediente.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                 
                                    </List>      
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                            {
                                                expediente.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                                                                
                                    </List>
                                </Grid>  
                            </>
                        )
                    }

                    {
                        tipoBusqueda === 'irregular' && (
                            <Grid item md={12} xs={12} > 
                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                    <InputBase sx={{ ml: 1, flex: 1 }} 
                                        inputProps={{ 'aria-label': 'Buscar irregular' }} 
                                        placeholder="Buscar por número de irregular"     
                                        name="irregular"
                                        value={ numeroIrregular } 
                                        onChange={ ( e ) => { setNumeroIrregular( e.target.value ) }}
                                        autoComplete="off"
                                    />

                                    <LoadingButton 
                                        variant='contained' 
                                        sx={{ borderRadius: 10 }} 
                                        loading={ loading }
                                        onClick={ handleClicBuscarIrregular }
                                        size='small'>
                                        <SearchIcon />
                                    </LoadingButton>                           
                                </Paper>
                                {   
                                    errors.irregular && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.irregular  } </Alert> )
                                }
                            </Grid>  
                        )
                    }
                    
                    {
                        (irregular.success) && (
                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Irregular:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { irregular.numeroIrregular }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Tipo de irregular:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { irregular.tipoIrregular }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                           
                                    {
                                        irregular.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                                 
                                    </List>      
                                </Grid>
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                            {
                                                irregular.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                                                                
                                    </List>
                                </Grid>   

                            </>
                        )
                    }

                    {
                        tipoBusqueda === 'amparo' && (
                            <Grid item md={12} xs={12} > 
                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                    
                                    <InputBase sx={{ ml: 1, flex: 1 }} 
                                        inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                        placeholder="Buscar por número de amparo"     
                                        name="amparo"
                                        value={ numeroAmparo } 
                                        onChange={ ( e ) => { setNumeroAmparo( e.target.value ) }}
                                        autoComplete="off" 
                                    />

                                    <LoadingButton 
                                        variant='contained' 
                                        sx={{ borderRadius: 10 }} 
                                        loading={ loading }
                                        onClick={ handleClicBuscarAmparo }
                                        size='small'>
                                        <SearchIcon />
                                    </LoadingButton>                           
                                </Paper>
                                {   
                                    errors.amparo && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.amparo  } </Alert> )
                                }
                            </Grid>                       
                        )
                    }

                    {
                        amparo.success === true && (
                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Amparo:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { amparo.numeroAmparo }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Tipo de amparo:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { amparo.tipoAmparo }
                                    </Typography>
                                </Grid> 

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Partes(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                        {
                                            amparo.partes.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                
                                    </List>                            
                            
                                </Grid> 

                            </>

                        )
                    }

                    {
                        tipoBusqueda === 'exhortoRecibido' && (
                            <>
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar exhorto recibido' }} 
                                            placeholder="Buscar por número de exhorto"     
                                            name="exhortoRecibido"
                                            value={ numeroExhortoRecibido } 
                                            onChange={ ( e ) => { setNumeroExhortoRecibido( e.target.value ) }}
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarExhortoRecibido }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                          
                                    </Paper>
                                    {   
                                        errors.exhortoRecibido && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.exhortoRecibido  } </Alert> )
                                    }
                                </Grid>
                            </>
                        )
                    }

                    {
                        (exhortoRecibido.success) && (
                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                    Exhorto:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                    { exhortoRecibido.numeroExhorto }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>                                                             
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Tipo de juicio:
                                    </Typography>                                
                                </Grid>

                                <Grid item md={4} xs={6} >                               
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { exhortoRecibido.tipoJuicio }
                                    </Typography>                                
                                </Grid> 

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        exhortoRecibido.actor.map((elem, i)=>(
                                            <ListItem key={ i } disablePadding> 
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                    { elem }
                                                </Typography>
                                            </ListItem>                    
                                        ))
                                    }                                
                                    </List>                            
                            
                                </Grid> 
                        
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                        
                                <Grid item md={4} xs={6} >
                                    <List disablePadding>
                                        {
                                            exhortoRecibido.demandado.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                  
                                    </List>
                                </Grid>    

                                <Grid item md={2} xs={6}>                                                             
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Autoridad del exhorto:
                                    </Typography>                                
                                </Grid>

                                <Grid item md={4} xs={6} >                               
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { exhortoRecibido.autoridadExhorto }
                                    </Typography>                                
                                </Grid>                             
                                
                                <Grid item md={2} xs={6}>    
                                    {
                                        exhortoRecibido.ponencia
                                        &&                                                     
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Ponencia:
                                        </Typography>   
                                    }                             
                                </Grid>                            

                                <Grid item md={4} xs={6} >
                                    {
                                        exhortoRecibido.ponencia
                                        &&       
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoRecibido.ponencia }
                                        </Typography>
                                    }
                                </Grid> 

                            </>
                        )
                    }   

                    {
                        <Grid item md={12} xs={12} > 

                        <FormControl fullWidth>
                            <InputLabel id="select-notificacion-label">Seleccione el tipo de notificación</InputLabel>

                            <Select
                                labelId="select-notificacion-label"
                                id="select-notificacion"                                            
                                label="Seleccione el tipo de notificación<"   
                                name="seleccioneNotificacion"
                                value={ tipoNotificacion }
                                onChange={ (e) => { handleChangeTipoNotificacion(e.target.value) } }      
                                disabled={ !expediente?.success && !irregular?.success && !amparo?.success && !exhortoRecibido?.success }                                          
                            >
                                <MenuItem value={0}>Selecciona una opción</MenuItem>                                     
                                {                                       
                                    tipoNotificacionArray                                          
                                    .map((elem)=>(
                                        <MenuItem key={ elem.idTipoNotificacion } value={ elem.idTipoNotificacion }> { elem.descripcion } </MenuItem>
                                    ))                                       
                                } 
                            </Select>

                        </FormControl>

                        {   
                            errors.tipoNotificacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.parte } </Alert> )
                        }

                        </Grid> 
                    }

                    {
                        ( tipoNotificacion === 3) && (
                        ( tipoBusqueda === 'expediente' && expediente?.success ) ||
                        ( tipoBusqueda === 'irregular' && irregular?.success ) ||
                        ( tipoBusqueda === 'amparo' && amparo?.success ) ||
                        ( tipoBusqueda === 'exhortoRecibido' && exhortoRecibido?.success ) )
                        ?
                            <>
                                <Grid item md={12} xs={12} > 
                                    <FormControl fullWidth>
                                        <InputLabel id="select-convocatoria-label">Seleccione la convocatoria</InputLabel>

                                        <Select
                                            labelId="select-convocatoria-label"
                                            id="select-convocatoria"                                            
                                            label="Seleccione la convocatoria<"   
                                            name="seleccioneConvocatoria"
                                            value={ convocatorias }
                                            onChange={  (e) => { setConvocatorias(e.target.value); }  }                                                
                                        >
                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                                     
                                            {                                       
                                                convocatoriasArray                                          
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.idConvocatoria } value={ elem.idConvocatoria } >  {elem.nombre}  &nbsp; <strong> {elem.fecha}</strong> </MenuItem>
                                                ))                                       
                                            } 
                                        </Select>

                                    </FormControl>
                                </Grid> 

                                <Grid item md={5} xs={12} >
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" sx={{ fontWeight: 'bold', fontSize: 14 }}>Se publicará la convocatoria por:</FormLabel>
                                        <FormGroup row
                                            aria-label="exhorto"
                                            name="exhorto"                        
                                        >                                            
                                            <FormControlLabel control={<Radio checked={ publicarEn === 1 } onChange={ (e) => handleChangeCentro( 1 ) }  />} label="Estrados" />
                                            <FormControlLabel control={<Radio checked={ publicarEn === 2  } onChange={ (e) => handleChangeCentro( 2 ) }  />} label="Centro de trabajo" />
                                        </FormGroup>
                                    </FormControl>
                                </Grid> 
                            </>
                        :
                        null
                    }   

                    {
                        ( ( tipoNotificacion !== 0 && tipoNotificacion !== 3 ) || ( tipoNotificacion === 3 && publicarEn === 2 ) ) && (
                        ( tipoBusqueda === 'expediente' && expediente?.success ) ||
                        ( tipoBusqueda === 'irregular' && irregular?.success ) ||
                        ( tipoBusqueda === 'amparo' && amparo?.success ) ||
                        ( tipoBusqueda === 'exhortoRecibido' && exhortoRecibido?.success ) )
                        ?
                            <Grid item md={12} xs={12} >

                                <FormControl fullWidth>
                                                                    
                                    <Grid container spacing={3}>
                                        
                                        <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                            <FormLabel sx={{ color: 'black' }} >Persona a notificar</FormLabel>
                                        </Grid>
                                        
                                        <Grid item md={8} xs={12} >
                                            <RadioGroup row >
                                                <Grid container>                                                
                                                    <Grid item md={6} xs={6} >
                                                        <FormControlLabel value="No" control={ <Radio checked={ nuevaParte === false } onChange={ () => { handleNuevaPersona( false ); } } /> } label="Actor / Demandado" />     
                                                    </Grid>   
                                                    <Grid item md={6} xs={6} >
                                                        <FormControlLabel value="Si" control={ <Radio checked={ nuevaParte === true } onChange={ () => { handleNuevaPersona( true ); } } /> } label="Otro" />
                                                    </Grid>                                                         
                                                </Grid>
                                            </RadioGroup>
                                        </Grid>

                                    </Grid>

                                </FormControl>

                            </Grid>
                        :
                            null
                    }

                    {
                        (   ( ( tipoNotificacion !== 0 && tipoNotificacion !== 3 ) || ( tipoNotificacion === 3 && publicarEn === 2 ) ) &&(
                            ( tipoBusqueda === 'expediente' && expediente?.success ) ||
                            ( tipoBusqueda === 'irregular' && irregular?.success ) ||
                            ( tipoBusqueda === 'amparo' && amparo?.success ) ||
                            ( tipoBusqueda === 'exhortoRecibido' && exhortoRecibido?.success ) )
                        ) && !nuevaParte
                        ?
                            <Grid item md={12} xs={12} > 

                                <FormControl fullWidth>
                                    <InputLabel id="select-parte-label">Seleccione la persona a notificar</InputLabel>

                                    <Select
                                        labelId="select-parte-label"
                                        id="select-parte"                                            
                                        label="Seleccione la persona a notificar"   
                                        name="seleccioneParte"
                                        value={ parte }
                                        onChange={ (e) => { setParte(e.target.value); } }                                                  
                                    >
                                        <MenuItem value={0}>Selecciona una opción</MenuItem>                                     
                                        {             
                                            tipoNotificacion === 3
                                            ?
                                                arrayParte                               
                                                .filter( (e) => e.idTipoParte === 11 )           
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombre } &nbsp; <strong>({ elem?.tipoParte?.descripcion ?? '' })</strong> </MenuItem>
                                                ))                                       
                                            :
                                        
                                                arrayParte                                          
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.id } value={ elem.id }> { elem.nombre } &nbsp; <strong>({ elem?.tipoParte?.descripcion ?? '' })</strong> </MenuItem>
                                                ))                                       
                                        } 
                                    </Select>

                                </FormControl>

                                {   
                                    errors.parte && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.parte } </Alert> )
                                }

                            </Grid>                             
                        :
                            null
                    }                    
                    
                    {
                        (
                            ( tipoBusqueda === 'expediente' && expediente?.success ) ||
                            ( tipoBusqueda === 'irregular' && irregular?.success ) ||
                            ( tipoBusqueda === 'amparo' && amparo?.success ) ||
                            ( tipoBusqueda === 'exhortoRecibido' && exhortoRecibido?.success ) 
                        ) && nuevaParte
                        ? 
                            <>
                            
                                {
                                    tribunal && nuevaParte
                                    &&  
                                        <Grid item md={12} xs={12} >

                                            <FormControl fullWidth>
                                                                                
                                                <Grid container spacing={3}>
                                                    
                                                    <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                        <FormLabel sx={{ color: 'black' }} >Selecciona una opción para agregar: </FormLabel>
                                                    </Grid>
                                                    
                                                    <Grid item md={4} xs={12} >
                                                        <RadioGroup row >
                                                            <Grid container >
                                                                <Grid item md={6} xs={6} >
                                                                    <FormControlLabel value="Masculino" control={ <Radio checked={ personaDependencia === 1 } onChange={ () => { handleChangePersonaDependencia(1) } } /> } label="Persona" />
                                                                </Grid>
                                                                <Grid item md={6} xs={6} >
                                                                    <FormControlLabel value="Femenino" control={ <Radio checked={ personaDependencia === 2 } onChange={ () => { handleChangePersonaDependencia(2) } } /> } label="Dependencia" />     
                                                                </Grid>                                                            
                                                            </Grid>
                                                        </RadioGroup>
                                                    </Grid>

                                                </Grid>

                                            </FormControl>

                                        </Grid>
                                }

                                {
                                    ( ( nuevaParte && !tribunal ) || ( nuevaParte && tribunal && personaDependencia === 1 ) )
                                    &&
                                        <Grid item md={ tribunal ? 8 : 8} xs={12} sx={{ mt: 1 }} > 
                                            <Paper component="div" sx={{ p: '12px 0px', display: 'flex', alignItems: 'center', width: '100%' }}>                            
                                                            
                                                <InputBase 
                                                    sx={{ ml: 1, flex: 1 }} 
                                                    inputProps={{ 'aria-label': 'Buscar persona' }} 
                                                    placeholder="Buscar persona"     
                                                    name="persona"
                                                    value={ nombreCompleto }   
                                                    onChange={ () => { } }             
                                                    autoComplete="off"      
                                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                                /> 

                                                <Button 
                                                    sx={{ borderRadius: 10, mr: 1 }} 
                                                    variant='contained' 
                                                    onClick={ handleOpenModalBuscarPersona }
                                                    size='small'
                                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                                >
                                                    <SearchIcon />
                                                </Button>         

                                                <Button 
                                                    sx={{ borderRadius: 10, mr: 1 }} 
                                                    variant='contained' 
                                                    onClick={ handleOpenModalNuevaPersona }
                                                    size='small' 
                                                    disabled={ tribunal ? ( personaDependencia === 2 || personaDependencia === 0 ) : false }
                                                >
                                                    <PersonAddIcon />
                                                </Button>         

                                            </Paper>

                                        </Grid>
                                }

                                {
                                    tribunal && nuevaParte && personaDependencia === 2
                                    &&   
                                        <Grid item md={8} xs={12} sx={{ mt: 1 }} >
                                            
                                            <Autocomplete                    
                                                disablePortal 
                                                fullWidth              
                                                value={ autocompleteValue }   
                                                options={ dependenciaArray }
                                                onChange={ (event, value) => { setDependencia( value?.idDependencia ?? 0 ); setAutocompleteValue( value ); } }                        
                                                getOptionLabel={ (option) => option.descripcion}
                                                renderOption={ ( props, option ) => (
                                                    <li { ...props } key={ option.idDependencia} >                         
                                                        { option.descripcion }
                                                    </li>
                                                )}
                                                renderInput={(params) => <TextField {...params} label="Dependencia" />}
                                                disabled={ personaDependencia === 1 || personaDependencia === 0 }                    
                                            />

                                        </Grid>  
                                }

                                {
                                    ( nuevaParte && personaDependencia !== 0 && tribunal)
                                    &&
                                        <Grid item md={ tribunal ? 4 : 4 } xs={12} sx={{ mt: 1 }} >                            
                                            <FormControl fullWidth>
                                                <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>
                                            
                                                <Select
                                                    labelId="select-tipoParte-label"
                                                    id="select-tipoParte"                                            
                                                    label="Tipo de parte"
                                                    name="tipoParte"
                                                    value={ tipoParte }
                                                    onChange={ ( e ) => { setTipoParte( e.target.value ); } }
                                                    disabled={ ( tribunal ? personaDependencia === 0 : false ) }  
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                    {      
                                                        amparo?.success
                                                        ?                                                                    
                                                            tipoParteArray
                                                            .filter( (element ) => { return element.idTipoParte === 3 || element.idTipoParte === 4 || element.idTipoParte === 5; } )
                                                            .map( ( element ) => (
                                                                <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                            ))                                        
                                                        :                                                            
                                                            tipoParteArray
                                                            .filter( (element ) => { return element.idTipoParte !== 3 && element.idTipoParte !== 4 && element.idTipoParte !== 5; } )
                                                            .map( ( element ) => (
                                                                <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                            ))
                                                    }
                                                </Select>

                                            </FormControl>
                                        </Grid>                
                                }

                                {
                                    ( nuevaParte && !tribunal)
                                    &&
                                        <Grid item md={ tribunal ? 4 : 4 } xs={12} sx={{ mt: 1 }} >                            
                                            <FormControl fullWidth>
                                                <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>
                                            
                                                <Select
                                                    labelId="select-tipoParte-label"
                                                    id="select-tipoParte"                                            
                                                    label="Tipo de parte"
                                                    name="tipoParte"
                                                    value={ tipoParte }
                                                    onChange={ ( e ) => { setTipoParte( e.target.value ); } }
                                                    disabled={ ( tribunal ? personaDependencia === 0 : false ) }  
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                    {      
                                                        amparo?.success
                                                        ?                                                                    
                                                            tipoParteArray
                                                            .filter( (element ) => { return element.idTipoParte === 3 || element.idTipoParte === 4 || element.idTipoParte === 5; } )
                                                            .map( ( element ) => (
                                                                <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                            ))                                        
                                                        :                                                            
                                                            tipoParteArray
                                                            .filter( (element ) => { return element.idTipoParte !== 3 && element.idTipoParte !== 4 && element.idTipoParte !== 5; } )
                                                            .map( ( element ) => (
                                                                <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }>{ element.descripcion }</MenuItem>                                                        
                                                            ))
                                                    }
                                                </Select>

                                            </FormControl>
                                        </Grid>                
                                }
                            
                            </>
                        :
                            null
                    }

                    {
                        ( ( tipoNotificacion !== 0 && tipoNotificacion !== 3 ) ) && (
                        ( tipoBusqueda === 'expediente' && expediente?.success ) ||
                        ( tipoBusqueda === 'irregular' && irregular?.success ) ||
                        ( tipoBusqueda === 'amparo' && amparo?.success ) ||
                        ( tipoBusqueda === 'exhortoRecibido' && exhortoRecibido?.success ) )
                        ?
                            <Grid item md={12} xs={12} sx={{ mt: 1 }} > 
                                <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                             
                                                
                                    <InputBase 
                                        sx={{ ml: 1, flex: 1 }} 
                                        inputProps={{ 'aria-label': 'Buscar persona' }} 
                                        placeholder="Buscar acuerdo"     
                                        name="acuerdo"
                                        value={ '' }   
                                        onChange={ () => { } }             
                                        autoComplete="off"      
                                    /> 

                                    <Button 
                                        sx={{ borderRadius: 10, mr: 1 }} 
                                        variant='contained' 
                                        onClick={ handleOpenModalBuscarAcuerdo }
                                        size='small'
                                    >
                                        <SearchIcon />
                                    </Button> 

                                </Paper>

                            </Grid>  
                        :
                            null
                    }   

                    {
                        idAcuerdo !== 0 
                        && (
                            <>
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Acuerdo:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { tipoAcuerdo }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Fecha:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { fechaAcuerdo }
                                    </Typography>
                                </Grid>
                            </>            
                        )
                    }

                    {   
                        errors.error && ( 
                            <Grid item md={12} xs={12} >
                                
                                <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.error  } </Alert> 
                                
                            </Grid>
                        )
                    }                                                   

                </Grid>            

                </DialogContent>

                <DialogActions>
                    <Button onClick={ cerrarModal }>Cancelar</Button>

                    <Button 
                        variant="contained" 
                        color="primary" 
                        startIcon={ <SaveIcon /> } 
                        onClick={ GenerarNotificacion } 
                        disabled={

                            tipoBusqueda === '' ||
                            (tipoBusqueda === 'expediente' && !expediente?.success) ||
                            (tipoBusqueda === 'irregular' && !irregular?.success)  ||
                            (tipoBusqueda === 'amparo' && !amparo?.success)  ||
                            (tipoBusqueda === 'exhortoRecibido' && !exhortoRecibido?.success) ||
                            tipoNotificacion === 0 || 
                            ( 
                                ( ( tipoNotificacion !== 3 ) && !nuevaParte )
                                ?
                                    tipoBusqueda === '' ||
                                    tipoBusqueda === 'expediente' ? parte === 0 || !expediente?.success || idAcuerdo === 0 : false ||
                                    tipoBusqueda === 'irregular' ? parte === 0 || !irregular?.success || idAcuerdo === 0 : false ||
                                    tipoBusqueda === 'amparo' ? parte === 0 || !amparo?.success || idAcuerdo === 0 : false ||
                                    tipoBusqueda === 'exhortoRecibido' ? parte === 0 || !exhortoRecibido?.success || idAcuerdo === 0 : false                             
                                :    
                                    ( ( tipoNotificacion !== 3 ) && nuevaParte ) 
                                    ?
                                        tipoBusqueda === '' ||
                                        tipoBusqueda === 'expediente' ? ( tribunal ? ( personaDependencia === 0 || ( personaDependencia === 1 && idPersonaNueva === 0 ) || ( personaDependencia === 2 && dependencia === 0 ) ||  tipoParte === 0 ) : ( idPersonaNueva === 0 || tipoParte === 0 ) ) || !expediente?.success || idAcuerdo === 0 : false ||
                                        tipoBusqueda === 'irregular' ? ( tribunal ? ( personaDependencia === 0 || ( personaDependencia === 1 && idPersonaNueva === 0 ) || ( personaDependencia === 2 && dependencia === 0 ) ||  tipoParte === 0 ) : ( idPersonaNueva === 0 || tipoParte === 0 ) ) || !irregular?.success || idAcuerdo === 0 : false ||
                                        tipoBusqueda === 'amparo' ? ( tribunal ? ( personaDependencia === 0 || ( personaDependencia === 1 && idPersonaNueva === 0 ) || ( personaDependencia === 2 && dependencia === 0 ) ||  tipoParte === 0 ) : ( idPersonaNueva === 0 || tipoParte === 0 ) ) || !amparo?.success || idAcuerdo === 0 : false ||
                                        tipoBusqueda === 'exhortoRecibido' ? ( tribunal ? ( personaDependencia === 0 || ( personaDependencia === 1 && idPersonaNueva === 0 ) || ( personaDependencia === 2 && dependencia === 0 ) ||  tipoParte === 0 ) : ( idPersonaNueva === 0 || tipoParte === 0 ) ) || !exhortoRecibido?.success || idAcuerdo === 0 : false
                                    :
                                        false                             
                            )||
                            ( tipoNotificacion === 3 && convocatorias === 0 ) ||
                            ( tipoNotificacion === 3 && publicarEn === 0 ) ||
                            ( tipoNotificacion === 3 && publicarEn === 2 && !nuevaParte && parte === 0 ) ||
                            ( tipoNotificacion === 3 && publicarEn === 2 && nuevaParte && 
                                ( 
                                    tribunal 
                                    ? 
                                        ( 
                                            personaDependencia === 0 || 
                                            ( personaDependencia === 1 && idPersonaNueva === 0 ) || 
                                            ( personaDependencia === 2 && dependencia === 0 ) ||  
                                            tipoParte === 0 
                                        ) 
                                    : 
                                        ( idPersonaNueva === 0 || tipoParte === 0 ) 
                                )
                            ) ||
                            loadingGuardar                                
                        }
                            
                    >
                        Guardar
                    </Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>        

            <ModalNuevaPersona 
                openModal={ openModalNuevaPersona } 
                handleCloseModal={ handleCloseModalNuevaPersona } 
                seleccionarPersona={ seleccionarPersona }
            />

            <ModalBuscarPersona 
                openModal={ openModalBuscarPersona } 
                handleCloseModal={ handleCloseModalBuscarPersona } 
                seleccionarPersona={ seleccionarPersona }
            />  

            <ModalBuscarAcuerdo 
                openModal={ openModalBuscarAcuerdo } 
                handleCloseModal={ handleCloseModalBuscarAcuerdo} 
                seleccionarAcuerdo={ seleccionarAcuerdo }
                expediente={ expediente }
                irregular={ irregular }
                amparo={ amparo }
                exhortoRecibido={ exhortoRecibido }
                convocatoria={ tipoNotificacion === 3  }
            />
        </>
    )
}
