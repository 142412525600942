import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, InputBase, InputLabel, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Select, Snackbar, Switch, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AppBarMenu from '../ui/AppBarMenu';

import SearchIcon from '@mui/icons-material/Search';
import BookIcon from '@mui/icons-material/Book';
import ListIcon from '@mui/icons-material/List';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import moment from 'moment';

import '../../css/index.css';

import { ObtenerTiposConsecutivos } from '../../actions/admin/catalogs/tipoConsecutivoActions';
import { ObtenerAutoridades } from '../../actions/admin/catalogs/autoridadActions';

import { AudienciasExpediente, EtapasExpediente, HabilitarDesabilitarExpediente, ObtenerExpedienteVirtual_Expediente } from '../../actions/recepcion/expedienteActions';
import { EtapasExhortoRecibido, ObtenerExpedienteVirtual_ExhortoRecibido } from '../../actions/recepcion/exhortoRecibidoActions';
import { EtapasIrregular, ObtenerExpedienteVirtualConvenioFueraJuicio, ObtenerExpedienteVirtual_Irregular } from '../../actions/recepcion/irregularActions';
import { ObtenerExpedienteVirtual_Amparo } from '../../actions/recepcion/amparoActions';

export const ExpedienteVirtualScreen = () => {

    const { administrador, coordinador, tribunal } = useSelector( state => state.auth );

    const [numeroReferencia, setNumeroReferencia] = useState( '' );
    
    const [expediente, setExpediente] = useState( {} );
    const [exhortoRecibido, setExhortoRecibido] = useState( {} );
    const [irregular, setIrregular] = useState( {} );
    const [amparo, setAmparo] = useState( {} );

    const [loading, setLoading] = useState( false );
    const [openDocumento, setOpenDocumento] = useState( false );
    const [documento, setDocumento] = useState( {} );

    const [loadingEtapas, setLoadingEtapas] = useState( false );
    const [etapas, setEtapas] = useState( [] );
    const [openEtapas, setOpenEtapas] = useState( false );

    const [loadingAudiencias, setLoadingAudiencias] = useState( false );
    const [audiencias, setAudiencias] = useState( [] );
    const [openAudiencias, setOpenAudiencias] = useState( false );

    const [tipoConsecutivo, setTipoConsecutivo] = useState( 0 );
    const [tipoConsecutivoArray, setTipoConsecutivoArray] = useState( [] );

    const [autoridad, setAutoridad] = useState( 0 );
    const [autoridadArray, setAutoridadArray] = useState( [] );

    const [usuariosAutorizados, setUsuariosAutorizados] = useState( [] );
    const [openUsuariosAutorizados, setOpenUsuariosAutorizados] = useState( false );

    const [fullScreen, setFullScreen] = useState( false );

    const [alert, setAlert] = useState( {  } );

    const [openArchivo, setOpenArchivo] = useState( false );
    const [archivo, setArchivo] = useState( '' );

    const handleCriteriosBusqueda = () => {

        setExpediente( {} );
        setUsuariosAutorizados( [] );
        setExhortoRecibido( {} );
        setIrregular( {} );
        setAmparo( {} );

        if( tipoConsecutivo === 1 ){
            handleBuscarExpediente();
        }
        else if( tipoConsecutivo === 8 ){
            handleBuscarExhortoRecibido();
        }
        else if( tipoConsecutivo === 2 ){
            handleBuscarIrregular();
        }
        else if( tipoConsecutivo === 7 ){
            handleBuscarAmparo();
        }
        else if( tipoConsecutivo === 11 ){
            handleBuscarIrregularConvenio();
        }
    }

    const handleBuscarExpediente = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_Expediente({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setExpediente( response.data );

                            setUsuariosAutorizados( response.data?.expedienteAutorizados?.map( ( elem ) => { 

                                elem.loading = false;
                                return elem;

                            }) ?? [] );

                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el expediente ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );             
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarExhortoRecibido = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_ExhortoRecibido({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setExhortoRecibido( response.data );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el exhorto ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarIrregular = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_Irregular({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setIrregular( response.data );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el irregular ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarIrregularConvenio = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtualConvenioFueraJuicio({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setIrregular( response.data );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el irregular ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleBuscarAmparo = async () => {

        setLoading( true );

        await ObtenerExpedienteVirtual_Amparo({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setAmparo( response.data );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: 'No se encontro el amparo ó no se tiene los permisos para visualizarlo.',
                                type: 'error'
                            } );   
                            
                            setTipoConsecutivo( 0 );
                            setAutoridad( 0 );
                            setNumeroReferencia( '' );

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    const handleObtenerEtapas = async () => {

        setLoadingEtapas( true );                            

        await EtapasExpediente( expediente.idExpediente ).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        setTimeout(() => {
                    
                            setLoadingEtapas( false );                            
                            setEtapas( response.data );
                            setOpenEtapas( true );

                        }, 500);                        

                    }

                }
                else{

                    setTimeout(() => {
                    
                        setLoadingEtapas( false );  

                    }, 500);     

                }

            }

        });       

    } 

    const handleObtenerEtapasExhortoRecibido = async () => {

        setLoadingEtapas( true );                            

        await EtapasExhortoRecibido( exhortoRecibido.idExhortoRecibido ).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        setTimeout(() => {
                    
                            setLoadingEtapas( false );                            
                            setEtapas( response.data );
                            setOpenEtapas( true );

                        }, 500);                        

                    }

                }
                else{

                    setTimeout(() => {
                    
                        setLoadingEtapas( false );  

                    }, 500);     

                }

            }

        });       

    } 

    const handleObtenerAudiencias = async () => {

        setLoadingAudiencias( true );                            

        await AudienciasExpediente( expediente.idExpediente ).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        setTimeout(() => {
                    
                            setLoadingAudiencias( false );                            
                            setAudiencias( response.data );
                            setOpenAudiencias( true );

                        }, 500);                        

                    }

                }
                else{

                    setTimeout(() => {
                    
                        setLoadingAudiencias( false );  

                    }, 500);     

                }

            }

        });       

    } 

    const handleObtenerEtapasIrregular = async () => {

        setLoadingEtapas( true );                            

        await EtapasIrregular( irregular.idIrregular ).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        setTimeout(() => {
                    
                            setLoadingEtapas( false );                            
                            setEtapas( response.data );
                            setOpenEtapas( true );

                        }, 500);                        

                    }

                }
                else{

                    setTimeout(() => {
                    
                        setLoadingEtapas( false );  

                    }, 500);     

                }

            }

        });       

    } 

    const handleChangeActivar = async ( activo, referencia, id ) => {

        setUsuariosAutorizados(
            usuariosAutorizados.map( ( elem ) => {
                if( elem.referencia === referencia ){
                    elem.loading = true;
                }
                return elem;
            })
        );

        await HabilitarDesabilitarExpediente({ idExpedienteAsignado: referencia, activar: activo, id }).then(response => {

            if (response) {

                if (response.status === 200) {

                    const { success, message } = response.data;

                    if ( success ) {

                        setTimeout(() => {

                            setUsuariosAutorizados(
                                usuariosAutorizados.map( ( elem ) => {
                                    if( elem.referencia === referencia ){
                                        elem.loading = false;
                                        elem.activo = activo;
                                    }
                                    return elem;
                                })
                            );
                            
                        }, 1000);

                    }
                    else {
                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } );                          

                            setUsuariosAutorizados(
                                usuariosAutorizados.map( ( elem ) => {
                                    if( elem.referencia === referencia ){
                                        elem.loading = false;
                                    }
                                    return elem;
                                })
                            );
                            
                        }, 1000);
                    }

                }

            }

        });       

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposConsecutivos().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoConsecutivoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerAutoridades().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAutoridadArray( response.data );
                    }
                }
                
            });

        }

        if( administrador || coordinador ){
            Obtener();
        }

    }, [ administrador, coordinador ]);

    return (
        <>
            <AppBarMenu />

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <Box sx={{ marginTop: '5em' }}>  
               
                <Container maxWidth="xl" sx={{ mb: 5 }} >
                    
                    <Grid container spacing={3} sx={{ mt: { sm: 10 } }}  >
                        
                        <Grid item xs={12} sx={{ mb: { sm: 1 } }}>

                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}> 
                               
                                    <Card>

                                        <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Criterios de busqueda </Typography> } />

                                        <Divider />

                                        <CardContent sx={{ mt: 1 }}>

                                            <Grid container spacing={3} >

                                                <Grid item md={4} sm={6} xs={12}> 
                                                
                                                    <FormControl fullWidth>
                                                        <InputLabel id="select-tipoConsecutivo-label">Tipo de consecutivo</InputLabel>
                                                    
                                                        <Select
                                                            labelId="select-tipoConsecutivo-label"
                                                            id="select-tipoConsecutivo"                                            
                                                            label="Tipo de consecutivo"
                                                            name="tipoConsecutivo"
                                                            value={ tipoConsecutivo }                                
                                                            onChange={ ( e ) => setTipoConsecutivo( e.target.value ) }
                                                        >
                                                            <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                            {
                                                                tribunal
                                                                ?
                                                                    tipoConsecutivoArray
                                                                    .filter( ( elem ) => { return elem.idTipoConsecutivo === 1 || elem.idTipoConsecutivo === 2 || elem.idTipoConsecutivo === 7 || elem.idTipoConsecutivo === 8|| elem.idTipoConsecutivo === 11; } )
                                                                    .map( ( elem ) => (
                                                                        <MenuItem key={ elem.idTipoConsecutivo } value={ elem.idTipoConsecutivo }> { elem.descripcion } </MenuItem>    
                                                                    ))
                                                                :
                                                                    tipoConsecutivoArray
                                                                    .filter( ( elem ) => { return elem.idTipoConsecutivo === 1 || elem.idTipoConsecutivo === 2 || elem.idTipoConsecutivo === 7 || elem.idTipoConsecutivo === 8; } )
                                                                    .map( ( elem ) => (
                                                                        <MenuItem key={ elem.idTipoConsecutivo } value={ elem.idTipoConsecutivo }> { elem.descripcion } </MenuItem>    
                                                                    ))
                                                            }  
                                                                                                
                                                        </Select>

                                                    </FormControl>
                                                
                                                </Grid>                                               

                                                {
                                                    ( administrador || coordinador )
                                                    &&     
                                                    <Grid item md={4} sm={6} xs={12}> 
                                                    
                                                        <FormControl fullWidth>
                                                            <InputLabel id="select-autoridad-label">Autoridad</InputLabel>
                                                        
                                                            <Select
                                                                labelId="select-autoridad-label"
                                                                id="select-autoridad"                                            
                                                                label="Autoridad"
                                                                name="autoridad"
                                                                value={ autoridad }                                
                                                                onChange={ ( e ) => setAutoridad( e.target.value ) }
                                                            >
                                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                                {
                                                                    coordinador
                                                                    ?
                                                                        autoridadArray 
                                                                        .filter( ( elem ) => { return elem.idAutoridad !== 1; } )
                                                                        .map( ( elem ) => (
                                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                        ))
                                                                    :
                                                                        autoridadArray.map( ( elem ) => (
                                                                            <MenuItem key={ elem.idAutoridad } value={ elem.idAutoridad }> { elem.descripcion } </MenuItem>    
                                                                        ))
                                                                }                          
                                                            </Select>

                                                        </FormControl>
                                                    
                                                    </Grid>
                                                }

                                                <Grid item md={4} sm={6} xs={12} > 

                                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                              
                                                        
                                                        <InputBase sx={{ ml: 1, flex: 1 }} inputProps={{ 'aria-label': 'Buscar por referencia' }} 
                                                            placeholder="Buscar por referencia"     
                                                            value={ numeroReferencia }
                                                            onChange={ ( e ) => setNumeroReferencia( e.target.value )  }
                                                        />

                                                        <LoadingButton 
                                                            sx={{ borderRadius: 10, mr: 1 }} 
                                                            variant='contained' 
                                                            onClick={ handleCriteriosBusqueda }
                                                            size='small'
                                                            loading={ loading }
                                                            disabled={
                                                                ( administrador || coordinador )
                                                                ?
                                                                    tipoConsecutivo === 0 ||
                                                                    autoridad === 0 ||
                                                                    numeroReferencia === ''
                                                                :
                                                                    tipoConsecutivo === 0 ||
                                                                    numeroReferencia === ''
                                                            }
                                                        >
                                                            <SearchIcon />
                                                        </LoadingButton> 

                                                    </Paper>

                                                </Grid>
                                            
                                            </Grid>

                                        </CardContent>
                                    
                                    </Card>

                                </Grid>

                            </Grid>

                        </Grid>                        
                        
                        <Grid item md={6} xs={12} >    

                            <Grid container spacing={3} >

                                <Grid item md={12} xs={12}> 

                                    <Card>

                                        <CardHeader 
                                            sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                            subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Información del trámite </Typography> } 
                                            action={
                                               ( expediente?.success && expediente?.expedienteAutorizados?.length !== 0 )
                                               &&
                                                <Button
                                                    variant='contained'
                                                    color='warning'
                                                    startIcon={ <GroupsOutlinedIcon /> }
                                                    size='small'
                                                    onClick={ () => { setOpenUsuariosAutorizados( true ); } }
                                                >
                                                    Usuarios autorizados
                                                </Button>
                                            }
                                        />
                                        
                                        <Divider />
                                    
                                        <CardContent>

                                            {
                                                loading
                                                ?
                                                    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                                        <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                                    </Box>
                                                :
                                                <>
                                                    {
                                                        expediente?.success 
                                                        &&
                                                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Número de expediente: 
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                            { expediente?.numeroExpediente }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                {
                                                                    expediente?.numeroExpedientePrincipal
                                                                    &&
                                                                    <>
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Número de expediente <br/> principal: 
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { expediente?.numeroExpedientePrincipal }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                }

                                                                {
                                                                    expediente?.expedientesAcumulados?.length !== 0
                                                                    &&
                                                                        <>
                                                                        
                                                                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                                <Grid item lg={4} md={4} xs={4}>
                                                                                    <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                        Expediente Acumulados: 
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item lg={8} md={8} xs={8} >
                                                                                    <List disablePadding>
                                                                                        {
                                                                                            expediente?.expedientesAcumulados?.map( ( element, i  ) => (                                    
                                                                                                <ListItem key={ i } disablePadding>                              
                                                                                                    <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                        { element }
                                                                                                    </Typography>                               
                                                                                                </ListItem>
                                                                                            ))
                                                                                        }                       
                                                                                    </List>
                                                                                </Grid>
                                                                            </Grid>
                                                                        
                                                                        </>  
                                                                }

                                                                {
                                                                    expediente?.ponencia
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Ponencia:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.ponencia }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }                                                             

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Tipo de juicio:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                            { expediente?.tipoJuicio }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>   

                                                                {
                                                                    expediente?.tipoCalificacion
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Tipo de calificación:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.tipoCalificacion }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>        
                                                                }                                           

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                    <Grid item md={4} xs={4}>

                                                                        <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Actor(es):
                                                                        </Typography>

                                                                    </Grid>

                                                                    <Grid item md={8} xs={8} >
                                                                        <List disablePadding>
                                                                            {
                                                                                expediente?.actor?.map( ( element, i  ) => (                                    
                                                                                    <ListItem key={ i } disablePadding>                              
                                                                                        <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                            { element }
                                                                                        </Typography>                               
                                                                                    </ListItem>
                                                                                ))
                                                                            }                       
                                                                        </List>

                                                                    </Grid>

                                                                </Grid>

                                                                {
                                                                    expediente?.demandado?.length !== 0
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>
                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Demandado(s):
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                         
                                                                            <List 
                                                                                disablePadding 
                                                                                sx={{ maxHeight: expediente?.demandado?.length > 10 ? 200 : null, overflow: expediente?.demandado?.length > 10 ? 'scroll' : null }}
                                                                            >                                                                                                                                                         
                                                                               
                                                                                {
                                                                                    expediente?.demandado?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disablePadding>                              
                                                                                            <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                { element }
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }  
                                                                                
                                                                            </List>

                                                                        </Grid>

                                                                    </Grid>   
                                                                }   

                                                                {
                                                                    expediente?.prestaciones?.length !== 0
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>
                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Prestacion(es):
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    expediente?.prestaciones?.map( ( element ) => (                                    
                                                                                        <ListItem key={ element.idTipoPrestacion } disablePadding>                    
                                                                                            <Typography gutterBottom variant="h6" fontSize={14} style={{ fontWeight: 'normal' }}>
                                                                                                { element.descripcion }
                                                                                            </Typography>                                
                                                                                        </ListItem>
                                                                                    ))
                                                                                }  
                                                                            </List>
                                                                        </Grid>

                                                                    </Grid>                                                                 
                                                                }

                                                                {
                                                                    expediente?.juez
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Juez:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.juez }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }   

                                                                {
                                                                    expediente?.secretario
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Secretario(a) Instructor:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.secretario }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }   

                                                                { 
                                                                    expediente?.estatus 
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Estatus:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { expediente?.estatus }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                }

                                                                {
                                                                    expediente?.etapa
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Etapa:
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item xs={8} >

                                                                            <LoadingButton
                                                                                variant='contained'
                                                                                color='inherit'
                                                                                size='small'
                                                                                startIcon={ <ListIcon /> }
                                                                                sx={{ textTransform: 'none' }}
                                                                                onClick={ handleObtenerEtapas }
                                                                                loading={ loadingEtapas }
                                                                            >
                                                                                { expediente?.etapa } &nbsp;
                                                                                {
                                                                                    expediente?.subEtapa
                                                                                    ?
                                                                                    '(' + expediente?.subEtapa + ')'
                                                                                    :
                                                                                        ''
                                                                                }
                                                                            </LoadingButton>

                                                                        </Grid>     

                                                                    </Grid>  
                                                                }            

                                                                {
                                                                    expediente?.audiencias
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 3, mb: 1, textAlign: 'right' }}>

                                                                        <Grid item xs={12}>
                                                                        
                                                                            <LoadingButton
                                                                                variant='contained'
                                                                                color='inherit'
                                                                                size='small'
                                                                                startIcon={ <CalendarTodayIcon /> }
                                                                                sx={{ textTransform: 'none' }}
                                                                                onClick={ handleObtenerAudiencias }
                                                                                loading={ loadingAudiencias }
                                                                            >
                                                                                Ver audiencias
                                                                            </LoadingButton> 

                                                                        </Grid>
                                                                    
                                                                    </Grid>
                                                                }

                                                            </Box>
                                                    }

                                                    {
                                                        exhortoRecibido?.success 
                                                        &&
                                                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Número de exhorto: 
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                            { exhortoRecibido?.numeroExhorto }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid> 

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Expediente de origen: 
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                            { exhortoRecibido?.numeroOrigen }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>  

                                                                {
                                                                    exhortoRecibido?.ponencia
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Ponencia:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { exhortoRecibido?.ponencia }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }                                                             

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Tipo de juicio:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                            { exhortoRecibido?.tipoJuicio }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>   

                                                                {
                                                                    exhortoRecibido?.claseExhorto
                                                                    &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Clase del exhorto:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { exhortoRecibido?.claseExhorto }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>   
                                                                }

                                                                {
                                                                    exhortoRecibido?.objetoExhorto
                                                                    &&
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Objeto del exhorto:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { exhortoRecibido?.objetoExhorto }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                }

                                                                {
                                                                    exhortoRecibido?.seNotifico
                                                                    &&                                                                
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Fecha diligencia:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { moment( exhortoRecibido.fechaDiligencia ).format('DD-MM-YYYY HH:mm a') } 
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                }

                                                                {
                                                                    exhortoRecibido?.fechaDevolucion
                                                                    &&                                                                
                                                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                            <Grid item lg={4} md={4} xs={4}>
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Fecha devolución:
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item lg={8} md={8} xs={8} >
                                                                                <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                    { moment( exhortoRecibido.fechaDevolucion ).format('DD-MM-YYYY HH:mm a') } 
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>  
                                                                }

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                    <Grid item md={4} xs={4}>

                                                                        <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Actor(es):
                                                                        </Typography>

                                                                    </Grid>

                                                                    <Grid item md={8} xs={8} >
                                                                        <List disablePadding>
                                                                            {
                                                                                exhortoRecibido?.actor?.map( ( element, i  ) => (                                    
                                                                                    <ListItem key={ i } disablePadding>                              
                                                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                            { element }
                                                                                        </Typography>                               
                                                                                    </ListItem>
                                                                                ))
                                                                            }                       
                                                                        </List>

                                                                    </Grid>

                                                                </Grid>

                                                                {
                                                                    exhortoRecibido?.demandado?.length !== 0
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>
                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Demandado(s):
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    exhortoRecibido?.demandado?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disablePadding>                              
                                                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                { element }
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }                             
                                                                            </List>
                                                                        </Grid>

                                                                    </Grid>   
                                                                }  
                                                                
                                                                {
                                                                    exhortoRecibido?.juez
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Juez:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { exhortoRecibido?.juez }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }   

                                                                {
                                                                    exhortoRecibido?.secretario
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Secretario(a) Instructor:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { exhortoRecibido?.secretario }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }   

                                                                { 
                                                                    exhortoRecibido?.estatus 
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Estatus:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { exhortoRecibido?.estatus }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                }

                                                                {
                                                                    exhortoRecibido?.etapa
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                             
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Etapa:
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <LoadingButton
                                                                                variant='contained'
                                                                                color='inherit'
                                                                                size='small'
                                                                                startIcon={ <ListIcon /> }
                                                                                sx={{ textTransform: 'none' }}
                                                                                onClick={ handleObtenerEtapasExhortoRecibido }
                                                                                loading={ loadingEtapas }
                                                                            >
                                                                                { exhortoRecibido?.etapa }
                                                                            </LoadingButton>
                                                                        </Grid>

                                                                    </Grid>  
                                                                }            
                                                            
                                                            </Box>
                                                    }

                                                    {
                                                        irregular?.success 
                                                        &&
                                                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        {
                                                                            irregular?.idTipoIrregular === 1
                                                                            ?
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Número de convenio: 
                                                                                </Typography>
                                                                            :
                                                                                <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                    Número de irregular: 
                                                                                </Typography>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                            { irregular?.numeroIrregular }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>  

                                                                {
                                                                    ( irregular?.tipoIrregular && irregular?.idTipoIrregular !== 1 )
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Tipo de irregular:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { irregular?.tipoIrregular }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }  

                                                                {
                                                                    irregular?.ponencia
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Ponencia:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { irregular?.ponencia }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }  

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                    <Grid item md={4} xs={4}>

                                                                        <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Actor(es):
                                                                        </Typography>

                                                                    </Grid>

                                                                    <Grid item md={8} xs={8} >
                                                                        <List disablePadding>
                                                                            {
                                                                                irregular?.actor?.map( ( element, i  ) => (                                    
                                                                                    <ListItem key={ i } disablePadding>                              
                                                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                            { element }
                                                                                        </Typography>                               
                                                                                    </ListItem>
                                                                                ))
                                                                            }                       
                                                                        </List>

                                                                    </Grid>

                                                                </Grid>

                                                                {
                                                                    irregular?.demandado?.length !== 0
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item md={4} xs={4}>
                                                                            <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Demandado(s):
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item md={8} xs={8} >
                                                                            <List disablePadding>
                                                                                {
                                                                                    irregular?.demandado?.map( ( element, i  ) => (                                    
                                                                                        <ListItem key={ i } disablePadding>                              
                                                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                                { element }
                                                                                            </Typography>                               
                                                                                        </ListItem>
                                                                                    ))
                                                                                }                             
                                                                            </List>
                                                                        </Grid>

                                                                    </Grid>   
                                                                }   

                                                                { 
                                                                    irregular?.estatus 
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Estatus:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { irregular?.estatus }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                }

                                                                {
                                                                    irregular?.etapa
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                        <Grid item xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Etapa:
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item xs={8} >

                                                                            <LoadingButton
                                                                                variant='contained'
                                                                                color='inherit'
                                                                                size='small'
                                                                                startIcon={ <ListIcon /> }
                                                                                sx={{ textTransform: 'none' }}
                                                                                onClick={ handleObtenerEtapasIrregular }
                                                                                loading={ loadingEtapas }
                                                                            >
                                                                                { irregular?.etapa } &nbsp;
                                                                                {
                                                                                    irregular?.subEtapa
                                                                                    ?
                                                                                    '(' + irregular?.subEtapa + ')'
                                                                                    :
                                                                                        ''
                                                                                }
                                                                            </LoadingButton>

                                                                        </Grid>     

                                                                    </Grid>  
                                                                } 

                                                            </Box>
                                                    }

                                                    {
                                                        amparo?.success 
                                                        &&
                                                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                    <Grid item lg={4} md={4} xs={4}>
                                                                        <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Número de amparo: 
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item lg={8} md={8} xs={8} >
                                                                        <Typography color="textPrimary" gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                            { amparo?.numeroAmparo }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>  

                                                                {
                                                                    amparo?.numeroExpediente
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Número de expediente:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { amparo?.numeroExpediente }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                } 

                                                                {
                                                                    amparo?.ponencia
                                                                    &&
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Ponencia:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { amparo?.ponencia }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>   
                                                                }  

                                                                {
                                                                    amparo?.fecha
                                                                    &&                                                                
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Fecha del amparo:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                            { moment( amparo.fecha ).format('DD-MM-YYYY HH:mm a') } 
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                }

                                                                <Grid container sx={{ mt: 1, mb: 1 }}>

                                                                    <Grid item md={4} xs={4}>

                                                                        <Typography variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                            Parte(s):
                                                                        </Typography>

                                                                    </Grid>

                                                                    <Grid item md={8} xs={8} >
                                                                        <List disablePadding>
                                                                            {
                                                                                amparo?.partes?.map( ( element, i  ) => (                                    
                                                                                    <ListItem key={ i } disableGutters>                              
                                                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                                            { element.parte } <br /> <strong>({ element.tipoParte })</strong>
                                                                                        </Typography>                               
                                                                                    </ListItem>
                                                                                ))
                                                                            }                       
                                                                        </List>

                                                                    </Grid>

                                                                </Grid> 

                                                                { 
                                                                    amparo?.autoridadAmparo 
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Autoridad del amparo:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { amparo?.autoridadAmparo  }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                }

                                                                { 
                                                                    amparo?.sentidoAmparo 
                                                                    &&                                                            
                                                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                                                        <Grid item lg={4} md={4} xs={4}>
                                                                            <Typography color="textPrimary" variant="h6" fontSize={14} fontWeight={'bold'} >
                                                                                Sentido del amparo:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item lg={8} md={8} xs={8} >
                                                                            <Typography color="textPrimary" gutterBottom variant="h6" fontSize={14}>
                                                                                { amparo?.sentidoAmparo  }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>  
                                                                }

                                                            </Box>
                                                    }

                                                </>                                                   
                                            }

                                        </CardContent>

                                    </Card>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item md={6} xs={12} >   

                            <Card id="CardEV">

                                <CardHeader id="CardHeaderEV" 
                                    sx={{ backgroundColor: (theme) => theme.palette.primary.main }}                                 
                                    subheader={ 
                                        <Typography sx={{ fontWeight: '400', color: 'white' }}> 
                                            
                                            Documentos&nbsp;              

                                            { expediente?.acuerdos ? '(' + expediente?.acuerdos?.length + ')' : '' }
                                            { exhortoRecibido?.acuerdos ? '(' + exhortoRecibido?.acuerdos?.length + ')' : '' }
                                            { irregular?.acuerdos ? '(' + irregular?.acuerdos?.length + ')' : '' }
                                            { amparo?.acuerdos ? '(' + amparo?.acuerdos?.length + ')' : '' }

                                        </Typography> 
                                    }                                     
                                />                                 
                                
                                <Divider />
                            
                                <CardContent style={{ maxHeight: '60vh', overflowY: 'scroll' }} >

                                    {
                                        loading
                                        ?
                                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                                <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                            </Box>
                                        :
                                        <>
                                            {
                                                expediente?.success 
                                                &&
                                                    <List sx={{ width: '100%', }}>                                        
                                                        {                                         
                                                            expediente?.acuerdos?.map( ( doc, i ) => (
                                                                <Box key={ i }>

                                                                    <Button 
                                                                        fullWidth 
                                                                        sx={{ textTransform: 'none', textAlign: 'left', color: 'inherit' }}
                                                                        onClick={ () => { setDocumento( doc ); setOpenDocumento( true ); } }
                                                                    >

                                                                        <ListItem>

                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <BookIcon color='primary' />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                                                                                
                                                                            <ListItemText 
                                                                                primary={ <Typography variant="h6" fontSize={14}> { doc.tipoAcuerdo } </Typography>} 
                                                                                secondary={ doc.fecha ? moment( doc.fecha ).format('DD-MM-YYYY HH:mm a') : '' }                                                                                
                                                                            />   

                                                                            {
                                                                                doc.tipo 
                                                                                ?
                                                                                    <Chip
                                                                                        variant='filled'                                                                               
                                                                                        label={ <Typography variant='subtitle2'> { doc.tipo } </Typography> }      
                                                                                        icon={ <ForwardToInboxIcon fontSize='small' /> }                                                                         
                                                                                        size='small'
                                                                                        sx={{ p: 2, mr: 2 }}
                                                                                    />
                                                                                :
                                                                                    null
                                                                            }

                                                                            {
                                                                                doc.noSeguimiento
                                                                                &&
                                                                                    <Chip
                                                                                        variant={ 'outlined' }                                                                             
                                                                                        label={ <Typography variant='body2' fontWeight={'bold'}> { doc.noSeguimiento } </Typography> }                       
                                                                                    />      
                                                                            }

                                                                        </ListItem>

                                                                    </Button> 

                                                                    <Divider />

                                                                </Box>
                                                            ))
                                                        }
                                                    </List>                                            
                                            }

                                            {
                                                exhortoRecibido?.success 
                                                &&
                                                    <List sx={{ width: '100%', }}>                                        
                                                        {                                         
                                                            exhortoRecibido?.acuerdos?.map( ( doc, i ) => (
                                                                <Box key={ i }>

                                                                    <Button 
                                                                        fullWidth 
                                                                        sx={{ textTransform: 'none', textAlign: 'left', color: 'inherit' }}
                                                                        onClick={ () => { setDocumento( doc ); setOpenDocumento( true ); } }
                                                                    >

                                                                        <ListItem>

                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <BookIcon color='primary' />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                                                                                
                                                                            <ListItemText 
                                                                                primary={ <Typography variant="subtitle2"> { doc.tipoAcuerdo } </Typography>} 
                                                                                secondary={ doc.fecha ? moment( doc.fecha ).format('DD-MM-YYYY HH:mm a') : '' }
                                                                            />   

                                                                            {
                                                                                doc.noSeguimiento
                                                                                &&
                                                                                    <Chip
                                                                                        variant={ 'outlined' }                                                                             
                                                                                        label={ <Typography variant='body2' fontWeight={'bold'}> { doc.noSeguimiento } </Typography> }                       
                                                                                    />      
                                                                            }

                                                                        </ListItem>

                                                                    </Button> 

                                                                    <Divider />

                                                                </Box>
                                                            ))
                                                        }
                                                    </List>                                            
                                            }

                                            {
                                                irregular?.success 
                                                &&
                                                    <List sx={{ width: '100%', }}>                                        
                                                        {                                         
                                                            irregular?.acuerdos?.map( ( doc, i ) => (
                                                                <Box key={ i }>

                                                                    <Button 
                                                                        fullWidth 
                                                                        sx={{ textTransform: 'none', textAlign: 'left', color: 'inherit' }}
                                                                        onClick={ () => { setDocumento( doc ); setOpenDocumento( true ); } }
                                                                    >

                                                                        <ListItem>

                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <BookIcon color='primary' />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                                                                                
                                                                            <ListItemText 
                                                                                primary={ <Typography variant="subtitle2"> { doc.tipoAcuerdo } </Typography>} 
                                                                                secondary={ doc.fecha ? moment( doc.fecha ).format('DD-MM-YYYY HH:mm a') : '' }
                                                                            />   

                                                                            {
                                                                                doc.noSeguimiento
                                                                                &&
                                                                                    <Chip
                                                                                        variant={ 'outlined' }                                                                             
                                                                                        label={ <Typography variant='body2' fontWeight={'bold'}> { doc.noSeguimiento } </Typography> }                       
                                                                                    />      
                                                                            }

                                                                        </ListItem>

                                                                    </Button> 

                                                                    <Divider />

                                                                </Box>
                                                            ))
                                                        }
                                                    </List>                                            
                                            }

                                            {
                                                amparo?.success 
                                                &&
                                                    <List sx={{ width: '100%', }}>                                        
                                                        {                                         
                                                            amparo?.acuerdos?.map( ( doc, i ) => (
                                                                <Box key={ i }>

                                                                    <Button 
                                                                        fullWidth 
                                                                        sx={{ textTransform: 'none', textAlign: 'left', color: 'inherit' }}
                                                                        onClick={ () => { setDocumento( doc ); setOpenDocumento( true ); } }
                                                                    >

                                                                        <ListItem>

                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <BookIcon color='primary' />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                                                                                
                                                                            <ListItemText 
                                                                                primary={ <Typography variant="subtitle2"> { doc.tipoAcuerdo } </Typography>} 
                                                                                secondary={ doc.fecha ? moment( doc.fecha ).format('DD-MM-YYYY HH:mm a') : '' }
                                                                            />   

                                                                            {
                                                                                doc.noSeguimiento
                                                                                &&
                                                                                    <Chip
                                                                                        variant={ 'outlined' }                                                                             
                                                                                        label={ <Typography variant='body2' fontWeight={'bold'}> { doc.noSeguimiento } </Typography> }                       
                                                                                    />      
                                                                            }

                                                                        </ListItem>

                                                                    </Button> 

                                                                    <Divider />

                                                                </Box>
                                                            ))
                                                        }
                                                    </List>                                            
                                            }

                                        </>
                                    }

                                </CardContent>
                                
                            </Card>     

                        </Grid>

                    </Grid>                    

                </Container>  

            </Box>

            <Dialog open={ openDocumento } onClose={ () => {} } fullWidth={ true } maxWidth="md" fullScreen={ fullScreen } >

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"> </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setFullScreen( (prev) => !prev ) } }
                    sx={{
                        position: 'absolute',
                        right: 55,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                   { fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon /> } 
                </IconButton>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setOpenDocumento( false ); setDocumento( {} ); setFullScreen( false ); } }
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
               
                <DialogContent>
                  
                   <Grid container spacing={3} height={'100%'} >   
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               documento
                               ?
                                    documento.firmado
                                    ?
                                        <iframe title='caratula' src={ documento.documento } height={ fullScreen ? "100%" : '500px' } width="100%"></iframe>
                                    :
                                        <iframe title='caratula' src={ documento.documento } height={ fullScreen ? "100%" : '500px' } width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró el documento
                               </Typography>
                           }

                       </Grid>

                       {
                            ( documento?.archivos && documento?.archivos?.length !== 0 )
                            &&
                            <>
                                <Grid item xs={12} md={12}>
                                
                                    <Typography variant="h6" component="div">
                                        Archivo(s)
                                    </Typography>                           
                                
                                    <ImageList variant="standard" gap={12}>
                                        {
                                            documento?.archivos?.map( ( e, index ) => (
                                                <ImageListItem key={ index }>

                                                    <img                                                  
                                                        src={`${ e.contenido }?w=248&fit=crop&auto=format`}
                                                        alt={ e.tipoArchivo }
                                                        style={{ borderRadius: 35 }}
                                                        loading="lazy"
                                                    />

                                                    <ImageListItemBar
                                                        title={ <Typography variant="subtitle1" > {e.tipoArchivo} </Typography>  }
                                                        position="below"
                                                        sx={{ textAlign: 'center' }}
                                                    />

                                                </ImageListItem>
                                            ))
                                        }
                                    </ImageList>
                        
                                </Grid>                                          

                            </>
                       }

                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenDocumento( false ); setDocumento( {} ); setFullScreen( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

            <Dialog open={ openEtapas } onClose={ () => {} } fullWidth={ true } maxWidth="sm" >
               
                <DialogTitle sx={{ fontSize: 24 }}>
                    Etapas
                </DialogTitle>

                <DialogContent>
                  
                   <Grid container spacing={3} >   
                      
                        <Grid item xs={12} sx={{ height: 400, maxWidth: 360 }} >

                            <List disablePadding>
                                {
                                    etapas?.map( ( elem, i ) => (  

                                        <div key={ i } >

                                            <ListItem secondaryAction={ <Typography color="text.secondary" variant='subtitle2' sx={{ fontSize: 14 }} > { elem.fecha ? moment( elem.fecha ).format('DD-MM-YYYY HH:mm a') : '' } </Typography> } >
                                             
                                                <ListItemText primary={ <Typography color="text.primary" variant='subtitle1' sx={{ fontSize: 16, fontWeight: 'bold' }} >{ elem.etapa }</Typography> }
                                                    secondary={ <Typography color="text.secondary" variant='subtitle2' sx={{ fontSize: 14 }} >{ elem.subEtapa }</Typography> }
                                                />
                                            </ListItem>

                                            <Divider />

                                        </div>        

                                    ))
                                }  
                            </List>

                        </Grid>

                   </Grid>
                  
                </DialogContent>

                <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenEtapas( false ); setEtapas( [] ); } }>
                       Cerrar
                   </Button>

                </DialogActions>                

            </Dialog>

            <Dialog open={ openAudiencias } onClose={ () => {} } fullWidth={ true } maxWidth="sm" >
               
                <DialogTitle sx={{ fontSize: 24 }}>
                    Audiencias
                </DialogTitle>

                <DialogContent>
                  
                   <Grid container spacing={3} >   
                      
                        <Grid item xs={12} sx={{ height: 400, maxWidth: 360 }} >

                            <List disablePadding>
                                {
                                    audiencias?.map( ( elem, i ) => (  

                                        <div key={ i } >

                                            <ListItem secondaryAction={ <Typography color="text.secondary" variant='subtitle2' sx={{ fontSize: 14 }} > { elem.fecha ? moment( elem.fecha ).format('DD-MM-YYYY HH:mm a') : '' } </Typography> } >
                                             
                                                <ListItemText primary={ <Typography color="text.primary" variant='subtitle1' sx={{ fontSize: 16, fontWeight: 'bold' }} >{ elem.tipoAudiencia }</Typography> }
                                                    secondary={ <Typography color="text.secondary" variant='subtitle2' sx={{ fontSize: 14 }} >{ elem.estatus }</Typography> }
                                                />
                                            </ListItem>

                                            <Divider />

                                        </div>        

                                    ))
                                }  
                            </List>

                        </Grid>

                   </Grid>
                  
                </DialogContent>

                <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAudiencias( false ); setAudiencias( [] ); } }>
                       Cerrar
                   </Button>

                </DialogActions>                

            </Dialog>    

            <Dialog open={ openUsuariosAutorizados } onClose={ () => {} } fullWidth={ true } maxWidth="sm" >
               
                <DialogTitle sx={{ fontSize: 24 }}>
                    Usuarios autorizados
                </DialogTitle>

                <DialogContent>
                  
                   <Grid container spacing={3} >   
                      
                        <Grid item xs={12} sx={{ height: 400, maxWidth: 360 }} >

                            <List disablePadding>
                                {
                                    usuariosAutorizados?.map( ( elem, i ) => (  

                                        <div key={ i } >

                                            <ListItem>   

                                                <ListItemText 
                                                    primary={
                                                        <Typography 
                                                            color="text.primary" 
                                                            variant='subtitle1' 
                                                            sx={{ fontSize: 16, fontWeight: 'bold' }} 
                                                        >
                                                            { elem.nombre + ' ' + elem.apellido1 + ' ' + elem.apellido2 }
                                                        </Typography> 
                                                    }
                                                    secondary={ 
                                                        <Typography 
                                                            color="text.secondary" 
                                                            variant='subtitle2' 
                                                            sx={{ fontSize: 14 }} 
                                                        >
                                                            { elem.correoElectronico } <br /> <strong>{ elem.telefonoCelular }</strong>
                                                        </Typography> 
                                                    }
                                                />

                                                <ListItemSecondaryAction sx={{ width: 60 }}>
                                                    {
                                                        elem?.loading
                                                        ?
                                                            <CircularProgress size={30} />
                                                        :                                                    
                                                            <Switch
                                                                checked={ elem.activo }
                                                                onChange={ ( e ) => handleChangeActivar( e.target.checked, elem.referencia, elem.idExpedienteAutorizado ) }
                                                            />
                                                    }
                                                </ListItemSecondaryAction>

                                            </ListItem>

                                            <Divider />

                                        </div>        

                                    ))
                                }  
                            </List>

                        </Grid>

                   </Grid>
                  
                </DialogContent>

                <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenUsuariosAutorizados( false ); } }>
                       Cerrar
                   </Button>

                </DialogActions>                

            </Dialog>

            <Dialog open={ openArchivo } onClose={ () => {} } fullWidth={ true } maxWidth="md" >

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"> </DialogTitle>      

                <IconButton
                    aria-label="close"
                    onClick={ () => { setOpenArchivo( false ); setArchivo( '' ); } }
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                
                <Grid container spacing={3} height={'100%'} >   
                
                    <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>

                        {                           
                            <iframe title='caratula' src={ archivo } height={ '500px' } width="100%"></iframe>                            
                        }

                    </Grid>

                </Grid>
                
                </DialogContent>

                <DialogActions>

                <Button color="primary" onClick={ () => { setOpenArchivo( false ); setArchivo( '' ); } }>
                    Cerrar
                </Button>

                </DialogActions>                

            </Dialog>  
            
        </>
    )
}
