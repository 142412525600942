import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import Dynamsoft from 'dwt';

import { Box, Button,  Card,  CardContent,  FormControl, Grid, InputLabel, MenuItem, Select, Paper, Container, Alert,  Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, TableCell, TableBody, TableHead, Table, TableContainer, TableFooter, TableRow, TablePagination, Tabs, Tab } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScannerIcon from '@mui/icons-material/Scanner';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import AppBarMenu from '../ui/AppBarMenu';

import { ObtenerAudienciaDetalle } from '../../actions/audiencias/audienciaActions';

import { types } from '../../types/types';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { usePages } from '../../hooks/usePages';
import { InformacionSeguimiento } from '../ui/InformacionSeguimiento';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const EscanearAnexosScreen = ( props ) => {
    
    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const containerId = 'dwtcontrolContainer';
    const width = "100%";
    const height = "500";
   
    const [DWObject, setDWObject] = useState( null );
    const [scanners, setScanners] = useState( [] );
    const [currentScanner, setCurrentScanner] = useState( "0" );
    const [twainReady, setTwainReady] = useState( false );    

    const [loading, setLoading] = useState( false );
    const [ajaxLoading, setAjaxLoading] = useState( false );

    const [loadingFinish, setLoadingFinish] = useState( false );  
    const [openConfirmacion, setOpenConfirmacion] = useState(false);

    const [error, setError] = useState( { type: 'warning', message: '' } );  

    const [noSeguimiento, setNoSeguimiento] = useState( 0 );

    const [expediente, setExpediente] = useState( { } ); 

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();    

    const [valueTab, setValueTab] = useState(0);

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [countPages, setCountPages] = useState( 0 ); 

    const ReadyTwain = () => {

        Dynamsoft.DWT.ProductKey = process.env.REACT_APP_API_KEY_DYNAMIC_TWAIN;
        Dynamsoft.DWT.ResourcesPath = "../../dwt-resources";
        Dynamsoft.DWT.Containers = [{ ContainerId: containerId, Width: width, Height: height }];

        Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {

            setDWObject( Dynamsoft.DWT.GetWebTwain( containerId ) );

        });

        checkScriptLoaded();
    }

    const checkScriptLoaded = () => {        
        if ( Dynamsoft.Lib.detect.scriptLoaded ) {            
            Dynamsoft.DWT.Load();
            setTwainReady( true );
        } 
        else {
            setTimeout(() => {
                checkScriptLoaded();               
            }, 1000);
        }
    }

    const onSourceChange = ( value ) => {
        setCurrentScanner( value );
    }

    const acquireImage = () => {

        DWObject.CloseSource();
        
        for (let i = 0; i < DWObject.SourceCount; i++) {
            if ( DWObject.GetSourceNameItems(i) === currentScanner) {
                DWObject.SelectSourceByIndex(i);
                break;
            }
        }

        DWObject.IfDuplexEnabled = false;
        DWObject.IfFeederEnabled = true;
        DWObject.IfShowUI = true;
        DWObject.PixelType = Dynamsoft.DWT.EnumDWT_PixelType.TWPT_BW;
        DWObject.Resolution = 300;

        DWObject.OpenSource();
        
        DWObject.AcquireImage( () => { setCountPages( DWObject.HowManyImagesInBuffer ); });   
    }

    const saveDocument = () => {      
        
        setOpenConfirmacion( false );

        if ( DWObject ) {

            if ( DWObject.HowManyImagesInBuffer > 0 ){

                const strHTTPServer = window.location.hostname;				
                const strActionPage = "Api/Audiencia/GuardarDigitalizacionAnexos/" + Id ?? 0;
                
                if( window.location.protocol === 'http:' ){
                    DWObject.IfSSL = false;
                    DWObject.HTTPPort = 5000;
                }
                else if( window.location.protocol === 'https:' ){
                    DWObject.IfSSL = true;
                }      

                const data = JSON.parse(window.localStorage.getItem('data'));

                if( data ){
                    const { token } = data;
                    DWObject.SetHTTPHeader( 'Authorization', 'Bearer ' + token);
                    DWObject.SetHTTPHeader( 'Access-Control-Allow-Origin', '*');
                }               
                
                DWObject.HTTPUploadAllThroughPostAsPDF(strHTTPServer, strActionPage, "documento.pdf", OnHttpUploadSuccess, OnHttpUploadFailure);	
            }

        }

    }

    const OnHttpUploadSuccess= ( ) => {
       
        setLoadingFinish( true );
       
        setTimeout(() => {
                        
            setLoadingFinish( false );
            
            setOpenConfirmacion( false );

            setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

            setLoading( true );     

        }, 1500);
        				
    }

    const OnHttpUploadFailure = (errorCode, errorString, sHttpResponse) => {
        console.log(errorCode, errorString, sHttpResponse);

        setLoadingFinish( false );
    }
    
    const loadImagesOrPDFs = () => {

        if( DWObject ){

            setError( '' );

            DWObject.IfShowFileDialog = true;
            DWObject.Addon.PDF.SetResolution( 200 );
            DWObject.Addon.PDF.SetConvertMode( 1 );
            DWObject.LoadImageEx("", 5, () => { setCountPages( DWObject.HowManyImagesInBuffer ); }, ( errorCode, errorString ) => { } );     
        }

    }    

    const handleValidate = () => {

        if ( DWObject.HowManyImagesInBuffer > 0 ){
            setOpenConfirmacion( true );
        }
        else{
            setError( { type: 'warning', message: 'Debe escanear o subir el documento'} );
        }            
        
    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };  

    const handleTabEscanear = () => {

        if( valueTab !== 0){

            ReadyTwain();

        }

    }
    
    useEffect(() => {
        
        if ( DWObject ) {
            const vCount = DWObject.SourceCount;
            let sourceNames = [];
            for (let i = 0; i < vCount; i++){
                sourceNames.push( DWObject.GetSourceNameItems(i) );
            }
            setScanners( sourceNames );
        }

    }, [ DWObject ]);

    useEffect(() => {      

        async function Obtener(){

            const params = {
                'idAudiencia': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerAudienciaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setNoSeguimiento( response.data.idAudiencia ?? '');
                        setExpediente( response.data.expediente );
                        setRows( response.data.anexos ?? [ ] );

                        setTimeout(() => {                          

                            setAjaxLoading( true );

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 21 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 21){

                                
                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true );

                            }

                            dispatch({ type: types.closeLoading });
                                                        
                        }, 1000);   
                        
                    }

                }

            }); 

        }     
        
        Obtener();

    }, [ Id, dispatch ]) 
    
    if( !ajaxLoading ){

        return <AppBarMenu />;

    }    

    if( !twainReady ){            
        ReadyTwain();
    }

    return (
        <>
            <AppBarMenu />           

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (  
                        <Container maxWidth="lg" component={ Paper } >  

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile sx={{ pt: 2 }}>
                                
                                <Tab label="Escanear anexos" { ...a11yProps(0) } onClick={ handleTabEscanear } />
                                <Tab label="Anexos" { ...a11yProps(1) } />
                                
                            </Tabs>

                            <TabPanel value={ valueTab } index={0}>

                                <Grid container spacing={3}  >

                                    <Grid item md={12} xs={12}>

                                        <InformacionSeguimiento 
                                            actividad={'Escanear anexo(s)'} 
                                            noSeguimiento={ 'GAU-' + noSeguimiento } 
                                            tipo={'Número de expediente'}
                                            referencia={ expediente?.numeroExpediente }
                                        />

                                    </Grid>

                                    {
                                        expediente.ponencia
                                        &&   
                                        <Grid item md={2} xs={6}>                                                                    
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>                                            
                                        </Grid>
                                    }

                                    {
                                        expediente.ponencia
                                        &&  
                                            <Grid item md={4} xs={6} >                                            
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { expediente.ponencia }
                                                </Typography>                                            
                                            </Grid> 
                                    }

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                               
                                        {
                                            expediente?.actor?.map( ( elem, i ) => (
                                                <ListItem key={ i } disablePadding>  
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                        { elem }
                                                    </Typography>      
                                                </ListItem>
                                            ))
                                        }                      
                                        </List>
                                    </Grid>  
                                
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List 
                                            disablePadding 
                                            sx={{ maxHeight: expediente?.demandado?.length > 10 ? 200 : null, overflow: expediente?.demandado?.length > 10 ? 'scroll' : null }}
                                        >  
                                        {
                                            expediente?.demandado?.map( ( elem, i ) => (
                                                <ListItem key={ i } disablePadding>  
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                        { elem }
                                                    </Typography>      
                                                </ListItem>
                                            ))
                                        }                                              
                                        </List>
                                    </Grid>

                                    <Grid item md={12} xs={12} ></Grid>

                                    <Grid item md={6} xs={12} style={{ height: '500px' }} >

                                        <div id={ containerId } style={{ Width: '100%' }} >

                                        </div>

                                        <br />
                                        {
                                            countPages !== 0
                                            &&
                                                <Typography fontWeight={'bold'}> Número de hojas: { countPages } </Typography>
                                        }

                                    </Grid>

                                    <Grid item md={6} xs={12}>

                                        <Grid container spacing={3} >

                                            <Grid item md={12} xs={12}>
                                            
                                                <FormControl fullWidth>
                                                    <InputLabel id="select-impresoras-label">Impresora(s) disponible(s)</InputLabel>
                                            
                                                    <Select 
                                                        label="Impresora(s) disponible(s)"
                                                        labelId="select-impresoras-label"
                                                        value={ currentScanner } 
                                                        onChange={ (e) => onSourceChange( e.target.value ) } >
                                                        
                                                        <MenuItem value="0" key="0"> Seleccione una impresora </MenuItem>
                                                        
                                                        {
                                                            scanners.length > 0 
                                                            && 
                                                            (
                                                                scanners.map((_name, _index) =>
                                                                    <MenuItem value={_name} key={_index}> {_name} </MenuItem>
                                                                )
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>

                                            </Grid>

                                            <Grid item md={6} xs={6} textAlign="left"> 
                                                <Button 
                                                    variant="contained" 
                                                    fullWidth
                                                    onClick={ acquireImage } 
                                                    disabled={ currentScanner === "0" }
                                                    startIcon={ <ScannerIcon /> }
                                                >
                                                    Escanear documento
                                                </Button>
                                            </Grid>

                                            <Grid item md={6} xs={6} textAlign="right"> 
                                                <Button
                                                    variant="contained" 
                                                    fullWidth
                                                    onClick={ loadImagesOrPDFs }
                                                    startIcon={ <FileUploadIcon /> }
                                                    color="inherit"
                                                    disabled={ DWObject === null }
                                                >
                                                    Subir archivo                
                                                </Button>
                                            </Grid>

                                            {   
                                                error.message && ( 
                                                    <Grid item md={12} xs={12}>
                                                        
                                                        <Alert color='warning' severity={ error.type } variant="filled" > { error.message } </Alert> 

                                                    </Grid>
                                                )
                                            }

                                        </Grid>   

                                    </Grid>
                                        
                                    <Grid item md={12} xs={12} >  

                                        <Grid container >
                                        
                                            <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>  
                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ handleValidate }
                                                    endIcon={ <CheckCircleIcon /> }
                                                    disabled={ DWObject === null }                                                    
                                                    loading={ loadingFinish }
                                                > 
                                                    Finalizar 
                                                </LoadingButton>
                                            </Grid>                                        
                                            
                                        </Grid>

                                    </Grid>

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={1}>

                                <Grid item md={12} xs={12}>
                                    <InformacionSeguimiento 
                                        actividad={'Escanear anexo(s)'} 
                                        noSeguimiento={ 'GAU-' + noSeguimiento } 
                                        tipo={'Número de expediente'}
                                        referencia={ expediente?.numeroExpediente }
                                    />
                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <TableContainer style={{ marginTop: '10px' }}>

                                        <Table>

                                            <TableHead>

                                                <TableRow>
                                                    <TableCell style={{ width:'20%' }}>TIPO DE ANEXO</TableCell>                              
                                                    <TableCell style={{ width:'20%' }}>TIPO DE FORMATO</TableCell>                              
                                                    <TableCell style={{ width:'10%' }}>CANTIDAD</TableCell>    
                                                    <TableCell style={{ width:'15%' }}>NÚMERO DE FOJAS</TableCell>   
                                                    <TableCell style={{ width:'25%' }}>OBSERVACIONES</TableCell>                                                       
                                                </TableRow>

                                            </TableHead>

                                            <TableBody>                    
                                                {
                                                    (rowsPerPage > 0
                                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : rows
                                                    ).map( (row) => (
                                                
                                                        <TableRow key={ row.idAnexo }>
                                                            <TableCell>{ row.tipoAnexo.descripcion }</TableCell>    
                                                            <TableCell>{ row.tipoFormato.descripcion }</TableCell>    
                                                            <TableCell>{ row.cantidad }</TableCell>    
                                                            <TableCell>{ row.numeroFojas }</TableCell>     
                                                            <TableCell>{ row.observaciones }</TableCell>  
                                                        </TableRow>
                                                    
                                                    ))
                                                }

                                                {
                                                    rows.length === 0 && 
                                                    (
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={6}
                                                                align="center"
                                                                style={{ fontWeight: 'bold', fontSize: 14 }}
                                                            >
                                                                {
                                                                    'No se han agregado anexo(s)'
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }

                                            </TableBody>

                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[ ]}
                                                        colSpan={6}
                                                        count={rows.length}                                        
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}                                                                                
                                                        onPageChange={ handleChangePage }
                                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                                        ActionsComponent={ TablePaginationActions }
                                                        labelDisplayedRows={
                                                            ({ from, to, count }) => {
                                                            return '' + from + '-' + to + ' de ' + count
                                                            }
                                                        }
                                                    />
                                                </TableRow>
                                            </TableFooter>

                                        </Table>

                                    </TableContainer>

                                </Grid>      

                            </TabPanel>

                        </Container>
                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la digitalización de los anexos?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <Button 
                        onClick={ saveDocument } 
                        variant='contained' 
                    > 
                        Aceptar 
                    </Button>

                </DialogActions>

            </Dialog>

        </>
    )
}
