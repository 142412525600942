import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';

import AppBarMenu from '../../ui/AppBarMenu';

import { Editor } from '@tinymce/tinymce-react';

import { types } from '../../../types/types';

import { CalendarScreen } from '../../audiencias/CalendarScreen';
import { AcuerdoNotificacionScreen } from './AcuerdoNotificacionScreen';

import { CalificarTramiteScreen } from './CalificarTramiteScreen';
import { DetalleDemandaScreen } from './detalles/DetalleDemandaScreen';
import { DetallePromocionScreen } from './detalles/DetallePromocionScreen';
import { DetalleAmparoScreen } from './detalles/DetalleAmparoScreen';
import { DetalleExhortoRecibidoScreen } from './detalles/DetalleExhortoRecibidoScreen';
import { DetalleIrregularScreen } from './detalles/DetalleIrregularScreen';
import { DetalleExhortoLibradoScreen } from './detalles/DetalleExhortoLibradoScreen';

import { ObtenerTiposAcuerdos } from '../../../actions/admin/catalogs/tipoAcuerdoActions';
import { ObtenerSubTiposAcuerdos } from '../../../actions/admin/catalogs/subTipoAcuerdoActions';
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';

import { ActualizarEstatus, AcuerdoDetalle, GuardarEscritoAcuerdo } from '../../../actions/tribunal/acuerdoActions';
import { ObtenerEtapasTribunales } from '../../../actions/admin/catalogs/etapaActions';
import { ObtenerSubEtapasTribunalesLaborales } from '../../../actions/admin/catalogs/subEtapaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { ObtenerExpedientePrincipal } from '../../../actions/recepcion/expedienteActions';
//import { BuscarUsuarioJuez } from '../../../actions/admin/users/usuarioAction';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AutorizarAcuerdoScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const { checkCalificarTramite, acuerdo, promocion } = useSelector( state => state.acuerdo );

    const { juzgado, tribunal } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    const [noSeguimiento, setNoSeguimiento] = useState( '' );
    const [contenido, setContenido] = useState( '' );

    const [valueTab, setValueTab] = useState(0);

    const [sendAjax, setSendAjax] = useState(false);

    const [ajaxLoading, setAjaxLoading] = useState(false);
    
    const [alert, setAlert] = useState( {  } );

    const [tipoAcuerdo, setTipoAcuerdo] = useState(0);
    const [tipoAcuerdoArray, setTipoAcuerdoArray] = useState( [] );

    const [subTipoAcuerdo, setSubTipoAcuerdo] = useState(0);
    const [subTipoAcuerdoArray, setSubTipoAcuerdoArray] = useState( [] );
    
    const [etapa, setEtapa] = useState(0);
    const [etapaArray, setEtapaArray] = useState( [] );

    const [subEtapa, setSubEtapa] = useState(0);
    const [subEtapaArray, setSubEtapaArray] = useState( [] );

    const [plantilla, setPlantilla] = useState(0);
    const [plantillaArray, setPlantillaArray] = useState( [] );

    const [actores, setActores] = useState( '' );
    const [demandados, setDemandados] = useState( '' );
    const [cadenaPartesAmparo, setCadenaPartesAmparo] = useState( '' ); 

    const [expediente, setExpediente] = useState( { } );
    const [exhortoLibrado, setExhortoLibrado] = useState( { } );
    const [exhortoRecibido, setExhortoRecibido] = useState( { } );
    const [amparo, setAmparo] = useState( { } );
    const [irregular, setIrregular] = useState( { } );
    const [expedientilloSuspension, setExpedientilloSuspension] = useState( { } );
    
    const [autorizar, setAutorizar] = useState(true);
    const [errors, setErrors] = useState( { } );

    const [observacion, setObservacion] = useState( '' );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );    
    const [loadingRegresarEscrito, setLoadingRegresarEscrito] = useState( false );  
    const [loadingGuardar, setLoadingGuardar] = useState( false );
    const [ValidateGuardar, setValidateGuardar] = useState( false );

    const [cuentaAsociada, setCuentaAsociada] = useState( '' );
    const [cuentaResponse, setCuentaResponse] = useState( { } );

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const [archivoProvisional, setArchivoProvisional] = useState( false );

    const [referencia, setReferencia] = useState( '' )
    const [tipoReferencia, setTipoReferencia] = useState( '' )

    const [numeroReferencia, setNumeroReferencia] = useState( '' );

    const [acumulacion, setAcumulacion] = useState( false )
    const [expedientePrincipal, setExpedientePrincipal] = useState( 0 )
    const [expedientesAcumulados, setExpedientesAcumulados] = useState( [] )

    const [observacionesMagistrados, setObservacionesMagistrados] = useState( [] )   

    const [promocionIrregular, setPromocionIrregular] = useState( false )    

    //const [juez, setJuez] = useState( 0 );
    //const [juezArray, setJuezArray] = useState( [ ] );

    const [parametricas, setParametricas] = useState({
        idTipoAcuerdo: 0,
        idSubTipoAcuerdo: 0,
        idPlantilla: 0,
        idEtapa: 0,
        idSubEtapa: 0,
    });

    const [tabValidation, setTabValidation] = useState({ 
        idDemandaInicial: 0, 
        idExpediente: 0,
        idPromocion: 0,
        idAmparo: 0,
        idExhortoRecibido: 0,
        idExhortoLibrado: 0,
    });    

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };    

    const handleChangeArchivoProvisional = ( value ) => {
        setArchivoProvisional( value );
    }

    const handleChangeTipoAcuerdo = ( value ) => {

        setTipoAcuerdo( value ); 
        setCuentaAsociada( '' );

        if( value === 7 && value !== 31 )
        {
            setArchivoProvisional( true );
        }        
        else{
            setArchivoProvisional( false );
        }

    }

    const handleChangeSubTipoAcuerdo = ( value ) => {

        setSubTipoAcuerdo( value ); 
        setCuentaAsociada( '' );

        if( tipoAcuerdo === 7 || ( tipoAcuerdo === 4 && value === 3 ) || ( tipoAcuerdo === 9 && value === 28 ) || ( tipoAcuerdo === 11 && value === 7 ) )
        {
            setArchivoProvisional( true );
        }
        else{
            setArchivoProvisional( false );
        }

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const Finalizar = async () => {
              
        setLoadingFinalizar( true );

        let idEstatus = 9;
        
        if( tribunal ){
            idEstatus = ( 
                ( tipoAcuerdo === 7 ) || 
                ( tipoAcuerdo === 4 && ( subTipoAcuerdo === 1 || subTipoAcuerdo === 3 ) ) ||
                ( tipoAcuerdo === 11 && ( subTipoAcuerdo === 4 || subTipoAcuerdo === 7 ) ) 
                ? 
                    65 
                : 
                    9 
            )
        }

        const params = {
            idAcuerdo : Id,
            idEstatus: idEstatus,
            archivoProvisional: archivoProvisional,
            cuentaAsociada: cuentaResponse,
            numeroReferencia: numeroReferencia,
            //juez: juez,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setOpenConfirmacion( false );

                    setLoadingFinalizar( false );

                    if( !response.data.usuarioActualAsignacion ){                            
                        setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                    }  
                    else if( response.data.usuarioActualAsignacion ){
                        setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                    }    

                    setSendAjax( true );
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });
    }

    const handleChangeEditor = () => {

        if( editorRef.current ){
        
            if( editorRef.current.getContent() !== '' ){

                setContenido(editorRef.current.getContent());

            }
        }

    }

    const obtenerPlantilla = async ( Id ) => {

        setPlantilla( Id );

        await ObtenerPlantillaDetalle( Id ).then( response => {

            if(response){

                if( response.status === 200){                  

                    if( editorRef.current ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', actores)
                            .replaceAll('[Demandado]', demandados)
                            .replaceAll('[Tipo_Juicio]', expediente?.tipoJuicio ?? "" )
                            .replaceAll('[Ponencia]', expediente?.ponencia ?? "" )
                            .replaceAll('[Numero_Expediente]', expediente?.numeroExpediente ?? "" )
                            .replaceAll('[Numero_Exhorto]', exhortoLibrado?.numeroExhortoLibrado ?? "" )
                            .replaceAll('[Autoridad_Exhorto]', exhortoLibrado?.autoridadExhorto ?? "" );
                        
                        editorRef.current.setContent( contenido );

                    }

                }
            }

        });

    }    

    const validateFiels = () => {
       
        setErrors( { } );

        let valid = true;
        let errores = {};    

        const contenido = editorRef.current.getContent();
        
        if( !autorizar && observacion.trim()  === '' ) {
            valid = false;
            errores.observaciones = 'Debes teclear un motivo de rechazo';
        } 

        if( autorizar && contenido.trim() === '' ){      
            valid = false;
            errores.contenido = 'Debes escribir el acuerdo';
        } 

        if( autorizar && (subTipoAcuerdo === 21 || subEtapa === 6) ){    
            if( expedientePrincipal === 0){           
                valid = false;
                errores.expedientesAcumulados = 'Debes seleccionar un expediente principal para acumular el expediente';
            }                   
        } 

        setErrors( errores );
        return valid;
    }

    const Regresar = async () => {

        const valid = validateFiels();
      
       if( !valid ){
           return false;
       }
       
       setLoadingRegresarEscrito( true );
       
       const params = {
           'idAcuerdo' : Id,
           'idEstatus': 5,
           'idEstatusAnterior': 8,
           'observacion': observacion,
           'archivoProvisional': archivoProvisional
       };

       await ActualizarEstatus( params ).then( response => {

           if( response ){

               if( response.status === 200){

                    setTimeout(() => {

                        setLoadingRegresarEscrito( false );

                        if( !response.data.usuarioActualAsignacion ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }    

                        setSendAjax( true );

                    }, 1000);   
               } 
               else {
                   setTimeout(() => {

                       setAlert({
                           open: true,
                           msg: 'Ocurrió un error, contacte con el administrador',
                           type: 'error'
                       }); 
                       
                       setLoadingRegresarEscrito( false );

                   }, 1000);  
               }
           }
       });

    }

    const GuardarAcuerdo = async () => {
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const contenido = editorRef.current.getContent();

        const params = {
            idAcuerdo : Id,
            contenido: contenido,
            idTipoAcuerdo: tipoAcuerdo,
            idSubTipoAcuerdo: subTipoAcuerdo,            
            idPlantilla: plantilla,       
            cuentaAsociada: cuentaResponse,
            idEtapa: etapa,
            idSubEtapa: subEtapa,
            idExpedientePrincipal: expedientePrincipal,
        };

        setLoadingGuardar( true );

        await GuardarEscritoAcuerdo( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        
                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 
                     
                        setLoadingGuardar( false ); 
                        setValidateGuardar( false );

                        if( tabValidation.idPromocion === 0 && exhortoLibrado?.success && ( checkCalificarTramite || exhortoLibrado?.idAutoridadExhortoLibrado !== 0 ) ){                            
                            setValidateGuardar( true );               
                        }     
                        else if( tabValidation.idPromocion === 0 &&  exhortoRecibido?.success && checkCalificarTramite ){
                            setValidateGuardar( true );
                        }   
                        else if( ( tabValidation.idPromocion === 0 || tabValidation.idPromocion !== 0 ) && exhortoRecibido?.success && (subTipoAcuerdo === 37 || subTipoAcuerdo === 38 || subTipoAcuerdo === 39) ){
                            setValidateGuardar( true );
                        } 
                        else if( ( amparo?.success && checkCalificarTramite ) ){
                            setValidateGuardar( true );
                        }     
                        else if( amparo?.success && amparo?.idTipoAmparo === 1 && subTipoAcuerdo === 15 && amparo?.fechaInformePrevio !== null ){                
                            setValidateGuardar( true );               
                        }
                        else if( amparo?.success && amparo?.idTipoAmparo === 2 && subTipoAcuerdo === 14 && amparo?.fechaInformeJustificado !== null ){                
                            setValidateGuardar( true );               
                        }
                        else if( amparo?.success && acuerdo?.idPromocion !== 0 && ( subTipoAcuerdo === 12 || subTipoAcuerdo === 13 ) && amparo?.fechaResolucionAmparo !== null ){                
                            setValidateGuardar( true );                 
                        }    
                        else if( amparo?.success && amparo?.idEstatus === 0 ){
                            setValidateGuardar( true );
                        }               
                        else if( ( expediente?.success || irregular?.success ) && !exhortoLibrado?.success  && !amparo?.success ){
                            setValidateGuardar( true );
                        }  
                        else if( amparo?.success && subTipoAcuerdo === 16 ){ 
                            setValidateGuardar( true );
                        }                   
                        else if( tabValidation.idPromocion !== 0 && expedientilloSuspension?.success ){    
                            setValidateGuardar( true );
                        }      
                        else if( tabValidation.idPromocion !== 0 && exhortoLibrado?.success && checkCalificarTramite ){                            
                            setValidateGuardar( true );               
                        }       
                        else if( tabValidation.idPromocion !== 0 && exhortoLibrado?.success && contenido !== '' && exhortoLibrado?.idAutoridadExhortoLibrado !== 0 ){                            
                            setValidateGuardar( true );               
                        }                   
                        
                        setParametricas({
                            idTipoAcuerdo: tipoAcuerdo,
                            idSubTipoAcuerdo: subTipoAcuerdo,
                            idPlantilla: plantilla,
                            idEtapa: etapa,
                            idSubEtapa: subEtapa,
                        })

                        if( tipoAcuerdo === 15 ){

                            setCuentaResponse( response.data.cuentaAsociada ?? { } );

                        }

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        const { message } = response.data;

                        setAlert({
                            open: true,
                            msg: message ? message : 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingGuardar( false ); 

                    }, 1000);  
                }
            }
        });
    }  

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idAcuerdo': Id,
            };

            dispatch({ type: types.openLoading });

            await AcuerdoDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {

                            setNoSeguimiento( response.data.id ?? "" );
                            setContenido( response.data.acuerdo ?? '' );
                            setExpediente( response.data.expediente );
                            setExhortoLibrado( response.data.exhortoLibrado );
                            setAmparo( response.data.amparo );
                            setIrregular( response.data.irregular );
                            setExhortoRecibido( response.data.exhortoRecibido );
                            setExpedientilloSuspension( response.data.expedientilloSuspension );

                            setArchivoProvisional( response.data.archivoProvisional ?? false );
                            setCuentaAsociada( response.data.cuentaAsociada?.correoElectronico ?? "" );
                            setCuentaResponse( response.data.cuentaAsociada ?? { } );  
                            setNumeroReferencia( response.data.numeroReferencia ?? '' );

                            setAcumulacion( response?.data?.acumulacion ?? false );
                            setExpedientePrincipal(  response?.data?.idExpedientePrincipal ?? 0 );
                            setExpedientesAcumulados(  response?.data?.expedientesAcumulados ?? [] );

                            setObservacionesMagistrados( response.data.observacionesMagistrados ?? [] );
                            setPromocionIrregular(  response?.data?.promocionIrregular ?? false );
                            
                            setParametricas({
                                idTipoAcuerdo: response.data.idTipoAcuerdo ?? 0,
                                idSubTipoAcuerdo: response.data.idSubTipoAcuerdo ?? 0,
                                idPlantilla: response.data.idPlantilla ?? 0,
                                idEtapa: response.data.idEtapaProvisional ?? 0,
                                idSubEtapa: response.data.idSubEtapaProvisional ?? 0,
                            })
                            
                            setTabValidation({ 
                                idDemandaInicial: response.data.idDemandaInicial ?? 0, 
                                idExpediente: response.data.idExpediente ?? 0, 
                                idPromocion: response.data.idPromocion ?? 0, 
                                idAmparo: response.data.idAmparo ?? 0, 
                                idExhortoRecibido: response.data.idExhortoRecibido ?? 0, 
                                idExhortoLibrado: response.data.idExhortoLibrado ?? 0, 
                                idIrregular: response.data.idIrregular ?? 0, 
                            });
                           
                            dispatch({
                                type: types.actualizarDetalleAcuerdo,                                
                                payload: {
                                    acuerdo:{                                   
                                        idDemandaInicial: response.data.idDemandaInicial ?? 0, 
                                        idExpediente: response.data.idExpediente ?? 0, 
                                        idPromocion: response.data.idPromocion ?? 0, 
                                        idAmparo: response.data.idAmparo ?? 0, 
                                        idExhortoRecibido: response.data.idExhortoRecibido ?? 0, 
                                        idExhortoLibrado: response.data.idExhortoLibrado ?? 0, 
                                        idIrregular: response.data.idIrregular ?? 0, 
                                        demandaDigitalizada: response.data.demandaDigitalizada ?? '', 
                                        exhortoDigitalizado: response.data.exhortoDigitalizado ?? '', 
                                        amparoDigitalizado: response.data.amparoDigitalizado ?? '',
                                        promocionDigitalizado: response.data.promocionDigitalizado ?? '',                                   
                                    },
                                    expediente: response.data.expediente,
                                    exhortoLibrado: response.data.exhortoLibrado,  
                                    irregular: response.data.irregular,
                                    exhortoRecibido: response.data.exhortoRecibido,
                                    amparo: response.data.amparo,
                                    promocion: response.data.promocion,
                                    expedientilloSuspension: response.data.expedientilloSuspension,
                                    demanda: {
                                        folioBuzon: response?.data?.folioBuzonDemanda ?? '',
                                        anexosBuzonElectronico: response?.data?.anexosBuzonDemanda ?? [],
                                    }
                                }
                            }); 

                            dispatch({
                                type: types.actualizarNotificacionesAcuerdo,                                
                                payload: response.data.notificaciones,                              
                            });

                            dispatch({
                                type: types.actualizarPrestacionesAcuerdo,                                
                                payload: response.data.prestaciones,                                 
                            }); 

                            dispatch({
                                type: types.audienciaSetAcuerdo,                                
                                payload: response.data.id ,                              
                            });

                            if( response.data.expediente ){
                                dispatch({
                                    type: types.audienciaSetExpediente,                                
                                    payload: response.data.expediente,                              
                                });
                            }

                            if( ( response?.data?.idAmparo ?? 0 ) !== 0 ){
                                setTipoReferencia( 'Número de amparo' );
                                setReferencia( response?.data?.amparo?.numeroAmparo ?? '' );
                            } 
                            else if( ( response?.data?.idExhortoRecibido ?? 0 ) !== 0 ){
                                setTipoReferencia( 'Número de exhorto recibido' );
                                setReferencia( response?.data?.exhortoRecibido?.numeroExhorto ?? '' );
                            } 
                            else if( ( response?.data?.idExhortoLibrado ?? 0) !== 0 ){
                                setTipoReferencia( 'Número de exhorto librado' );
                                setReferencia( response?.data?.exhortoLibrado?.numeroExhortoLibrado ?? '' );
                            } 
                            else if(( response?.data?.idExpediente ?? 0 ) !== 0 ){
                                setTipoReferencia( 'Número de expediente' );
                                setReferencia( response?.data?.expediente?.numeroExpediente ?? '' );
                            }   
                            else if( ( response?.data?.idIrregular ?? 0) !== 0 ){
                                if( response?.data?.irregular?.idTipoIrregular === 1 ){
                                    setTipoReferencia( 'Número de convenio' );
                                }
                                else {
                                    setTipoReferencia( 'Número de irregular' );
                                }
                                setReferencia( response?.data?.irregular?.numeroIrregular ?? '' );
                            }                  

                            if( !response?.data?.amparo?.success && !response?.data?.exhortoLibrado?.success && ( response?.data?.expediente?.success || response?.data?.irregular?.success || response?.data?.promocion?.success ) && response.data.acuerdo ){ 
                                setValidateGuardar( true ); 
                            }
                            else if( response?.data?.exhortoLibrado?.success && response?.data?.exhortoLibrado?.idAutoridadExhortoLibrado !== 0 && response.data.acuerdo ){ 
                                setValidateGuardar( true );   
                            }                            
                            else if( response?.data?.amparo?.success && response?.data?.amparo?.idTipoAmparo === 1 && response.data.idSubTipoAcuerdo === 15 && response?.data?.amparo?.fechaInformePrevio !== null && response.data.acuerdo ){                
                                setValidateGuardar( true );                            
                            }
                            else if( response?.data?.amparo?.success && response?.data?.amparo?.idTipoAmparo === 2 && response.data.idSubTipoAcuerdo === 14 && response?.data?.amparo?.fechaInformeJustificado !== null && response.data.acuerdo ){                
                                setValidateGuardar( true );                            
                            }
                            else if( response?.data?.amparo?.success && response.data.idPromocion !== 0  && ( response.data.idSubTipoAcuerdo === 12 || response.data.idSubTipoAcuerdo === 13 ) && response?.data?.amparo?.fechaResolucionAmparo !== null && response.data.acuerdo ){                
                                setValidateGuardar( true );                            
                            }
                            else if( response?.data?.amparo?.success && response.data.idPromocion === 0 && response?.data?.amparo?.idEstatus === 0 && response.data.acuerdo ){                
                                setValidateGuardar( true );                            
                            }
                            else if( response?.data?.amparo?.success && response?.data?.idSubTipoAcuerdo === 16 ){    
                                setValidateGuardar( true );
                            }  
                            else if( response?.data?.promocion?.success && response?.data?.expedientilloSuspension?.success ){    
                                setValidateGuardar( true );
                            } 
                            else if( response?.data?.exhortoRecibido?.success && ( response?.data?.idSubTipoAcuerdo === 37 || response?.data?.idSubTipoAcuerdo === 38 || response?.data?.idSubTipoAcuerdo === 39 ) ){    
                                setValidateGuardar( true );
                            }       

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setSendAjax( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 8 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setSendAjax( true ); 
                                }

                            }  

                            if( response.data.idEstatus !== 8){     
                                
                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }

                                setSendAjax( true );
                            }               
                            
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                        }, 600);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])

    useEffect(() => {

        async function Obtener(){    

            await ObtenerTiposAcuerdos().then( response => {

                if(response){
                    if( response.status === 200){

                        setTipoAcuerdoArray( response.data );

                    }
                }

            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {

        async function Obtener(){  
            
            setPlantilla( 0 );
            setPlantillaArray( [] );

            setSubTipoAcuerdo( 0 );
            setSubTipoAcuerdoArray( [] );

            if(tipoAcuerdo !== 0){   

                await ObtenerSubTiposAcuerdos(tipoAcuerdo).then( response => {
                
                    if(response){

                        if( response.status === 200){
                            setSubTipoAcuerdoArray( response.data );                            
                        }

                    }

                });

            }       

        }

        Obtener();

    }, [ tipoAcuerdo ]);
          
    useEffect(() => {             
        
        if( tipoAcuerdo === 0 || subTipoAcuerdo === 0){              

            setPlantilla( 0 );
            setPlantillaArray( [] );       
        }        
    
        async function Obtener(){

            if( tipoAcuerdo !== 0 ){            
                const params = {
                    'idTipoAcuerdo': tipoAcuerdo,
                    'idSubTipoAcuerdo': subTipoAcuerdo,
                    'idTipoNotificacion': 0,
                };

                await ObtenerPlantilla( params ).then( response => {

                    if( response ){

                        if( response.data ){
                            setPlantillaArray( response.data );
                        }

                    }

                });
            }

        }     
        
        Obtener();

    }, [tipoAcuerdo, subTipoAcuerdo])

    useEffect(() => {

        async function Obtener(){    

            await ObtenerEtapasTribunales().then( response => {

                if(response){
                    if( response.status === 200){

                        setEtapaArray( response.data );

                    }
                }

            });

        }

        if( juzgado ){
            Obtener();
        }

    }, [ juzgado ]);

    useEffect(() => {

        async function Obtener(){             
        
            setSubEtapa( 0 );
            setSubEtapaArray( [] );

            if(etapa !== 0){   

                await ObtenerSubEtapasTribunalesLaborales(etapa).then( response => {
                
                    if(response){

                        if( response.status === 200){
                            setSubEtapaArray( response.data );                            
                        }

                    }

                });

            }       

        }

        if( juzgado ){
            Obtener();
        }

    }, [ juzgado, etapa ]);

    useEffect(() => {

        if( expediente?.success ){          

            let cadenaActores = '';
            expediente?.actor.forEach((value, key) => { 
                if ( key + 1 !== expediente?.actor?.length ){ 
                    cadenaActores += (value + ', ')
                } 
                else {
                    cadenaActores += (value + ' ')
                }
            });
            setActores( cadenaActores );
            
            let cadenaDemandados = '';
            expediente?.demandado?.forEach((value, key) => { 
                if ( key + 1 !== expediente?.demandado?.length ){ 
                    cadenaDemandados += (value + ', ')
                } 
                else {
                    cadenaDemandados += (value + ' ')
                }
            });
            setDemandados( cadenaDemandados );
            
        }

    }, [ expediente ])

    useEffect(() => {

        if( amparo?.success ){          

            let cadenaPA = '';
            amparo?.partes.forEach((value, key) => { 
                if (key + 1 !== amparo?.partes.length){ 
                    cadenaPA += (value.parte + ' (' + value.tipoParte + '), ')
                } else {
                    cadenaPA += (value.parte + ' (' + value.tipoParte + ') ')
                }
            });
            setCadenaPartesAmparo(cadenaPA);           
        }

    }, [ amparo ])

    useEffect(() => {

        if( parametricas.idTipoAcuerdo !== 0 && tipoAcuerdoArray.length !== 0){
            setTipoAcuerdo(parametricas.idTipoAcuerdo);
        }

    }, [ parametricas, tipoAcuerdoArray ])

    useEffect(() => {

        setSubTipoAcuerdo( 0 );

        if( parametricas.idSubTipoAcuerdo !== 0 && tipoAcuerdo !== 0 && parametricas.idTipoAcuerdo === tipoAcuerdo && subTipoAcuerdoArray.length !== 0){
            const validate = subTipoAcuerdoArray.find( ( element ) => { return element.idSubTipoAcuerdo === parametricas.idSubTipoAcuerdo; } );

            if( validate ){
                setSubTipoAcuerdo(parametricas.idSubTipoAcuerdo);
            }
            else{
                setSubTipoAcuerdo( 0 );
            }
        }

    }, [ parametricas, tipoAcuerdo, subTipoAcuerdoArray ])

    useEffect(() => {

        setPlantilla( 0 );

        if( parametricas.idPlantilla !== 0 && tipoAcuerdo !== 0 && parametricas.idTipoAcuerdo === tipoAcuerdo && plantillaArray.length !== 0){
            const validate = plantillaArray.find( ( element ) => { return element.idPlantilla === parametricas.idPlantilla; } );

            if ( validate ){
                setPlantilla(parametricas.idPlantilla);
            }
            else{
                setPlantilla( 0 );
            }
        }

    }, [ parametricas, tipoAcuerdo, subTipoAcuerdo, plantillaArray ])

    useEffect(() => {     

        if( checkCalificarTramite && exhortoLibrado?.success && contenido ){                
            setValidateGuardar( true );                            
        }
        else if( exhortoLibrado?.success && exhortoLibrado?.fechaDevolucion && contenido ){                
            setValidateGuardar( true );                            
        }

    }, [ exhortoLibrado, contenido, checkCalificarTramite ])

    useEffect(() => {     
        
        if( checkCalificarTramite && amparo?.success && contenido ){                
            setValidateGuardar( true );                            
        }
        else if( amparo?.success && amparo?.idTipoAmparo === 1 & amparo?.fechaInformePrevio && contenido ){                
            setValidateGuardar( true );                            
        }
        else if( amparo?.success && amparo?.idTipoAmparo === 2 & amparo?.fechaInformeJustificado && contenido ){                
            setValidateGuardar( true );                            
        }
        else if( amparo?.success && acuerdo?.idPromocion !== 0 && amparo?.fechaResolucionAmparo && contenido ){                
            setValidateGuardar( true );                            
        }

    }, [ amparo, acuerdo, contenido, checkCalificarTramite ])

    useEffect(() => {     

        if( checkCalificarTramite && exhortoRecibido?.success && contenido ){                
            setValidateGuardar( true );                            
        }
        else if( exhortoRecibido?.success && exhortoRecibido?.idObjetoExhorto !== 0 && exhortoRecibido.idClaseExhorto !== 0 && contenido ){    
            setValidateGuardar( true );                            
        }        

    }, [ exhortoRecibido, contenido, checkCalificarTramite ])

    useEffect(() => {

        if( parametricas.idEtapa !== 0 && etapaArray.length !== 0){
            setEtapa(parametricas.idEtapa);
        }

    }, [ parametricas, etapaArray ])

    useEffect(() => {

        setSubEtapa( 0 );

        if( parametricas.idSubEtapa !== 0 && etapa !== 0 && parametricas.idEtapa === etapa && subEtapaArray.length !== 0){
            const validate = subEtapaArray.find( ( element ) => { return element.idSubEtapa === parametricas.idSubEtapa; } );

            if( validate ){
                setSubEtapa(parametricas.idSubEtapa);
            }
            else{
                setSubEtapa( 0 );
            }
        }

    }, [ parametricas, etapa, subEtapaArray ])

    useEffect(() => {

        async function obtener(){

            await ObtenerExpedientePrincipal( expediente?.idExpediente ?? 0 ).then( response => {

                if(response){                    
                    if( response.status === 200){

                        setExpedientesAcumulados( response.data );

                    }
                }

            });

        }

        if( expediente?.success && etapa === 7 && subEtapa === 6 ){
            obtener();
        }

    }, [ etapa, subEtapa, expediente ])

    /*useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);*/

    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }
    
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    sendAjax 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile sx={{ pt: 2 }}>
                                
                                <Tab label="Acuerdo" { ...a11yProps(0) } />
                                
                                <Tab label="Notificación" { ...a11yProps(1) } onClick={ handleChangeEditor } />

                                <Tab 
                                    label="Agenda de audiencias" 
                                    { ...a11yProps(2) } 
                                    onClick={ handleChangeEditor } 
                                    disabled={ tabValidation.idExpediente === 0 }
                                />
                                
                                <Tab 
                                    label="Calificar trámite" 
                                    { ...a11yProps(3) } 
                                    onClick={ handleChangeEditor } 
                                    disabled={                                     
                                        ( tabValidation.idDemandaInicial !== 0 ) ||    
                                        ( tabValidation.idDemandaInicial === 0 && tabValidation.idPromocion !== 0 && expediente?.success && tabValidation.idAmparo === 0 && tabValidation.idExhortoLibrado === 0 ) ||
                                        ( tabValidation.idDemandaInicial === 0 && tabValidation.idPromocion === 0 && expediente?.success && tabValidation.idAmparo === 0 && tabValidation.idExhortoLibrado === 0 ) ||    
                                        ( amparo?.success && amparo?.idEstatus === 0 ) ||
                                        ( tabValidation.idIrregular !== 0 ) ||                                
                                        ( tabValidation.idPromocion !== 0 && expedientilloSuspension?.success ) ||                                
                                        ( tabValidation.idPromocion !== 0 && tabValidation.idExhortoLibrado === 0 && tabValidation.idExhortoRecibido !== 0 )||
                                        ( tabValidation.idPromocion === 0 && tabValidation.idExhortoLibrado === 0 && tabValidation.idExhortoRecibido !== 0 && exhortoRecibido?.idEstatus !== 0)
                                    }
                                />                     

                                <Tab label="Promocion" { ...a11yProps(4) } sx={{ display: ( tabValidation.idPromocion !== 0 ? 'flex' : 'none') }} onClick={ handleChangeEditor } />                                
                                
                                <Tab label="Amparo" { ...a11yProps(5) } sx={{ display: ( tabValidation.idAmparo !== 0 ? 'flex' : 'none') }} onClick={ handleChangeEditor } />                                
                                
                                <Tab label="Exhorto Librado" { ...a11yProps(6) } sx={{ display: ( tabValidation.idExhortoLibrado !== 0 ? 'flex' : 'none') }} onClick={ handleChangeEditor } /> 

                                <Tab label="Exhorto recibido" { ...a11yProps(7) } sx={{ display: ( tabValidation.idExhortoRecibido !== 0 ? 'flex' : 'none') }} onClick={ handleChangeEditor } /> 
                                <Tab label={ irregular?.idTipoIrregular === 1 ? "Convenio fuera de juicio" : "Irregular" } { ...a11yProps(8) } sx={{ display: ( tabValidation.idIrregular !== 0 ? 'flex' : 'none') }} onClick={ handleChangeEditor } />                                
                                <Tab label={ tabValidation.idDemandaInicial !== 0 ? 'Demanda' : 'Expediente' } { ...a11yProps(9) } sx={{ display: ( (tabValidation.idDemandaInicial !== 0 || tabValidation.idExpediente !== 0 ) ? 'flex' : 'none') }} onClick={ handleChangeEditor } />                                
                            </Tabs>

                            <TabPanel value={ valueTab } index={0}>

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Autorizar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                {
                                    ( acumulacion && tabValidation.idDemandaInicial !== 0 )
                                    &&
                                        <Grid container mt={2}>  
                                            <Grid item md={12} xs={12} mt={-1} >
                                                <Alert severity="info" color="warning">
                                                    <strong>Se encontraron coincidencias de este expediente para acumular</strong>
                                                </Alert>               
                                            </Grid>   
                                        </Grid>                    
                                }
                                
                                <Grid container spacing={3} mt={1}>  
                                                     
                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoAcuerdo-label">Tipo de acuerdo</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoAcuerdo-label"
                                                id="select-tipoAcuerdo"                                            
                                                label="Tipo de Acuerdo"
                                                name="tipoAcuerdo"
                                                value={ tipoAcuerdo }
                                                onChange={ ( e ) => { handleChangeTipoAcuerdo( e.target.value ); } }
                                                disabled
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                
                                                {
                                                    tabValidation?.idDemandaInicial !== 0 && expediente?.idTipoJuicio !== 4 && expediente?.idTipoCalificacion === 0
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 1 || element.idTipoAcuerdo === 2 || element.idTipoAcuerdo === 7; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }             

                                                {
                                                    promocion?.idTipoPromocion === 7 && expediente?.idTipoJuicio !== 4 && expediente?.idTipoCalificacion === 0
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 1 || element.idTipoAcuerdo === 2 || element.idTipoAcuerdo === 7; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }      

                                                {
                                                    promocion?.idTipoPromocion === 7 && expediente?.idTipoJuicio !== 4 && expediente?.idTipoCalificacion === 2
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 1 || element.idTipoAcuerdo === 2 || element.idTipoAcuerdo === 7; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }    

                                                {
                                                    promocion?.idTipoPromocion === 7 && expediente?.idTipoJuicio !== 4 && expediente?.idTipoCalificacion === 1
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 7; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }                                                   

                                                {
                                                    ( 
                                                        tabValidation?.idPromocion !== 0 && 
                                                        expediente?.idTipoJuicio !== 4 && 
                                                        expediente?.idEtapa === 2 && 
                                                        expediente?.idTipoCalificacion === 2 && 
                                                        tabValidation.idExhortoLibrado === 0 && 
                                                        tabValidation.idAmparo === 0 &&
                                                        promocion?.idTipoPromocion !== 7
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 1 || element.idTipoAcuerdo === 2; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( tabValidation?.idDemandaInicial !== 0 && expediente?.idTipoJuicio === 4 && expediente?.idTipoCalificacion === 0 && tabValidation.idExhortoLibrado === 0 && tabValidation.idAmparo === 0 )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 1; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( tabValidation?.idDemandaInicial !== 0 && expediente?.idTipoJuicio === 4 && expediente?.idTipoCalificacion === 0 && juzgado)
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 7; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( tabValidation?.idExhortoLibrado !== 0 || tabValidation?.idExhortoRecibido !== 0 )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 13; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( tabValidation?.idIrregular !== 0)
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 11; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }
                                                
                                                {
                                                    ( tabValidation.idAmparo !== 0) &&
                                                    !expedientilloSuspension?.success
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 6; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    expedientilloSuspension?.success
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 18; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        //expediente?.idEtapa !== 0 &&  
                                                        expediente?.idEtapa !== 5 && 
                                                        expediente?.idEtapa !== 6 && 
                                                        expediente?.idEtapa !== 7 &&  
                                                        expediente?.idEtapa !== 8 &&                                              
                                                        expediente?.idEtapa !== 9 &&   
                                                        expediente?.idEtapa !== 10 &&   
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        promocion?.idTipoPromocion !== 7
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 7; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 && 
                                                        expediente?.idEtapa === 6 &&  
                                                        expediente?.idSubEtapa === 1003 &&  
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 7; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 15 &&          
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 10; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {/* {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 4 || expediente?.idEtapa === 9 || expediente?.idEtapa === 10 ) &&    
                                                        expediente?.idEstatus !== 62 &&
                                                        tabValidation.idAmparo === 0 &&                                          
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 8 || element.idTipoAcuerdo === 9;  
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                } */}

                                                {
                                                    ( 
                                                        //tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&
                                                        expediente?.idEtapa !== 4 &&
                                                        expediente?.idEtapa !== 6 &&
                                                        expediente?.idEtapa !== 7 &&
                                                        expediente?.idEtapa !== 8 &&
                                                        expediente?.idEtapa !== 9 &&
                                                        expediente?.idEtapa !== 10 &&
                                                        //expediente?.idEtapa !== 0 &&
                                                        expediente?.idEstatus !== 62 &&
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 4; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa !== 0 &&    
                                                        expediente?.idEtapa !== 7 &&                                                 
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 21; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                    
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 18; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 15 && expediente?.idTipoJuicio === 2) &&  
                                                        expediente?.idEtapa !== 7 &&     
                                                        tabValidation.idAmparo === 0 &&                                            
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tribunal 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 19; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 14 && expediente?.idTipoJuicio === 1) &&  
                                                        expediente?.idEtapa !== 7 &&      
                                                        tabValidation.idAmparo === 0 &&                                           
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tribunal 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 19; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 3 && expediente?.idTipoJuicio === 1) &&  
                                                        expediente?.idEtapa !== 7 &&      
                                                        tabValidation.idAmparo === 0 &&                                           
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tribunal 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 20; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa !== 0 &&  
                                                        tabValidation.idAmparo === 0 &&                                            
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 14 || element.idTipoAcuerdo === 15 || element.idTipoAcuerdo === 16 || element.idTipoAcuerdo === 17; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 13 &&
                                                        tabValidation.idAmparo === 0 &&                                            
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tribunal
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 22; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation?.idDemandaInicial !== 0 && 
                                                        expediente?.idTipoJuicio === 4 && 
                                                        expediente?.idTipoCalificacion === 0 &&  
                                                        tabValidation.idExhortoLibrado === 0 && 
                                                        tabValidation.idAmparo === 0  &&
                                                        juzgado
                                                    )                                                    
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 8;  
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation?.idPromocion === 0 && 
                                                        expediente?.idTipoJuicio !== 4  &&
                                                        expediente?.idEtapa === 2 &&  
                                                        expediente?.idTipoCalificacion === 2 && 
                                                        tabValidation.idExhortoLibrado === 0 && 
                                                        tabValidation.idAmparo === 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { return element.idTipoAcuerdo === 23; } )
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 15 &&          
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 24; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 15  &&          
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 27; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 11 || expediente?.idEtapa === 22 ) &&     
                                                        ( expediente?.idTipoJuicio !== 2 )  &&
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 25; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 11 || expediente?.idEtapa === 22 ) &&     
                                                        ( expediente?.idTipoJuicio !== 2 )  &&
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        ( tabValidation.idPromocion !== 0 || tabValidation.idPromocion === 0 ) &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 34; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 20  &&          
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 26; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 20  &&          
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        ( tabValidation.idPromocion !== 0 || tabValidation.idPromocion === 0 ) &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 35; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                        
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion === 0  &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 28; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }         

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 && 
                                                        (
                                                            expediente?.idEtapa === 5 ||
                                                            expediente?.idEtapa === 7 ||
                                                            expediente?.idEtapa === 9
                                                        ) &&
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&   
                                                        tabValidation.idPromocion === 0                                                       
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 29; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }  

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 && 
                                                        ( expediente?.idEtapa === 11 ) &&
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&   
                                                        tabValidation.idPromocion === 0                                                       
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 31; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }      

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 23 &&                                                        
                                                        tabValidation.idAmparo === 0 &&                                           
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 19; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }  

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                                                                    
                                                        tabValidation.idAmparo === 0 &&                                           
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tribunal 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 32; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }           

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 15  &&          
                                                        tabValidation.idAmparo === 0 &&                                    
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        ( tabValidation.idPromocion !== 0 || tabValidation.idPromocion === 0 )
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 33; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }       

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                      
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        expediente?.acumulado
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 7; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }   

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                      
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        expediente?.idEtapa === 11  &&          
                                                        expediente?.idTipoJuicio === 6 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 40; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }            

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                      
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        (
                                                            ( expediente?.idEtapa === 3  &&  expediente?.idSubEtapa === 1044 ) ||
                                                            ( expediente?.idEtapa === 27 )           
                                                        ) &&
                                                        expediente?.idTipoJuicio === 6 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 38; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }       

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                      
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        (
                                                            ( expediente?.idEtapa === 3  && expediente?.idSubEtapa === 1046 ) || 
                                                              expediente?.idEtapa === 28
                                                        )
                                                        &&          
                                                        expediente?.idTipoJuicio === 6 
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 41; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }            

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                      
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&      
                                                        tabValidation.idPromocion !== 0 &&                                                                                    
                                                        ( expediente?.idSubEtapa === 1021 || expediente?.idEtapa === 9 )
                                                    )
                                                    ?
                                                        tipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idTipoAcuerdo === 8; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={elem.idTipoAcuerdo} value={ elem.idTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }                                             
                                                
                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.tipoAcuerdo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoAcuerdo } </Alert> )
                                        }
                                    </Grid>  

                                    <Grid item md={6} xs={12} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-subTipoAcuerdo-label">Subtipo de acuerdo</InputLabel>
                                        
                                            <Select
                                                labelId="select-subTipoAcuerdo-label"
                                                id="select-subTipoAcuerdo"                                            
                                                label="Subtipo de Acuerdo"
                                                name="subTipoAcuerdo"
                                                value={ subTipoAcuerdo }
                                                onChange={ ( e ) => { handleChangeSubTipoAcuerdo( e.target.value ); } }
                                                disabled
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                     
                                                                                 
                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 0 &&       
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tribunal
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 19; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 0 &&       
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado &&
                                                        promocion.idTipoPromocion === 7
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo !== 20 && element.idSubTipoAcuerdo !== 31; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 0 &&       
                                                        expediente?.idTipoJuicio !== 4 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 34; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 0 &&       
                                                        expediente?.idTipoJuicio !== 4 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado &&
                                                        promocion?.idTipoPromocion !== 7
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo !== 31 && element.idSubTipoAcuerdo !== 19 && element.idSubTipoAcuerdo !== 21 && element.idSubTipoAcuerdo !== 34; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( 
                                                            expediente?.idEtapa === 1 || 
                                                            expediente?.idEtapa === 2 ||
                                                            expediente?.idEtapa === 3 || 
                                                            expediente?.idEtapa === 11 || 
                                                            expediente?.idEtapa === 12 || 
                                                            expediente?.idEtapa === 13 ||
                                                            expediente?.idEtapa === 14 ||
                                                            expediente?.idEtapa === 15 ||
                                                            expediente?.idEtapa === 19 ||
                                                            expediente?.idEtapa === 20 ||
                                                            expediente?.idEtapa === 21 ||
                                                            expediente?.idEtapa === 22 ||
                                                            expediente?.idEtapa === 23 ||
                                                            expediente?.idEtapa === 24 ||
                                                            expediente?.idEtapa === 25 ||
                                                            expediente?.idEtapa === 26 ||
                                                            expediente?.idEtapa === 27 ||
                                                            expediente?.idEtapa === 28
                                                        ) &&   
                                                        tipoAcuerdo !== 4 && 
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo !== 19 && element.idSubTipoAcuerdo !== 20 && element.idSubTipoAcuerdo !== 31 && element.idSubTipoAcuerdo !== 34; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 4 || ( expediente?.idEtapa === 6 && expediente?.idSubEtapa === 1003 ) ) &&   
                                                        tabValidation.idAmparo === 0 &&                                                                             
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 31; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( expediente?.idEtapa === 4 || ( expediente?.idEtapa === 6 && expediente?.idSubEtapa === 1003 ) ) && 
                                                        expediente?.idTipoJuicio === 2 &&     
                                                        tabValidation.idAmparo === 0 &&                                                                             
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 20; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( tipoAcuerdo === 8 || tipoAcuerdo === 9 ) &&    
                                                        tabValidation.idAmparo === 0 &&                                                                                 
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tribunal
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray                                                       
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( tipoAcuerdo === 8 || tipoAcuerdo === 9 ) &&    
                                                        tabValidation.idAmparo === 0 &&                                                                                 
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 35; 
                                                        })                                                       
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        tipoAcuerdo === 9  &&    
                                                        tabValidation.idAmparo === 0 &&                                                                                 
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray                                                         
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        tipoAcuerdo === 4 &&   
                                                        expediente?.idEtapa !== 5 &&     
                                                        tabValidation.idAmparo === 0 &&                                                                            
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray                                                       
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                                        
                                                        tabValidation.idExpediente !== 0 &&    
                                                        tipoAcuerdo === 4 &&   
                                                        expediente?.idEtapa === 5 &&      
                                                        tabValidation.idAmparo === 0 &&                                                                         
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 2 || element.idSubTipoAcuerdo === 3; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 1 &&
                                                        amparo?.idEstatus === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 11; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 2 &&
                                                        amparo?.idEstatus === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 17; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 1 &&
                                                        amparo?.idEstatus === 55 &&
                                                        amparo?.idSentidoAmparo === 0 &&
                                                        ( expediente?.idSubEtapa !== 1003 && expediente?.idSubEtapa !== 1004 ) &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 12 || element.idSubTipoAcuerdo === 13; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 1 &&
                                                        amparo?.idEstatus === 55 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 16; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 2 &&
                                                        amparo?.idEstatus === 55 &&
                                                        amparo?.idSentidoAmparo === 0 &&
                                                        ( expediente?.idSubEtapa !== 1003 && expediente?.idSubEtapa !== 1004 ) &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 12 || element.idSubTipoAcuerdo === 13; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 1 &&
                                                        amparo?.idEstatus === 55 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 15; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tipoAcuerdo === 6 &&                                                                                                 
                                                        tabValidation.idAmparo !== 0 &&
                                                        amparo?.idTipoAmparo === 2 &&
                                                        amparo?.idEstatus === 55 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0                                                        
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 14; 
                                                        })                                                  
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular !== 0 &&      
                                                        irregular?.idTipoIrregular === 1 && 
                                                        irregular?.idSubEtapa === 0 &&                                                  
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0                                                   
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo !== 5 && element.idSubTipoAcuerdo !== 40; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular !== 0 &&      
                                                        irregular?.idTipoIrregular === 1 && 
                                                        ( irregular?.idSubEtapa === 1017 || irregular?.idSubEtapa === 1019 )&&                                                  
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0                                                   
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 6 || element.idSubTipoAcuerdo === 7; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular !== 0 &&      
                                                        irregular?.idTipoIrregular === 2 &&                                                  
                                                        tabValidation.idAmparo === 0 &&                                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0                                                   
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 5; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular === 0 &&                                                    
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido !== 0 &&   
                                                        exhortoRecibido.idEstatus === 0  
                                                                                              
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 32; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular === 0 &&                                                    
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        tabValidation.idExhortoLibrado !== 0                                               
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 33; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular === 0 &&                                                    
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido !== 0                                                   
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 39;
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    (                                          
                                                        tabValidation.idIrregular === 0 &&                                                    
                                                        tabValidation.idAmparo === 0 &&
                                                        ( tabValidation.idPromocion === 0 || tabValidation.idPromocion !== 0 ) &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido !== 0 &&   
                                                        exhortoRecibido.idEstatus === 55                                                                                               
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 37; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }
                                                {
                                                    (                                          
                                                        tabValidation.idIrregular === 0 &&                                                    
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idPromocion === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido !== 0 &&   
                                                        exhortoRecibido.idEstatus === 62 &&
                                                        exhortoRecibido.idEtapa !== 8                                         
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 38; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 0 &&       
                                                        expediente?.idTipoJuicio !== 4 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 19; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idEtapa === 0 &&       
                                                        expediente?.idTipoJuicio === 4 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 18 || element.idSubTipoAcuerdo === 34; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial !== 0 &&
                                                        tabValidation.idExpediente !== 0 &&                                                      
                                                        tabValidation.idAmparo === 0 &&
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        acumulacion
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 21; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                
                                                {
                                                    (                                          
                                                        tabValidation.idIrregular !== 0 &&      
                                                        irregular?.idTipoIrregular === 2 &&   
                                                        irregular?.idEstatus === 55 &                                               
                                                        tabValidation.idAmparo === 0 &&                                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0                                                   
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray     
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 40; 
                                                        })                                             
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idSubEtapa === 1021 &&       
                                                        expediente?.idTipoJuicio === 6 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 25; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idSubEtapa === 1021 &&       
                                                        expediente?.idTipoJuicio === 5 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 41; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        expediente?.idSubEtapa === 1040 &&       
                                                        expediente?.idTipoJuicio === 5 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 42 || element.idSubTipoAcuerdo === 43; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                                {
                                                    ( 
                                                        tabValidation.idDemandaInicial === 0 &&
                                                        tabValidation.idExpediente !== 0 &&    
                                                        ( 
                                                            expediente?.idSubEtapa === 1041 ||
                                                            expediente?.idSubEtapa === 1042
                                                        ) &&       
                                                        expediente?.idTipoJuicio === 5 &&
                                                        tabValidation.idAmparo === 0 &&                                       
                                                        tabValidation.idExhortoLibrado === 0 &&
                                                        tabValidation.idExhortoRecibido === 0 &&
                                                        tabValidation.idIrregular === 0 &&
                                                        tabValidation.idPromocion !== 0 &&
                                                        juzgado
                                                    )
                                                    ?
                                                        subTipoAcuerdoArray
                                                        .filter( (element ) => { 
                                                            return element.idSubTipoAcuerdo === 44; 
                                                        })
                                                        .map((elem)=>(
                                                            <MenuItem key={ elem.idSubTipoAcuerdo } value={ elem.idSubTipoAcuerdo }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    :
                                                    null
                                                }

                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.subTipoAcuerdo && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.subTipoAcuerdo } </Alert> )
                                        }
                                    </Grid>        

                                    <Grid item md={12} xs={8} >                            
                                        <FormControl fullWidth>
                                            <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                        
                                            <Select
                                                labelId="select-plantilla-label"
                                                id="select-plantilla"                                            
                                                label="Plantilla"
                                                name="plantilla"
                                                value={ plantilla }
                                                onChange={ ( e ) => { obtenerPlantilla( e.target.value ); } }
                                                disabled
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                {
                                                    plantillaArray.map((elem)=>(
                                                        <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>
                                    </Grid>                                   

                                    {
                                        tipoAcuerdo === 15
                                        &&   
                                        <>
                                            {
                                                cuentaResponse?.success
                                                &&
                                                <>                                        
                                                    <Grid item md={4} xs={12}>
                                                        <Typography variant='body2'>
                                                            <strong>Solicitante: </strong> <br />
                                                            { cuentaResponse?.nombre + ' ' + cuentaResponse?.apellido1 + ' ' + cuentaResponse?.apellido2 }
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item md={4} xs={12}>
                                                        <Typography variant='body2'>
                                                            <strong>Correo electrónico: </strong> <br />
                                                            {cuentaResponse?.correoElectronico}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item md={4} xs={12}>
                                                        <Typography variant='body2'>
                                                            <strong>Teléfono celular: </strong> <br />
                                                            {cuentaResponse?.telefonoCelular}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }                                                  
                                        </>
                                    }    

                                    {
                                        tipoAcuerdo === 28
                                        &&
                                        <>
                                        
                                            <Grid item md={6} xs={12} >                            
                                                <FormControl fullWidth>
                                                    <InputLabel id="select-etapa-label">Etapa</InputLabel>
                                                
                                                    <Select
                                                        labelId="select-etapa-label"
                                                        id="select-etapa"                                            
                                                        label="Etapa"
                                                        name="etapa"
                                                        value={ etapa }
                                                        onChange={ ( e ) => { setEtapa( e.target.value ); } }
                                                        disabled
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                        
                                                        {                                                           
                                                            etapaArray                                                            
                                                            .map((elem)=>(
                                                                <MenuItem key={elem.idEtapa} value={ elem.idEtapa }>{ elem.descripcion }</MenuItem>
                                                            ))
                                                        }                          
                                                        
                                                    </Select>

                                                </FormControl>
                                                {   
                                                    errors.etapa && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.etapa } </Alert> )
                                                }
                                            </Grid>  

                                            <Grid item md={6} xs={12} >                            
                                                <FormControl fullWidth>
                                                    <InputLabel id="select-subEtapa-label">Sub etapa</InputLabel>
                                                
                                                    <Select
                                                        labelId="select-subEtapa-label"
                                                        id="select-subEtapa"                                            
                                                        label="Sub etapa"
                                                        name="subEtapa"
                                                        value={ subEtapa }
                                                        onChange={ ( e ) => { setSubEtapa( e.target.value ); } }
                                                        //disabled={ etapa === 0 || subEtapaArray.length === 0 }
                                                        disabled
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem> 
                                                        {                                                           
                                                            subEtapaArray     
                                                            .filter( (element ) => { 
                                                                return element.idEtapa === etapa; 
                                                            })                                             
                                                            .map((elem)=>(
                                                                <MenuItem key={ elem.idSubEtapa } value={ elem.idSubEtapa }>{ elem.descripcion }</MenuItem>
                                                            ))                                                            
                                                        }

                                                    </Select>

                                                </FormControl>
                                                {   
                                                    errors.subEtapa && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.subEtapa } </Alert> )
                                                }
                                            </Grid>   
                                        
                                        </>

                                    }   

                                    {   
                                        ( ( acumulacion && subTipoAcuerdo === 21 ) || ( etapa === 7 && subEtapa === 6 ) )
                                        &&
                                            <Grid item md={12} xs={12} > 

                                                <FormControl fullWidth>
                                                    <InputLabel id="select-expediente-principal-label">Seleccione el expediente principal</InputLabel>

                                                    <Select
                                                        labelId="select-expediente-principal-label"
                                                        id="select-expediente-principal"                                            
                                                        label="Seleccione el expediente principal"   
                                                        name="seleccione-expediente-principal"
                                                        value={ expedientePrincipal }
                                                        onChange={ (e) => { setExpedientePrincipal( e.target.value ); } }    
                                                        disabled                      
                                                    >
                                                        <MenuItem value={0}>Selecciona una opción</MenuItem>                                     
                                                        {                                       
                                                            expedientesAcumulados                                          
                                                            .map((elem)=>(
                                                                <MenuItem key={ elem.idExpediente } value={ elem.idExpediente }> 
                                                                    <strong>Exp. { elem.numeroExpediente }</strong>&nbsp;- Secretario(a) de Acuerdo y Trámite:&nbsp;<strong>{ elem.secretario }</strong>&nbsp; - Fecha:&nbsp;<strong>({ elem?.fecha ?? '' })</strong> 
                                                                </MenuItem>
                                                            ))                                       
                                                        } 
                                                    </Select>

                                                </FormControl>

                                                {   
                                                    errors.expedientesAcumulados && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.expedientesAcumulados } </Alert> )
                                                }

                                            </Grid> 
                                    }

                                    {
                                        (
                                            ( tipoAcuerdo === 7 && subTipoAcuerdo !== 31 ) ||
                                            ( tipoAcuerdo === 4 && subTipoAcuerdo === 3 ) ||
                                            ( tipoAcuerdo === 9 && subTipoAcuerdo === 28 ) ||
                                            ( tipoAcuerdo === 11 && subTipoAcuerdo === 7 )  ||
                                            ( tipoAcuerdo === 11 && subTipoAcuerdo === 40 ) ||
                                            ( tipoAcuerdo === 28 && etapa === 7 ) ||
                                            ( tipoAcuerdo === 28 && subEtapa === 2 ) ||
                                            ( tipoAcuerdo === 28 && subEtapa === 13 ) ||
                                            ( tipoAcuerdo === 28 && subEtapa === 1020 ) 
                                        )
                                        ?
                                            <Grid item md={12} xs={12} >

                                                <FormControl fullWidth disabled>
                                                                                    
                                                    <Grid container spacing={3}>
                                                        
                                                        <Grid item md={6} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                            <FormLabel sx={{ color: 'black' }} >¿Desea enviar el expediente al archivo provisional?</FormLabel>
                                                        </Grid>
                                                        
                                                        <Grid item md={6} xs={12} >
                                                            <RadioGroup row >
                                                                <Grid container >
                                                                    <Grid item md={6} xs={6} >
                                                                        <FormControlLabel value="Si" control={ <Radio checked={ archivoProvisional === true } onChange={ () => { handleChangeArchivoProvisional(true) } } /> } label="Si" />
                                                                    </Grid>
                                                                    <Grid item md={6} xs={6} >
                                                                        <FormControlLabel value="No" control={ <Radio checked={ archivoProvisional === false } onChange={ () => { handleChangeArchivoProvisional(false) } } /> } label="No" />     
                                                                    </Grid>                                                            
                                                                </Grid>
                                                            </RadioGroup>
                                                        </Grid>

                                                    </Grid>

                                                </FormControl>

                                            </Grid>
                                        :
                                            null
                                    } 

                                    {
                                        ( tribunal && ( tipoAcuerdo === 22 || ( tabValidation.idPromocion === 0 && tabValidation.idAmparo !== 0 )) )
                                        &&
                                            <Grid item md={6} xs={12} >  
                                                        
                                                <TextField   
                                                    label={
                                                        tipoAcuerdo === 22
                                                        ?
                                                            "Escribe el número de cierre de instrucción (1/" + (new Date().getFullYear()) + ")"
                                                        :
                                                        ( tabValidation.idPromocion === 0 && tabValidation.idAmparo !== 0 )
                                                        ?
                                                            "Escribe el número de amparo (1/" + (new Date().getFullYear()) + ")"
                                                        :
                                                            ""
                                                    } 
                                                    name="numeroReferencia"
                                                    value={ numeroReferencia } 
                                                    onChange={ ( e ) => { setNumeroReferencia( e.target.value ) }}
                                                    autoComplete="off"
                                                    fullWidth
                                                    disabled
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />        
                                                
                                            </Grid>    
                                    }    

                                    {
                                        ( tabValidation.idExpediente !== 0 && tabValidation.idPromocion !== 0 && tabValidation.idExhortoLibrado === 0 )
                                        &&
                                            <Grid item md={12} xs={12} >

                                                <FormControl fullWidth disabled>
                                                                                    
                                                    <Grid container spacing={3}>
                                                        
                                                        <Grid item md={6} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                                                            <FormLabel sx={{ color: 'black' }} >¿Es una promoción irregular?</FormLabel>
                                                        </Grid>
                                                        
                                                        <Grid item md={6} xs={12} >
                                                            <RadioGroup row >
                                                                <Grid container >
                                                                    <Grid item md={6} xs={6} >
                                                                        <FormControlLabel value="Si" control={ <Radio checked={ promocionIrregular === true } onChange={ () => { setPromocionIrregular( true ); } } /> } label="Si" />
                                                                    </Grid>
                                                                    <Grid item md={6} xs={6} >
                                                                        <FormControlLabel value="No" control={ <Radio checked={ promocionIrregular === false } onChange={ () => { setPromocionIrregular( false ); } } /> } label="No" />     
                                                                    </Grid>                                                            
                                                                </Grid>
                                                            </RadioGroup>
                                                        </Grid>

                                                    </Grid>

                                                </FormControl>

                                            </Grid>
                                    }               

                                    <Grid item md={12} xs={12} >  

                                        <Editor
                                            onInit = { (evt, editor) => editorRef.current = editor }
                                            initialValue = { contenido }
                                            apiKey = { ObtenerApiKeyRichText( process.env ) }
                                            init = {
                                                {
                                                    height: 450,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount pagebreak'
                                                    ],
                                                    toolbar: 'undo redo | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                        'removeformat | print pagebreak |  Atajos',
                                                        setup: (editor) => {                                        
                                                            editor.ui.registry.addMenuButton('Atajos', {
                                                                icon: 'bookmark' ,
                                                                tooltip: "Atajos",
                                                                fetch: function (callback) {
                                                                    var items = [];

                                                                    if( expediente?.success && expediente?.ponencia && !amparo?.success ){                                            
                                                                        items = [   
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                                                            
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + actores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + demandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.tipoJuicio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ expediente?.ponencia + '</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else if( expediente?.success && !amparo?.success ){                                            
                                                                        items = [   
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                                                            
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + actores + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + demandados + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.tipoJuicio + '</strong>'); } },                                                  
                                                                        ];
                                                                    }

                                                                    if( exhortoLibrado?.success && expediente?.ponencia ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                              
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExhortoLibrado + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ actores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ demandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+expediente.tipoJuicio+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado?.autoridadExhorto+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ expediente.ponencia +'</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else if( exhortoLibrado?.success ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                              
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoLibrado.numeroExhortoLibrado + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ actores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ demandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+expediente.tipoJuicio+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoLibrado?.autoridadExhorto+'</strong>'); } },
                                                                        ];
                                                                    }

                                                                    if( exhortoRecibido?.success && exhortoRecibido?.ponencia ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                              
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoRecibido.numeroExhorto + '</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Número de origen',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoRecibido.numeroExhortoOrigen + '</strong>'); } },                                                                                                                                                      
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ actores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ demandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoRecibido.tipoJuicio+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoRecibido?.autoridadExhorto+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ exhortoRecibido.ponencia +'</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else if( exhortoRecibido?.success ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                              
                                                                            { type: 'menuitem', text: 'Número de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoRecibido.numeroExhorto + '</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Número de origen',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + exhortoRecibido.numeroExhortoOrigen + '</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ actores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ demandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoRecibido.tipoJuicio+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad de exhorto',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+exhortoRecibido?.autoridadExhorto+'</strong>'); } },
                                                                        ];
                                                                    }

                                                                    if( irregular?.success && irregular?.ponencia ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                              
                                                                            { type: 'menuitem', text: 'Número de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + irregular.numeroIrregular + '</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ actores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ demandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+irregular.tipoIrregular+'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ irregular.ponencia +'</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else if( irregular?.success ){
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                              
                                                                            { type: 'menuitem', text: 'Número de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + irregular.numeroIrregular + '</strong>'); } },                                                                            
                                                                            { type: 'menuitem', text: 'Actor',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ actores +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Demandado',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ demandados +'</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de irregular',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+irregular.tipoIrregular+'</strong>'); } },
                                                                        ];
                                                                    }

                                                                    if( expediente?.success && expediente?.ponencia && amparo?.success && !expedientilloSuspension.success){                                            
                                                                        items = [   
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                                           
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.autoridadAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.tipoAmparo + '</strong>'); } },       
                                                                            { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + cadenaPartesAmparo + '</strong>'); } },    
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.tipoJuicio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ expediente?.ponencia + '</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else if( expediente?.success && amparo?.success && !expedientilloSuspension.success){                                            
                                                                        items = [ 
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                 
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.numeroExpediente + '</strong>'); } }, 
                                                                            { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.autoridadAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.tipoAmparo + '</strong>'); } },       
                                                                            { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + cadenaPartesAmparo + '</strong>'); } },               
                                                                        ];
                                                                    }
                                                                    else if( !expediente?.success && amparo?.success && !expedientilloSuspension.success){                                            
                                                                        items = [  
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                                   
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.autoridadAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.tipoAmparo + '</strong>'); } },       
                                                                            { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + cadenaPartesAmparo + '</strong>'); } },    
                                                                        ];
                                                                    }      
                                                                    
                                                                    if( expediente?.success && expediente?.ponencia && amparo?.success && expedientilloSuspension.success){                                            
                                                                        items = [   
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                                           
                                                                            { type: 'menuitem', text: 'Número de expedientillo de suspensión',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expedientilloSuspension?.numeroExpedientillo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.numeroExpediente + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.autoridadAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.tipoAmparo + '</strong>'); } },       
                                                                            { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + cadenaPartesAmparo + '</strong>'); } },    
                                                                            { type: 'menuitem', text: 'Tipo de juicio',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.tipoJuicio + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Ponencia',  onAction: function (_) { editor.insertContent('&nbsp;<strong>'+ expediente?.ponencia + '</strong>'); } },
                                                                        ];
                                                                    }
                                                                    else if( expediente?.success && amparo?.success && expedientilloSuspension.success){                                            
                                                                        items = [  
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                
                                                                            { type: 'menuitem', text: 'Número de expedientillo de suspensión',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expedientilloSuspension?.numeroExpedientillo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de expediente',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expediente?.numeroExpediente + '</strong>'); } }, 
                                                                            { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.autoridadAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.tipoAmparo + '</strong>'); } },       
                                                                            { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + cadenaPartesAmparo + '</strong>'); } },               
                                                                        ];
                                                                    }
                                                                    else if( !expediente?.success && amparo?.success && expedientilloSuspension.success){                                            
                                                                        items = [    
                                                                            { type: 'menuitem', text: 'Fecha',  onAction: function (_) { editor.insertContent('&nbsp;<strong>[Fecha_Actual]</strong>'); } },                                                                                                                 
                                                                            { type: 'menuitem', text: 'Número de expedientillo de suspensión',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + expedientilloSuspension?.numeroExpedientillo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Número de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.numeroAmparo + '</strong>'); } },                 
                                                                            { type: 'menuitem', text: 'Autoridad de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.autoridadAmparo + '</strong>'); } },
                                                                            { type: 'menuitem', text: 'Tipo de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + amparo?.tipoAmparo + '</strong>'); } },       
                                                                            { type: 'menuitem', text: 'Partes de amparo',  onAction: function (_) { editor.insertContent('&nbsp;<strong>' + cadenaPartesAmparo + '</strong>'); } },    
                                                                        ];
                                                                    }      

                                                                    callback(items);
                                                                }
                                                            });
                                                        },  
                                                        content_style: 'body { font-family: Arial; font-size:12pt; margin: 1rem auto; max-width: 900px; }',                                                         
                                                }
                                            }  
                                        />

                                    </Grid>           

                                    {   
                                        errors.contenido && ( 
                                            <Grid item md={12} xs={12}>
                                                <Alert color='warning' severity="warning" variant="filled"> { errors.contenido } </Alert> 
                                            </Grid>
                                        )
                                    }

                                    {/* {                                     
                                        ( ( tipoAcuerdo === 1 || tipoAcuerdo === 28 ) && juzgado )
                                        &&
                                        <Grid item md={12} xs={12} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-juez-label">Juez</InputLabel>
                                            
                                                <Select
                                                    labelId="select-juez-label"
                                                    id="select-juez"                                            
                                                    label="Juez"
                                                    name="juez"
                                                    value={ juez }                                
                                                    onChange={ ( e ) => setJuez( e.target.value ) }                                        
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        juezArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                        ))
                                                    }                                          
                                                </Select>

                                            </FormControl>

                                        </Grid>
                                    } */}

                                    {
                                        observacionesMagistrados.length !== 0
                                        &&                                
                                        <>
                                            <Grid item md={3} xs={6}>
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                    Observaciones:                                        
                                                </Typography>
                                            </Grid>

                                            <Grid item md={8} xs={6} >

                                                <Grid container spacing={3} >                                    
                                                    {                                                
                                                        observacionesMagistrados?.map( ( element ) => (
                                                            <Grid key={ element.id } item md={12} xs={12} >
                                                                <Typography 
                                                                    variant="subtitle2" 
                                                                    style={{ fontWeight: 'normal', color:'gray', textAlign: 'justify' }}                                                                
                                                                >
                                                                    <strong> { element.usuario}: </strong>
                                                                    <br />
                                                                        { element.observacion }
                                                                    <br />
                                                                </Typography>
                                                            </Grid>
                                                        ))                                                
                                                    }                                                
                                                </Grid>
                                                
                                            </Grid>
                                        </>    
                                    }                                  

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                        
                                        <LoadingButton
                                            variant="contained" 
                                            onClick={ GuardarAcuerdo } 
                                            startIcon={ <SaveIcon /> }
                                            loading={ loadingGuardar }
                                        > 
                                            Guardar acuerdo
                                        </LoadingButton>

                                    </Grid>           
                                    
                                    <Grid item md={4} xs={12} >
                                        <FormControl component="fieldset" fullWidth>
                                        <FormLabel component="legend">¿Desea autorizar el acuerdo?</FormLabel>
                                            <RadioGroup row
                                                aria-label="autorizarAcuerdo"
                                                defaultValue="si"
                                                name="radio-buttons-group"  
                                            >
                                                <FormControlLabel value="si" control={ <Radio checked={ autorizar === true } onChange={ () => { setErrors( { } ); setAutorizar(true); setObservacion( '' ); } } />}  label="Si" />
                                                <FormControlLabel value="no" control={ <Radio checked={ autorizar === false } onChange={ () => { setErrors( { } ); setAutorizar(false); setObservacion( '' ); } } />} label="No" />                                                
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {
                                        !autorizar && (
                                            <>
                                                <Grid item md={8} xs={12} >
                                                    <TextField                              
                                                        label="Motivo"                                                     
                                                        multiline
                                                        rows={4}  
                                                        fullWidth                          
                                                        name="observacion"   
                                                        variant="outlined"
                                                        value={ observacion }
                                                        onChange={ ( e ) => { setObservacion( e.target.value ) }}
                                                    />
                                                    {   
                                                        errors.observaciones && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.observaciones } </Alert> )
                                                    }
                                                </Grid>    
                                            </>
                                        )
                                    } 

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 

                                        <Grid container spacing={3}>
                                            
                                            <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 

                                                <LoadingButton 
                                                    variant="contained" 
                                                    onClick={ Regresar } 
                                                    startIcon={ <ArrowBackIcon />  } 
                                                    disabled={ autorizar }
                                                    loading={ loadingRegresarEscrito }
                                                > 
                                                    Regresar escrito 
                                                </LoadingButton>

                                            </Grid>
                                            
                                            <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                                
                                                <Button 
                                                    variant="contained" 
                                                    onClick={ () => { setOpenConfirmacion( true ); } } 
                                                    endIcon={ <CheckCircleIcon />  } 
                                                    disabled={ 
                                                        !autorizar || !ValidateGuardar || ( tipoAcuerdo === 15 && cuentaAsociada === '' ) ||

                                                        ( 
                                                            tipoAcuerdo === 28 && juzgado &&
                                                            (
                                                                parametricas.idEtapa !== etapa || parametricas.idSubEtapa !== subEtapa 
                                                            )  
                                                        )   
                                                        
                                                        //|| ( ( tipoAcuerdo === 1 || tipoAcuerdo === 28 ) && juez === 0 )
                                                    }
                                                > 
                                                    Finalizar autorización 
                                                </Button>

                                            </Grid>

                                        </Grid>

                                    </Grid>    

                                </Grid>

                            </TabPanel>

                            <TabPanel value={ valueTab } index={1}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <AcuerdoNotificacionScreen Id={ Id } edit={ false } />

                            </TabPanel>

                            <TabPanel value={ valueTab } index={2}>                        
                                  
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />
                              
                                <CalendarScreen edit={ false } />

                            </TabPanel>
                           
                            <TabPanel value={ valueTab } index={3}>                        
                                                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <CalificarTramiteScreen edit={ false } />

                            </TabPanel>                                                               

                            <TabPanel value={ valueTab } index={4}>    

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetallePromocionScreen />

                            </TabPanel>       

                            <TabPanel value={ valueTab } index={5}>  

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleAmparoScreen edit={ false } />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={6}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleExhortoLibradoScreen />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={7}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleExhortoRecibidoScreen edit={ false } />

                            </TabPanel>                         

                            <TabPanel value={ valueTab } index={8}>                        
                                
                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleIrregularScreen edit={ false } />

                            </TabPanel>  

                            <TabPanel value={ valueTab } index={9}>  

                                <InformacionSeguimiento 
                                    noSeguimiento={ 'GAC-' + noSeguimiento }
                                    actividad={ 'Elaborar acuerdo' }
                                    tipo={ tipoReferencia }
                                    referencia={ referencia }
                                />

                                <DetalleDemandaScreen edit={ false } />

                            </TabPanel>    

                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la autorización del acuerdo?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
