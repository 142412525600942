import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Card, CardContent, CardHeader, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import { Calendar, momentLocalizer  } from 'react-big-calendar';

import { EventComponentAgenda, EventComponentDay, EventComponentMonth, EventComponentWeek } from '../../helpers/ComponentsAgenda';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import '../../css/Calendar.css';

import moment from 'moment';

import 'moment/locale/es-mx';

import { ModalAgendarAudiencia } from '../modals/ModalAgendarAudiencia';

import { types } from '../../types/types';

import { Agenda, BuscarAgendaJuezScretario } from '../../actions/audiencias/agendaActions';
import { BuscarUsuarioJuez, BuscarUsuarioSecretario } from '../../actions/admin/users/usuarioAction';
import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export const CalendarAudienciasSecretarioScreen = ( { edit, enAudiencia } ) => {
    
    const dispatch = useDispatch();
 
    const localizer = momentLocalizer(moment);
    
    const [openModal, setOpenModal] = useState(false);
    
    const [bigCalendarView, setBigCalendarView] = useState(false);
    
    const { audiencias } = useSelector( state => state.audiencias );

    const [juez, setJuez] = useState( 0 );
    const [juezArray, setJuezArray] = useState( [ ] );
    
    const [secretario, setSecretario] = useState( 0 );
    const [secretarioArray, setSecretarioArray] = useState( [ ] );

    const [loadingSearch, setLoadingSearch] = useState( false );

    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 

        setJuez( 0 );
        setSecretario( 0 );
    }
    
    const handleOpenModal = () => {      

        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };       

    const handleSelectEvent = ( { id, idTipoAudiencia, title, expediente, estatus, start, end, motivoCancelacion, idJuez, idSecretario} ) => {
      
        dispatch( {
            type: types.audienciaSelected,
            payload: { 
                id: id,
                title: title,
                idTipoAudiencia: idTipoAudiencia,
                start: start, 
                end: end,          
                expediente: expediente,                
                motivoCancelacion,
                estatus: estatus,
                action: 'Modificar',
                idJuez: idJuez,    
                idSecretario: idSecretario,         
            } 
        });

        handleOpenModal();
    }

    const handleSelectSlot = ( { start } ) => {        

        const days = moment( start ).day();

        if( days === 0 || days === 6 ){
            return false;
        }

        const toDate = new Date();
        const DateDefaultInicio = start.getFullYear() + '-' + ( ( '00' +  ( start.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + start.getDate() ).slice(-2) + ' ' + ( '00' + toDate.getHours() ).slice(-2) +  ':00';  
        const DateDefaultFin = start.getFullYear() + '-' + ( ( '00' +  ( start.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + start.getDate() ).slice(-2) + ' ' + ( '00' + ( toDate.getHours() + 1) ).slice(-2) +  ':00';  
        
        let Inicio = "";
        let Fin = "";

        if( start.getHours() === 0 && start.getMinutes() === 0 ){            
            Inicio = moment(DateDefaultInicio);  
            Fin = moment(DateDefaultFin); 
        }
        else{
            Inicio = start;  
            Fin = start; 
        }         

        dispatch( {
            type: types.audienciaSelected,
            payload: {                
                start: Inicio, 
                end: Fin,         
                action: 'Agregar'     ,
                motivoCancelacion: ''              
            } 
        });
        
        handleOpenModal();
    }

    const AgregarAudiencia = () => {

        const toDate = new Date();
        const Inicio = toDate.getFullYear() + '-' + ( ( '00' +  ( toDate.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + toDate.getDate() ).slice(-2) + ' ' + ( '00' +toDate.getHours() ).slice(-2) +  ':00';  
        const Fin = toDate.getFullYear() + '-' + ( ( '00' + ( toDate.getMonth() + 1 ) ).slice(-2) ) + '-' + ( '00' + toDate.getDate() ).slice(-2) + ' ' + ( '00' + ( toDate.getHours() + 1) ).slice(-2) +  ':00'; 
          
        dispatch( {
            type: types.audienciaSelected,
            payload: {                
                start: Inicio, 
                end: Fin,         
                action: 'Agregar',
                motivoCancelacion: ''           
            } 
        });

        handleOpenModal();
    }

    const handleBuscarTramites = async () => {
        
        setLoadingSearch( true );
        setFiltroAplicado( true );                

    }

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]); 
    
    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioSecretario( ).then( response => {

                if( response ){

                    if( response.data ){
                        setSecretarioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);   
    
    useEffect(() => {

        async function Obtener(){

            dispatch({ type: types.openLoading });

            await Agenda().then( response => {
        
                if( response ){
    
                    if( response.status === 200){
                     
                        const rows = response.data.map(element => {

                            element.start = new Date( element.start );
                            element.end = new Date( element.end );
                            
                            return element;
                        });

                        setTimeout(() => {
                            
                            dispatch({
                                type: types.audienciasLoad,
                                payload: rows
                            });  
    
                            dispatch({ type: types.closeLoading });

                        }, 600);    
    
                    }
    
                }
            
            }); 
        }

        if( !filtroAplicado ){
            Obtener();        
        }

    }, [ dispatch, filtroAplicado ]);  

    useEffect(() => {

        async function ObtenerAgendaJuez(){

            dispatch({ type: types.openLoading });

            await BuscarAgendaJuezScretario({ idJuez: juez, idSecretario: secretario }).then( response => {
        
                if( response ){
    
                    if( response.status === 200){
                     
                        const rows = response.data.map(element => {

                            element.start = new Date( element.start );
                            element.end = new Date( element.end );

                            setLoadingSearch( false );
                            
                            return element;
                        });
    
                        setTimeout(() => {
                            
                            dispatch({
                                type: types.audienciasLoad,
                                payload: rows
                            });  
    
                            dispatch({ type: types.closeLoading });

                        }, 600);    
    
                    }
    
                }
            
            }); 
        }
       
        if( filtroAplicado ){
            ObtenerAgendaJuez();
        }

    }, [ dispatch, filtroAplicado, juez, secretario ]);  

    return (
        <>

            <Box sx={{ minHeight: '100%' }}>  
            
                <Container maxWidth="lg">   

                    <Grid container spacing={3} > 

                        <Grid item lg={12} md={12} xs={12}>

                            <Card>

                                <CardHeader sx={{ backgroundColor: (theme) => theme.palette.primary.main }} subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Filtro(s) </Typography> }/>
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3}>   

                                        <Grid item md={5} xs={12} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-juez-label">Juez</InputLabel>

                                                <Select
                                                    labelId="select-juez-label"
                                                    id="select-juez"                                            
                                                    label="Juez"
                                                    name="juez"
                                                    value={ juez }                                
                                                    onChange={ ( e ) => setJuez( e.target.value ) }
                                                    disabled={ filtroAplicado }
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        juezArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                        ))
                                                    }                                          
                                                </Select>

                                            </FormControl>

                                        </Grid>
                                        
                                        <Grid item md={5} xs={12} >

                                            <FormControl fullWidth>
                                                <InputLabel id="select-secretario-label">Secretario(a) de Acuerdo y Trámite</InputLabel>

                                                <Select
                                                    labelId="select-secretario-label"
                                                    id="select-secretario"                                            
                                                    label="Secretario(a) de Acuerdo y Trámite"
                                                    name="secretario"
                                                    value={ secretario }                                
                                                    onChange={ ( e ) => setSecretario( e.target.value ) }                                           
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                                    {
                                                        secretarioArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                                        ))
                                                    }                                          
                                                </Select>

                                            </FormControl>

                                        </Grid>

                                        <Grid item md={2} xs={12} >

                                            <LoadingButton 
                                                type="button" 
                                                fullWidth 
                                                variant="contained"
                                                startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                                                onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarTramites() } }
                                                loading={ loadingSearch }
                                                disabled={ juez === 0 && secretario === 0 }
                                            > 
                                                { filtroAplicado ? 'limpiar' : 'Buscar'}
                                            </LoadingButton> 

                                        </Grid>  

                                    </Grid>

                                </CardContent>

                            </Card>

                        </Grid>

                        {
                            edit
                            &&
                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>  
                                    <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarAudiencia }> 
                                        Agregar audiencia
                                    </Button> 
                                </Grid>
                       }

                        <Grid item md={12} xs={12} mb={3}>  

                            <Paper sx={{ padding: 2 }}>

                                <Calendar
                                    defaultDate={ new Date() }
                                    events={ audiencias } 
                                    localizer={ localizer }
                                    formats = {
                                        {
                                        timeGutterFormat: 'hh:mm a'
                                        }
                                    }
                                    min={ new Date(0, 0, 0, 8, 0, 0) }
                                    max={ new Date(0, 0, 0, 20, 0, 0) }
                                    culture="es"
                                    defaultView="month"
                                    startAccessor="start"
                                    endAccessor="end"
                                    messages={
                                        {
                                            next: "Siguiente",
                                            previous: "Anterior",
                                            today: "Hoy",
                                            month: "Mes",
                                            week: "Semana",
                                            day: "Día",
                                            noEventsInRange: <strong>No se encontraron audiencias. </strong>,
                                            showMore: ( target ) => '+' + target + ' más',
                                            date: 'Fecha', 
                                            time: 'Hora', 
                                            event: 'Evento'
                                        }
                                    }
                                    step={ 15 }
                                    timeslots={ 1 }
                                    onSelectEvent={ event => { handleSelectEvent(event); } }
                                    onSelectSlot={ handleSelectSlot }
                                    onView={ ( e ) => { setBigCalendarView( e ); } }
                                    selectable                            
                                    style={ { height: '75vh' } }        
                                    disa
                                    components={{
                                        month: {
                                            event: EventComponentMonth
                                        },
                                        week: {
                                            event: EventComponentWeek
                                        },
                                        day: {
                                            event: EventComponentDay
                                        },
                                        agenda: {
                                            event: EventComponentAgenda
                                        },                                
                                    }}     
                                    eventPropGetter={
                                        function( event ) {
                                            
                                            var style = { };

                                            if( bigCalendarView !== 'agenda' ){

                                                if( event?.estatus === 'Audiencia cancelada' ){
                                                    style = { backgroundColor: '#da4141', color: 'white' };
                                                } 
                                                else if( event?.estatus === 'Audiencia celebrada' ){
                                                    style = { backgroundColor: '#51933d', color: 'white' };
                                                } 
                                                else if( event?.estatus === 'Audiencia en proceso' ){
                                                    style = { backgroundColor: '#31ad98', color: 'white' };
                                                }     

                                            }       
                                         
                                            return { style: style };
                                        }
                                    }     
                                    dayPropGetter={
                                        function( date ){

                                            var style = { };

                                            if( ( moment( date ).day() === 0 || moment( date ).day() === 6 ) ){
                                                style = { backgroundColor: 'rgb(230, 230, 230)' };
                                            }      

                                            return { style: style };
                                        }
                                    }
                                />
                            </Paper>

                        </Grid>

                    </Grid>
                                        
                </Container>

            </Box>

            <ModalAgendarAudiencia openModal={ openModal } handleCloseModal={ handleCloseModal } edit={ edit } enAudiencia={ enAudiencia } />

        </>
    )
}
