import React , { useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputBase, LinearProgress, List, ListItem, Paper, Radio, RadioGroup, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';

/*
import { BuscarExpediente, BuscarExpedientePonencia } from '../../actions/recepcion/expedienteActions';
import { BuscarIrregular, BuscarIrregularPonencia } from '../../actions/recepcion/irregularActions';
import { BuscarAmparo } from '../../actions/recepcion/amparoActions';
import { BuscarExhortoRecibidoEnTramite, BuscarExhortoRecibidoPonencia } from '../../actions/recepcion/exhortoRecibidoActions';
import { BuscarExhortoLibrado, BuscarExhortoLibradoPonencia } from '../../actions/tribunal/exhortoLibradoActions';
*/

import { BuscarExpediente } from '../../actions/recepcion/expedienteActions';
import { BuscarIrregular, BuscarIrregularConvenioFueraJuicio } from '../../actions/recepcion/irregularActions';
import { BuscarAmparo } from '../../actions/recepcion/amparoActions';
import { BuscarExhortoRecibidoEnTramite } from '../../actions/recepcion/exhortoRecibidoActions';
import { BuscarExhortoLibrado } from '../../actions/tribunal/exhortoLibradoActions';

import { GuardarAcuerdo } from '../../actions/tribunal/acuerdoActions';

export const ModalNuevoAcuerdo = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const { tribunal } = useSelector(state => state.auth);

    const [loading, setLoading] = useState( false );

    const [habiliarGuardar, setHabiliarGuardar] = useState( true );

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [errors, setErrors] = useState( { } );

    const [tipoBusqueda, setTipoBusqueda] = useState( '' );

    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [numeroIrregular, setNumeroIrregular] = useState('');
    const [numeroAmparo, setNumeroAmparo] = useState('');
    const [numeroExhortoRecibido, setNumeroExhortoRecibido] = useState('');
    const [numeroExhortoLibrado, setNumeroExhortoLibrado] = useState('');

    const [expediente, setExpediente] = useState( {} );
    const [irregular, setIrregular] = useState( {} );
    const [amparo, setAmparo] = useState( {} );
    const [exhortoRecibido, setExhortoRecibido] = useState( {} );    
    const [exhortoLibrado, setExhortoLibrado] = useState( { } );    

    const GenerarAcuerdo = async () => {      
        const params = {
            idExpediente : expediente.idExpediente,
            idIrregular : irregular.idIrregular,
            idAmparo : amparo.idAmparo,
            idExhortoRecibido: exhortoRecibido.idExhortoRecibido,
            idExhortoLibrado: exhortoLibrado.idExhortoLibrado
        };

        setLoadingGuardar(true);
        
        await GuardarAcuerdo( params ).then( response => {

            if( response ){

                if( response.status === 200){
                    if(response.data){
                       
                        const { idAcuerdo } = response.data;

                        setTimeout(() => {
                            setLoadingGuardar(false); 
                            history.push("/Tribunal/GestionarAcuerdo/ElaborarAcuerdo/" + idAcuerdo);
                        }, 1200);  
                    }
                } 
                else {
                    setTimeout(() => {                      
                        
                        const { message } = response.data;

                        setLoadingGuardar(false);

                        let errores = {};  
                        errores.error = 'Ocurrió un error, contacte con el administrador ' + ( message ? `(${ message })` : '' );
                        errores.type = 'error';

                        setErrors( errores );

                    }, 1200);  
                }
            }
        });  
    }

    const limpiarDatos = () => {
        setErrors( { } );
        setNumeroExpediente('');
        setNumeroIrregular('');
        setNumeroAmparo('');
        setExpediente( {} );
        setIrregular( {} );
        setAmparo( {} );
        setExhortoRecibido( {} );
        setExhortoLibrado( {} );
    }

    const cerrarModal = () => {
        
        handleCloseModal();

        setErrors( { } );
        setTipoBusqueda('');
        setNumeroExpediente('');
        setNumeroIrregular('');
        setNumeroAmparo('');
        setExpediente( {} );
        setIrregular( {} );
        setAmparo( {} );
        setExhortoRecibido( {} );
        setExhortoLibrado( {} );

    }

    const validateFielsExpediente = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExpediente === '' ){
            valid = false;
            errores.expediente = 'Debes teclear un número de expediente';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsIrregular = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroIrregular === '' ){
            valid = false;
            errores.irregular = 'Debes teclear un número de irregular';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsAmparo = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroAmparo === '' ){
            valid = false;
            errores.amparo = 'Debes teclear un número de amparo';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsExhortoRecibido = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExhortoRecibido === '' ){
            valid = false;
            errores.numeroExhortoRecibido = 'Debes teclear un número de exhorto recibido';           
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const validateFielsExhortoLibrado = () => {
        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( numeroExhortoLibrado === '' ){
            valid = false;
            errores.numeroExhortoLibrado = 'Debes teclear un número de exhorto librado';
            errores.type = 'warning';
            setLoading( false );
        } 

        setErrors( errores );
        return valid;
    }

    const errorExpediente = () => {
        setHabiliarGuardar(true);
        setErrors( { } );
        
        let errores = {};  
        errores.expediente = 'No se encontró el expediente';
        errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente('');
    }

    const errorIrregular = () => {
        setHabiliarGuardar(true);
        setErrors( { } );

        let errores = {};  
        errores.irregular = 'No se encontró el irregular';
        errores.type = 'warning';
        
        setIrregular( {} );
        setErrors( errores );

        setNumeroIrregular('');
    }

    const errorAmparo = () => {
        setHabiliarGuardar(true);
        setErrors( { } );

        let errores = {};  
        errores.amparo = 'No se encontró el amparo';
        errores.type = 'warning';
        
        setAmparo( {} );
        setErrors( errores );

        setNumeroAmparo('');
    }

    const errorExhortoRecibido = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExhortoRecibido = 'No se encontró el exhorto en trámite';
        errores.type = 'warning';
       
        setExhortoRecibido( { } );
        setErrors( errores );
        setNumeroExhortoRecibido('');
    }

    const errorExhortoLibrado = () => {
        setErrors( { } );
        let errores = {};  
       
        errores.numeroExhortoLibrado = 'No se encontró el exhorto librado';
        errores.type = 'warning';
       
        setExhortoLibrado( { } );
        setErrors( errores );
        setNumeroExhortoLibrado('');
    }

    const handleChangeTipoBusqueda = (tipoBusqueda) => {
        setHabiliarGuardar(true);
        setTipoBusqueda(tipoBusqueda);
        limpiarDatos();
    } 

    const handleClicBuscarExpediente = async () => {
    
        setLoading( true );

        const valid = validateFielsExpediente();

        if( !valid ){
           
            return false;
        }

        const params = {
            numeroExpediente: numeroExpediente
        }

        /*if(tribunal){
            await BuscarExpedientePonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                             
                                if(response.data.success){
                                    setExpediente(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarExpediente( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                  
                    if( response.status === 200){
                  
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                             
                                if(response.data.success){
                                    setExpediente(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorExpediente();
                                }
                            }
                        }, 1500);  
                        setNumeroExpediente('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroExpediente('');
                    }
                }
            });
        //}
    }

    const handleClicBuscarIrregular = async () => {

        setLoading( true );

        const valid = validateFielsIrregular();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroIrregular: numeroIrregular
        }

        /*if(tribunal){
            await BuscarIrregularPonencia( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setIrregular(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorIrregular();
                                }
                            }
                        }, 1500);  
                        setNumeroIrregular('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroIrregular('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarIrregular( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setIrregular(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorIrregular();
                                }
                            }
                        }, 1500);  
                        setNumeroIrregular('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroIrregular('');
                    }
                }
            });
        //}
    }

    const handleClicBuscarAmparo = async () => {

        setLoading( true );

        const valid = validateFielsAmparo();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroAmparo: numeroAmparo
        }

        /*if(tribunal){
            await BuscarAmparo( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setAmparo(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorAmparo();
                                }
                            }
                        }, 1500);  
                        setNumeroAmparo('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroAmparo('');
                    }
                }
            });
        }else if (juzgado){*/
            await BuscarAmparo( params ).then( response => {
    
                setLoading( true );
    
                if( response ){
                   
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setAmparo(response.data);
                                    setHabiliarGuardar(false);
                                }else{
                                    errorAmparo();
                                }
                            }
                        }, 1500);  
                        setNumeroAmparo('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );
    
                        }, 1500);  
                        setNumeroAmparo('');
                    }
                }
            });
        //}
                
    }

    const handleClicBuscarExhortoRecibido = async () => {

        setLoading( true );

        const valid = validateFielsExhortoRecibido();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExhorto: numeroExhortoRecibido
        }
        
        /*if(tribunal){

            await BuscarExhortoRecibidoPonencia( params ).then( response => {

                setLoading( true );

                if( response ){
                
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExhortoRecibido(response.data);
                                    setHabiliarGuardar(false);
                                }
                                else{
                                    errorExhortoRecibido();
                                }
                                setNumeroExhortoRecibido('');
                            }
                        }, 1500);  
                        setExhortoRecibido('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );

                        }, 1500);  
                        setExhortoRecibido('');
                    }
                }
            });
        
        }
        else if (juzgado){*/

            await BuscarExhortoRecibidoEnTramite( params ).then( response => {

                setLoading( true );

                if( response ){
                
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExhortoRecibido(response.data);
                                    setHabiliarGuardar(false);
                                }
                                else{
                                    errorExhortoRecibido();
                                }
                                setNumeroExhortoRecibido('');
                            }
                        }, 1500);  
                        setExhortoRecibido('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );

                        }, 1500);  
                        setExhortoRecibido('');
                    }
                }
            });

        //}
    }    

    const handleClicBuscarExhortoLibrado = async () => {

        setLoading( true );

        const valid = validateFielsExhortoLibrado();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroExhortoLibrado: numeroExhortoLibrado
        }

        /*if( tribunal ){
            await BuscarExhortoLibradoPonencia( params ).then( response => {

                setLoading( true );

                if( response ){
                
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExhortoLibrado(response.data);
                                    
                                    setHabiliarGuardar( false );
                                }
                                else{
                                    errorExhortoLibrado();
                                }
                                setNumeroExhortoLibrado('');
                            }
                        }, 1500);  
                        setExhortoLibrado('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );

                        }, 1500);  
                        setExhortoLibrado('');
                    }
                }
            });
        }
        else if( juzgado ){    */            
            await BuscarExhortoLibrado( params ).then( response => {

                setLoading( true );

                if( response ){
                
                    if( response.status === 200){
                        setTimeout(() => {
                            setLoading( false );
                            if(response.data){
                                if(response.data.success){
                                    setExhortoLibrado(response.data);

                                    setHabiliarGuardar( false );
                                }
                                else{
                                    errorExhortoLibrado();
                                }
                                setNumeroExhortoLibrado('');
                            }
                        }, 1500);  
                        setExhortoLibrado('');
                    } 
                    else {
                        setTimeout(() => {
                            setLoading( false );

                        }, 1500);  
                        setExhortoLibrado('');
                    }
                }
            });
        //}
    }

    const handleClicBuscarConvenioFueraJuicio = async () => {

        setLoading( true );

        const valid = validateFielsIrregular();
       
        if( !valid ){
            return false;
        }

        const params = {
            numeroIrregular: numeroIrregular
        }
      
        await BuscarIrregularConvenioFueraJuicio( params ).then( response => {

            setLoading( true );

            if( response ){
                
                if( response.status === 200){
                    setTimeout(() => {
                        setLoading( false );
                        if(response.data){
                            if(response.data.success){
                                setIrregular(response.data);
                                setHabiliarGuardar(false);
                            }else{
                                errorIrregular();
                            }
                        }
                    }, 1500);  
                    setNumeroIrregular('');
                } 
                else {
                    setTimeout(() => {
                        setLoading( false );

                    }, 1500);  
                    setNumeroIrregular('');
                }
            }
        });
      
    }

    return (
        <>

            <Dialog open={ openModal } maxWidth='md' fullWidth={true} >

                <LinearProgress sx={{ display: 'none' }}></LinearProgress>

                <DialogTitle>Nuevo Acuerdo</DialogTitle>
            
                <DialogContent>
                    
                    <Grid container spacing={4} >

                        <Grid item md={12} xs={12} >
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Generar acuerdo para:</FormLabel>
                                <RadioGroup row
                                    aria-label="tipoOficio"
                                    defaultValue="expediente"
                                    name="radio-buttons-group"  
                                >
                                    <FormControlLabel value="expediente" control={ <Radio checked={ tipoBusqueda === 'expediente' } onChange={ () => { handleChangeTipoBusqueda('expediente') } } />} label="Expediente" />
                                    <FormControlLabel value="expedienteIrregular" control={ <Radio checked={ tipoBusqueda === 'irregular' } onChange={ () => { handleChangeTipoBusqueda('irregular') } } />} label="Irregular" />
                                    <FormControlLabel value="amparo" control={ <Radio checked={ tipoBusqueda === 'amparo' } onChange={ () => { handleChangeTipoBusqueda('amparo') } } />} label="Amparo" />
                                    <FormControlLabel value="exhortoRecibido" control={ <Radio checked={ tipoBusqueda === 'exhortoRecibido' } onChange={ () => { handleChangeTipoBusqueda('exhortoRecibido') } } />} label="Exhorto recibido" />
                                   
                                    {
                                        tribunal
                                        &&
                                            <FormControlLabel value="convenioFueraJuicio" control={ <Radio checked={ tipoBusqueda === 'convenioFueraJuicio' } onChange={ () => { handleChangeTipoBusqueda('convenioFueraJuicio') } } />} label="Convenio fuera de juicio" />                                   
                                    }
                                    
                                    {/*<FormControlLabel value="exhortoLibrado" control={ <Radio checked={ tipoBusqueda === 'exhortoLibrado' } onChange={ () => { handleChangeTipoBusqueda('exhortoLibrado') } } />} label="Exhorto librado" /> */}

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {
                            tipoBusqueda === 'expediente' && (
                                <>
                                    <Grid item md={12} xs={12} > 
                                        <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                            
                                            <InputBase 
                                                sx={{ ml: 1, flex: 1 }} 
                                                inputProps={{ 'aria-label': 'Buscar expediente' }} 
                                                placeholder="Buscar por número de expediente"     
                                                name="expediente"
                                                value={ numeroExpediente } 
                                                onChange={ ( e ) => { setNumeroExpediente( e.target.value ) }}
                                                autoComplete="off"
                                            />

                                            <LoadingButton 
                                                variant='contained' 
                                                sx={{ borderRadius: 10 }} 
                                                loading={ loading }
                                                onClick={ handleClicBuscarExpediente }
                                                size='small'>
                                                <SearchIcon />
                                            </LoadingButton>                          
                                        </Paper>
                                        {   
                                            errors.expediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.expediente  } </Alert> )
                                        }
                                    </Grid>
                                </>
                            )
                        }
                        
                        {
                            (expediente.success) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Expediente:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { expediente.numeroExpediente }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        {
                                            expediente.ponencia
                                            &&                                
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        {
                                            expediente.ponencia
                                            &&  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { expediente.ponencia }
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                           
                                        {
                                            expediente.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                 
                                        </List>      
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                                {
                                                    expediente.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                                                                
                                        </List>
                                    </Grid>  
                                </>
                            )
                        }

                        {
                            ( tipoBusqueda === 'irregular' || tipoBusqueda === 'convenioFueraJuicio' )
                            && (
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar irregular' }} 
                                            placeholder={ tipoBusqueda === 'convenioFueraJuicio' ? "Buscar por número de convenio" : "Buscar por número de irregular" }
                                            name="irregular"
                                            value={ numeroIrregular } 
                                            onChange={ ( e ) => { setNumeroIrregular( e.target.value ) }}
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ () => tipoBusqueda === 'convenioFueraJuicio' ? handleClicBuscarConvenioFueraJuicio() : handleClicBuscarIrregular() }
                                            size='small'
                                        >
                                            <SearchIcon />
                                        </LoadingButton>                           
                                    </Paper>
                                    {   
                                        errors.irregular && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.irregular  } </Alert> )
                                    }
                                </Grid>  
                            )
                        }
                        
                        {
                            (irregular.success) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            {  tipoBusqueda === 'convenioFueraJuicio' ? 'Convenio' : 'Irregular' }:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { irregular.numeroIrregular }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        {
                                            tipoBusqueda === 'irregular'
                                            &&
                                                <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                    Tipo de irregular:
                                                </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        {
                                            tipoBusqueda === 'irregular'
                                            &&
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                    { irregular.tipoIrregular }
                                                </Typography>                                        
                                        }
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                           
                                        {
                                            irregular.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                                 
                                        </List>      
                                    </Grid>
                                    
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                                {
                                                    irregular.demandado.map((elem, i)=>(
                                                        <ListItem key={ i } disablePadding> 
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                                { elem }
                                                            </Typography>
                                                        </ListItem>                    
                                                    ))
                                                }                                                                                                
                                        </List>
                                    </Grid>    
                                </>
                            )
                        }

                        {
                            tipoBusqueda === 'amparo' && (
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                            placeholder="Buscar por número de amparo"     
                                            name="amparo"
                                            value={ numeroAmparo } 
                                            onChange={ ( e ) => { setNumeroAmparo( e.target.value ) }}
                                            autoComplete="off" 
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarAmparo }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                           
                                    </Paper>
                                    {   
                                        errors.amparo && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.amparo  } </Alert> )
                                    }
                                </Grid>                       
                            )
                        }

                        {
                            amparo.success === true && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Amparo:
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { amparo.numeroAmparo }
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Tipo de amparo:
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { amparo.tipoAmparo }
                                        </Typography>
                                    </Grid> 
        
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Partes(es):
                                        </Typography>
                                    </Grid>
        
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                               
                                            {
                                                amparo.partes.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem.parte } <br/> <strong>({ elem.tipoParte })</strong>
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                
                                        </List>                            
                                
                                    </Grid>         
                                </>
        
                            )
                        }

                        {
                            tipoBusqueda === 'exhortoRecibido' && (
                                <Grid item md={12} xs={12} > 
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase sx={{ ml: 1, flex: 1 }} 
                                            inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                            placeholder="Buscar por número de exhorto recibido"     
                                            name="exhorto"
                                            value={ numeroExhortoRecibido } 
                                            onChange={ ( e ) => { setNumeroExhortoRecibido( e.target.value ) }}
                                            autoComplete="off" 
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarExhortoRecibido }
                                            size='small'>
                                            <SearchIcon />
                                        </LoadingButton>                           
                                    </Paper>
                                    {   
                                        errors.numeroExhortoRecibido && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExhortoRecibido  } </Alert> )
                                    }
                                </Grid>                       
                            )
                        }

                        {
                            (exhortoRecibido.success) && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Exhorto:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { exhortoRecibido.numeroExhorto }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>                                                             
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Tipo de juicio:
                                        </Typography>                                
                                    </Grid>

                                    <Grid item md={4} xs={6} >                               
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoRecibido.tipoJuicio }
                                        </Typography>                                
                                    </Grid>                                    

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Actor(es):
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>                               
                                        {
                                            exhortoRecibido.actor.map((elem, i)=>(
                                                <ListItem key={ i } disablePadding> 
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                        { elem }
                                                    </Typography>
                                                </ListItem>                    
                                            ))
                                        }                                
                                        </List>                            
                                
                                    </Grid> 
                            
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                            Demandado(s):
                                        </Typography>
                                    </Grid>
                            
                                    <Grid item md={4} xs={6} >
                                        <List disablePadding>
                                            {
                                                exhortoRecibido.demandado.map((elem, i)=>(
                                                    <ListItem key={ i } disablePadding> 
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray' }} >
                                                            { elem }
                                                        </Typography>
                                                    </ListItem>                    
                                                ))
                                            }                                                  
                                        </List>
                                    </Grid>    

                                    <Grid item md={2} xs={6}>                                                             
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Autoridad del exhorto:
                                        </Typography>                                
                                    </Grid>

                                    <Grid item md={4} xs={6} >                               
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoRecibido.autoridadExhorto }
                                        </Typography>                                
                                    </Grid>                             
                                    
                                    <Grid item md={2} xs={6}>    
                                        {
                                            exhortoRecibido.ponencia
                                            &&                                                     
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>   
                                        }                             
                                    </Grid>                            

                                    <Grid item md={4} xs={6} >
                                        {
                                            exhortoRecibido.ponencia
                                            &&       
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { exhortoRecibido.ponencia }
                                            </Typography>
                                        }
                                    </Grid> 
                                </>
                            )
                        }

                        {
                            tipoBusqueda === 'exhortoLibrado' && (
                                <Grid item md={12} xs={12} > 
                        
                                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                                        
                                        <InputBase 
                                            sx={{ ml: 1, flex: 1 }}
                                            inputProps={{ 'aria-label': 'Buscar usuario' }} 
                                            placeholder="Buscar por número de exhorto librado"     
                                            name="exhortoLibrado"
                                            value={ numeroExhortoLibrado }                                      
                                            onChange={ ( e ) => { setNumeroExhortoLibrado( e.target.value ); } }
                                            autoComplete="off"
                                        />

                                        <LoadingButton 
                                            variant='contained' 
                                            sx={{ borderRadius: 10 }} 
                                            loading={ loading }
                                            onClick={ handleClicBuscarExhortoLibrado }
                                            size='small'
                                        >
                                            <SearchIcon />
                                        </LoadingButton>            

                                    </Paper>
                                    {   
                                        errors.numeroExhortoLibrado && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExhortoLibrado  } </Alert> )
                                    }

                                </Grid>                  
                            )
                        }

                        {
                            exhortoLibrado.success && (
                                <>
                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                        Exhorto librado:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoLibrado.numeroExhortoLibrado }
                                        </Typography>
                                    </Grid>

                                    <Grid item md={2} xs={6}>
                                        <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                            Autoridad del exhorto:
                                        </Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                            { exhortoLibrado.autoridadExhorto }
                                        </Typography>
                                    </Grid>  

                                    <Grid item md={2} xs={6}>
                                        {
                                            exhortoLibrado.ponencia
                                            &&                                
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        }
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        {
                                            exhortoLibrado.ponencia
                                            &&  
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { exhortoLibrado.ponencia }
                                            </Typography>
                                        }
                                    </Grid>

                                </>
                            )
                        }

                        {   
                            errors.error && ( 
                                <Grid item md={12} xs={12} >
                                    
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.error  } </Alert> 
                                    
                                </Grid>
                            )
                        }  

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ cerrarModal }>Cancelar</Button>
                    <Button  variant="contained" color="primary" startIcon={ <SaveIcon /> } onClick={ GenerarAcuerdo } disabled={ habiliarGuardar } >Guardar</Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

        </>
    )
}