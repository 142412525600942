import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, Tab, Tabs, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Editor } from '@tinymce/tinymce-react';

import AppBarMenu from '../../ui/AppBarMenu';

import moment from 'moment';

import { types } from '../../../types/types';

import { ObtenerSentidosSentencias } from '../../../actions/admin/catalogs/sentidoSentenciaActions';
import { ActualizarEstatusFirmarSentencia, ObtenerFirmaSentenciaDetalle } from '../../../actions/sentencias/sentenciaActions';
import { ObtenerPlantilla, ObtenerPlantillaDetalle } from '../../../actions/admin/templates/templateActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';
import { SentenciaNotificacionScreen } from './SentenciaNotificacionScreen';
import { ObtenerApiKeyRichText } from '../../../helpers/ObtenerApiKeyRichText';

function TabPanel( props ) {
    const { children, value, index, ...other } = props;
  
    return (
        <Grid role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
            { 
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </Grid>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const FirmarSentenciaScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const { tribunal, juzgado } = useSelector( state => state.auth );

    const dispatch = useDispatch();

    const editorRef = useRef(null);

    const [valueTab, setValueTab] = useState(0);

    const [loading, setLoading] = useState( false );
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );   
   
    const [sentencia, setSentencia] = useState( { } );

    const [sentidoSentencia, setSentidoSentencia] = useState( 0 );
    const [sentidoSentenciaAray, setSentidoSentenciaArray] = useState( [ ] );

    const [plantilla, setPlantilla] = useState( 0 );
    const [plantillaArray, setPlantillaArray] = useState( [ ] );

    const [fechaCitacion, setFechaCitacion] = useState( '' );
    const [perspectivaGenero, setPerspectivaGenero] = useState( false );

    const [cadenaActores, setCadenaActores] = useState( '' ); 
    const [cadenaDemandados, setCadenaDemandados] = useState( '' ); 

    const [openConfirmacion, setOpenConfirmacion] = useState( false );    
    const [ajaxLoading, setAjaxLoading] = useState( false );

    const [alert, setAlert] = useState( {  } );

    const [richText, setRichText] = useState( '' ); 

    const [partes, setPartes] = useState( [] );

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    }; 

    const handleChangePerspectivaGenero = ( value ) => {

        setPerspectivaGenero( value );
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleChangePlantilla = ( plantilla ) => {
        setPlantilla(plantilla);
        obtenerPlantilla(plantilla);
    }

    const obtenerPlantilla = async ( plantilla ) => {

        await ObtenerPlantillaDetalle( plantilla ).then( response => {

            if(response){

                if( response.status === 200){

                    if( response.data.contenido !== 0 ){

                        let contenido = '';

                        contenido = response.data.contenido                        
                            .replaceAll('[Actor]', cadenaActores)
                            .replaceAll('[Demandado]', cadenaDemandados)
                            .replaceAll('[Tipo_Juicio]', sentencia.tipoJuicio ?? "" )
                            .replaceAll('[Ponencia]', sentencia.ponencia ?? "" )
                            .replaceAll('[Numero_Exhorto]', sentencia.numeroExhorto ?? "" )
                            .replaceAll('[Numero_Expediente]', sentencia.numeroExpediente ?? "" );

                        editorRef.current.setContent( contenido );

                    }
 
                }
            }

        });

    }

    const Finalizar = async () => {     
        
        setLoadingFinalizar( true );

        const params = {
            'idSentenciaUsuario' : Id,
            'idEstatus': 30,
        };

        await ActualizarEstatusFirmarSentencia( params ).then( response => {

            if( response ){

                if( response.status === 200){                    

                    setOpenConfirmacion( false );

                    setLoading( true );

                    setLoadingFinalizar( false );
                    
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    useEffect(() => {      

        async function Obtener(){

            await ObtenerSentidosSentencias().then( response => {

                if( response ){

                    if( response.data ){
                        setSentidoSentenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])
    
    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idTipoAcuerdo': 5,
                'idSubTipoAcuerdo': 0,
                'idTipoNotificacion': 0,
            };

            await ObtenerPlantilla( params ).then( response => {

                if( response ){

                    if( response.data ){
                        setPlantillaArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idSentenciaUsuario': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerFirmaSentenciaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {

                            setSentencia( response.data );      
                            setSentidoSentencia( response.data.idSentidoSentencia ?? 0 );
                            setPerspectivaGenero( response.data.perspectivaGenero );
                            setFechaCitacion( response.data.fechaCitacion ?? '' );
                            setPlantilla( response.data.idPlantilla ?? 0 );
                            setRichText( response.data.contenido ?? '' );                     
                            setPartes( response.data.partes ?? [] );            

                            let cadenaA = '';
                            response.data.actor.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.actor.length){ 
                                    cadenaA += (value + ', ')
                                } else {
                                    cadenaA += (value + ' ')
                                }
                            })
                            setCadenaActores(cadenaA);

                            let cadenaD = '';
                            response.data.demandado.forEach((value, key) => { 
                                if (key + 1 !== response?.data?.demandado.length){ 
                                    cadenaD += (value + ', ')
                                } else {
                                    cadenaD += (value + ' ')
                                }
                            })
                            setCadenaDemandados(cadenaD);

                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });                                       
                            
                            if( response.data.idEstatus !== 29){
                                
                                setLoading( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])
 
    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            No se tienen actividades pendientes para este trámite
                                        </Typography>

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>    
                    )
                    :
                    (       
                        
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>

                            <Tabs value={ valueTab } onChange={ handleChangeTab } aria-label="basic tabs example" sx={{ pt: 2 }}>
                                
                                <Tab label="Sentencia" { ...a11yProps(0) } />
                                <Tab label="Notificación" { ...a11yProps(1) } />

                            </Tabs>    

                            <TabPanel value={ valueTab } index={0}>
                                
                                    <InformacionSeguimiento 
                                        noSeguimiento={ 'GSE-' + sentencia?.idSentencia }
                                        actividad={ 'Firmar sentencia' }
                                        tipo={ 'Número de expediente' }
                                        referencia={ sentencia?.numeroExpediente }
                                    />
                                        
                                    <Grid container spacing={3} mt={1}>

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                Número de Sentencia (Resolución):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={ tribunal ? 4 : 10 } xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { sentencia?.numeroSentencia }
                                            </Typography>
                                        </Grid>

                                        {
                                            juzgado && sentencia?.tipoSentencia
                                            &&
                                                <Grid item md={2} xs={6}>
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                        Tipo de Sentencia:                                          
                                                    </Typography>
                                                </Grid>
                                        }
                                        
                                        {
                                            juzgado && sentencia?.tipoSentencia
                                            &&
                                                <Grid  item md={10} xs={6}>
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray'  }}>
                                                        { sentencia?.tipoSentencia }                                            
                                                    </Typography>
                                                </Grid>
                                        }
                                        
                                        {
                                            sentencia?.numeroCierreInstruccion
                                            &&
                                                <Grid item md={2} xs={6}>                                            
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                                        Número de Cierre de instrucción:
                                                    </Typography>                                                                                        
                                                </Grid>
                                        }
                                        
                                        {
                                            sentencia?.numeroCierreInstruccion
                                            &&
                                                <Grid item md={4} xs={6} >                                            
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { sentencia?.numeroCierreInstruccion }
                                                    </Typography>                                                
                                                </Grid> 
                                        }   
                                        
                                        {
                                            sentencia?.ponencia
                                            && 
                                                <Grid item md={2} xs={6}>                                                                                
                                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                        Ponencia:
                                                    </Typography>                                                
                                                </Grid>
                                        }
                                        
                                        {
                                            sentencia?.ponencia
                                            &&  
                                                <Grid item md={10} xs={6} >                                                
                                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                        { sentencia?.ponencia }
                                                    </Typography>                                                
                                                </Grid>
                                        }

                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={4} xs={6} >
                                            <List disablePadding>                               
                                            {
                                                sentencia?.actor?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                      
                                            </List>
                                        </Grid>  
                                        
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item md={4} xs={6} >
                                            <List 
                                                disablePadding 
                                                sx={{ maxHeight: sentencia?.demandado?.length > 10 ? 300 : null, overflow: sentencia?.demandado?.length > 10 ? 'scroll' : null }}
                                            >  
                                            {
                                                sentencia?.demandado?.map( ( elem, i ) => (
                                                    <ListItem key={ i } disablePadding>  
                                                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                            { elem }
                                                        </Typography>      
                                                    </ListItem>
                                                ))
                                            }                                              
                                            </List>
                                        </Grid>                

                                        <Grid item md={12} xs={12} > </Grid>
                                        
                                        <Grid item md={4} xs={12} >  

                                            <FormControl fullWidth>
                                                <InputLabel id="select-sentidoSentencia-label">Sentido de la sentencia</InputLabel>
                                            
                                                <Select
                                                    labelId="select-sentidoSentencia-label"
                                                    id="select-sentidoSentencia"                                            
                                                    label="Sentido de la sentencia"
                                                    name="sentidoSentencia"
                                                    value={ sentidoSentencia }
                                                    onChange={ ( e ) => { setSentidoSentencia( e.target.value ); } }
                                                    disabled={ true }   
                                                >
                                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                    {
                                                        sentidoSentenciaAray.map((elem)=>(
                                                            <MenuItem key={elem.idSentidoSentencia} value={ elem.idSentidoSentencia }>{ elem.descripcion }</MenuItem>
                                                        ))
                                                    }
                                                </Select>

                                            </FormControl>
                                    
                                        </Grid>  

                                        <Grid item md={4} xs={12} >
                                        
                                            <TextField                              
                                                label="Fecha citación"
                                                type="datetime-local"              
                                                fullWidth
                                                value={ fechaCitacion }    
                                                onChange={ ( e ) => { setFechaCitacion( e.target.value ); } } 
                                                inputProps={{
                                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                                }}                             
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}        
                                                disabled={ true }     
                                            />

                                        </Grid>                           

                                        <Grid item md={4} xs={12} >

                                            <FormControl component="fieldset" disabled={ true } >
                                                <FormLabel component="legend">¿Con perspectiva género?</FormLabel>
                                                <RadioGroup row
                                                    aria-label="seRevisaProyecto"
                                                    defaultValue="si"
                                                    name="radio-buttons-group"                                              
                                                >
                                                    <FormControlLabel control={ <Radio checked={ perspectivaGenero === false } onChange={ () => { handleChangePerspectivaGenero( false ); } } />} label="No" />
                                                    <FormControlLabel control={ <Radio checked={ perspectivaGenero === true } onChange={ () => { handleChangePerspectivaGenero( true ); } } />} label="Si" />  
                                                </RadioGroup>
                                            </FormControl>

                                        </Grid>

                                        { 
                                            !sentencia?.proyectoSentencia
                                            &&                                
                                            <Grid item md={12} xs={12} >                            
                                                <FormControl fullWidth>
                                                    <InputLabel id="select-plantilla-label">Plantilla</InputLabel>
                                                
                                                    <Select
                                                        labelId="select-plantilla-label"
                                                        id="select-plantilla"                                            
                                                        label="Plantilla"
                                                        name="plantilla"
                                                        value={ plantilla }
                                                        onChange={ (e) => { handleChangePlantilla(e.target.value) } }
                                                        disabled={ true }   
                                                    >
                                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                                        {
                                                            plantillaArray
                                                            .filter( ( element ) => { return element.idSubTipoAcuerdo === 8 || element.idSubTipoAcuerdo === 9; })                                                
                                                            .map((elem)=>(
                                                                <MenuItem key={elem.idPlantilla} value={ elem.idPlantilla }>{ elem.descripcion }</MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                </FormControl>
                                            </Grid>  
                                        }

                                        <Grid item md={12} xs={12} >  
                                            
                                            <Editor
                                                onInit = { (evt, editor) => editorRef.current = editor }
                                                initialValue = { richText }
                                                apiKey = { ObtenerApiKeyRichText( process.env ) }
                                                init = {
                                                    {
                                                        height: 450,
                                                        menubar: true,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount pagebreak'
                                                        ],
                                                        toolbar: 'undo redo | ' +
                                                            'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify lineheight | bullist numlist outdent indent | ' +
                                                            'removeformat | print pagebreak',                                                                                                  
                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; margin: 1rem auto; max-width: 900px; }', 
                                                    }
                                                }  
                                            />

                                        </Grid>    
                                                                
                                        <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                            
                                            <Button 
                                                variant="contained" 
                                                onClick={ () => { setOpenConfirmacion( true ); } } 
                                                endIcon={ <CheckCircleIcon />  } 
                                            > 
                                                Finalizar y Firmar sentencia 
                                            </Button>

                                        </Grid>                                  

                                    </Grid>

                            </TabPanel> 

                            <TabPanel value={ valueTab } index={1}>

                                <Grid item md={12} xs={12}>
                                    <Typography variant="h6" sx={{ color: 'gray', mb: 1 }} >
                                        Número de seguimiento: GSE-{ sentencia?.idSentencia }
                                    </Typography>
                                </Grid>

                                <SentenciaNotificacionScreen 
                                    Id={ Id } 
                                    edit={ false } 
                                    notificaciones={ sentencia?.notificaciones ?? [ ] } 
                                    partes={ partes }
                                    sentencia={ sentencia } 
                                    setSentencia={ setSentencia } 
                                />
                                
                            </TabPanel> 

                        </Container>
                
                    )

                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar y firmar la sentencia?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

        </>
    )
}
