import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import AppBarMenu from '../../ui/AppBarMenu';

import { Alert, Box, Container, FormControl, Grid, InputBase, InputLabel, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import moment from 'moment';

import { types } from '../../../types/types';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { usePages } from '../../../hooks/usePages';

import { ObtenerTipoJuicio } from '../../../actions/admin/catalogs/tipojuicioActions';
import { BuscarExpedienteAnterior, GuardarExpedienteAnterior } from '../../../actions/recepcion/expedienteActions';
import { BuscarUsuarioJuez, BuscarUsuarioSecretario } from '../../../actions/admin/users/usuarioAction';
import { ObtenerEtapas } from '../../../actions/admin/catalogs/etapaActions';
import { ObtenerSubEtapas } from '../../../actions/admin/catalogs/subEtapaActions';

export const GestionarExpedienteAnteriorScreen = () => {

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({  });
    const [alert, setAlert] = useState( {  } );

    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const [numeroExpediente, setNumeroExpediente] = useState('');
    const [expediente, setExpediente] = useState( {} );

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [juez, setJuez] = useState( 0 );
    const [juezArray, setJuezArray] = useState( [ ] );

    const [secretario, setSecretario] = useState( 0 );
    const [secretarioArray, setSecretarioArray] = useState( [ ] );

    const [etapa, setEtapa] = useState(0);
    const [etapaArray, setEtapaArray] = useState( [] );

    const [subEtapa, setSubEtapa] = useState(0);
    const [subEtapaArray, setSubEtapaArray] = useState( [] );

    const [rows, setRows] = useState( [ ] );

    const [expedienteEncontrado, setExpedienteEncontrado] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleCleanFields = () => {

        setTipoJuicio( 0 );
        setJuez( 0 );
        setSecretario( 0 );

        setSubEtapa( 0 );
        setSubEtapaArray( [] );
        setEtapa( 0 );

        setExpediente( {} );
        setRows( [] );

        setErrors( {} );
    }

    const validateFiels = () => {

        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( tipoJuicio === 0){           
            valid = false;
            errores.tipoJuicio = 'Debes seleccionar un tipo juicio';
        } 

        if( juez === 0){           
            valid = false;
            errores.juez = 'Debes seleccionar un juez';
        } 

        if( secretario === 0){           
            valid = false;
            errores.secretario = 'Debes seleccionar un secretario';
        } 

        if( etapa === 0){           
            valid = false;
            errores.etapa = 'Debes seleccionar una etapa';
        } 
        else if( etapa !== 0 && subEtapa === 0 && subEtapaArray.length > 0 ){
            valid = false;
            errores.subEtapa = 'Debes seleccionar una sub etapa';
        }      

        setErrors( errores );

        return valid;
    }

    const errorExpediente = () => {
       
        setErrors( { } );

        let errores = {};  
            errores.numeroExpediente = 'No se encontró el expediente';
            errores.type = 'warning';
        
        setExpediente( {} );
        setErrors( errores );

        setNumeroExpediente( '' );
    }

    const handleBuscarExpediente = async () => {     

        dispatch({ type: types.openLoading });

        handleCleanFields();

        await BuscarExpedienteAnterior({ numeroExpediente: numeroExpediente }).then( response => {

            if( response ){
               
                if( response.status === 200){

                    setTimeout(() => {

                        if( response.data ){

                            if( response.data.success ){

                                setExpediente( response.data );
                                setRows( response?.data?.partes ?? [] );

                                setExpedienteEncontrado( response?.data?.encontrado ?? false );
                            }
                            else{
                                errorExpediente();
                            }

                            dispatch({ type: types.closeLoading });
                            setNumeroExpediente('');
                        }

                    }, 600);  

                    
                } 
                else {

                    setTimeout(() => {

                        dispatch({ type: types.closeLoading });
                        setNumeroExpediente('');

                    }, 600);  

                }
            }

        });

    }   

    const handleGuardarExpediente = async () => {

        const valid = validateFiels();

        if ( !valid ) return;

        setLoadingGuardar( true );      

        const params = {
           idTipoJuicio: tipoJuicio,
           idJuez: juez,   
           idSecretario: secretario,     
           idEtapa: etapa,
           idSubEtapa: subEtapa,      
           numeroFolio: expediente?.folio, 
           numeroExpediente: expediente?.numeroExpediente, 
           fechaRadicacion: expediente?.fechaRadicacion,
           partes: expediente?.partes,
        }

        await GuardarExpedienteAnterior( params ).then( resp => {


            if( resp ){

                if( resp.status === 200 ){

                    const { success, message } = resp.data;

                    if( success ){

                        setTimeout(() => {
            
                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } ); 

                            setLoadingGuardar( false );
                            setExpedienteEncontrado( true );
                
                        }, 400);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } ); 
            
                            setLoadingGuardar( false );
                
                        }, 400);

                    }

                }

            }

        });

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoJuicio().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoJuicioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioSecretario( ).then( response => {

                if( response ){

                    if( response.data ){
                        setSecretarioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);   

    useEffect(() => {

        async function Obtener(){    

            await ObtenerEtapas().then( response => {

                if( response ){

                    if( response.status === 200){

                        setEtapaArray( response.data );

                    }
                }

            });

        }
      
        Obtener();
        
    }, [ ]);

    useEffect(() => {

        async function Obtener(){             
        
            setSubEtapa( 0 );
            setSubEtapaArray( [] );

            if( etapa !== 0 ){   

                await ObtenerSubEtapas( etapa ).then( response => {
                
                    if( response ){

                        if( response.status === 200){
                            setSubEtapaArray( response.data );                            
                        }

                    }

                });

            }       

        }
     
        Obtener();

    }, [ etapa ]);          

    useEffect(() => {

        if( expediente?.idTipoJuicio !== 0 && tipoJuicioArray.length !== 0 ){

            setTipoJuicio( expediente?.idTipoJuicio ?? 0 );

        }
        
    }, [ expediente, tipoJuicioArray ]);         

    useEffect(() => {

        if( expediente?.idJuez !== 0 && juezArray.length !== 0 ){

            setJuez( expediente?.idJuez ?? 0 );

        }
        
    }, [ expediente, juezArray ]);       

    useEffect(() => {

        if( expediente?.idSecretario !== 0 && secretarioArray.length !== 0 ){

            setSecretario( expediente?.idSecretario ?? 0 );

        }
        
    }, [ expediente, secretarioArray ]);      

    useEffect(() => {

        if( expediente?.idEtapa !== 0 && etapaArray.length !== 0 ){

            setEtapa( expediente?.idEtapa ?? 0 );

        }
        
    }, [ expediente, etapaArray ]);      

    useEffect(() => {

        if( etapa !== 0 && expediente?.idSubEtapa !== 0 && subEtapaArray.length !== 0 ){

            setSubEtapa( expediente?.idSubEtapa ?? 0 );

        }
        
    }, [ expediente, etapa, subEtapaArray ]);      

    return (
        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
        
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>   

                <Container maxWidth="lg" sx={{ pb: 3 }}> 

                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                        
                        <InputBase 
                            sx={{ ml: 1, flex: 1 }} 
                            inputProps={{ 'aria-label': 'Buscar expediente' }} 
                            placeholder="Buscar por número de expediente"     
                            name="expediente"
                            value={ numeroExpediente }                                      
                            onChange={ ( e ) => { setNumeroExpediente( e.target.value ); } }
                            autoComplete="off"
                        /> 

                        <LoadingButton 
                            variant='contained' 
                            sx={{ borderRadius: 10 }} 
                            onClick={ handleBuscarExpediente }
                            size='small'
                            disabled={ numeroExpediente === '' }
                        >
                            <SearchIcon />
                        </LoadingButton>   

                    </Paper>

                    {   
                        errors.numeroExpediente && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExpediente  } </Alert> )
                    }

                </Container>    

                <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>   

                    <Grid container sx={{ mt: 1, mb: 1 }} spacing={3}>                       
                       
                        <Grid item md={2} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de folio:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.folio }
                            </Typography>
                        </Grid>      

                        <Grid item md={2} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de expediente:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.numeroExpediente }
                            </Typography>
                        </Grid>    

                        {
                            ( expedienteEncontrado || !expediente?.numeroExpediente )
                            &&
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Tipo de juicio:
                                    </Typography>
                                </Grid>
                        }          

                        <Grid item md={ ( expedienteEncontrado || !expediente?.numeroExpediente ) ? 4 : 6 } xs={6} > 
                            {
                                ( expedienteEncontrado || !expediente?.numeroExpediente )
                                ?
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { tipoJuicioArray.find( (e) => e.idTipoJuicio === expediente?.idTipoJuicio )?.descripcion ?? '' }
                                    </Typography> 
                                :
                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoJuicio-label">Tipo de juicio</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoJuicio-label"
                                                id="select-tipoJuicio"                                            
                                                label="Tipo de juicio"
                                                name="tipoJuicio"
                                                value={ tipoJuicio }
                                                onChange={ (e) => setTipoJuicio( e.target.value ) }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                {                          
                                                    tipoJuicioArray
                                                    .filter( (element ) => { return element.juzgado === true; } )
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                                                    ))
                                                    
                                                }
                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.tipoJuicio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuicio } </Alert> )
                                        }
                                    </>
                            }
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Fecha de radicación:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.fechaRadicacion ? moment( expediente?.fechaRadicacion ).format('YYYY-MM-DD') : '' }
                            </Typography>                                                
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Presentante:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.presentante }
                            </Typography>                                                
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Fecha de presentación:
                            </Typography>
                        </Grid>
                        
                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.fechaPresentacion ? moment( expediente?.fechaPresentacion ).format('YYYY-MM-DD hh:mm a') : '' }
                            </Typography>                                                
                        </Grid>                        

                        <Grid item md={2} xs={12}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Sintesís:
                            </Typography>
                        </Grid>

                        <Grid item md={10} xs={12} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { expediente?.sintesis }
                            </Typography>                                                
                        </Grid> 

                        <Grid item md={6} xs={12} >

                            <FormControl fullWidth>
                                <InputLabel id="select-juez-label">Juez</InputLabel>

                                <Select
                                    labelId="select-juez-label"
                                    id="select-juez"                                            
                                    label="Juez"
                                    name="juez"
                                    value={ juez }                                
                                    onChange={ ( e ) => setJuez( e.target.value ) }                 
                                    disabled={ expedienteEncontrado || !expediente?.numeroExpediente }                          
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                    {
                                        juezArray.map( ( elem ) => (
                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                        ))
                                    }                                          
                                </Select>

                                {   
                                    errors.juez && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.juez } </Alert> )
                                }

                            </FormControl>

                        </Grid>

                        <Grid item md={6} xs={12} >

                            <FormControl fullWidth>
                                <InputLabel id="select-secretario-label">Secretario</InputLabel>

                                <Select
                                    labelId="select-secretario-label"
                                    id="select-secretario"                                            
                                    label="Secretario"
                                    name="secretario"
                                    value={ secretario }                                
                                    onChange={ ( e ) => setSecretario( e.target.value ) }         
                                    disabled={ expedienteEncontrado || !expediente?.numeroExpediente }                            
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                    {
                                        secretarioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                        ))
                                    }                                          
                                </Select>

                                {   
                                    errors.secretario && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.secretario } </Alert> )
                                }

                            </FormControl>

                        </Grid>

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-etapa-label">Etapa</InputLabel>
                            
                                <Select
                                    labelId="select-etapa-label"
                                    id="select-etapa"                                            
                                    label="Etapa"
                                    name="etapa"
                                    value={ etapa }
                                    onChange={ ( e ) => { setEtapa( e.target.value ); } }
                                    disabled={ expedienteEncontrado || !expediente?.numeroExpediente }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    
                                    {    
                                        tipoJuicio === 5
                                        ?
                                            etapaArray 
                                            .filter( (element ) => { 
                                                return element.idEtapa !== 16 && element.idEtapa !== 27 && element.idEtapa !== 28; 
                                            })                                                               
                                            .map((elem)=>(
                                                <MenuItem key={elem.idEtapa} value={ elem.idEtapa }>{ elem.descripcion }</MenuItem>
                                            ))
                                        :
                                            etapaArray      
                                            .filter( (element ) => { 
                                                return element.idEtapa !== 16; 
                                            })                                                         
                                            .map((elem)=>(
                                                <MenuItem key={elem.idEtapa} value={ elem.idEtapa }>{ elem.descripcion }</MenuItem>
                                            ))
                                    }                          
                                    
                                </Select>

                            </FormControl>
                            {   
                                errors.etapa && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.etapa } </Alert> )
                            }
                        </Grid>  

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-subEtapa-label">Sub etapa</InputLabel>
                            
                                <Select
                                    labelId="select-subEtapa-label"
                                    id="select-subEtapa"                                            
                                    label="Sub etapa"
                                    name="subEtapa"
                                    value={ subEtapa }
                                    onChange={ ( e ) => { setSubEtapa( e.target.value ); } }
                                    disabled={ etapa === 0 || subEtapaArray.length === 0 || expedienteEncontrado || !expediente?.numeroExpediente }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem> 
                                    {    
                                        etapa === 3
                                        ?
                                            tipoJuicio === 5
                                            ?
                                                subEtapaArray     
                                                .filter( (element ) => { 
                                                    return element.idEtapa === etapa && element.idSubEtapa === 1045; 
                                                })                                             
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.idSubEtapa } value={ elem.idSubEtapa }>{ elem.descripcion }</MenuItem>
                                                )) 
                                            :
                                                subEtapaArray     
                                                .filter( (element ) => { 
                                                    return element.idEtapa === etapa; 
                                                })                                             
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.idSubEtapa } value={ elem.idSubEtapa }>{ elem.descripcion }</MenuItem>
                                                ))  
                                        :    
                                            etapa === 9
                                            ?
                                                tipoJuicio === 6
                                                ?
                                                    subEtapaArray     
                                                    .filter( (element ) => { 
                                                        return element.idEtapa === etapa && element.idSubEtapa === 10; 
                                                    })                                             
                                                    .map((elem)=>(
                                                        <MenuItem key={ elem.idSubEtapa } value={ elem.idSubEtapa }>{ elem.descripcion }</MenuItem>
                                                    )) 
                                                :
                                                    subEtapaArray     
                                                    .filter( (element ) => { 
                                                        return element.idEtapa === etapa && element.idSubEtapa !== 10; 
                                                    })                                             
                                                    .map((elem)=>(
                                                        <MenuItem key={ elem.idSubEtapa } value={ elem.idSubEtapa }>{ elem.descripcion }</MenuItem>
                                                    ))  
                                            :  
                                                subEtapaArray     
                                                .filter( (element ) => { 
                                                    return element.idEtapa === etapa; 
                                                })                                             
                                                .map((elem)=>(
                                                    <MenuItem key={ elem.idSubEtapa } value={ elem.idSubEtapa }>{ elem.descripcion }</MenuItem>
                                                ))                                                            
                                    }

                                </Select>

                            </FormControl>
                            {   
                                errors.subEtapa && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.subEtapa } </Alert> )
                            }
                        </Grid>                          

                        <Grid item xs={12} >  

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'50%', fontWeight: 'bold' }}>NOMBRE</TableCell>
                                            <TableCell style={{ width:'30%', fontWeight: 'bold' }}>TIPO DE PARTE</TableCell>                                
                                            <TableCell style={{ width:'20%', fontWeight: 'bold' }}>PERSONA MORAL </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                    {
                                        (   rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map( ( row, index ) => (

                                        <TableRow key={ index } >
                                            <TableCell> { row.nombre } { row.apellidoPaterno } { row.apellidoMaterno } </TableCell>
                                            <TableCell> { row.tipo } </TableCell>
                                            <TableCell> { row.personaMoral === 1 ? 'Si' : 'No' } </TableCell>
                                        </TableRow>

                                        ))
                                    }

                                    {
                                        rows.length === 0 && 
                                        (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="center"
                                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                                >
                                                    {
                                                        'No se existen parte(s)'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={3}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>     

                        </Grid>

                        {
                            !expedienteEncontrado
                            &&
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                                
                                    <LoadingButton
                                        variant="contained" 
                                        onClick={ handleGuardarExpediente } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                        disabled={ !expediente?.numeroExpediente }
                                    > 
                                        Guardar expediente
                                    </LoadingButton>

                                </Grid>   
                        }
                      
                    </Grid>  

                </Container>

            </Box>        
        
        </>
    )
}
