import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { usePages } from '../../../hooks/usePages';

import { types } from '../../../types/types';

import { ObtenerTipoFormato } from '../../../actions/admin/catalogs/tipoformatoActions';
import { ObtenerTiposAnexos } from '../../../actions/admin/catalogs/tipoAnexoActions';

import { AgregarAnexos, GenerarAcuse } from '../../../actions/recepcion/promocionActions';

import { ActualizarEstatus } from '../../../actions/recepcion/recepcionActions';

export const RegistrarAnexosScreen = ({ handleNext, handleBack, idRecepcion, setAjaxFinish, setUsuarioAsignacion }) => {

    const dispatch = useDispatch();

    const { anexos, recepcion } = useSelector( state => state.recepcion );    
    
    const [idReferencia, setReferencia] = useState( 0 );

    const [errors, setErrors] = useState({ });

    const [tipoAnexo, setTipoAnexo] = useState( 0 );
    const [tipoAnexoArray, setTipoAnexoArray] = useState( [ ] );

    const [tipoFormato, setTipoFormato] = useState( 0 );
    const [tipoFormatoArray, setTipoFormatoArray] = useState( [ ] );
    
    const [cantidad, setCantidad] = useState( 1 );
    const [numeroFojas, setNumeroFojas] = useState( 1 );
    const [observaciones, setObservaciones] = useState( '' );

    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [openAvisoConfirmacion, setOpenAvisoConfirmacion] = useState(false);

    const [rows, setRows] = useState( [ ] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();    

    const [loadingBack, setLoadingBack] = useState( false );
    const [loadingNext, setLoadingNext] = useState( false );

    const [loadingGenerarAcuse, setLoadingGenerarAcuse] = useState( false );

    const [alerta, setAlerta] = useState({ type: 'error', msg: '' });

    const [open, setOpen] = useState( false );

    const [autocompleteValue, setAutocompleteValue] = useState( null );

    const { type, msg } = alerta;

    const handleOpenConfirmacion = () => {       
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleOpenAvisoConfirmacion = () => {       
        setOpenAvisoConfirmacion(true);
    };

    const handleCloseAvisoConfirmacion = () => {
        setOpenAvisoConfirmacion(false);
    };

    const handleChangeCantidad = ( e ) => {

        if( e.target.value > 0 ){
            setCantidad( e.target.value );
        }

    }

    const handleChangeNumeroFojas = ( e ) => {

        if( e.target.value > 0 ){
            setNumeroFojas( e.target.value );
        }

    }

    const handleClicAgregarAnexo = () => {

        const tipoAnexoData =  tipoAnexoArray.find( ( element ) => { return element.idTipoAnexo === tipoAnexo });
        const tipoFormatoData =  tipoFormatoArray.find( ( element ) => { return element.idTipoFormato === tipoFormato });
        const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

        // const anexoValidate =  rows.find( ( element ) => { return element.idTipoAnexo === tipoAnexo && element.idTipoFormato === tipoFormato; });

        // if( !anexoValidate ){
            const data = {
                id: parseInt( id ),
                idTipoAnexo: tipoAnexo,
                tipoAnexo: tipoAnexoData,
                idTipoFormato: tipoFormato,
                tipoFormato: tipoFormatoData,
                cantidad: cantidad,
                numeroFojas: numeroFojas,
                observaciones: observaciones
            };
    
            setRows( [ ...rows, data ] );
    
            setErrors( {} ); 
            setTipoAnexo( 0 );
            setTipoFormato( 0 );
            setCantidad( 1 );
            setNumeroFojas( 1 );
            setObservaciones( '' );
            setAutocompleteValue( null );
        // } 
        // else {
        //     let errores = {};

        //     errores.error = 'El anexo ya se encuentra agregado';
        
        //     setErrors( errores );  
        // }
        
    }

    const handleClicEliminarAnexo = ( Id ) => {

        handleOpenConfirmacion();

        setReferencia( Id );
        
    }

    const eliminarAnexo = () => {

        var newArray = rows.filter(function( element ){ 
            return element.id !== idReferencia;
        });

        setRows( newArray );

        dispatch({

            type: types.eliminarAnexo,
            payload: { id: idReferencia }

        });

        handleCloseConfirmacion();
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);        
    };

    const validarInformacion = async () => {
        
        setLoadingNext( true );          

        let arrayAnexos = [];

        rows.forEach(element => {
            
            const anexo = {
                idTipoAnexo : element.idTipoAnexo,
                idTipoFormato : element.idTipoFormato,
                cantidad: element.cantidad,
                numeroFojas: element.numeroFojas,
                observaciones: element.observaciones
            };       
            
            arrayAnexos.push( anexo );
        
        });       

        const params = {

            idRecepcion: idRecepcion,
            anexos : arrayAnexos

        };

        await AgregarAnexos( params ).then( response => {

            if( response ){

                if( response.status === 200 ){

                    dispatch({

                        type: types.agregarAnexos,
                        payload: rows
            
                    });
            
                    setTimeout(() => {
                                    
                        setLoadingNext( false );

                        if( recepcion.acuseFirmado ){
                            setLoadingNext( true );
                            handleClicGenerarAcuse();
                        }
                        else{
                            handleOpenAvisoConfirmacion( true );
                        }
            
                    }, 500);  

                }

            }

        });
       
    }

    const handleClicGenerarAcuse = async() => {

        setLoadingGenerarAcuse( true );

        const params = {
            idRecepcion: idRecepcion
        };

        await GenerarAcuse( params ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){
                                          
                        setTimeout(() => {
                            
                            setLoadingGenerarAcuse( false );

                            if( response.data.usuarioActualAsignacion ){
                                
                                handleNext();

                            }
                            else{

                                setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  
                                
                                setAjaxFinish( true );
                                
                            }

                        }, 800);

                    }

                }
                else{                    

                    setTimeout(() => {

                        setLoadingGenerarAcuse( false );
                                            
                        const { message } = response.data;

                        setAlerta( { type: 'error', msg : message } );

                        setOpen( true );

                    }, 500);
                }

            }

        });       

    }

    const Anterior = async() => {

        const params = {
            idRecepcion: idRecepcion,
            idEstatus: 1
        }

        setLoadingBack( true );

        await ActualizarEstatus( params ).then( response => {

            if( response ){
                if( response.status === 200 ){                    
                        
                    setTimeout(() => {
                        
                        setLoadingBack( false );

                        handleBack();

                    }, 500);  
                    
                }
            }
        });

    }

    useEffect(() => {

        function Obtener(){          

            ObtenerTiposAnexos( ).then( response => {

                if( response.status === 200){
                    setTipoAnexoArray( response.data ); 
                }
            })  
        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {

        function Obtener(){    

            ObtenerTipoFormato( ).then( response => {

                if( response.status === 200){
                    setTipoFormatoArray( response.data ); 
                }
            })

        }

        Obtener();
        
    }, [  ]);

    useEffect(() => {
      
        if( anexos.length !== 0 ){

            setRows( anexos );

        }

    }, [ anexos ])

    useEffect(() => {
     
        const requests = axios.CancelToken.source(); 

        return () => {
            requests.cancel();
        }

    }, [ ])

    return (
        <>
            <Snackbar open={ open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 3000 } onClose={ handleClose }>
                <Alert onClose={ handleClose } variant="filled" severity={ type }sx={{ width: '100%' }}>
                    { msg }
                </Alert>
            </Snackbar>

            <Grid item md={4} xs={12} >                            
               
                <FormControl fullWidth>

                    <Autocomplete                
                        disablePortal 
                        fullWidth              
                        value={ autocompleteValue }   
                        options={ tipoAnexoArray }
                        onChange={ (event, value) => { setTipoAnexo( value?.idTipoAnexo ?? 0 ); setAutocompleteValue( value ); } }                        
                        getOptionLabel={ (option) => option.descripcion}
                        renderOption={ ( props, option ) => (
                            <li { ...props } key={ option.idTipoAnexo} >                         
                                { option.descripcion }
                            </li>
                        )}
                        renderInput={(params) => <TextField {...params} label="Tipo de anexo" placeholder='Seleccione una opción' InputLabelProps={{ shrink: true }} />}         
                    />      

                </FormControl>

            </Grid> 

            <Grid item md={4} xs={12} >                            
                <FormControl fullWidth>
                    <InputLabel id="select-tipoFormato-label">Tipo de formato</InputLabel>
                
                    <Select
                        labelId="select-tipoFormato-label"
                        id="select-tipoFormato"                                            
                        label="Tipo de formato"
                        name="tipoFormato"
                        value={ tipoFormato }
                        onChange={ ( e ) => { setTipoFormato( e.target.value ); } }
                    >
                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                        {
                            tipoFormatoArray.map( ( element ) => (
                                <MenuItem key={ element.idTipoFormato } value={ element.idTipoFormato }>{ element.descripcion }</MenuItem>                                                        
                            ))
                        }                     
                        
                    </Select>

                </FormControl>
            </Grid> 

            <Grid item md={2} xs={12} >
                <TextField
                    fullWidth
                    label="Cantidad"
                    name="cantidad"   
                    variant="outlined"
                    type="number"
                    value={ cantidad }                    
                    onChange={ handleChangeCantidad }                                
                    inputProps={{
                        min: 1
                    }}
                />
            </Grid>  

            <Grid item md={2} xs={12} >
                <TextField
                    fullWidth
                    label="Número de fojas"
                    name="fojas"   
                    variant="outlined"
                    type="number"
                    value={ numeroFojas }
                    onChange={ handleChangeNumeroFojas }     
                    inputProps={{
                        min: 1
                    }}                                                        
                />
            </Grid>  

            <Grid item md={10} xs={12} >
                <TextField
                    fullWidth
                    label="Observaciones"
                    name="observaciones"
                    variant="outlined"        
                    multiline
                    rows={4}                                        
                    InputLabelProps={{
                        shrink: true
                    }}                               
                    value={ observaciones }
                    onChange={ ( e ) => { setObservaciones( e.target.value ); } }                                
                />
            </Grid>

            <Grid item md={2} xs={12} >
                <Button 
                    variant="contained"                    
                    startIcon={ <AddIcon /> }
                    fullWidth
                    disabled={ tipoAnexo === 0 || tipoFormato === 0 }
                    onClick={ handleClicAgregarAnexo }
                >
                    Agregar
                </Button>
            </Grid>   

            <Grid item md={12} xs={12} >

                <TableContainer style={{ marginTop: '10px' }}>

                    <Table>

                        <TableHead>

                            <TableRow>
                                <TableCell style={{ width:'20%' }}>TIPO DE ANEXO</TableCell>                              
                                <TableCell style={{ width:'20%' }}>TIPO DE FORMATO</TableCell>                              
                                <TableCell style={{ width:'10%' }}>CANTIDAD</TableCell>    
                                <TableCell style={{ width:'15%' }}>NÚMERO DE FOJAS</TableCell>   
                                <TableCell style={{ width:'25%' }}>OBSERVACIONES</TableCell>                              
                                <TableCell style={{ width:'10%' }}> </TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>       

                            {
                                (rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map( (row) => (
                            
                                    <TableRow key={ row.id }>
                                        <TableCell>{ row.tipoAnexo.descripcion }</TableCell>    
                                        <TableCell>{ row.tipoFormato.descripcion }</TableCell>    
                                        <TableCell>{ row.cantidad }</TableCell>    
                                        <TableCell>{ row.numeroFojas }</TableCell>     
                                        <TableCell>{ row.observaciones }</TableCell>                              
                                        <TableCell> 
                                            <IconButton color="primary" onClick={ () => { handleClicEliminarAnexo( row.id ); } }>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                
                                ))
                            }

                            {
                                rows.length === 0 && 
                                (
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            align="center"
                                            style={{ fontWeight: 'bold', fontSize: 14 }}
                                        >
                                            {
                                                'No se han agregado anexo(s)'
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[ ]}
                                    colSpan={6}
                                    count={rows.length}                                        
                                    rowsPerPage={rowsPerPage}
                                    page={page}                                                                                
                                    onPageChange={ handleChangePage }
                                    onRowsPerPageChange={ handleChangeRowsPerPage }
                                    ActionsComponent={ TablePaginationActions }
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                        return '' + from + '-' + to + ' de ' + count
                                        }
                                    }
                                />
                            </TableRow>
                        </TableFooter>

                    </Table>

                </TableContainer>

            </Grid> 

            {   
                errors.error && ( 
                    <Grid item md={12} xs={12} >

                        <Alert color='warning' severity="warning" variant="filled" > { errors.error }  </Alert> 

                    </Grid>
                )
            }

            <Grid item md={12} xs={12} sx={{ mt: 1 }}>  

                <Grid container spacing={3}>

                    <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>  
                        <LoadingButton 
                            variant="contained" 
                            color="inherit"
                            onClick={ Anterior }
                            startIcon={ <ArrowBackIcon /> }
                            loading={ loadingBack }
                        > 
                            Regresar 
                        </LoadingButton>
                    </Grid>

                    <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>  
                        <LoadingButton 
                            variant="contained" 
                            onClick={ validarInformacion }
                            endIcon={ <ArrowForwardIcon /> }
                            loading={ loadingNext }
                        > 
                            Siguiente 
                        </LoadingButton>
                    </Grid>                                        

                </Grid>

            </Grid>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea quitar el registro seleccionado?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>

                    <Button onClick={ handleCloseConfirmacion }> Cancelar </Button>
                    <Button onClick={ eliminarAnexo } variant='contained' > Aceptar </Button>
                    
                </DialogActions>

            </Dialog>

            <Dialog open={ openAvisoConfirmacion } onClose={ () => {} } >
                
                <DialogTitle>
                    Aviso de confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea generar el acuse de recibo con la información registrada?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>

                    <Button onClick={ handleCloseAvisoConfirmacion }> Cancelar </Button>
                    <LoadingButton 
                        onClick={ handleClicGenerarAcuse } 
                        variant='contained' 
                        loading={ loadingGenerarAcuse }
                    > 
                        Aceptar 
                    </LoadingButton>
                    
                </DialogActions>

            </Dialog>
               
        </>
    )
}
