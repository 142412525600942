import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Grid, Container } from '@mui/material';

import AppBarMenu from '../../ui/AppBarMenu';

import { UserInfoDetailScreen } from './UserInfoDetailScreen';
import { UserEditDetailScreen } from './UserEditDetailScreen';

import { ObtenerUsuario } from '../../../actions/admin/users/usuarioAction';

import { types } from '../../../types/types';

export const UserDetailScreen = (props) => {

    const { id } = props.match.params;   

    const [usuario, setUsuario] = useState( { } );

    const dispatch = useDispatch();

    useEffect(() => {        
        
        async function Obtener(){         

            dispatch( { type: types.openLoading } );
            
            await ObtenerUsuario( id ).then( response => {

                if(response){
                    if( response.status === 200){

                        setTimeout(() => {
                            
                            setUsuario(response.data);

                            dispatch( { type: types.closeLoading } );   

                        }, 1200);                        
                    }                    
                }
            });
        }

        Obtener();

    }, [ id, dispatch]);

    return (
        <>
            <AppBarMenu />
            
            <Box sx={{ marginTop: 15 }} >  
               
                <Container maxWidth="lg">

                    <Grid container spacing={3} >

                        <Grid item md={4} xs={12} >
                      
                            <UserInfoDetailScreen { ...usuario } />

                        </Grid>

                        <Grid item md={8} xs={12} >
                          
                            <UserEditDetailScreen idUsuario={ id } usuario={ usuario } />                   

                        </Grid>
                        
                    </Grid>

                </Container>

            </Box>
        </>
    )
}
