export const catalogsData = [
    { 
        id: 1, 
        descripcion: 'Asentamiento' , 
        path: '/Admin/Catalog/Asentamiento'
    },
    { 
        id: 2, 
        descripcion: 'Autoridad' , 
        path: '/Admin/Catalog/Autoridad'
    },
    { 
        id: 3, 
        descripcion: 'Autoridad del amparo' , 
        path: '/Admin/Catalog/AutoridadAmparo'
    },
    { 
        id: 4, 
        descripcion: 'Autoridad del exhorto' , 
        path: '/Admin/Catalog/AutoridadExhorto'
    },
    { 
        id: 5, 
        descripcion: 'Ciudad' , 
        path: '/Admin/Catalog/Ciudad'
    },
    { 
        id: 6, 
        descripcion: 'Clase de documento' , 
        path: '/Admin/Catalog/ClaseDocumento'
    },
    { 
        id: 7, 
        descripcion: 'Clase de exhorto' , 
        path: '/Admin/Catalog/ClaseExhorto'
    },
    { 
        id: 8, 
        descripcion: 'Dependencia' , 
        path: '/Admin/Catalog/Dependencia'
    },
    { 
        id: 9,
        descripcion: 'Distrito Judicial' , 
        path: '/Admin/Catalog/DistritoJudicial'
    },
    { 
        id: 10, 
        descripcion: 'Estado', 
        path: '/Admin/Catalog/Estado'
    },
    { 
        id: 11,
        descripcion: 'Estatus' , 
        path: '/Admin/Catalog/Estatus'
    },
    { 
        id: 12,
        descripcion: 'Etapa' , 
        path: '/Admin/Catalog/Etapa'
    },
    { 
        id: 13,
        descripcion: 'Forma de notificación' , 
        path: '/Admin/Catalog/FormaNotificacion'
    },
    { 
        id: 14,
        descripcion: 'Materia' , 
        path: '/Admin/Catalog/Materia'
    },
    { 
        id: 15,
        descripcion: 'Municipio' , 
        path: '/Admin/Catalog/Municipio'
    },
    { 
        id: 16,
        descripcion: 'Nacionalidad' , 
        path: '/Admin/Catalog/Nacionalidad'
    },
    { 
        id: 17,
        descripcion: 'Objeto de exhorto' , 
        path: '/Admin/Catalog/ObjetoExhorto'
    },
    { 
        id: 18,
        descripcion: 'Pais' , 
        path: '/Admin/Catalog/Pais'
    },
    { 
        id: 19,
        descripcion: 'Ponencia' , 
        path: '/Admin/Catalog/Ponencia'
    },
    { 
        id: 20,
        descripcion: 'Puesto' , 
        path: '/Admin/Catalog/Puesto'
    },
    { 
        id: 21,
        descripcion: 'Rol' , 
        path: '/Admin/Catalog/Rol'
    },
    { 
        id: 22,
        descripcion: 'Sentido del amparo' , 
        path: '/Admin/Catalog/SentidoAmparo'
    },
    { 
        id: 23,
        descripcion: 'Sentido de la sentencia' , 
        path: '/Admin/Catalog/SentidoSentencia'
    },
    { 
        id: 24,
        descripcion: 'Sexo' , 
        path: '/Admin/Catalog/Sexo'
    },
    { 
        id: 25,
        descripcion: 'Subtipo de acuerdo' , 
        path: '/Admin/Catalog/SubTipoAcuerdo'
    },
    { 
        id: 26,
        descripcion: 'Tipo de acuerdo' , 
        path: '/Admin/Catalog/TipoAcuerdo'
    },
    { 
        id: 27,
        descripcion: 'Tipo de amparo' , 
        path: '/Admin/Catalog/TipoAmparo'
    },
    { 
        id: 28,
        descripcion: 'Tipo de anexo' , 
        path: '/Admin/Catalog/TipoAnexo'
    },
    { 
        id: 29,
        descripcion: 'Tipo de asentamiento' , 
        path: '/Admin/Catalog/TipoAsentamiento'
    },
    { 
        id: 30,
        descripcion: 'Tipo de audiencia' , 
        path: '/Admin/Catalog/TipoAudiencia'
    },
    { 
        id: 31,
        descripcion: 'Tipo de calificacion' , 
        path: '/Admin/Catalog/TipoCalificacion'
    },
    { 
        id: 32,
        descripcion: 'Tipo de causa de diferimiento' , 
        path: '/Admin/Catalog/TipoCausaDiferimiento'
    },
    { 
        id: 33,
        descripcion: 'Tipo de consecutivo' , 
        path: '/Admin/Catalog/TipoConsecutivo'
    },
    { 
        id: 34,
        descripcion: 'Tipo de convenio' , 
        path: '/Admin/Catalog/TipoConvenio'
    },
    { 
        id: 35,
        descripcion: 'Tipo de dependencia' , 
        path: '/Admin/Catalog/TipoDependencia'
    },
    { 
        id: 36,
        descripcion: 'Tipo de domicilio' , 
        path: '/Admin/Catalog/TipoDomicilio'
    },
    { 
        id: 37,
        descripcion: 'Tipo de exhorto' , 
        path: '/Admin/Catalog/TipoExhorto'
    },
    { 
        id: 38,
        descripcion: 'Tipo de formato' , 
        path: '/Admin/Catalog/TipoFormato'
    },
    { 
        id: 39,
        descripcion: 'Tipo de identificacion' , 
        path: '/Admin/Catalog/TipoIdentificacion'
    },
    { 
        id: 40,
        descripcion: 'Tipo de irregular' , 
        path: '/Admin/Catalog/TipoIrregular'
    },
    { 
        id: 41,
        descripcion: 'Tipo de juicio' , 
        path: '/Admin/Catalog/TipoJuicio'
    },
    { 
        id: 42,
        descripcion: 'Tipo de movimiento' , 
        path: '/Admin/Catalog/TipoMovimiento'
    },
    { 
        id: 43,
        descripcion: 'Tipo de no celebración' , 
        path: '/Admin/Catalog/TipoNoCelebracion'
    },
    { 
        id: 44,
        descripcion: 'Tipo de notificación' , 
        path: '/Admin/Catalog/TipoNotificacion'
    },
    { 
        id: 45,
        descripcion: 'Tipo de parte' , 
        path: '/Admin/Catalog/TipoParte'
    },
    { 
        id: 46,
        descripcion: 'Tipo de persona' , 
        path: '/Admin/Catalog/TipoPersona'
    },
    { 
        id: 47,
        descripcion: 'Tipo de prestacion' , 
        path: '/Admin/Catalog/TipoPrestacion'
    },
    { 
        id: 48,
        descripcion: 'Tipo de promoción' , 
        path: '/Admin/Catalog/TipoPromocion'
    },
    { 
        id: 49,
        descripcion: 'Tipo de sesion' , 
        path: '/Admin/Catalog/TipoSesion'
    },
    { 
        id: 50,
        descripcion: 'Tipo de trámite' , 
        path: '/Admin/Catalog/TipoTramite'
    },
    { 
        id: 51,
        descripcion: 'Tipo de usuario' , 
        path: '/Admin/Catalog/TipoUsuario'
    },
    { 
        id: 52,
        descripcion: 'Zona' , 
        path: '/Admin/Catalog/Zona'
    },
    { 
        id: 54,
        descripcion: 'Sub etapa' , 
        path: '/Admin/Catalog/SubEtapa'
    },
    { 
        id: 55,
        descripcion: 'Proceso' , 
        path: '/Admin/Catalog/Proceso'
    },
    { 
        id: 56,
        descripcion: 'Tipo de documento' , 
        path: '/Admin/Catalog/TipoDocumento'
    },
    { 
        id: 57,
        descripcion: 'Tipo de operación' , 
        path: '/Admin/Catalog/TipoOperacion'
    },
    { 
        id: 58,
        descripcion: 'Tipo de oficio' , 
        path: '/Admin/Catalog/TipoOficio'
    },
    { 
        id: 59,
        descripcion: 'Autoridad Exhorto Librado' , 
        path: '/Admin/Catalog/AutoridadExhortoLibrado'
    },
    { 
        id: 60,
        descripcion: 'Tipo de Prestación Juicio' , 
        path: '/Admin/Catalog/TipoPrestacionJuicio'
    },
    { 
        id: 61,
        descripcion: 'Tipo acreditado' , 
        path: '/Admin/Catalog/TipoAcreditado'
    },
];