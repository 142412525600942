import React, { useState } from 'react';

import { useHistory } from 'react-router';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';

import { GenerarListadoEnvio } from '../../actions/notificaciones/listadoEnvioActions';

export const ModalGenerarListadoEnvio = ( { openModal, handleCloseModal } ) => {

    const history = useHistory();

    const [loadingGuardar, setLoadingGuardar] = useState(false);

    const [tipo, setTipo] = useState( '' );

    const [errors, setErrors] = useState( { } );

    const cerrarModal = () => {

        handleCloseModal();

        setTipo( '' );

        setErrors( { } );
    }

    const handleGenerarLista = async () => {

        setLoadingGuardar( true );
                  
        if( tipo === 'NuevaLista' ){
        
            await GenerarListadoEnvio( ).then( response => {

                if( response ){

                    if( response.status === 200){

                        if(response.data){
                            
                            const { idListadoEnvio } = response.data;

                            setTimeout(() => {

                                setLoadingGuardar(false); 

                                history.push("/Notificacion/ListadoEnvio/Generar/" + idListadoEnvio);

                            }, 1200);  
                        }

                    } 
                    else {
                        setTimeout(() => {                      
                            
                            const { success, message } = response.data;
                           
                            setLoadingGuardar(false);

                            let errores = {};  
                            
                            errores.error = message ? message : 'Ocurrió un error, contacte con el administrador ';

                            if( message ){
                                errores.type = !success ? 'warning' : 'error';
                            }
                            else{
                                errores.type = 'error';
                            }                            

                            setErrors( errores );

                        }, 1200);  
                    }
                }
            }); 
        
        }
        else if( tipo === 'ConsultarLista' ){

            setTimeout(() => {

                setLoadingGuardar(false); 

                history.push("/Notificacion/ListadoEnvio/Consultar/");

            }, 1200);  

        }

    }
        
    return (
        <>
            <Dialog open={ openModal } maxWidth='md' fullWidth={true}>
                <DialogTitle>Generar Listado de envío</DialogTitle>
            
                <DialogContent>

                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12} >

                            <FormControl component="fieldset">
                                <RadioGroup 
                                    row
                                    aria-label="tipo"
                                    defaultValue=""
                                    name="radio-buttons-group"  
                                >
                                    <FormControlLabel value="NuevaLista" control={ <Radio checked={ tipo === 'NuevaLista' } onChange={ () => { setTipo('NuevaLista'); } } />} label="Nuevo listado de envío" />
                                    <FormControlLabel value="ConsultarLista" control={ <Radio checked={ tipo === 'ConsultarLista' } onChange={ () => { setTipo('ConsultarLista'); } } />} label="Consultar listados de envío" />
                           
                                </RadioGroup>
                            </FormControl>

                        </Grid>

                        {   
                            errors.error && ( 

                                <Grid item md={12} xs={12} >                                    
                                    <Alert color={ errors.type } severity={ errors.type } variant="filled" > { errors.error  } </Alert>                                    
                                </Grid>

                            )
                        }    

                    </Grid>               

                </DialogContent>

                <DialogActions>
                    <Button variant="text" color="primary" onClick={ cerrarModal }>Cancelar</Button>
                    <Button variant="contained" color="primary" startIcon={ <SaveIcon /> } onClick={ handleGenerarLista } disabled={ loadingGuardar || tipo === '' } >Guardar</Button>
                </DialogActions>

                <LinearProgress sx={{ display: loadingGuardar ? 'block' : 'none', height: 5 }}></LinearProgress>

            </Dialog>

        </>
    )
}
