import React, { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, Dialog,  DialogActions, DialogContent, Grid, List, ListItem, Typography } from '@mui/material';

import { ExpedienteDetalle } from '../../actions/recepcion/expedienteActions';

export const ModalDetalleExpediente = ( { open, setOpen, idExpediente } ) => {

    const [loading, setLoading] = useState( false );
    const [expediente, setExpediente] = useState( { } );

    useEffect(() => {   
        
        async function Obtener(){
            
            setLoading( true );

            await ExpedienteDetalle( idExpediente ).then( response => {

                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setExpediente( response.data );
                            setLoading( false );

                        }, 500);
                        
                    }

                }

            });
        }
        
        if( open ){
            Obtener();
        }

    }, [ open, idExpediente ])      

    return (
        <>
            <Dialog open={ open } maxWidth='md' fullWidth >
              
                <DialogContent>

                    <Grid item md={12} xs={12} >
                        
                        {
                            loading
                            ?
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                    <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                                </Box>
                            :
                                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Número de expediente:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { expediente?.numeroExpediente }
                                            </Typography>
                                        </Grid>
                                    </Grid>  

                                    {
                                        expediente?.ponencia
                                        &&   
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item md={4} xs={4}>
                                                <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                    Ponencia:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={8} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { expediente?.ponencia }
                                                </Typography>
                                            </Grid>
                                        </Grid>    
                                    }

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                Tipo de juicio:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={8} xs={8} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                { expediente?.tipoJuicio }
                                            </Typography>
                                        </Grid>
                                    </Grid>   

                                    {
                                        expediente?.tipoCalificacion
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item md={4} xs={4}>
                                                <Typography gutterBottom variant="subtitle2" sx={{ fontWeight: 'Bold' }} >
                                                    Tipo de calificación:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={8} xs={8} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                                    { expediente?.tipoCalificacion }
                                                </Typography>
                                            </Grid>
                                        </Grid> 
                                    }                                      

                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                        <Grid item md={4} xs={4}>

                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                Actor(es):
                                            </Typography>

                                        </Grid>

                                        <Grid item md={8} xs={8} >
                                            <List disablePadding>
                                                {
                                                    expediente?.actor?.map( ( element, i  ) => (                                    
                                                        <ListItem key={ i } disablePadding>                              
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                { element }
                                                            </Typography>                               
                                                        </ListItem>
                                                    ))
                                                }                       
                                            </List>

                                        </Grid>

                                    </Grid>

                                    <Grid container sx={{ mt: 1, mb: 1 }}>

                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                Demandado(s):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={8} xs={8} >
                                            <List 
                                                disablePadding 
                                                sx={{ maxHeight: expediente?.demandado?.length > 10 ? 200 : null, overflow: expediente?.demandado?.length > 10 ? 'scroll' : null }}
                                            >  
                                                {
                                                    expediente?.demandado?.map( ( element, i  ) => (                                    
                                                        <ListItem key={ i } disablePadding>                              
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                { element }
                                                            </Typography>                               
                                                        </ListItem>
                                                    ))
                                                }                             
                                            </List>
                                        </Grid>

                                    </Grid>   

                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                        <Grid item md={4} xs={4}>
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                Prestacion(es):
                                            </Typography>
                                        </Grid>

                                        <Grid item md={8} xs={8} >
                                            <List disablePadding>
                                                {
                                                    expediente?.prestaciones?.map( ( element ) => (                                    
                                                        <ListItem key={ element.idTipoPrestacion } disablePadding>                    
                                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal' }}>
                                                                { element.descripcion }
                                                            </Typography>                                
                                                        </ListItem>
                                                    ))
                                                }  
                                            </List>
                                        </Grid>
                                    </Grid>  

                                    {
                                        expediente?.demanda
                                        &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid item mmd={12} xs={12} >
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                                    Demanda escaneada:
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={12} md={12} xs={12}>
                                                                
                                                <iframe 
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }} 
                                                    src={ expediente?.demanda }                                
                                                />                                           

                                            </Grid>       
                                        </Grid>   
                                    }         

                                </Box>
                        }
                          
                    </Grid> 

                </DialogContent>              
              
                <DialogActions>
                    <Button onClick={ () => { setOpen( false ); setExpediente({ }); } } color="primary">
                        Cerrar
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
