import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import AppBarMenu from '../../ui/AppBarMenu';

import { Alert, Box, Container, FormControl, Grid, InputBase, InputLabel, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import moment from 'moment';

import { types } from '../../../types/types';

import { TablePaginationActions } from '../../../helpers/TablePaginationActions';

import { usePages } from '../../../hooks/usePages';

import { ObtenerTipoJuicio } from '../../../actions/admin/catalogs/tipojuicioActions';
import { BuscarExhortoAnterior, GuardarExhortoAnterior } from '../../../actions/recepcion/exhortoRecibidoActions';
import { BuscarUsuarioJuez, BuscarUsuarioSecretario } from '../../../actions/admin/users/usuarioAction';
import { ObtenerEtapas } from '../../../actions/admin/catalogs/etapaActions';
import { ObtenerAutoridadesExhorto } from '../../../actions/admin/catalogs/autoridadExhortoActions';
import { ObtenerObjetoExhortos } from '../../../actions/admin/catalogs/objetoExhortoActions';
import { ObtenerClaseExhortos } from '../../../actions/admin/catalogs/claseExhortoActions';

export const GestionarExhortoAnteriorScreen = () => {

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({  });
    const [alert, setAlert] = useState( {  } );

    const [loadingGuardar, setLoadingGuardar] = useState( false );

    const [numeroExhorto, setNumeroExhorto] = useState('');
    const [exhorto, setExhorto] = useState( {} );

    const [tipoJuicio, setTipoJuicio] = useState( 0 );
    const [tipoJuicioArray, setTipoJuicioArray] = useState( [ ] );

    const [juez, setJuez] = useState( 0 );
    const [juezArray, setJuezArray] = useState( [ ] );

    const [secretario, setSecretario] = useState( 0 );
    const [secretarioArray, setSecretarioArray] = useState( [ ] );

    const [etapa, setEtapa] = useState(0);
    const [etapaArray, setEtapaArray] = useState( [] );

    const [autoridadExhorto, setAutoridadExhorto] = useState( 0 );
    const [autoridadExhortoArray, setAutoridadExhortoArray] = useState( [ ] );
    
    const [objetoExhorto, setObjetoExhorto] = useState(0);
    const [objetoExhortoArray, setObjetoExhortoArray] = useState( [ ] );

    const [claseExhorto, setClaseExhorto] = useState(0);
    const [claseExhortoArray, setClaseExhortoArray] = useState( [ ] ) ;

    const [rows, setRows] = useState( [ ] );

    const [ExhortoEncontrado, setExhortoEncontrado] = useState( false );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleCleanFields = () => {

        setTipoJuicio( 0 );
        setJuez( 0 );
        setSecretario( 0 );

        setEtapa( 0 );

        setExhorto( {} );
        setRows( [] );

        setErrors( {} );
    }

    const validateFiels = () => {

        setErrors( { } );

        let valid = true;
        let errores = {};    

        if( tipoJuicio === 0){           
            valid = false;
            errores.tipoJuicio = 'Debes seleccionar un tipo juicio';
        } 

        if( juez === 0){           
            valid = false;
            errores.juez = 'Debes seleccionar un juez';
        } 

        if( secretario === 0){           
            valid = false;
            errores.secretario = 'Debes seleccionar un secretario';
        } 

        if( etapa === 0){           
            valid = false;
            errores.etapa = 'Debes seleccionar una etapa';
        }   

        if( autoridadExhorto === 0 ){
            valid = false;
            errores.autoridadExhorto = 'Debes seleccionar una autoridad del exhorto';
        }

        setErrors( errores );

        return valid;
    }

    const errorExhorto = () => {
       
        setErrors( { } );

        let errores = {};  
            errores.numeroExhorto = 'No se encontró el exhorto';
            errores.type = 'warning';
        
        setExhorto( {} );
        setErrors( errores );

        setNumeroExhorto( '' );
    }

    const handleBuscarExhorto = async () => {     

        dispatch({ type: types.openLoading });

        handleCleanFields();

        await BuscarExhortoAnterior({ numeroExhorto: numeroExhorto }).then( response => {

            if( response ){
               
                if( response.status === 200){

                    setTimeout(() => {

                        if( response.data ){

                            if( response.data.success ){

                                setExhorto( response.data );
                                setRows( response?.data?.partes ?? [] );

                                setExhortoEncontrado( response?.data?.encontrado ?? false );
                            }
                            else{
                                errorExhorto();
                            }

                            dispatch({ type: types.closeLoading });
                            setNumeroExhorto('');
                        }

                    }, 600);  

                    
                } 
                else {

                    setTimeout(() => {

                        dispatch({ type: types.closeLoading });
                        setNumeroExhorto('');

                    }, 600);  

                }
            }

        });

    }   

    const handleGuardarExhorto = async () => {

        const valid = validateFiels();

        if ( !valid ) return;

        setLoadingGuardar( true );      

        const params = {
           idTipoJuicio: tipoJuicio,
           idJuez: juez,   
           idSecretario: secretario,     
           idEtapa: etapa,
           idAutoridadExhorto: autoridadExhorto,   
           idObjetoExhorto: objetoExhorto,
           idClaseExhorto: claseExhorto,   
           numeroFolio: exhorto?.folio,
           numeroExhorto: exhorto?.numeroExhorto, 
           numeroOrigen: exhorto?.numeroOrigen, 
           fechaRadicacion: exhorto?.fechaRadicacion,
           partes: exhorto?.partes,
        }

        await GuardarExhortoAnterior( params ).then( resp => {

            if( resp ){

                if( resp.status === 200 ){

                    const { success, message } = resp.data;

                    if( success ){

                        setTimeout(() => {
            
                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } ); 

                            setLoadingGuardar( false );
                            setExhortoEncontrado( true );
                
                        }, 400);

                    }
                    else {

                        setTimeout(() => {

                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } ); 
            
                            setLoadingGuardar( false );
                
                        }, 400);

                    }

                }

            }

        });

    }

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerTipoJuicio().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoJuicioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioJuez( ).then( response => {

                if( response ){

                    if( response.data ){
                        setJuezArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);    

    useEffect(() => {
      
        async function Obtener(){

            await BuscarUsuarioSecretario( ).then( response => {

                if( response ){

                    if( response.data ){
                        setSecretarioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);   

    useEffect(() => {

        async function Obtener(){    

            await ObtenerEtapas().then( response => {

                if( response ){

                    if( response.status === 200){

                        const etapas = response.data;

                        setEtapaArray( etapas.filter( (e) => e.idEtapa === 1 || e.idEtapa === 7 || e.idEtapa === 8 || e.idEtapa === 12 || e.idEtapa === 15 ) );

                    }
                }

            });

        }
      
        Obtener();
        
    }, [ ]);

    useEffect(() => {
      
        async function Obtener(){

            await ObtenerAutoridadesExhorto().then( response => {

                if( response ){

                    if( response.data ){
                        setAutoridadExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ]);  

    useEffect(() => {      
        
        async function Obtener(){

            await ObtenerObjetoExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setObjetoExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {      

        async function Obtener(){

            await ObtenerClaseExhortos().then( response => {

                if( response ){

                    if( response.data ){
                        setClaseExhortoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])

    useEffect(() => {

        if( exhorto?.idTipoJuicio !== 0 && tipoJuicioArray.length !== 0 ){

            setTipoJuicio( exhorto?.idTipoJuicio ?? 0 );

        }
        
    }, [ exhorto, tipoJuicioArray ]);         

    useEffect(() => {

        if( exhorto?.idJuez !== 0 && juezArray.length !== 0 ){

            setJuez( exhorto?.idJuez ?? 0 );

        }
        
    }, [ exhorto, juezArray ]);       

    useEffect(() => {

        if( exhorto?.idSecretario !== 0 && secretarioArray.length !== 0 ){

            setSecretario( exhorto?.idSecretario ?? 0 );

        }
        
    }, [ exhorto, secretarioArray ]);      

    useEffect(() => {

        if( exhorto?.idEtapa !== 0 && etapaArray.length !== 0 ){

            setEtapa( exhorto?.idEtapa ?? 0 );

        }
        
    }, [ exhorto, etapaArray ]);      

    useEffect(() => {

        if( exhorto?.idAutoridadExhorto !== 0 && autoridadExhortoArray.length !== 0 ){

            setAutoridadExhorto( exhorto?.idAutoridadExhorto ?? 0 );

        }
        
    }, [ exhorto, autoridadExhortoArray ]);    

    useEffect(() => {

        if( exhorto?.idClaseExhorto !== 0 && claseExhortoArray.length !== 0 ){

            setClaseExhorto( exhorto?.idClaseExhorto ?? 0 );

        }
        
    }, [ exhorto, claseExhortoArray ]);    

    useEffect(() => {

        if( exhorto?.idObjetoExhorto !== 0 && objetoExhortoArray.length !== 0 ){

            setObjetoExhorto( exhorto?.idObjetoExhorto ?? 0 );

        }
        
    }, [ exhorto, objetoExhortoArray ]);    

    return (
        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
        
            <AppBarMenu />

            <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>   

                <Container maxWidth="lg" sx={{ pb: 3 }}> 

                    <Paper component="div" sx={{ p: '10px 2px', display: 'flex', alignItems: 'center', width: '100%' }} >                            
                        
                        <InputBase 
                            sx={{ ml: 1, flex: 1 }} 
                            inputProps={{ 'aria-label': 'Buscar Exhorto' }} 
                            placeholder="Buscar por número de Exhorto"     
                            name="Exhorto"
                            value={ numeroExhorto }                                      
                            onChange={ ( e ) => { setNumeroExhorto( e.target.value ); } }
                            autoComplete="off"
                        /> 

                        <LoadingButton 
                            variant='contained' 
                            sx={{ borderRadius: 10 }} 
                            onClick={ handleBuscarExhorto }
                            size='small'
                            disabled={ numeroExhorto === '' }
                        >
                            <SearchIcon />
                        </LoadingButton>   

                    </Paper>

                    {   
                        errors.numeroExhorto && ( <Alert color={ errors.type } severity={ errors.type } variant="filled" style={{ marginTop: '1em' }} > { errors.numeroExhorto  } </Alert> )
                    }

                </Container>    

                <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>   

                    <Grid container sx={{ mt: 1, mb: 1 }} spacing={3}>                       
                       
                        <Grid item md={2} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de folio:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto?.folio }
                            </Typography>
                        </Grid>     
                      
                        <Grid item md={2} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de origen:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto?.numeroOrigen }
                            </Typography>
                        </Grid>    

                        <Grid item md={2} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Número de Exhorto:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto?.numeroExhorto }
                            </Typography>
                        </Grid>    

                        {
                            ( ExhortoEncontrado || !exhorto.numeroExhorto )
                            &&
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                        Tipo de juicio:
                                    </Typography>
                                </Grid>
                        }          

                        <Grid item md={ ( ExhortoEncontrado || !exhorto.numeroExhorto ) ? 4 : 6 } xs={6} > 
                            {
                                ( ExhortoEncontrado || !exhorto.numeroExhorto )
                                ?
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                        { tipoJuicioArray.find( (e) => e.idTipoJuicio === exhorto.idTipoJuicio )?.descripcion ?? '' }
                                    </Typography> 
                                :
                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-tipoJuicio-label">Tipo de juicio</InputLabel>
                                        
                                            <Select
                                                labelId="select-tipoJuicio-label"
                                                id="select-tipoJuicio"                                            
                                                label="Tipo de juicio"
                                                name="tipoJuicio"
                                                value={ tipoJuicio }
                                                onChange={ (e) => setTipoJuicio( e.target.value ) }
                                            >
                                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                                {                          
                                                    tipoJuicioArray
                                                    .filter( (element ) => { return element.juzgado === true; } )
                                                    .map( ( element ) => (
                                                        <MenuItem key={ element.idTipoJuicio } value={ element.idTipoJuicio }>{ element.descripcion }</MenuItem>                        
                                                    ))
                                                    
                                                }
                                            </Select>

                                        </FormControl>
                                        {   
                                            errors.tipoJuicio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuicio } </Alert> )
                                        }
                                    </>
                            }
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Fecha de radicación:
                            </Typography>
                        </Grid>

                        <Grid item md={10} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto.fechaRadicacion ? moment( exhorto.fechaRadicacion ).format('YYYY-MM-DD') : '' }
                            </Typography>                                                
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Presentante:
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto.presentante }
                            </Typography>                                                
                        </Grid>  

                        <Grid item md={2} xs={6}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Fecha de presentación:
                            </Typography>
                        </Grid>
                        
                        <Grid item md={4} xs={6} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto.fechaPresentacion ? moment( exhorto.fechaPresentacion ).format('YYYY-MM-DD hh:mm a') : '' }
                            </Typography>                                                
                        </Grid>                   

                        <Grid item md={2} xs={12}>
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'bold' }} >
                                Sintesís:
                            </Typography>
                        </Grid>

                        <Grid item md={10} xs={12} >                        
                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal'  }}>
                                { exhorto.sintesis }
                            </Typography>                                                
                        </Grid> 

                        <Grid item md={6} xs={12} >

                            <FormControl fullWidth>
                                <InputLabel id="select-juez-label">Juez</InputLabel>

                                <Select
                                    labelId="select-juez-label"
                                    id="select-juez"                                            
                                    label="Juez"
                                    name="juez"
                                    value={ juez }                                
                                    onChange={ ( e ) => setJuez( e.target.value ) }                 
                                    disabled={ ExhortoEncontrado || !exhorto?.numeroExhorto }                          
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                    {
                                        juezArray.map( ( elem ) => (
                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                        ))
                                    }                                          
                                </Select>

                                {   
                                    errors.juez && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.juez } </Alert> )
                                }

                            </FormControl>

                        </Grid>

                        <Grid item md={6} xs={12} >

                            <FormControl fullWidth>
                                <InputLabel id="select-secretario-label">Secretario</InputLabel>

                                <Select
                                    labelId="select-secretario-label"
                                    id="select-secretario"                                            
                                    label="Secretario"
                                    name="secretario"
                                    value={ secretario }                                
                                    onChange={ ( e ) => setSecretario( e.target.value ) }         
                                    disabled={ ExhortoEncontrado || !exhorto?.numeroExhorto }                            
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>     
                                    {
                                        secretarioArray.map( ( elem ) => (
                                            <MenuItem key={ elem.id } value={ elem.id }> { elem.nombreCompleto } </MenuItem>    
                                        ))
                                    }                                          
                                </Select>

                                {   
                                    errors.secretario && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.secretario } </Alert> )
                                }

                            </FormControl>

                        </Grid>

                        <Grid item md={6} xs={12} >                            
                            <FormControl fullWidth>
                                <InputLabel id="select-etapa-label">Etapa</InputLabel>
                            
                                <Select
                                    labelId="select-etapa-label"
                                    id="select-etapa"                                            
                                    label="Etapa"
                                    name="etapa"
                                    value={ etapa }
                                    onChange={ ( e ) => { setEtapa( e.target.value ); } }
                                    disabled={ ExhortoEncontrado || !exhorto?.numeroExhorto }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    
                                    {                                                           
                                        etapaArray                                                            
                                        .map((elem)=>(
                                            <MenuItem key={elem.idEtapa} value={ elem.idEtapa }>{ elem.descripcion }</MenuItem>
                                        ))
                                    }                          
                                    
                                </Select>

                            </FormControl>
                            {   
                                errors.etapa && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.etapa } </Alert> )
                            }
                        </Grid>  

                        <Grid item md={6} xs={12} >  

                            <FormControl fullWidth>
                                <InputLabel id="select-autoridadExhorto-label">Autoridad del exhorto</InputLabel>
                            
                                <Select
                                    labelId="select-autoridadExhorto-label"
                                    id="select-autoridadExhorto"                                            
                                    label="Autoridad del exhorto"
                                    name="autoridadExhorto"
                                    value={ autoridadExhorto }
                                    onChange={ ( e ) => { setAutoridadExhorto( e.target.value ); } }
                                    disabled={ ExhortoEncontrado || !exhorto?.numeroExhorto }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>                        
                                    {
                                        autoridadExhortoArray.map( ( element ) => (
                                            <MenuItem key={ element.idAutoridadExhorto } value={ element.idAutoridadExhorto }>{ element.descripcion }</MenuItem>                        
                                        ))
                                    }
                                </Select>
                                {   
                                    errors.autoridadExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.autoridadExhorto } </Alert> )
                                }
                            </FormControl>

                        </Grid>    

                        <Grid item md={6} xs={12} > 

                            <FormControl fullWidth>
                                <InputLabel id="select-claseExhorto-label">Clase del exhorto</InputLabel>
                            
                                <Select
                                    labelId="select-claseExhorto-label"
                                    id="select-claseExhorto"                                            
                                    label="Clase del exhorto"
                                    name="claseExhorto"
                                    value={ claseExhorto }
                                    onChange={(e) => { setClaseExhorto(e.target.value)}  }     
                                    disabled={ ExhortoEncontrado || !exhorto?.numeroExhorto }                                         
                                >
                                <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        claseExhortoArray.map((elem)=>(
                                            <MenuItem key={elem.idClaseExhorto} value={ elem.idClaseExhorto }>{ elem.descripcion }</MenuItem>
                                        ))
                                    }

                                </Select>
                                {   
                                    errors.claseExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.claseExhorto } </Alert> )
                                }
                            </FormControl>

                        </Grid>  

                        <Grid item md={6} xs={12} >    

                            <FormControl fullWidth>
                                <InputLabel id="select-objetoExhorto-label">Objeto del exhorto</InputLabel>
                            
                                <Select
                                    labelId="select-objetoExhorto-label"
                                    id="select-objetoExhorto"                                            
                                    label="Objeto del exhorto"
                                    name="objetoExhorto"
                                    value={ objetoExhorto }
                                    onChange={(e) => {setObjetoExhorto(e.target.value)}  }       
                                    disabled={ ExhortoEncontrado || !exhorto?.numeroExhorto }                                        
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        objetoExhortoArray.map((elem)=>(
                                            <MenuItem key={elem.idObjetoExhorto} value={ elem.idObjetoExhorto }>{ elem.descripcion }</MenuItem>
                                        ))
                                    }
                                </Select>
                                {   
                                    errors.objetoExhorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.objetoExhorto } </Alert> )
                                }
                            </FormControl>

                        </Grid>                     

                        <Grid item xs={12} >  

                            <TableContainer style={{ marginTop: '10px' }}>

                                <Table>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell style={{ width:'50%', fontWeight: 'bold' }}>NOMBRE</TableCell>
                                            <TableCell style={{ width:'30%', fontWeight: 'bold' }}>TIPO DE PARTE</TableCell>                                
                                            <TableCell style={{ width:'20%', fontWeight: 'bold' }}>PERSONA MORAL </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>

                                    {
                                        (   rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map( ( row, index ) => (

                                        <TableRow key={ index } >
                                            <TableCell> { row.nombre } { row.apellidoPaterno } { row.apellidoMaterno } </TableCell>
                                            <TableCell> { row.tipo } </TableCell>
                                            <TableCell> { row.personaMoral === 1 ? 'Si' : 'No' } </TableCell>
                                        </TableRow>

                                        ))
                                    }

                                    {
                                        rows.length === 0 && 
                                        (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={6}
                                                    align="center"
                                                    style={{ fontWeight: 'bold', fontSize: 14 }}
                                                >
                                                    {
                                                        'No se existen parte(s)'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                    </TableBody>

                                    <TableFooter>

                                        <TableRow>

                                            <TablePagination
                                                rowsPerPageOptions={[ ]}
                                                colSpan={3}
                                                count={rows.length}                                        
                                                rowsPerPage={rowsPerPage}
                                                page={page}                                                                                
                                                onPageChange={ handleChangePage }
                                                onRowsPerPageChange={ handleChangeRowsPerPage }
                                                ActionsComponent={ TablePaginationActions }
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => {
                                                    return '' + from + '-' + to + ' de ' + count
                                                    }
                                                }
                                            />

                                        </TableRow>

                                    </TableFooter>

                                </Table>

                            </TableContainer>     

                        </Grid>

                        {
                            !ExhortoEncontrado
                            &&
                                <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                                                
                                    <LoadingButton
                                        variant="contained" 
                                        onClick={ handleGuardarExhorto } 
                                        startIcon={ <SaveIcon /> }
                                        loading={ loadingGuardar }
                                        disabled={ !exhorto?.numeroExhorto }
                                    > 
                                        Guardar Exhorto
                                    </LoadingButton>

                                </Grid>   
                        }
                      
                    </Grid>  

                </Container>

            </Box>        
        
        </>
    )
}
