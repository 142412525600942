import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import AddBoxIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SendIcon from '@mui/icons-material/Send';
import CreateIcon from '@mui/icons-material/Create';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FolderIcon from '@mui/icons-material/Folder';
import ReorderIcon from '@mui/icons-material/Reorder';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import useStyles from '../../../theme/useStyles';

import '../../../css/index.css';

import { ModalNuevaRecepcion } from '../../modals/ModalNuevaRecepcion';
import { ModalNuevoAcuerdo } from '../../modals/ModalNuevoAcuerdo';
import { ModalNuevaNotificacion } from '../../modals/ModalNuevaNotificacion';
import { ModalCelebrarAudiencia } from '../../modals/ModalCelebrarAudiencia';
import { ModalNuevoProyectoSentencia } from '../../modals/ModalNuevoProyectoSentencia';
import { ModalNuevaSentencia } from '../../modals/ModalNuevaSentencia';
import { ModalNuevaSentenciaPublica } from '../../modals/ModalNuevaSentenciaPublica';
import { ModalNuevoOficio } from '../../modals/ModalNuevoOficio';
import { ModalNuevoRegistroValor } from '../../modals/ModalNuevoRegistroValor';
import { ModalNuevoIrregular } from '../../modals/ModalNuevoIrregular';
import { ModalNuevoExhortoLibrado } from '../../modals/ModalNuevoExhortoLibrado';
import { ModalNuevoExpedienteConsultado } from '../../modals/ModalNuevoExpedienteConsultado';
import { ModalNuevaListaAcuerdos } from '../../modals/ModalNuevaListaAcuerdos';
import { ModalNuevaSentenciaCorta } from '../../modals/ModalNuevaSentenciaCorta';
import { ModalNuevaConvocatoria } from '../../modals/ModalNuevaConvocatoria';
import { ModalGenerarListadoEnvio } from "../../modals/ModalGenerarListadoEnvio";

import { types } from '../../../types/types';

export const ListItemNew = () => {

    const { roles } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    
    const classes = useStyles();
    
    const [anchorEl, anchorElOpen] = useState(null);

    const [openModalNuevaRecepcion, setOpenModalNuevaRecepcion] = useState(false);
    const [openModalNuevoAcuerdo, setOpenModalNuevoAcuerdo] = useState(false);
    const [openModalNuevaNotificacion, setOpenModalNuevaNotificacion] = useState(false);
    const [openModalCelebrarAudiencia, setOpenModalCelebrarAudiencia] = useState(false);

    const [openModalNuevoProyectoSentencia, setOpenModalNuevoProyectoSentencia] = useState(false);
    const [openModalNuevaSentencia, setOpenModalNuevaSentencia] = useState(false);
    const [openModalNuevaSentenciaPublica, setOpenModalNuevaSentenciaPublica] = useState(false);
    const [openModalNuevaSentenciaCorta, setOpenModalNuevaSentenciaCorta] = useState(false);

    const [openModalNuevoOficio, setOpenModalNuevoOficio] = useState(false);
    const [openModalNuevoRegistroValor, setOpenModalNuevoRegistroValor] = useState(false);

    const [openModalNuevoIrregular, setOpenModalNuevoIrregular] = useState(false);
    const [openModalNuevoExhortoLibrado, setOpenModalNuevoExhortoLibrado] = useState(false);

    const [openModalNuevoExpedienteConsultado, setOpenModalNuevoExpedienteConsultado] = useState(false);

    const [openModalNuevaListaAcuerdos, setOpenModalNuevaListaAcuerdos] = useState(false);

    const [openModalNuevaConvocatoria, setOpenModalNuevaConvocatoria] = useState(false);

    const [openModalGenerarListadoEnvio, setOpenModalGenerarListadoEnvio] = useState(false);
  
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        anchorElOpen( e.currentTarget );
    };
  
    const handleClose = () => {
        anchorElOpen( null );
    };

    const limpiar = () =>{        
        dispatch({ type: types.audienciaClear });            
    }
   
    const handleOpenModalNuevaRecepcion = () => {       
        handleClose();
        setOpenModalNuevaRecepcion(true);
    };

    const handleCloseModalNuevaRecepcion = () => {
        setOpenModalNuevaRecepcion(false);
    };

    const handleOpenModalNuevoAcuerdo = () => {       
        handleClose();
        setOpenModalNuevoAcuerdo(true);
    };

    const handleCloseModalNuevoAcuerdo = () => {
        setOpenModalNuevoAcuerdo(false);
    };

    const handleOpenModalNuevaNotificacion = () => {       
        handleClose();
        setOpenModalNuevaNotificacion(true);
    };

    const handleCloseModalNuevaNotificacion = () => {
        setOpenModalNuevaNotificacion(false);
    };

    const handleOpenModalCelebrarAudiencia = () => {       
        handleClose();
        setOpenModalCelebrarAudiencia(true);
    };

    const handleCloseModalCelebrarAudiencia = () => {
        setOpenModalCelebrarAudiencia(false);
    };

    const handleOpenModalNuevoProyectoSentencia = () => {       
        handleClose();
        setOpenModalNuevoProyectoSentencia(true);
    };

    const handleCloseModalNuevoProyectoSentencia = () => {
        setOpenModalNuevoProyectoSentencia(false);
    };

    const handleOpenModalNuevaSentencia = () => {       
        handleClose();
        setOpenModalNuevaSentencia(true);
    };

    const handleCloseModalNuevaSentencia = () => {
        setOpenModalNuevaSentencia(false);
    };

    const handleOpenModalNuevaSentenciaPublica = () => {       
        handleClose();
        setOpenModalNuevaSentenciaPublica(true);
    };

    const handleCloseModalNuevaSentenciaPublica = () => {
        setOpenModalNuevaSentenciaPublica(false);
    };

    const handleOpenModalNuevaSentenciaCorta = () => {       
        handleClose();
        setOpenModalNuevaSentenciaCorta(true);
    };

    const handleCloseModalNuevaSentenciaCorta = () => {
        setOpenModalNuevaSentenciaCorta(false);
    };

    const handleOpenModalNuevoOficio = () => {       
        handleClose();
        setOpenModalNuevoOficio(true);
    };

    const handleCloseModalNuevoOficio = () => {
        setOpenModalNuevoOficio(false);
    };

    const handleOpenModalNuevoRegistroValor = () => {       
        handleClose();
        setOpenModalNuevoRegistroValor(true);
    };

    const handleCloseModalNuevoRegistroValor = () => {
        setOpenModalNuevoRegistroValor(false);
    };

    const handleOpenModalNuevoIrregular = () => {       
        handleClose();
        setOpenModalNuevoIrregular(true);
    };

    const handleCloseModalNuevoIrregular = () => {
        setOpenModalNuevoIrregular(false);
    };

    const handleOpenModalNuevoExhortoLibrado = () => {       
        handleClose();
        setOpenModalNuevoExhortoLibrado(true);
    };

    const handleCloseModalNuevoExhortoLibrado = () => {
        setOpenModalNuevoExhortoLibrado(false);
    };

    const handleOpenModalNuevoExpedienteConsultado = () => {       
        handleClose();
        setOpenModalNuevoExpedienteConsultado(true);
    };

    const handleCloseModalNuevoExpedienteConsultado = () => {
        setOpenModalNuevoExpedienteConsultado(false);
    };

    const handleOpenModalNuevaListaAcuerdos = () => {       
        handleClose();
        setOpenModalNuevaListaAcuerdos(true);
    };

    const handleCloseModalNuevaListaAcuerdos = () => {
        setOpenModalNuevaListaAcuerdos(false);
    };

    const handleOpenModalNuevaConvocatoria = () => {       
        handleClose();
        setOpenModalNuevaConvocatoria(true);
    };

    const handleCloseModalNuevaConvocatoria = () => {
        setOpenModalNuevaConvocatoria(false);
    };

    const handleOpenModalGenerarListadoEnvio = () => {       
        handleClose();
        setOpenModalGenerarListadoEnvio(true);
    };

    const handleCloseModalGenerarListadoEnvio = () => {
        setOpenModalGenerarListadoEnvio(false);
    };

    return (
        <>
            <ListItemText className={classes.listItemOptions} >
                <Button color="inherit" className={classes.buttonIcon} onClick={ handleOpen }>
                    <AddBoxIcon className={classes.PaddingIcon} />

                    <Typography color="inherit" variant="subtitle2" className={classes.ListTypography2} >
                        Nuevo
                    </Typography>
                    
                    <KeyboardArrowDownIcon />                            
                </Button>
            </ListItemText>  

            <Menu elevation={ 2 } anchorEl={ anchorEl } anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }} keepMounted open={ open } onClose={ handleClose }>
                
                {
                    roles?.RegistradorInformacion && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaRecepcion }> 
                            <ListItemIcon className={ classes.listItemIcon }> <AccountTreeIcon /> </ListItemIcon>
                            <ListItemText>Recibir trámite</ListItemText>
                        </MenuItem>
                    )
                }
                
                {
                    ( roles?.ElaboradorAcuerdo || roles?.ElaboradorInformesAmparo || roles?.ElaboradorAcuerdoOficioso )
                    && 
                    (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoAcuerdo }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar acuerdo</ListItemText>
                        </MenuItem>
                    )
                }
                
                {
                    roles?.GeneradorNotificacion && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaNotificacion }> 
                            <ListItemIcon className={ classes.listItemIcon }> <SendIcon /> </ListItemIcon>
                            <ListItemText>Gestionar notificación</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.AgendadorAudiencias && (
                        <Link to="/Audiencia/AgendarAudiencia" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ () => { handleClose(); limpiar(); } }> 
                                <ListItemIcon className={ classes.listItemIcon }> <CalendarTodayIcon /> </ListItemIcon>
                                <ListItemText>Agendar Audiencia</ListItemText>
                            </MenuItem>
                        </Link>
                    )
                }

                {
                    roles?.CelebradorAudiencia && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalCelebrarAudiencia }> 
                            <ListItemIcon className={ classes.listItemIcon }> <AccountTreeIcon /> </ListItemIcon>
                            <ListItemText>Gestionar Audiencia</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.ElaboradorProyectoSentencia && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoProyectoSentencia }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar proyecto de sentencia</ListItemText>
                        </MenuItem>
                    )
                 }

                {
                    roles?.ElaboradorSentencia && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaSentencia }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar sentencia</ListItemText>
                        </MenuItem>
                    )
                }
                
                {
                    roles?.ElaboradorSentenciaPublica && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaSentenciaPublica }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar sentencia pública</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.ElaboradorSentenciaCorta && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaSentenciaCorta }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar sentencia corta</ListItemText>
                        </MenuItem>
                    )
                }
                
                {
                    roles?.GeneradorListaAcuerdos && (                       
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaListaAcuerdos }> 
                            <ListItemIcon className={ classes.listItemIcon }> <ReorderIcon /> </ListItemIcon>
                            <ListItemText>Gestionar lista de acuerdos y estrados</ListItemText>
                        </MenuItem>                        
                    )
                }

                {
                    roles?.GeneradorExpedientilloIrregular && (                        
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoIrregular }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar irregular o <br /> convenio fuera de juicio</ListItemText>
                        </MenuItem>                        
                    )
                }
                
                {
                    roles?.ElaboradorOficio && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoOficio }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar oficio</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.ElaboradorExhortoLibrado && (                        
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoExhortoLibrado }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar exhorto librado</ListItemText>
                        </MenuItem>                        
                    )
                }

                {
                    roles?.RegistradorValores && (
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoRegistroValor }> 
                            <ListItemIcon className={ classes.listItemIcon }> <MonetizationOnIcon /> </ListItemIcon>
                            <ListItemText>Gestionar registro de valores</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.RegistradorExpedienteParaConsulta && (                       
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevoExpedienteConsultado }> 
                            <ListItemIcon className={ classes.listItemIcon }> <FolderIcon /> </ListItemIcon>
                            <ListItemText>Gestionar préstamo de expediente</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.ElaboradorConvocatoria && (                       
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaConvocatoria }> 
                            <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                            <ListItemText>Gestionar convocatoria</ListItemText>
                        </MenuItem>
                    )
                }

                {
                    roles?.GeneradorCaratulas && (    
                        <Link to="/Caratula/GenerarCaratula" className={classes.link}>
                            <MenuItem className={ classes.menuItem } onClick={ handleOpenModalNuevaConvocatoria }> 
                                <ListItemIcon className={ classes.listItemIcon }> <FileCopyIcon /> </ListItemIcon>
                                <ListItemText>Generar carátulas</ListItemText>
                            </MenuItem>
                        </Link>        
                    )
                }

                {
                    roles?.GeneradorListadoEnvio && (                       
                        <MenuItem className={ classes.menuItem } onClick={ handleOpenModalGenerarListadoEnvio }> 
                            <ListItemIcon className={ classes.listItemIcon }> <FileCopyIcon /> </ListItemIcon>
                            <ListItemText>Generar listado envío</ListItemText>
                        </MenuItem>                        
                    )
                }

                {
                    roles?.RegistrarExpedienteAnterior && (           
                        <Link to="/Expediente/GestionarExpedienteAnterior" className={classes.link}>          
                            <MenuItem className={ classes.menuItem }> 
                                <ListItemIcon className={ classes.listItemIcon }> <AccountTreeIcon /> </ListItemIcon>
                                <ListItemText>Gestionar expediente anterior</ListItemText>
                            </MenuItem>                        
                        </Link>  
                    )
                }

                {
                    roles?.RegistrarExhortoAnterior && (           
                        <Link to="/Exhorto/GestionarExhortoAnterior" className={classes.link}>          
                            <MenuItem className={ classes.menuItem }> 
                                <ListItemIcon className={ classes.listItemIcon }> <AccountTreeIcon /> </ListItemIcon>
                                <ListItemText>Gestionar exhorto anterior</ListItemText>
                            </MenuItem>                        
                        </Link>  
                    )
                }

                {
                    roles?.GestionarRegistroMasivo && (           
                        <Link to="/Expediente/GestionarRegistroMasivo" className={classes.link}>          
                            <MenuItem className={ classes.menuItem }> 
                                <ListItemIcon className={ classes.listItemIcon }> <CreateIcon /> </ListItemIcon>
                                <ListItemText>Gestionar registro masivo</ListItemText>
                            </MenuItem>                        
                        </Link>  
                    )
                }
                               
            </Menu>  

            {/* Modales */}

            <ModalNuevaRecepcion 
                openModal= { openModalNuevaRecepcion } 
                handleCloseModal= { handleCloseModalNuevaRecepcion }            
            />

            <ModalNuevoAcuerdo
                openModal= { openModalNuevoAcuerdo } 
                handleCloseModal= { handleCloseModalNuevoAcuerdo }            
            />

            <ModalNuevaNotificacion
                openModal= { openModalNuevaNotificacion } 
                handleCloseModal= { handleCloseModalNuevaNotificacion }            
            />

            <ModalCelebrarAudiencia
                openModal= { openModalCelebrarAudiencia } 
                handleCloseModal= { handleCloseModalCelebrarAudiencia }            
            />

            <ModalNuevoProyectoSentencia
                openModal= { openModalNuevoProyectoSentencia } 
                handleCloseModal= { handleCloseModalNuevoProyectoSentencia }            
            />

            <ModalNuevaSentencia
                openModal= { openModalNuevaSentencia } 
                handleCloseModal= { handleCloseModalNuevaSentencia }            
            />

            <ModalNuevaSentenciaPublica
                openModal= { openModalNuevaSentenciaPublica } 
                handleCloseModal= { handleCloseModalNuevaSentenciaPublica }            
            />

            <ModalNuevoOficio
                openModal= { openModalNuevoOficio } 
                handleCloseModal= { handleCloseModalNuevoOficio }            
            />

            <ModalNuevoRegistroValor
                openModal= { openModalNuevoRegistroValor } 
                handleCloseModal= { handleCloseModalNuevoRegistroValor }            
            />

            <ModalNuevoIrregular
                openModal= { openModalNuevoIrregular } 
                handleCloseModal= { handleCloseModalNuevoIrregular }            
            />

            <ModalNuevoExhortoLibrado
                openModal= { openModalNuevoExhortoLibrado } 
                handleCloseModal= { handleCloseModalNuevoExhortoLibrado }            
            />

            <ModalNuevoExpedienteConsultado
                openModal= { openModalNuevoExpedienteConsultado } 
                handleCloseModal= { handleCloseModalNuevoExpedienteConsultado }            
            />

            <ModalNuevaListaAcuerdos
                openModal= { openModalNuevaListaAcuerdos } 
                handleCloseModal= { handleCloseModalNuevaListaAcuerdos }            
            />

            <ModalNuevaSentenciaCorta
                openModal= { openModalNuevaSentenciaCorta } 
                handleCloseModal= { handleCloseModalNuevaSentenciaCorta }            
            />

            <ModalNuevaConvocatoria
                openModal= { openModalNuevaConvocatoria } 
                handleCloseModal= { handleCloseModalNuevaConvocatoria }            
            />

            <ModalGenerarListadoEnvio     
                openModal= { openModalGenerarListadoEnvio } 
                handleCloseModal= { handleCloseModalGenerarListadoEnvio }            
            />

        </>
    )
}
