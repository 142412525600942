import React, {  useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Snackbar, Radio, RadioGroup, Select, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';

import { ObtenerTiposCausasDiferimiento } from '../../../actions/admin/catalogs/tipoCausaDiferimientoActions';
import { ObtenerTiposConvenios } from '../../../actions/admin/catalogs/tipoConvenioActions';
import { ObtenerTipoNoCelebracion } from '../../../actions/admin/catalogs/tiponocelebracionActions';

import { ActualizarAudiencia, ActualizarEstatus } from '../../../actions/audiencias/audienciaActions';

import moment from 'moment';

import axios from 'axios';

import { types } from '../../../types/types';

export const RegistrarInformacionScreen = ({ activeFinish, setActiveFinish, Id, setLoading, edit, setUsuarioAsignacion, setMensajeFinalizacion }) => {

    const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");

    const { audiencia } = useSelector( state => state.audiencias);

    const dispatch = useDispatch();

    const [errors, setErrors] = useState( { } );

    const [alert, setAlert] = useState( {  } );

    const [fecha, setFecha] = useState( '' );

    const [difiere, setDifiere] = useState( false );
    const [tipoDiferimiento, setTipoDiferimiento] = useState( 0 );
    const [tipoDiferimientoArray, setTipoDiferimientoArray] = useState( [ ] );
    const [motivoDefirimiento, setMotivoDefirimiento] = useState( '' );

    const [convenio, setConvenio] = useState( false );
    const [convenioPregunta, setConvenioPregunta] = useState( true );
    const [tipoConvenio, setTipoConvenio] = useState( 0 );
    const [tipoConvenioArray, setTipoConvenioArray] = useState( [] );

    const [celebro, setCelebro] = useState( true );
    
    const [tipoNoCelebracion, setTipoNoCelebracion] = useState( 0 );
    const [tipoNoCelebracionArray, setTipoNoCelebracionArray] = useState( [] );

    const [loadingInformacion, setLoadingInformacion] = useState( false );
    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );  

    const Finalizar = async () => {

        setLoadingFinalizar( true );

        const params = {
            idAudiencia : Id,
            idEstatus: celebro ? 14 : 47,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacion( false );

                        setLoadingFinalizar( false );

                        if( !response.data.usuarioActualAsignacion && response.data.idEstatus !== 47 ){                            
                            setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );                                                     
                        }  
                        else if( response.data.usuarioActualAsignacion && response.data.idEstatus !== 47 ){
                            setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );
                        }
                        else{
                            setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                        }      

                        setLoading( true );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

 
        if( fecha === '' && celebro ){
            
            valid = false;
            errores.fecha = 'Debes indicar la fecha inicio de la audiencia';
        }
        
        if( tipoDiferimiento === 0 && difiere ){
           
            valid = false;
            errores.tipoDiferimiento = 'Debes seleccionar el tipo de diferimiento';
        }

        if( motivoDefirimiento === '' && difiere ){
         
            valid = false;
            errores.motivoDefirimiento = 'Debes indicar el motivo del diferimiento';
        }
      
        if( tipoConvenio === 0 && convenio ){
           
            valid = false;
            errores.tipoConvenio = 'Debes seleccionar el tipo de convenio';
        }

        if( tipoNoCelebracion === 0 && !celebro ){
       
            valid = false;
            errores.tipoNoCelebracion = 'Debes seleccionar el tipo de no celebración';
        }

        setErrors( errores );
        return valid;

    }
    
    const GuardarInformacion = async () => {
      
        const valid = validateFiels();
       
        if( !valid ){
            return false;
        }

        const params = {
            idAudiencia : audiencia.idAudiencia,
            fechaHoraInicio : fecha ? fecha : null,
            seDifiere : difiere,
            idTipoCausaDiferimiento : tipoDiferimiento,
            motivoDiferimiento : motivoDefirimiento,
            generarConvenio : convenio,
            idTipoConvenio : tipoConvenio,
            seCelebro : celebro,
            idTipoNoCelebracion : tipoNoCelebracion
        };

        setLoadingInformacion( true );

        await ActualizarAudiencia( params ).then( response => {

            if( response ){

                if( response.status === 200 ){
                    
                    setTimeout( () => {
                        
                        setActiveFinish( true );

                        setAlert( {
                            open: true,
                            msg: 'Se guardaron los datos correctamente',
                            type: 'success'
                        } ); 

                        dispatch({ 
                            type: types.actualizarDatosAudiencia,
                            payload: {
                                ...audiencia,
                                generarConvenio: convenio,
                                seDifiere: difiere,
                                motivoDiferimiento: motivoDefirimiento,
                                fechaHoraInicio: fecha,
                                idTipoCausaDiferimiento: tipoDiferimiento,
                                idTipoConvenio: tipoConvenio,
                                seCelebro: celebro,
                                idTipoNoCelebracion: tipoNoCelebracion,                             
                            }
                        });
                     
                        setLoadingInformacion( false ); 

                    }, 1000);  
                } 
                else {

                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: 'Ocurrió un error, contacte con el administrador',
                            type: 'error'
                        }); 
                        
                        setLoadingInformacion( false ); 

                    }, 1000);  
                }
            }
        });

    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleOcultarMostrarTipoDiferimiento = ( val ) => {
        setDifiere(val);
        setTipoDiferimiento(0);
        setMotivoDefirimiento('');

        if(val){
            setConvenioPregunta(!val);
            setConvenio(!val);
            setTipoConvenio(0);
        }else{
            setConvenioPregunta(!val);
            setConvenio(val);
            setTipoConvenio(0);
        }

        
    
    }

    const handleOcultarMostrarConvenio = ( val ) => {
        setConvenio(val);
        setTipoConvenio(0);
    }

    const handleOcultarMostrarCelebro = ( val ) => {
        setCelebro(val)
        
        if(val){           
            setTipoNoCelebracion(0);

            const DateNow = moment( new Date()).format("YYYY-MM-DDTHH:mm");
            setFecha( DateNow );
        } 
        else{          
            setTipoNoCelebracion(0);
            setDifiere(false);
            setTipoDiferimiento(0);
            setMotivoDefirimiento('');
            setConvenioPregunta(true);
            setConvenio(false);
            setTipoConvenio(0);
            setFecha('');
        }
    }

    useEffect(() => {      
    
        let isSubscribed = true;

        async function Obtener(){

            await ObtenerTiposCausasDiferimiento().then( response => {

                if( response && isSubscribed ){

                    if( response.data ){
                        setTipoDiferimientoArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

        return () => ( isSubscribed = false )

    }, [  ])

    useEffect(() => {      
    
        let isSubscribed = true;

        async function Obtener(){

            await ObtenerTiposConvenios().then( response => {

                if( response && isSubscribed ){

                    if( response.data ){
                        setTipoConvenioArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

        return () => ( isSubscribed = false )

    }, [  ])

    useEffect(() => {      

        let isSubscribed = true;

        async function Obtener(){

            await ObtenerTipoNoCelebracion().then( response => {

                if( response && isSubscribed ){

                    if( response.data ){
                        setTipoNoCelebracionArray( response.data );
                    }

                }

            });

        }     
        
        Obtener();

        return () => ( isSubscribed = false )

    }, [  ])

    useEffect(() => {      

        if( Object.keys( audiencia ).length !== 0 ){

            setFecha( audiencia.fechaHoraInicio ? moment( audiencia.fechaHoraInicio ).format("YYYY-MM-DDTHH:mm")  : DateNow  );   
            setCelebro( audiencia.seCelebro ?? false );             
            setDifiere( audiencia.seDifiere ?? false );
            setConvenio( audiencia.generarConvenio ?? false );   
            setMotivoDefirimiento( audiencia.motivoDiferimiento ?? '' );  

            if( audiencia?.seDifiere ){
                setConvenioPregunta( false );
            }
            
        }
      
    }, [ audiencia, DateNow ])
        
    useEffect(() => {      

        if( audiencia && tipoDiferimientoArray.length > 0 ){
            setTipoDiferimiento( audiencia.idTipoCausaDiferimiento ?? 0 );   
        }

        if( audiencia && tipoConvenioArray.length > 0 ){
            setTipoConvenio( audiencia.idTipoConvenio ?? 0 );   
        }

        if( audiencia && tipoNoCelebracionArray.length > 0 ){
            setTipoNoCelebracion( audiencia.idTipoNoCelebracion ?? 0 );   
        }
      
    }, [ audiencia, tipoDiferimientoArray, tipoConvenioArray, tipoNoCelebracionArray ])
        
    useEffect(() => {
     
        const requests = axios.CancelToken.source(); 

        return () => {
            requests.cancel();
        }

    }, [ ])

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }            
            
            <Grid item md={2} xs={3}>
                <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight:'bold'  }} >
                    Tipo de audiencia:
                </Typography>
            </Grid>

            <Grid item md={4} xs={9} >
                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                    { audiencia.tipoAudiencia }
                </Typography>
            </Grid>

            <Grid item md={2} xs={3}>
                <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight:'bold'  }} >
                    Fecha:
                </Typography>
            </Grid>

            <Grid item md={4} xs={9} >
                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                    { audiencia.fechaAgenda }
                </Typography>
            </Grid> 

            {
                audiencia.ponencia 
                && 
                <>
                    <Grid item md={2} xs={3}>
                
                        <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight:'bold'  }} >
                            Ponencia:
                        </Typography>
                            
                    </Grid>

                    <Grid item md={4} xs={9} >
                    
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                            { audiencia.ponencia }
                        </Typography>
                    
                    </Grid>                 
                </>
            }            

            {
                audiencia?.juez
                &&
                <>                
                    <Grid item md={2} xs={3}>              
                        <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight:'bold'  }} >
                            Juez:
                        </Typography>                
                    </Grid>

                    <Grid item md={4} xs={9} >                
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                            { audiencia?.juez }
                        </Typography>
                    </Grid>                 
                </>   
            }    

            {
                audiencia?.secretario
                &&
                <>                
                    <Grid item md={2} xs={3}>              
                        <Typography gutterBottom variant="subtitle2" sx={{ color: 'gray', fontWeight:'bold'  }} >
                            Secretario(a) de Acuerdo y Trámite:
                        </Typography>                
                    </Grid>

                    <Grid item md={4} xs={9} >                
                        <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color: 'gray' }}>
                            { audiencia?.secretario }
                        </Typography>
                    </Grid>                 
                </>   
            }           

            <Grid item md={12} xs={12}> </Grid>

            <Grid item md={4} xs={12} >
                <FormControl component="fieldset" disabled={ !edit }>
                    <FormLabel component="legend">¿Se celebró la audiencia?</FormLabel>
                    <RadioGroup row
                        aria-label="celebroaudiencia"
                        defaultValue="no"
                        name="radio-buttons-group"  
                    >                                    
                        <FormControlLabel value="no" control={ <Radio checked={ !celebro } onChange={ () => { handleOcultarMostrarCelebro( false ); } } />} label="No" />
                        <FormControlLabel value="si" control={ <Radio checked={ celebro }  onChange={ () => { handleOcultarMostrarCelebro( true ); } }/>} label="Si" />                                    
                    </RadioGroup>
                </FormControl>
            </Grid>

            {
                celebro && (
                    <>
                        <Grid item md={8} xs={12} >
                            <TextField                              
                                label="Fecha inicio de la audiencia"
                                type="datetime-local"   
                                fullWidth
                                name="fecha"                             
                                value={ fecha }    
                                onChange={ ( e ) => { setFecha( e.target.value ); } }                                                     
                                inputProps={{
                                    max: moment( new Date() ).format('YYYY-MM-DDThh:mm')
                                }}                             
                                InputLabelProps={{
                                    shrink: true,
                                }}                   
                                disabled={ !edit }
                            />
                            {   
                                errors.fecha && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.fecha } </Alert> )
                            }
                        </Grid>
                        
                        <Grid item md={4} xs={12} >
                            <FormControl component="fieldset" disabled={ !edit }>
                                <FormLabel component="legend">¿Se difieré la audiencia?</FormLabel>
                                <RadioGroup row
                                    aria-label="difiereAudiencia"
                                    defaultValue="no"
                                    name="radio-buttons-group"  
                                >
                                    <FormControlLabel value="no" control={ <Radio checked={ !difiere } onChange={ () => { handleOcultarMostrarTipoDiferimiento(false) } } />} label="No" />
                                    <FormControlLabel value="si" control={ <Radio checked={ difiere }  onChange={ () => { handleOcultarMostrarTipoDiferimiento(true) } }/>} label="Si" />
                                    
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={8} xs={12} >   
                        {
                            
                            difiere && (
                                <FormControl fullWidth disabled={ !edit }>
                                    <InputLabel id="select-tipoDiferimiento-label">Tipo de diferimiento</InputLabel>
                                    <Select
                                        labelId="select-tipoDiferimiento-label"
                                        id="select-tipoDiferimiento"                                            
                                        label="Tipo de diferimiento"
                                        name="tipoDiferimiento"
                                        value={ tipoDiferimiento }
                                        onChange={ (e) => { setTipoDiferimiento(e.target.value)}  }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            tipoDiferimientoArray.map((elem)=>(
                                                <MenuItem key={elem.idTipoCausaDiferimiento} value={ elem.idTipoCausaDiferimiento }>{ elem.descripcion }</MenuItem>
                                            ))
                                        }

                                    </Select>
                                    {
                                        errors.tipoDiferimiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoDiferimiento } </Alert> )
                                    } 
                                </FormControl>
                            )
                        }                         
                        </Grid>
                        
                        <Grid item md={12} xs={12} >
                        {
                            difiere && (
                                <FormControl fullWidth>
                                    <TextField                              
                                        label="Motivo"                                                     
                                        multiline
                                        rows={4}  
                                        fullWidth                          
                                        name = "motivoDiferimiento"
                                        variant="outlined"
                                        value= {motivoDefirimiento}
                                        onChange={ ( e ) => { setMotivoDefirimiento( e.target.value ) }}
                                        disabled={ !edit }
                                    />
                                {   
                                    errors.motivoDefirimiento && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.motivoDefirimiento } </Alert> )
                                }
                                </FormControl>   
                            )
                        }
                        </Grid>

                        {
                            convenioPregunta && (
                                <Grid item md={4} xs={12} >
                                    <FormControl component="fieldset" disabled={ !edit }>
                                        <FormLabel component="legend">¿Se llego a un convenio?</FormLabel>
                                        <RadioGroup row
                                            aria-label="convenioAudiencia"
                                            defaultValue="no"
                                            name="radio-buttons-group"  
                                        >
                                            <FormControlLabel value="no" control={ <Radio checked={ !convenio  } onChange={ () => { handleOcultarMostrarConvenio(false) } } />} label="No" />
                                            <FormControlLabel value="si" control={ <Radio checked={ convenio }  onChange={ () => { handleOcultarMostrarConvenio(true) } }/>} label="Si" />
                                            
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                             )
                        }

                       

                        <Grid item md={8} xs={12} > 
                        {

                            convenio && (
                                <FormControl fullWidth disabled={ !edit }>
                                    <InputLabel id="select-tipoConvenio-label">Tipo de convenio</InputLabel>
                                
                                    <Select
                                        labelId="select-tipoConvenio-label"
                                        id="select-tipoConvenio"                                            
                                        label="Tipo de convenio"
                                        name="tipoConvenio"
                                        value={ tipoConvenio }
                                        onChange={ (e) => { setTipoConvenio(e.target.value)}  }
                                    >
                                        <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                        {
                                            tipoConvenioArray.map((elem)=>(
                                                <MenuItem key={elem.idTipoConvenio} value={ elem.idTipoConvenio }>{ elem.descripcion }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {   
                                        errors.tipoConvenio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoConvenio } </Alert> )
                                    }
                                </FormControl>
                            )
                        }                           
                        </Grid> 


                    </>
                ) 
                
            }

            {
                !celebro && (
                    <>
                        <Grid item md={8} xs={12} > 
                            <FormControl fullWidth disabled={ !edit }>
                                <InputLabel id="select-tipoNoCelebracion-label">Tipo de no celebración</InputLabel>
                            
                                <Select
                                    labelId="select-tipoNoCelebracion-label"
                                    id="select-tipoNoCelebracion"                                            
                                    label="Tipo de no celebración"
                                    name="tipoNoCelebracion"
                                    value={ tipoNoCelebracion }
                                    onChange={ (e) => { setTipoNoCelebracion(e.target.value)}  }
                                >
                                    <MenuItem key={0} value={0}>Selecciona una opción</MenuItem>
                                    {
                                        tipoNoCelebracionArray.map((elem)=>(
                                            <MenuItem key={elem.idTipoNoCelebracion} value={ elem.idTipoNoCelebracion }>{ elem.descripcion }</MenuItem>
                                        ))
                                    }
                                </Select>
                                {   
                                    errors.tipoNoCelebracion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoNoCelebracion } </Alert> )
                                }
                            </FormControl>                       
                        </Grid> 
                    </>
                ) 
                
            }

            {
                edit
                &&
                <>
                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}> 
                        <LoadingButton 
                            variant="contained" 
                            onClick={ GuardarInformacion } 
                            startIcon={ <SaveIcon /> }
                            loading={ loadingInformacion }
                        > 
                            Guardar información
                        </LoadingButton>
                    </Grid>

                    <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>  
                        <Button 
                            variant="contained" 
                            onClick={ () => { setOpenConfirmacion( true ); } }
                            endIcon={ <CheckCircleIcon /> }
                            disabled={ !activeFinish }
                        > 
                            Finalizar 
                        </Button>
                    </Grid>
                </>
            }

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la celebración de la audiencia?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>
            
        </>
    )
}
