import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Alert, Box, Card, CardContent, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Paper, Snackbar, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import moment from 'moment';

import { types } from '../../../types/types';

import AppBarMenu from '../../ui/AppBarMenu';

import { ObtenerSentenciaCortaDetalle, ActualizarEstatus } from '../../../actions/sentencias/sentenciaCortaActions';
import { InformacionSeguimiento } from '../../ui/InformacionSeguimiento';

export const VisualizarSentenciaCortaScreen = ( props ) => {

    const { Id } = props?.match?.params;
    
    const dispatch = useDispatch();
    
    const [sentenciaCorta, setSentenciaCorta] = useState( { } ); 
    
    const [loading, setLoading] = useState( false );
    
    const [loadingFinalizar, setLoadingFinalizar] = useState( false ); 
    
    const [openConfirmacion, setOpenConfirmacion] = useState( false ); 
    
    const [ajaxLoading, setAjaxLoading] = useState( false );
    
    const [alert, setAlert] = useState( {  } );   

    const [usuarioAsignacion, setUsuarioAsignacion] = useState( '' );

    const [mensajeFinalizacion, setMensajeFinalizacion] = useState( '' );

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }    

    const Finalizar = async () => {

        setLoadingFinalizar( true );
        
        const params = {
            'idSentenciaCorta' : Id,
            'idEstatus': 47,
        };

        await ActualizarEstatus( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {
                                                

                        setOpenConfirmacion( false );                                     

                        setLoadingFinalizar( false );

                        setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );

                        setLoading( true );
                        

                    }, 1000);  
                } 
                else {
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        });

    }

    useEffect(() => {      
    
        async function Obtener(){

            const params = {
                'idSentenciaCorta': Id,
            };

            dispatch({ type: types.openLoading });

            await ObtenerSentenciaCortaDetalle( params ).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {                            

                            setSentenciaCorta( response.data );            
                                                     
                            setAjaxLoading( true );

                            dispatch({ type: types.closeLoading });

                            if( !response.data.usuarioActualAsignacion ){ 

                                if( response.data.idEstatus !== 47 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se asignó a ' + response.data.usuarioAsignacion ?? '' );  

                                    setLoading( true ); 
                                }     
                            }  
                            else if( response.data.usuarioActualAsignacion ){
                             
                                if( response.data.idEstatus !== 47 && response.data.idEstatus !== 70 ){
                                    setUsuarioAsignacion( 'La siguiente actividad se encuentra en tu inbox' );

                                    setLoading( true ); 
                                }

                            }  
                            
                            if( response.data.idEstatus !== 70){

                                if( response.data.idEstatus === 47 ){
                                    setMensajeFinalizacion( 'No se tienen actividades pendientes para este trámite' );
                                }
                                
                                setLoading( true ); 

                            }
                            
                        }, 1000);   
                        
                    }

                }

            });

        }     
        
        Obtener();

    }, [ Id, dispatch ])
 
    if( !ajaxLoading ){
        return ( <AppBarMenu /> );
    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <AppBarMenu />

            <Box sx={{ marginTop: '7em', minHeight: '100%', py: 3 }}>  

                {
                    loading 
                    ?                            
                    (
                        <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                            <Container maxWidth="lg" >                        

                                <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                    
                                    <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                    
                                        <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                            Se finalizó la actividad
                                        </Typography>

                                        <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                            { mensajeFinalizacion }
                                        </Typography>

                                        {
                                            usuarioAsignacion
                                            ?
                                                <Typography component="div" sx={{ mt: 1, fontSize: 14, textAlign: 'right' }}>                                 
                                                    { usuarioAsignacion }
                                                </Typography>
                                            :
                                            null
                                        }

                                    </CardContent>

                                </Card>                   
                                                        
                            </Container>                    

                        </Box>  
                    )
                    :
                    (                
                        <Container maxWidth="lg" component={ Paper } sx={{ pb: 3 }}>
                            
                            <InformacionSeguimiento 
                                noSeguimiento={ 'GSEC-' + sentenciaCorta?.idSentenciaCorta }
                                actividad={ 'Visualizar sentencia corta' }
                                tipo={ 'Número de expediente' }
                                referencia={ sentenciaCorta?.numeroExpediente }
                            />
                                
                            <Grid container spacing={3} mt={1}>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Número de Sentencia (Resolución):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaCorta?.numeroSentencia }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Sentencia con perspectiva de género:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaCorta?.perspectivaGenero ? 'Si' : 'No' }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Fecha de sentencia:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaCorta?.fechaSentencia ? moment(sentenciaCorta?.fechaSentencia).format('DD-MM-YYYY HH:mm') : '' }
                                    </Typography>
                                </Grid>

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold'  }}>
                                        Sentido de sentencia:
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                        { sentenciaCorta?.sentidoSentencia }
                                    </Typography>
                                </Grid>

                                {
                                    sentenciaCorta?.ponencia
                                    &&                                
                                        <Grid item md={2} xs={6}>
                                            <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }}>
                                                Ponencia:
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    sentenciaCorta?.ponencia
                                    &&  
                                        <Grid item md={10} xs={6} >
                                            <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }}>
                                                { sentenciaCorta?.ponencia }
                                            </Typography>
                                        </Grid>
                                }

                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Actor(es):
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <List disablePadding>                               
                                    {
                                        sentenciaCorta?.actor?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                      
                                    </List>
                                </Grid>  
                                
                                <Grid item md={2} xs={6}>
                                    <Typography gutterBottom variant="subtitle2" style={{ color:'gray', fontWeight: 'bold' }} >
                                        Demandado(s):
                                    </Typography>
                                </Grid>
                                
                                <Grid item md={4} xs={6} >
                                    <List 
                                        disablePadding 
                                        sx={{ maxHeight: sentenciaCorta?.demandado?.length > 10 ? 300 : null, overflow: sentenciaCorta?.demandado?.length > 10 ? 'scroll' : null }}
                                    >  
                                    {
                                        sentenciaCorta?.demandado?.map( ( elem, i ) => (
                                            <ListItem key={ i } disablePadding>  
                                                <Typography gutterBottom variant="subtitle2" style={{ fontWeight: 'normal', color:'gray'  }} >
                                                    { elem }
                                                </Typography>      
                                            </ListItem>
                                        ))
                                    }                                              
                                    </List>

                                </Grid>

                                <Grid item lg={12} md={12} xs={12}>                             
                                   

                                    {
                                        sentenciaCorta?.sentenciaCortaFirmada
                                        ?
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ sentenciaCorta.sentenciaCortaFirmada }  
                                            />
                                        :                                        
                                            <iframe 
                                                title='iframe  Demanda o Escrito Inicial'
                                                style={{ width: '100%', height: '65vh' }} 
                                                src={ sentenciaCorta.sentenciaCorta }  
                                            />                                        
                                    }


                                </Grid>


                                <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 

                                    <Button 
                                        variant="contained" 
                                        onClick={ () => { setOpenConfirmacion( true ); } }  
                                        endIcon={ <CheckCircleIcon /> }
                                    > 
                                        Finalizar 
                                    </Button>

                                </Grid>    

                            </Grid>

                        </Container>

                    )
                }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la visualización de la sentencia pública?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); } }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                        Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>


        </>
    )
}
