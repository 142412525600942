import React from 'react';

import { Provider } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material';

import { AppRouter } from './routers/AppRouter';

import { store } from './store/store';

const theme = createTheme({
    palette: {
        primary: {            
            main: '#0A192D',
        },
        secondary: {
            main: '#0A192D',
        },
        info: {
            main: '#0A192D'
        },
    },
    components:{      
        MuiAutocomplete: {
            styleOverrides:{
                input: {
                    "&::placeholder": {
                        opacity: 1,
                    },
                }
           }
        },
        MuiInputBase: {
            styleOverrides:{
                input: {
                    "&::placeholder": {
                        opacity: 0.8,
                        color:'#000',
                    },
                    "&:disabled": {
                        opacity: 0.8,
                        color:'#000',
                        WebkitTextFillColor: "#000 !important",
                    }
                }
           }
        }

    }
});

export const SajiApp = () => {
   
    return (
        <Provider store = { store }>
            
            <ThemeProvider theme={theme}>
            
                <AppRouter />
            
            </ThemeProvider>

        </Provider>
    )
}
