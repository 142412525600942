import React, { useEffect, useState } from 'react';

import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, Card, CardContent, IconButton } from '@mui/material';

import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';

import { types } from '../../types/types';

import SourceIcon from '@mui/icons-material/Source';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/Print';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import AppBarMenu from '../ui/AppBarMenu';

import { Detalle, PublicarListadoEnvio, FinalizarListadoEnvio } from '../../actions/notificaciones/listadoEnvioActions';
import { InformacionSeguimientoSimple } from '../ui/InformacionSeguimiento';
import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export const GenerarListadoEnvioScreen = ( props ) => {

    const { Id } = props?.match?.params;

    const dispatch = useDispatch();

    const [detalleNotificaciones, setDetalleNotificaciones] = useState( {} );

    const [listadoEnvio, setListadoEnvio] = useState( [] );

    const [detalleListadoEnvio, setDetalleListadoEnvio] = useState( [] );

    const [ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage ] = usePages(10);

    const [loading, setLoading] = useState(false);

    const [habiliarFinalizar, setHabiliarFinalizar] = useState(true);

    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [openConfirmacionFinalizar, setOpenConfirmacionFinalizar] = useState(false);

    const [openListadoEnvio, setOpenListadoEnvio] = useState(false);

    const [loadingGenerar, setLoadingGenerar] = useState( false ); 
    const [loadingFinalizar, setLoadingFinalizar] = useState( false );  

    const [ajaxLoading, setAjaxLoading] = useState(false);

    const [publicarTodo, setPublicarTodo] = useState(false);

    const [alert, setAlert] = useState( {  } );

    const [openCedula, setOpenCedula] = useState( false );
    const [openDocumentoAcuerdo, setOpenDocumentoAcuerdo] = useState( false );

    const [documentoCedula, setDocumentoCedula] = useState( {} );

    const [documentoAcuerdo, setDocumentoAcuerdo] = useState( {} );

    const [fullScreen, setFullScreen] = useState( false );

    const handleOpenConfirmacion = () => {   
        setOpenConfirmacion(true);
    };

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false);
    };

    const handleOpenConfirmacionFinalizar = () => {   
        setOpenConfirmacionFinalizar(true);
    };

    const handleCloseConfirmacionFinalizar = () => {
        setOpenConfirmacionFinalizar(false);
    };

    const handlePublicarTodo = ( checked ) => {

        setDetalleNotificaciones( detalleNotificaciones?.map( ( element ) => { return { ...element, selected: checked }; }) );

        setPublicarTodo( checked );

    };

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }
    
    const Finalizar = async () => {

        setLoadingFinalizar( true );

        const params = {
            'idListadoEnvio' : Id
        };

       await FinalizarListadoEnvio( params ).then( response => {

            if( response ){

                if( response.status === 200){

                    setTimeout(() => {

                        setOpenConfirmacionFinalizar( false );

                        setLoading( true );

                        setLoadingFinalizar( false );

                    }, 1000);  
                } 
                else {
                    
                    setTimeout(() => {

                        setAlert({
                            open: true,
                            msg: response.data.message,
                            type: 'error'
                        }); 

                        setOpenConfirmacionFinalizar( false );
                        
                        setLoadingFinalizar( false );

                    }, 1000);  
                }
            }
        }); 

    }

    const GenerarLista = async () => {

        setLoadingGenerar( true );

        let arrayListadoEnvio = [];

        detalleNotificaciones.forEach(element => {

            if(element.selected) {
                
                arrayListadoEnvio.push( element );
            }
            
        });    
        
        const params = {

            idListadoEnvio : Id,
            notificaciones : arrayListadoEnvio

        };

        await PublicarListadoEnvio( params ).then( response => {

            if( response ){

                if( response.status === 200){
             
                    if( response.data ){
        
                        setDetalleNotificaciones(response.data.notificacionDetalle);

                        setListadoEnvio(response.data);
                        
                        setHabiliarFinalizar(false);

                        setLoadingGenerar( false );

                        setOpenConfirmacion(false);

                        setOpenListadoEnvio(true);

                        resetPage();
                        
                    }

                }

            }

        });   

    }

    const handleChangeCheckBox = ( { target }, id ) => {

        const newArray = detalleNotificaciones.map( ( element ) =>{

            if( element.id === id ){
                element.selected = target.checked;
            }   
            
            return element;
        });

        setDetalleNotificaciones(newArray);
    }

    useEffect(() => {

        async function Obtener(){
            
            const params = {
                'idListadoEnvio' : Id,
            };

            dispatch({ type: types.openLoading });

            await Detalle( params ).then( response => {

                if( response ){

                    if( response.data ){  

                        setListadoEnvio( response.data );

                        setTimeout(() => {
                            
                            if(response.data.listadoEnvio !== ''){
                                setHabiliarFinalizar(false);
                            }                            
                                                     
                            setDetalleNotificaciones( response?.data?.notificacionDetalle?.map( ( element ) => { return { ...element, selected: false }; }) );
                            setDetalleListadoEnvio( response.data.idListadoEnvio )  ;
                            dispatch({ type: types.closeLoading });   

                            if( response.data.idEstatus !== 80 ){

                                setLoading( true );
                                
                            }
                            
                            setAjaxLoading( true );            

                        }, 1200);                       

                    }

                }

            });
        }     
        
        Obtener();

    }, [ Id, dispatch ]);

    if( !ajaxLoading ){

        return ( <AppBarMenu /> );

    }

    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant="filled" severity={ alert.type } sx={{ width: '100%' }}>
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            <AppBarMenu />

            <Box sx={{ marginTop: '7em', minHeight: '100%', py: 3 }}>  

            {
                loading 
                ?                            
                (
                    <Box sx={{ marginTop: '5em', minHeight: '100%', py: 3 }}>  

                        <Container maxWidth="lg" >                        

                            <Card variant="outlined" sx={{ position: 'relative', width: '100%' }} >
                                
                                <CardContent sx={{ backgroundColor: (theme) => theme.palette.info.main, color: 'white' }} >
                                
                                    <Typography component="div" sx={{ mt: 1, fontSize: 18 }}>
                                        Se finalizó la actividad
                                    </Typography>

                                    <Typography component="div" sx={{ mt: 1, fontSize: 14 }}>
                                        No se tienen actividades pendientes para este trámite
                                    </Typography>

                                </CardContent>

                            </Card>                   
                                                    
                        </Container>                    

                    </Box>  
                ) 
                
                :

                (                        
                    <Container maxWidth="lg" sx={{ mt: 1, pb: 2 }} component={ Paper } >
                            
                        <Grid container spacing={3} >

                            <Grid  item md={12} xs={12}>                               
                                <InformacionSeguimientoSimple noSeguimiento={ 'GLEN-' + detalleListadoEnvio} />
                            </Grid>

                            <Grid item md={12} xs={12}>

                                <TableContainer>

                                    <Table>

                                        <TableHead>

                                            <TableRow>
                                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>FECHA</TableCell>
                                                <TableCell style={{ width: '10%', fontWeight: 'bold' }}>REFERENCIA</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>PERSONA A NOTIFICAR</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>DOMICILIO</TableCell>
                                                <TableCell style={{ width: '15%', fontWeight: 'bold' }}>TIIPO DE NOTIFICACIÓN</TableCell>
                                                <TableCell style={{ width: '30%', fontWeight: 'bold' }}>TIPO DE ACUERDO</TableCell>
                                                <TableCell style={{ width: '5%', fontWeight: 'bold' }}>ACUERDO</TableCell>
                                                <TableCell style={{ width: '5%', fontWeight: 'bold' }}>CÉDULA</TableCell>
                                                {
                                                    listadoEnvio.listadoEnvio === ""
                                                    &&
                                                        <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                                                            ENVIAR
                                                            <Checkbox checked={ publicarTodo } onChange={ ( e ) => handlePublicarTodo( e.target.checked ) }/>
                                                        </TableCell>
                                                }
                                            </TableRow>

                                        </TableHead>

                                        <TableBody>

                                            {
                                                (
                                                    rowsPerPage > 0
                                                    ?
                                                    detalleNotificaciones.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                    :
                                                    detalleNotificaciones
                                                ).map( ( row ) => (

                                                    <TableRow key={ row.id } >

                                                        <TableCell> { row.fechaNotificacion ? moment( row.fechaNotificacion ).format('DD-MM-YYYY hh:mm a') : '' }  </TableCell>  
                                                        
                                                        <TableCell> { row.referencia } </TableCell>     

                                                        <TableCell> { row.personaNotificar } <br /> (<strong>{ row.tipoParte }</strong>)  </TableCell> 

                                                        <TableCell> { row.domicilio } </TableCell>   

                                                        <TableCell> { row.tipoNotificacion } </TableCell>         

                                                        <TableCell> { row.tipoAcuerdo } <br /> <strong>{ row.subTipoAcuerdo }</strong>  </TableCell>   
                                                        
                                                        <TableCell sx={{textAlign: 'center'}}>                              

                                                            {
                                                                row?.id
                                                                &&                                                            
                                                                <IconButton
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    size='small'
                                                                    onClick={ () => { setDocumentoAcuerdo( row ); setOpenDocumentoAcuerdo( true ); } }
                                                                >
                                                                    <SourceIcon /> 
                                                                </IconButton>
                                                            }

                                                        </TableCell>   
                                                        
                                                        <TableCell sx={{textAlign: 'center'}}>                               

                                                            {
                                                                row?.id
                                                                &&                                                            
                                                                <IconButton
                                                                    variant='contained'
                                                                    color='inherit'
                                                                    size='small'
                                                                    onClick={ () => { setDocumentoCedula( row ); setOpenCedula( true ); } }
                                                                >
                                                                    <SourceIcon /> 
                                                                </IconButton>
                                                            }

                                                        </TableCell>                                                       
                                                       

                                                        {
                                                            ( row.publicar && listadoEnvio.listadoEnvio === "" )
                                                            &&
                                                                <TableCell> 
                                                                    <Checkbox checked={ row.selected ?? false } onChange={ ( e ) => { handleChangeCheckBox( e, row.id ) } }/>
                                                                </TableCell>                                                                  
                                                        }

                                                    </TableRow>

                                                ))
                                            }

                                            {
                                                detalleNotificaciones.length === 0 && 
                                                (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={9}
                                                            align="center"
                                                            style={{ fontWeight: 'bold', fontSize: 15 }}
                                                        >
                                                            No se encontraron resultados
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }

                                        </TableBody>

                                        <TableFooter>

                                            <TableRow>
                                                <TablePagination 
                                                    colSpan={9}
                                                    rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                    count={ detalleNotificaciones.length }
                                                    rowsPerPage={ rowsPerPage }
                                                    page={ page }
                                                    onPageChange={ handleChangePage }
                                                    onRowsPerPageChange={ handleChangeRowsPerPage }

                                                    ActionsComponent={ TablePaginationActions }

                                                    labelRowsPerPage="Filas por página"                                            
                                                    labelDisplayedRows={
                                                        ({ from, to, count }) => {
                                                        return '' + from + '-' + to + ' de ' + count
                                                        }
                                                    }                                                                                       
                                                />
                                            </TableRow>

                                        </TableFooter>

                                    </Table>
                                    
                                </TableContainer>

                            </Grid>

                            <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}  > 
                            {
                                listadoEnvio.listadoEnvio === ""
                                ?
                                    <Button variant="contained" onClick={ handleOpenConfirmacion } startIcon={ <PrintIcon /> } disabled={ detalleNotificaciones.filter( ( element ) => { return element.selected === true; } ).length === 0 }> 
                                        Generar listado de envío 
                                    </Button>
                                :
                                    <Button variant="contained" onClick={  () => { setOpenListadoEnvio( true ); } } startIcon={ <PrintIcon /> } > 
                                        Visualizar listado de envío 
                                    </Button>                 
                            }

                            </Grid>    

                            <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}> 
                                <LoadingButton variant="contained" onClick={ handleOpenConfirmacionFinalizar } endIcon={ <CheckCircleIcon /> } disabled={ habiliarFinalizar }> Finalizar </LoadingButton>
                            </Grid>    

                        </Grid>

                    </Container>
                
                )

            }

            </Box>

            <Dialog open={ openConfirmacion } onClose={ () => {} } >
                    
                    <DialogTitle>
                        Confirmación
                    </DialogTitle>
                    
                    <DialogContent>
                    
                        <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                            ¿Desea generar el listado de envío?
                        </DialogContentText>
                    
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={ handleCloseConfirmacion } disabled={loadingGenerar}> Cancelar </Button>

                        <LoadingButton 
                            onClick={ GenerarLista } 
                            variant='contained' 
                            loading={ loadingGenerar }
                        > 
                            Aceptar 
                        </LoadingButton>

                    </DialogActions>

            </Dialog>

            <Dialog open={ openConfirmacionFinalizar } onClose={ () => {} } >
                
                <DialogTitle>
                    Confirmación
                </DialogTitle>
                
                <DialogContent>
                
                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)' }}>
                        ¿Desea finalizar la publicación del listado de envío?
                    </DialogContentText>
                
                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleCloseConfirmacionFinalizar }> Cancelar </Button>

                    <LoadingButton 
                        onClick={ Finalizar } 
                        variant='contained' 
                        loading={ loadingFinalizar }
                    > 
                    Aceptar 
                    </LoadingButton>

                </DialogActions>

            </Dialog>

            <Dialog open={ openListadoEnvio } onClose={ () => {} } fullWidth={ true } maxWidth="md"  >
            
                <DialogContent>
                    
                    <Grid container spacing={3} >                                                
                    
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
    
                            {
                                listadoEnvio.listadoEnvio || listadoEnvio.listadoEnvioFirmado
                                ?
                                listadoEnvio.listadoEnvioFirmado
                                    ?
                                        <iframe title='lista' src={ listadoEnvio.listadoEnvioFirmado } height="500px" width="100%"></iframe>
                                    :
                                        <iframe title='lista' src={"data:application/pdf;base64," +  listadoEnvio.listadoEnvio } height="500px" width="100%"></iframe>
                                :
                                <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                    No se encontró el listado de envío
                                </Typography>
                            }

                        </Grid>
                        
                    </Grid>
                    
                </DialogContent>

                <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenListadoEnvio( false ); } }>
                        Cerrar
                    </Button>

                </DialogActions>                

            </Dialog> 

            <Dialog open={ openCedula } onClose={ () => {} } fullWidth={ true } maxWidth="md" fullScreen={ fullScreen } >

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"> </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setFullScreen( (prev) => !prev ) } }
                    sx={{
                        position: 'absolute',
                        right: 55,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                     { fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon /> } 
                </IconButton>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setOpenCedula( false ); setDocumentoCedula( {} ); } }
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
               
                <DialogContent>
                  
                   <Grid container spacing={3} height={'100%'} >   
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                                documentoCedula
                               ?

                                 <iframe title='cedula' src={ documentoCedula.cedulaNotificacionFirmado } height={ fullScreen ? "100%" : '500px' } width="100%"></iframe>
                                   
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró el documento
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenCedula( false ); setDocumentoCedula( {} ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

            <Dialog open={ openDocumentoAcuerdo } onClose={ () => {} } fullWidth={ true } maxWidth="md" fullScreen={ fullScreen } >

                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title"> </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setFullScreen( (prev) => !prev ) } }
                    sx={{
                        position: 'absolute',
                        right: 55,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    { fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon /> } 
                </IconButton>

                <IconButton
                    aria-label="close"
                    onClick={ () => { setOpenDocumentoAcuerdo( false ); setDocumentoAcuerdo( {} ); } }
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                    <DialogContent>
                    
                    <Grid container spacing={3} height={'100%'} >   
                    
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>

                            {
                                    documentoAcuerdo
                                ?

                                    <iframe title='cedula' src={ documentoAcuerdo.documentoAcuerdoFirmado } height={ fullScreen ? "100%" : '500px' } width="100%"></iframe>
                                    
                                :
                                <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                    No se encontró el documento
                                </Typography>
                            }

                        </Grid>
                        
                    </Grid>
                    
                    </DialogContent>

                    <DialogActions>

                        <Button color="primary" onClick={ () => { setOpenDocumentoAcuerdo( false ); setDocumentoAcuerdo( {} ); } }>
                            Cerrar
                        </Button>

                    </DialogActions>                

            </Dialog>           

        </>
    )
}
