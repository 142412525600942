import React, { useEffect, useState } from 'react';

import { Avatar, Box, Button, Card, CardActions, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BookIcon from '@mui/icons-material/Book';

import moment from 'moment';

import AppBarMenu from '../ui/AppBarMenu';

import { AudienciasExpediente, ObtenerExpedienteVirtual_Expediente, ObtenerMisExpedientes } from '../../actions/recepcion/expedienteActions';

export const MisExpedientesScreen = () => {

    const [loadingMisExpedientes, setLoadingMisExpedientes] = useState( true );
    const [misExpedientes, setMisExpedientes] = useState( [] );

    const [loading, setLoading] = useState( false );
    const [expediente, setExpediente] = useState( false );
    const [openDocumentos, setOpenDocumentos] = useState( false );

    const [openDocumento, setOpenDocumento] = useState( false );
    const [documento, setDocumento] = useState( {} );

    const [loadingAudiencias, setLoadingAudiencias] = useState( false );
    const [audiencias, setAudiencias] = useState( [] );
    const [openAudiencias, setOpenAudiencias] = useState( false );

    const handleObtenerAudiencias = async ( id ) => {

        setOpenAudiencias( true );
        setLoadingAudiencias( true );                            

        await AudienciasExpediente( id ).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        setTimeout(() => {
                    
                            setLoadingAudiencias( false );                            
                            setAudiencias( response.data );
                            setOpenAudiencias( true );

                        }, 500);                        

                    }

                }
                else{

                    setTimeout(() => {
                    
                        setLoadingAudiencias( false );  

                    }, 500);     

                }

            }

        });       

    } 

    const handleBuscarExpediente = async ( numeroReferencia, autoridad ) => {

        setOpenDocumentos( true );
        setLoading( true );

        await ObtenerExpedienteVirtual_Expediente({ numeroReferencia, IdAutoridad: autoridad }).then( response =>{

            if( response ){

                if( response.status === 200 ){

                    const { success } = response.data;

                    if( success ){

                        setTimeout(() => {     

                            setExpediente( response.data );
                            setLoading( false );

                        }, 500);                        

                    }
                    else{

                        setTimeout(() => {    

                            setLoading( false );

                        }, 500);  

                    }

                }

            }

        });       

    } 

    useEffect(() => {
        
        async function Obtener(){

            setLoadingMisExpedientes( true );
            
            await ObtenerMisExpedientes().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setTimeout(() => {
                            
                            setLoadingMisExpedientes( false );
                            setMisExpedientes( response.data );

                        }, 1000);                        

                    }
                    else{
                        setTimeout(() => {
                            
                            setLoadingMisExpedientes( false );

                        }, 600);  
                    }

                }
                else{
                    setTimeout(() => {
                        
                        setLoadingMisExpedientes( false );

                    }, 600);  
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>
            <AppBarMenu />

            <Box sx={{ mt: 5, minHeight: '100%', py: 3 }} >

                <Container maxWidth="lg">     

                    {
                        loadingMisExpedientes
                        ?
                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                <CircularProgress size={50} sx={{ mt: 15, mb: 2 }} />
                            </Box>
                        : 
                            <Grid sx={{ mt: 5 }} container spacing={3} > 
                            
                                {
                                    misExpedientes.map( ( { idExpediente, numeroExpediente, actor, demandado, tipoJuicio, audiencias, idAutoridad } ) => (

                                        <Grid key={ idExpediente } item xs={12} sm={6} md={4} >
                                    
                                            <Card sx={{ p: 2 }}>

                                                <CardContent sx={{ textAlign: 'center' }}>

                                                    <Typography variant="h4" component="div">
                                                        <strong>EXP. { numeroExpediente }</strong>
                                                    </Typography>

                                                    <Typography variant="h5" sx={{ mt: 2, fontSize: 18 }}>
                                                        { actor?.toUpperCase() }
                                                    </Typography>

                                                    <Typography variant="h5" sx={{ mt: 2, fontSize: 20 }}>
                                                        <strong>vs</strong> 
                                                    </Typography>

                                                    <Typography variant="h5" sx={{ mt: 2, fontSize: 18 }}>
                                                    { demandado?.toUpperCase() }
                                                    </Typography>

                                                    <Typography variant="h5" sx={{ mt: 2, fontSize: 18 }}>
                                                        <strong>{ tipoJuicio }</strong>
                                                    </Typography>

                                                </CardContent>

                                                <CardActions>

                                                    <Button                                                
                                                        variant='contained'
                                                        startIcon={ <CalendarTodayIcon /> }
                                                        size='small'
                                                        disabled={ !audiencias }
                                                        onClick={ () => handleObtenerAudiencias( idExpediente ) }
                                                    >
                                                        Ver audiencias
                                                    </Button>

                                                    <Button 
                                                        variant='contained'
                                                        startIcon={ <FolderIcon /> }
                                                        size='small'
                                                        onClick={ () => handleBuscarExpediente( numeroExpediente, idAutoridad ) }
                                                    >
                                                        Ver documentos
                                                    </Button>                                        

                                                </CardActions>

                                            </Card>

                                        </Grid>                                            

                                    ))
                                }

                            </Grid>
                    }                      

                    <Grid sx={{ marginTop: 2, textAlign: 'center' }} container spacing={3} >
                        {
                            ( misExpedientes.length === 0 && !loadingMisExpedientes )
                            && 
                            (
                                <Grid item md={12} >
                                    <Typography variant="h5" sx={{ mt: 1, mb: 1, color: '#999595' }}>
                                        No se encontraron expedientes asignados
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>

                </Container>

            </Box>

            <Dialog open={ openAudiencias } onClose={ () => {} } fullWidth={ true } maxWidth="sm" >
               
               <DialogTitle sx={{ fontSize: 24 }}>
                   Audiencias
               </DialogTitle>

               <DialogContent>                
                 
                    {
                        loadingAudiencias
                        ?
                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                            </Box>
                        :
                            <Grid container spacing={3} >   
                                <Grid item xs={12} sx={{ height: 400, maxWidth: 360 }} >

                                    <List disablePadding>
                                        {
                                            audiencias?.map( ( elem, i ) => (  

                                                <div key={ i } >

                                                    <ListItem secondaryAction={ <Typography color="text.secondary" variant='subtitle2' sx={{ fontSize: 14 }} > { elem.fecha ? moment( elem.fecha ).format('DD-MM-YYYY HH:mm a') : '' } </Typography> } >
                                                        
                                                        <ListItemText primary={ <Typography color="text.primary" variant='subtitle1' sx={{ fontSize: 16, fontWeight: 'bold' }} >{ elem.tipoAudiencia }</Typography> }
                                                            secondary={ <Typography color="text.secondary" variant='subtitle2' sx={{ fontSize: 14 }} >{ elem.estatus }</Typography> }
                                                        />
                                                    </ListItem>

                                                    <Divider />

                                                </div>        

                                            ))
                                        }  
                                    </List>

                                </Grid>
                                
                            </Grid>
                    }                 
                 
               </DialogContent>

               <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenAudiencias( false ); setAudiencias( [] ); } }>
                        Cerrar
                    </Button>

               </DialogActions>                

            </Dialog>    

            <Dialog open={ openDocumentos } onClose={ () => {} } fullWidth={ true } maxWidth="sm" >
               
               <DialogTitle sx={{ fontSize: 24 }}>
                   Documentos&nbsp;
                   { expediente?.acuerdos ? '(' + expediente?.acuerdos?.length + ')' : '' }        
               </DialogTitle>

               <DialogContent>  

                    {
                        loading
                        ?
                            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                <CircularProgress size={35} sx={{ mt: 2, mb: 2 }} />
                            </Box>
                        :
                            <Grid container spacing={3} >   
                        
                                <Grid item xs={12} sx={{ height: 400, maxWidth: 360 }} >

                                    {
                                        expediente?.success 
                                        &&
                                            <List sx={{ width: '100%', }}>                                        
                                                {                                         
                                                    expediente?.acuerdos?.map( ( doc, i ) => (
                                                        <Box key={ i }>

                                                            <Button 
                                                                fullWidth 
                                                                sx={{ textTransform: 'none', textAlign: 'left', color: 'inherit' }}
                                                                onClick={ () => { setDocumento( doc ); setOpenDocumento( true ); } }
                                                            >

                                                                <ListItem>

                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <BookIcon color='primary' />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                                                                        
                                                                    <ListItemText 
                                                                        primary={ <Typography variant="subtitle2"> { doc.tipoAcuerdo } </Typography>} 
                                                                        secondary={ doc.fecha ? moment( doc.fecha ).format('DD-MM-YYYY HH:mm a') : '' }
                                                                    />   

                                                                </ListItem>

                                                            </Button> 

                                                            <Divider />

                                                        </Box>
                                                    ))
                                                }
                                            </List>                                            
                                    }

                                </Grid>

                            </Grid>
                    }               
                 
               </DialogContent>

               <DialogActions>

                    <Button color="primary" onClick={ () => { setOpenDocumentos( false ); setExpediente( {} ); } }>
                        Cerrar
                    </Button>

               </DialogActions>                

            </Dialog>    

            <Dialog open={ openDocumento } onClose={ () => {} } fullWidth={ true } maxWidth="md" >
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                       <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
 
                           {
                               documento
                               ?
                                    documento.firmado
                                    ?
                                        <iframe title='caratula' src={ documento.documento } height="500px" width="100%"></iframe>
                                    :
                                    <iframe title='caratula' src={ "data:application/pdf;base64," + documento.documento } height="500px" width="100%"></iframe>
                               :
                               <Typography variant='subtitle2' sx={{ fontSize: 18, fontWeight: 'bold', mt: 10, mb: 10 }}>
                                   No se encontró el documento
                               </Typography>
                           }

                       </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenDocumento( false ); setDocumento( {} ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog>  

        </>
    )
}
